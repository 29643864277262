// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-gallery .grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  height: 100%;
  padding: 20px;
  background: url('fondgalerie.jpg') no-repeat center center fixed;
  background-size: cover;
}
app-gallery .grid .item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  min-width: 280px;
}
app-gallery .ps__rail-y {
  opacity: 1;
}
app-gallery .ps__rail-y .ps__thumb-y {
  background-color: #24B7C7;
}
app-gallery .ps__rail-y:hover .ps__thumb-y {
  width: 16px;
  background-color: #24B7C7;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/gamification/core/gallery/gallery.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/gamification/core/gallery/gallery.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,qCAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,gEAAA;EACA,sBAAA;ACAR;ADEQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;ACAZ;ADII;EACI,UAAA;ACFR;ADIQ;EACI,yBAAA;ACFZ;ADMY;EACI,WAAA;EACA,yBAAA;ACJhB","sourcesContent":["app-gallery {\n    .grid {\n        display: grid;\n        grid-template-columns: repeat(4, 1fr);\n        grid-gap: 10px;\n        height: 100%;\n        padding: 20px;\n        background: url('../../assets/fondgalerie.jpg') no-repeat center center fixed;\n        background-size: cover;\n\n        .item {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            min-height: 280px;\n            min-width: 280px;\n        }\n    }\n\n    .ps__rail-y {\n        opacity: 1;\n\n        .ps__thumb-y {\n            background-color: #24B7C7;\n        }\n\n        &:hover {\n            .ps__thumb-y {\n                width: 16px;\n                background-color: #24B7C7;\n            }\n        }\n    }\n}","app-gallery .grid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 10px;\n  height: 100%;\n  padding: 20px;\n  background: url(\"../../assets/fondgalerie.jpg\") no-repeat center center fixed;\n  background-size: cover;\n}\napp-gallery .grid .item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 280px;\n  min-width: 280px;\n}\napp-gallery .ps__rail-y {\n  opacity: 1;\n}\napp-gallery .ps__rail-y .ps__thumb-y {\n  background-color: #24B7C7;\n}\napp-gallery .ps__rail-y:hover .ps__thumb-y {\n  width: 16px;\n  background-color: #24B7C7;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
