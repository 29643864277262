import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {BaseActivityComponent} from '../base-activity.component';
import {combineLatest, Observable, of} from 'rxjs';
import {Params, Router} from '@angular/router';
import * as _ from 'lodash-es';
import {ActivityGranule} from '@modules/activities/core/models';
import {MultiActivityGranule} from '@modules/activities/core/models/activities/typologies/multi-activity.granule';

@Component({
    selector: 'app-multi',
    templateUrl: './multi.component.html'
})
export class MultiComponent extends BaseActivityComponent<MultiActivityGranule> implements OnInit, OnDestroy {
    private subLessonId: string; // id question set
    public hideContent = false; // used to hide content when some modal are opened to not showing in background next exo

    private router = inject(Router)

// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit(): void {
        if (!this.lessonsService.currentAssignment && !this.lessonsService.isAtLeastTrainer()) {
            this.communicationCenter
                .getRoom('activities')
                .next('backToLesson', {
                    forceUrl: this.lessonsConfigurationService.settings.forceUrlByDefaultWhenBackToLessonList ? [] : null,
                    initialiseSubject: true
                });
        } else {
            combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
                .pipe(
                    take(1)
                ).subscribe(data => {
                this.subLessonId = data[0]['subLessonId'];
                const isSummary = data[1] && data[1]?.navigateDirectlyToSummary === 'true';
                this.initialize(isSummary);
            });

            this.communicationCenter
                .getRoom('activities')
                .getSubject('previous')
                .pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    filter((doNext: boolean) => !!doNext),
                    tap(() => this.launchPreviousActivity())
                )
                .subscribe();

            this.communicationCenter
                .getRoom('activities')
                .getSubject('next')
                .pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    filter((doNext: boolean) => !!doNext),
                    tap(() => this.launchNextActivity())
                )
                .subscribe();


            this.communicationCenter
                .getRoom('activities')
                .getSubject('gotoSelectedActivity')
                .pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    filter((activity: {id: string, title: string, type: string}) => !!activity),
                    tap((activity) => this.goToSelectedActivity(activity))
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('activities')
            .next('backToLesson', {
                forceUrl: this.lessonsConfigurationService.settings.forceUrlByDefaultWhenBackToLessonList ? [] : null,
                initialiseSubject: false
            });
        /**
         * le communication center utilise des replays subject, donc la dernière valeur émise est sauvé et nexté pour la prochaine souscription.
         * Ainsi, on a besoin de reinitialisé la donnée pour éviter un effet de bord au lancement d'un nouveau parcours
         */
        this.communicationCenter.getRoom('activities')
            .getSubject('next')
            .next(false);
        super.ngOnDestroy();
    }

    protected initialize(isSummaryDirectly = false): void {
        this.reset(false, null, isSummaryDirectly);
        const activities: ActivityGranule[] = [];
        this.lessonsService.getLessonObs(this.subLessonId).pipe(
            take(1),
            mergeMap(subLesson => {
                const activities$ = subLesson.get('reference').map(activity => this.activitiesService.loadActivitiesFromId(activity.id).pipe(take(1)));
                return combineLatest(activities$).pipe(
                    take(1),
                    tap(activitiesEntities => {
                        activitiesEntities.forEach((activity) => {
                            if (this.activitiesService.typeActivitiesToSkip(activity)) {
                                // activity.attributes.metadatas.title = subLesson.get('metadatas').title;
                                activities.push(activity);
                            }
                        });
                    })
                );
            })).subscribe(() => {
            this.communicationCenter
                .getRoom('progress-bar-exo')
                .getSubject('numberOfQuestion')
                .next(activities.length);
            this.lessonsService.editSubLessonContentEdited = activities;
            if (this.activatedRoute.snapshot?.queryParams?.navigateDirectlyToSummary) {
                this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
                this.navigateToRecapOrReward(this.subLessonId, this.activatedRoute.snapshot?.queryParams);
            } else {
                this.playFirstActivity();
            }
            this.communicationCenter.getRoom('header-exo').getSubject('show').next(true);
        });
    }

    protected reset(resetAllSubscribe = false, type = null, isSummaryDirectly = false): Observable<boolean> {
        if (!isSummaryDirectly) {
            this.communicationCenter.getRoom('progress-bar-exo').getSubject('reset').next(true);
        }
        if (this.lessonsService.currentAssignment) {
            this.lessonsService.initializeProgressInSubLesson();
        }
        this.lessonsService.editSubLessonContentEdited = [];
        this.lessonsService.editCurrentActivityInSubLesson = null;
        return super.reset(resetAllSubscribe, type);
    }

    private goToSelectedActivity(stepItemActivity: {id: string, title: string, type: string}): void {
        if (this.lessonsService.subLessonContentEdited.length) {
            const currentActivityIndex = this.lessonsService.subLessonContentEdited.findIndex((act: DataEntity) => +act.id === +stepItemActivity.id) || 0;
            this.activitiesService.currentActivityIndex = currentActivityIndex;
            if (+this.indexInSublesson !== +currentActivityIndex) {
                this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
                this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[currentActivityIndex],
                    false,
                    '../..',
                    null, this.activatedRoute.snapshot?.queryParams);
            }
        }
    }

    public canLaunchPrevious(): boolean {
        return this.indexInSublesson > 0;
    }

    public canLaunchNext(): boolean {
        return this.indexInSublesson < this.lessonsService.subLessonContentEdited.length - 1;
    }

    public launchPreviousActivity(): void {
        if (this.canLaunchPrevious() === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex--;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[this.indexInSublesson], false, '../..');
    }

    public launchNextActivity(): void {
        if (this.canLaunchNext() === false) {
            if (this.lessonsService.currentLesson && this.lessonsConfigurationService.settings.useSummaryPageInsteadOfRecap) {
                const score = this.lessonsService.currentAssignment ? this.lessonsService.calculateAndSaveQuestionSetProgress(this.subLessonId) : 0;
                this.activitiesService.playScreenStatus = 0;
                    this.navigateToRecapOrReward(this.subLessonId, this.activatedRoute.snapshot?.queryParams);
                    if (this.lessonsConfigurationService.settings.activitiesBroadcastLifeCycle) {
                        const statementData = {
                            id: `questionSet/${this.subLessonId}`,
                            result: {
                                score: {
                                    scaled: score / 100,
                                },
                                completion: true
                            },
                            assignmentId: this.lessonsService.currentAssignment?.id || null
                        };

                        this.communicationCenter
                            .getRoom('lrs')
                            .getSubject('activity_complete')
                            .next(statementData);
                    }
            } else {
                this.activitiesService.loadNextActivity();
            }
        } else {
            let currentActivityIndex = +this.indexInSublesson;
            currentActivityIndex++;
            this.activitiesService.currentActivityIndex = currentActivityIndex;
            this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
            this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[currentActivityIndex],
                false,
                '../..',
                null,
                this.activatedRoute.snapshot?.queryParams);
        }
    }

    /**
     * navigate to recap or to reward (reward page was done for ubolino and is launch at end af each question set)
     * @private
     */
    private navigateToRecapOrReward(subLessonId, queryParams: Params = {}): void {
        const params = _.merge({},{subLessonId}, queryParams);
        this.activitiesService.preparePlayerToTheEndOfTheLesson();
        const urlPrefix = this.lessonsService.currentAssignment ? '/followed/assignment' : null;
        if (this.lessonsConfigurationService.settings.useSummaryPageInsteadOfRecap === true) {
            // hide progress bar
            this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
            if (this.lessonsConfigurationService.settings.showRewardPageAfterSubLessonEnded && !queryParams.navigateDirectlyToSummary) {
                this.lessonNavigationService.navigateToReward(this.lessonsService.currentLesson.id, urlPrefix, params);
            } else {
                this.lessonNavigationService.navigateToSummary(this.lessonsService.currentLesson.id, urlPrefix, params);
            }
        } else {
            this.lessonNavigationService.navigateToRecap(this.activitiesService.currentLesson.id, urlPrefix);
        }
    }

    public displayUserActionButton(userActionButtonName: string): boolean {
        if (userActionButtonName === 'hideSideNavigation' && this.router.url.includes('embedded')) {
            return true;
        }
        if (this.lessonsConfigurationService.settings.actionButtonsInMultiMatrix[this.getCurrentActivityType()]) {
            return this.lessonsConfigurationService.settings.actionButtonsInMultiMatrix[this.getCurrentActivityType()].includes(userActionButtonName);
        }
        return this.lessonsConfigurationService.settings.actionButtonsInMultiMatrix['default'].includes(userActionButtonName);
    }

    public get indexInSublesson(): number {
        if (this.lessonsService.currentActivityInSubLesson) {
            return this.lessonsService.subLessonContentEdited
                .findIndex((activity) => +activity.id === +this.lessonsService.currentActivityInSubLesson.id);
        }
        return 0;
    }

    public getCurrentActivityType(): TypologyLabel | '' {
        return this.indexInSublesson !== null && this.lessonsService.subLessonContentEdited[this.indexInSublesson] ?
            this.lessonsService.subLessonContentEdited[this.indexInSublesson].attributes.metadatas.typology.label : '';
    }

    private playFirstActivity(): void {
        const currentActivityIndex = +this.indexInSublesson || 0;
        this.activitiesService.currentActivityIndex = currentActivityIndex;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        const params: Params = _.merge({}, this.activatedRoute.snapshot.queryParams, {startOnStepIndex: 0});
        this.lessonNavigationService.navigateToAnActivityInsideALessonActuallyPlayed(this.lessonsService.subLessonContentEdited[currentActivityIndex], true, '.', null, params);
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(attributes): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }

    public getHideUserActionButtonsWhenDisabled() {
        return this.lessonsConfigurationService.settings.hideUserActionButtonsWhenDisabled;
    }
}
