
import {takeUntil, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {MatTableDataSource} from '@angular/material/table';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-resource-upload-modal-step4',
    templateUrl: './resource-upload-modal-step4.component.html',
    styleUrls: ['./resource-upload-modal-step4.component.scss']
})
export class ResourceUploadModalStep4Component implements OnInit, OnDestroy {

    @Input() mode = 'creation';
    @Input() resource: CorpusRessource;

    @Output() canceled = new EventEmitter<void>();
    @Output() conceptsValidated = new EventEmitter<any>();

    public dataSource = new MatTableDataSource([]);
    public displayedColumns = ['label'];
    public titleFilter: UntypedFormControl;
    public concepts = [];
    public selectedConcepts = [];
    public isfrozen = false;

    private unsubscribeInTakeUntil = new Subject<void>();

    constructor(
        public corpusService: CorpusService,
        public dialogRef: MatDialogRef<ResourceUploadModalStep4Component>,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }


    ngOnInit(): void {
        this.isfrozen = this.mode === 'edition';

        this.titleFilter = new UntypedFormControl('');
        this.titleFilter.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(query => {
                if (query.trim()) {
                    this.dataSource.data = this.filter(query);
                } else {
                    this.dataSource.data = this.selectedConcepts;
                }
                this.changeDetectorRef.detectChanges();
            });

        this.corpusService.getConcepts().pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(data => {
            this.concepts =  data.entities.map(entity => {
                return {
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                };
            });

            if (this.mode === 'edition') {
                this.selectedConcepts = this.concepts.filter(tag => this.resource.concepts.map(concept => concept.id).includes(tag.id)).slice();
                this.dataSource.data = this.selectedConcepts.slice();
                this.isfrozen = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public validateStep(): void {
        if (this.mode === 'edition') {
            this.isfrozen = true;
            this.resource.concepts = this.selectedConcepts;
            this.resource.save().subscribe(() => {
                this.conceptsValidated.emit(this.selectedConcepts);
                this.isfrozen = false;
            });
        } else {
            this.conceptsValidated.emit(this.selectedConcepts);
        }
    }

    private filter(name: string): Array<string> {
        return this.concepts.filter(chip =>
            chip.name.toLowerCase().indexOf(name.trim().toLowerCase()) > -1);
    }
}