import {AccountManagementServicesInstantiatorService} from '@modules/account-management';
import {ActivitiesServicesInstantiatorService} from '@modules/activities';
import {AssignmentServicesInstantiatorService} from '@modules/assignation';
import {AuthenticationServicesInstantiatorService} from '@modules/authentication';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {BdService} from '@modules/bdtool';
import {ConfigurationService} from '@modules/configuration';
import {CorpusServicesInstantiatorService} from '@modules/corpus';
import {FormDialogService} from 'fuse-core/components/form-dialog/form-dialog.service';
import {GamecodeServicesInstantiatorService} from '@modules/gamecode';
import {GlobalAuthorizationService} from '../../../../src/app/global-authorization.service';
import {GroupsManagementServicesInstantiatorService} from '@modules/groups-management';
import {Injectable} from '@angular/core';
import {LicensingService} from '@modules/licensing';
import {LrsService} from '@modules/lrs';
import {MindmapServicesInstantiatorService} from '@modules/mindmap';
import {NewsService} from 'fuse-core/news/news.service';
import {NotepadServicesInstantiatorService} from '@modules/notepad';
import {SnackbarNewsService} from 'fuse-core/news/snackbar/snackbar-news.service';
import {SvgIconList} from '../../../../src/app/themes/isc_lycee/svgIconList';
import {TimelineService} from '@modules/timeline';

@Injectable()
export class ServiceInstantiator {

    constructor(
        private _accountManagementServices: AccountManagementServicesInstantiatorService,
        private _activitiesServices: ActivitiesServicesInstantiatorService,
        private _assignmentServices: AssignmentServicesInstantiatorService,
        private _authenticationServices: AuthenticationServicesInstantiatorService,
        private _basicPageService: BasicPageService,
        private _bdService: BdService,
        private _configurationService: ConfigurationService,
        private _corpusServices: CorpusServicesInstantiatorService,
        private _formDialogService: FormDialogService,
        private _gamecodeServices: GamecodeServicesInstantiatorService,
        private _globalAuthorizationService: GlobalAuthorizationService,
        private _groupsManagementServices: GroupsManagementServicesInstantiatorService,
        private _licensingService: LicensingService,
        private _lrsService: LrsService,
        private _mindmapServices: MindmapServicesInstantiatorService,
        private _newsService: NewsService,
        private _notepadServices: NotepadServicesInstantiatorService,
        private _snackbarNewsService: SnackbarNewsService,
        private _svgIconList: SvgIconList,
        private _timelineService: TimelineService,
    ) {
    }

}
