import {Component, Inject, ElementRef, ViewChild, AfterViewInit, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {EventService} from 'shared/event.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {LRSTracking} from '../../../app/settings';

declare var window: any;
@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent implements AfterViewInit {
    public titleDialog: string;
    public bodyDialog: any;
    public labelConfirmDialog: string;
    public labelConfirmDialogPlaceHolder: string;
    public labelTrueDialog: string;
    public labelFalseDialog: string;
    public labelOtherDialog: string; // other button for return info to parent that we have click on it
    public confirmMessage: string;
    public showReportbutton: boolean;
    public resourceId: string;
    public inputValue: string;
    private isAutoFocus?: boolean = false; // is pass to true if in data autofocus field exist
    private inputErrorLengthMessage = '';
    public originalInputValue = '';
    private maxInputSize = 100000;
    @ViewChild('inputToFocus') inputToFocus: ElementRef;
    private communicationCenter = inject(CommunicationCenterService);
    private LRSTracking = LRSTracking;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private eventService: EventService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.titleDialog = data.titleDialog;
        this.bodyDialog = this.domSanitizer.bypassSecurityTrustHtml(data.bodyDialog);
        this.labelTrueDialog = data.labelTrueDialog;
        this.labelFalseDialog = data.labelFalseDialog;
        this.labelConfirmDialog = data.labelConfirmDialog;
        this.labelOtherDialog = data.labelOtherDialog;
        this.labelConfirmDialogPlaceHolder = data.labelConfirmDialogPlaceHolder;
        this.showReportbutton = data.showReportbutton;
        this.resourceId = data.resourceId;
        this.inputValue = data.inputValue;
        this.originalInputValue = data.inputValue;
        this.maxInputSize = data.maxInputSize;
        this.inputErrorLengthMessage = data.inputErrorLengthMessage;
        if (data.autofocus) {
            this.isAutoFocus = true;
        }
    }

    /**
     * autofocus on input field if data contain field autofocus by default value is false
     */
    ngAfterViewInit(): void {
        // urlLink
        const urlLink = document.getElementById("urlLink") as HTMLLinkElement;
        if (urlLink) {
            const href = urlLink.href;
            // send custom event
            this.sendDownloadTraces(href);
        }

        // iframeVideo
        const iframeVideo = document.getElementById("iframeVideoPlayer") as HTMLIFrameElement;
        if (iframeVideo) {
            const src = iframeVideo.src;
            // send custom event
            this.sendDownloadTraces(src);
        }

        // document
        const documentLink = document.getElementById("documentLink") as HTMLLinkElement;
        if (documentLink) {
            const href = documentLink.href;
            this.sendDownloadTraces(href);
        }

        // image
        const image = document.getElementById("imagePlayer") as HTMLImageElement;
        if (image) {
            const src = image.src;
            this.sendDownloadTraces(src);
        }

        // audio
        const audio = document.getElementById("audioPlayer") as HTMLAudioElement;
        if (audio) {
            let hasPlayed = false;
            const sources = audio.getElementsByTagName("source");

            if (sources.length > 0) {
                const firstSource = sources[0]; // Récupérer la première balise <source>
                const src = firstSource.src;    // Accéder à l'attribut src de la balise <source>

                audio.addEventListener("play", (event) => {
                    if (!hasPlayed) {
                        // send custom event
                        this.eventService.trackEvent('Audio', 'play', src);

                        // send statement to lrs
                        if (this.LRSTracking) {
                            this.communicationCenter
                                .getRoom('lrs')
                                .getSubject('audio_played')
                                .next({
                                    id: 'audio',
                                    src: src,
                                    title : this.titleDialog
                                });
                        }
                        hasPlayed = true;
                    }
                });

                audio.addEventListener("ended", (event) => {
                    // send custom event
                    this.eventService.trackEvent('Audio', 'ended', src);
                    // send statement to lrs
                    if (this.LRSTracking) {
                        this.communicationCenter
                            .getRoom('lrs')
                            .getSubject('audio_ended')
                            .next({
                                id: 'audio',
                                src: src,
                                title : this.titleDialog
                            });
                    }

                    hasPlayed = false;
                });
            }
        }

        // video
        const video = document.getElementById("videoPlayer") as HTMLVideoElement;
        if (video) {
            let hasPlayed = false;
            const sources = video.getElementsByTagName("source");

            if (sources.length > 0) {
                const firstSource = sources[0]; // Récupérer la première balise <source>
                const src = firstSource.src;    // Accéder à l'attribut src de la balise <source>

                video.addEventListener("play", (event) => {
                    if (!hasPlayed) {
                        // send custom event
                        this.eventService.trackEvent('Video', 'play', src);

                        // send statement to lrs
                        if (this.LRSTracking) {
                            this.communicationCenter
                                .getRoom('lrs')
                                .getSubject('video_played')
                                .next({
                                    id: 'video',
                                    src: src,
                                    title : this.titleDialog
                                });
                        }
                        hasPlayed = true;
                    }
                });

                video.addEventListener("ended", (event) => {
                    // send custom event
                    this.eventService.trackEvent('Video', 'ended', src);
                    // send statement to lrs
                    if (this.LRSTracking) {
                        this.communicationCenter
                            .getRoom('lrs')
                            .getSubject('video_ended')
                            .next({
                                id: 'video',
                                src: src,
                                title : this.titleDialog
                            });
                    }

                    hasPlayed = false;
                });
            }
        }

        if (this.isAutoFocus) {
            setTimeout(() => this.inputToFocus.nativeElement.focus(), 1000);
        }
    }

    // input have a limit of size
    public isLengthInputTooLong(): boolean {
        return this.inputValue ? this.inputValue.length > this.maxInputSize : false;
    }

    private sendCustomEventToMatomo(href: string) {
        if (typeof window !== 'undefined' && window._paq) {
            window._paq.push(['trackLink', href, 'download']);
        }
    }

    private sendStatementToLrs(href: string) {
        if (this.LRSTracking) {
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('media_downloaded')
                .next({
                    id: href.split('.').pop().split(/\#|\?/)[0],
                    src: href,
                    type: href.split('.').pop().split(/\#|\?/)[0],
                    title : this.titleDialog
                });
        }
    }

    private sendDownloadTraces(href: string) {
        // send custom event
        this.sendCustomEventToMatomo(href);
        // send statement to lrs
        this.sendStatementToLrs(href);
    }

}