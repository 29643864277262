import {filter, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {Group, Institution, Learner, Workgroup} from '@modules/groups-management/core/definitions';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';

@Injectable()
export class WorkgroupService {

    public onWorkgroupsChanged: BehaviorSubject<Workgroup[]> = new BehaviorSubject([]);
    public workgroupsList: Workgroup[] = [];
    public archiveMode = false;
    public schoolyear = 'all';
    private userData: DataEntity;
    private learnersList: Learner[] = [];
    private workgroupsCollection: { [key: number]: DataEntity } = {};
    private workgroupSubscription: Subscription = null;
    private institutions: Institution[] = [];


    constructor(
        private groupsManagement: GroupsManagementService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.userData = data;
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('setProjectWorkgroups')
            .subscribe((data) => this.updateProjectInWorkgroups(data));
    }

    public get groups(): Workgroup[] {
        return this.workgroupsList.slice();
    }

    loadWorkgroups(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('groups');
    }

    public filterArchived(archived: boolean): void {
        this.archiveMode = archived;
        if (this.schoolyear !== 'all' && this.schoolyear !== '0') {
            // cumul filter schoolYear and archived
            this.onWorkgroupsChanged.next(this.workgroupsList.filter((group) => group.archived === archived)
                .filter((group) => group.schoolyear_term && group.schoolyear_term['id'] === this.schoolyear));
        } else {
            this.onWorkgroupsChanged.next(this.workgroupsList.filter((group) => group.archived === archived));
        }
    }

    /**
     * filter on school year take care of archived flag
     * @param schoolYearFromFilter
     */
    public filterSchoolYear(schoolYearFromFilter?: string): any {
        this.schoolyear = schoolYearFromFilter && schoolYearFromFilter !== '' ? schoolYearFromFilter : this.groupsManagement.getCurrentSchoolyearTermId();
        if (this.schoolyear === 'all') {
            this.onWorkgroupsChanged.next(this.workgroupsList.filter((group) => group.archived === this.archiveMode));
        } else {
            this.onWorkgroupsChanged.next(this.workgroupsList
                .filter((group) => group.schoolyear_term && group.schoolyear_term['id'] === this.schoolyear)
                .filter((group) => group.archived === this.archiveMode));
        }
    }

    /**
     * Given a list of workgroups, add a project ID to those workgroups and remove project ID from workgroups not listed.
     * @param data Object containing workgroups ID (workgroups) and project ID (project)
     */
    updateProjectInWorkgroups(data): void {
        const projectId = data.project.toString();

        // Remove projectId from groups not listed in data.groups
        this.workgroupsList
            .filter((workgroup: Workgroup) => workgroup.projects.indexOf(projectId) > -1)
            .forEach((workgroup: Workgroup) => {
                if (data.workgroups.indexOf(workgroup.id) === -1) {
                    workgroup.projects.splice(workgroup.projects.indexOf(projectId), 1);
                    this.saveWorkgroup(workgroup);
                }
            });

        // Add projectId to workgroups listed in workgroups
        this.workgroupsList
            .filter((workgroup: Workgroup) => data.workgroups.indexOf(workgroup.id) > -1)
            .forEach((workgroup: Workgroup) => {
                if (workgroup.projects.indexOf(projectId) === -1) {
                    workgroup.projects.push(projectId);
                    this.saveWorkgroup(workgroup);
                }
            });
    }

    addWorkgroup(workgroup): Observable<DataEntity> {
        return this.octopusConnect
            .createEntity('groups', {
                label: workgroup.workgroupname.trim(),
                type: this.groupsManagement.settings.workgroupType.toString(),
                schoolyear_term: (workgroup.schoolyear_term ? workgroup.schoolyear_term : ''),
                archived: false,
                projects: [],
                parents: this.isInstitutionAssociated(workgroup)
            });
    }

    saveWorkgroup(workgroup): Observable<DataEntity> {
        const workgroupEntity = this.workgroupsCollection[workgroup.id];
        const oldGroupName: string = workgroupEntity.get('label');
        workgroupEntity.set('label', workgroup.workgroupname.trim());
        workgroupEntity.set('archived', workgroup.archived);
        workgroupEntity.set('projects', workgroup.projects);
        workgroupEntity.set('schoolyear_term', (workgroup.schoolyear_term ? workgroup.schoolyear_term : ''));
        const obs = workgroupEntity.save();

        obs.pipe(take(1)).subscribe(() => {
            if (workgroup.workgroupname !== oldGroupName) {

                this.communicationCenter
                    .getRoom('notifications')
                    .next('sendNotification', {
                        recipient: this.workgroupsList.find((item => item.id === workgroup.id)).learnersIds,
                        type: 'GROUP_NAME_CHANGED',
                        content: {
                            author: (this.userData ? this.userData.get('label') : ''),
                            oldName: oldGroupName,
                            newName: workgroup.workgroupname
                        }
                    });
            }
        });

        return obs;
    }

    deleteWorkgroup(workgroup): Observable<boolean> {
        if (workgroup.learnersIds.length) {
            this.communicationCenter.getRoom('groups-management')
                .getSubject('learnersChanged')
                .next([workgroup.learnersIds, workgroup, (entity) => this.removeGroup(entity)]);
        } else {
            return this.removeGroup(workgroup);
        }
    }

    removeGroup(workgroup): Observable<boolean> {
        const workgroupEntity = this.workgroupsCollection[workgroup.id];

        if (workgroupEntity) {
            return workgroupEntity.remove();
        }
    }

    getWorkgroupService(): string[] {
        return this.workgroupsList.map((workgroup) => workgroup.workgroupname);
    }

    private postLogout(): void {
        if (this.workgroupSubscription) {
            this.workgroupSubscription.unsubscribe();
            this.workgroupSubscription = null;
        }
    }

    private postAuthentication(): void {
        if (!this.workgroupSubscription) {
            const learnerList$ = this.communicationCenter
                .getRoom('groups-management')
                .getSubject('learnerList');

            const obsLoad = this.loadWorkgroups();

            this.workgroupSubscription = obsLoad.subscribe((data: DataCollection) => {
                this.workgroupsList = [];
                for (const entity of data.entities) {
                    try {
                        // eslint-disable-next-line radix
                        if (entity.get('type') && parseInt(entity.get('type').toString()) === this.groupsManagement.settings.workgroupType) {
                            this.workgroupsList.push({
                                // eslint-disable-next-line radix
                                id: (entity.id ? parseInt(entity.id.toString()) : -1),
                                workgroupname: entity.get('label'),
                                archived: entity.get('archived'),
                                learners: [],
                                learnersIds: [],
                                projects: (entity.get('projects') ? entity.get('projects').slice() : []),
                                schoolyear_term: (entity.get('schoolyear_term') ? entity.get('schoolyear_term') : ''),
                                admins: entity.get('admins'),
                                parent: (entity.get('parents') ? entity.get('parents')[0] : ''),
                                locked: entity.get('locked')
                            });
                        }
                    } catch (e) {
                        // We catch a swallow the error to don't stop the groups list creation, if a group as bad values (like type as "null")
                        // it's throw an error, we write the error in the console, but we continue to compose the listing
                        console.error(e, entity);
                    }
                    this.workgroupsCollection[entity.id] = entity;
                }
                this.communicationCenter
                    .getRoom('groups-management')
                    .next('workgroupsList', this.workgroupsList);

                this.communicationCenter
                    .getRoom('groups-management')
                    .getSubject('institutionListFiltered')
                    .pipe(
                        tap((institutions: Institution[]) => this.institutions = institutions)
                    )
                    .subscribe();

                this.filterArchived(this.archiveMode);
            });

            // TODO - Document split into two subscriptions
            obsLoad.pipe(take(1)).subscribe((data: DataCollection) => {
                learnerList$.pipe(filter(learnersList => !!learnersList)).subscribe((learnersList: Learner[]) => {
                    this.learnersList = learnersList;

                    this.workgroupsList.forEach((workgroup: Workgroup) => {
                        const learners = this.learnersList
                            .filter((learner: Learner) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
                            .map((learner: Learner) => learner.username);

                        workgroup.learners = learners;
                        workgroup.learnersIds = this.learnersList
                            .filter((learner: Learner) => learner.workgroups.indexOf(workgroup.workgroupname) > -1)
                            .map((learner: Learner) => learner.id);
                    });
                });
            });
        }
        if (this.groupsManagement.settings.filterGroupListingsByCurrentYearByDefault) {
            this.filterSchoolYear();
        }
    }

    private isInstitutionAssociated(val: Workgroup): string[] {
        if (val.parent) {
            return [val.parent.id];
        }
        if (this.groupsManagement.isGroupMustBeAssociatedWithUsersInstitution()
            && this.institutions && this.institutions.length) {
            return [this.institutions[0].id];
        }
        else {
            return null;
        }
    }
}
