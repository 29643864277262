import {merge} from 'lodash-es';
import {BaseOctopusConfig} from '../../../../base.octopus-config';
import {defaultApiURL} from '../../../../src/app/settings/isc_lycee';

const overrideConfig = {
    configuration: {
        http: {
            apiUrl: defaultApiURL,
        }
    }
}

export const IscLyceeOctopusConfig = merge({}, BaseOctopusConfig, overrideConfig);