import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {NotepadService} from './notepad.service';
import {NotepadListComponent} from './notepad-list/notepad-list.component';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {NotepadCardComponent} from './notepad-card/notepad-card.component';
import {NotepadDataEditorModalComponent} from './notepad-data-editor-modal/notepad-data-editor-modal.component';
import {NotepadContentEditorComponent} from './notepad-content-editor/notepad-content-editor.component';
import {NotepadGenericAlertModalComponent} from './notepad-generic-alert-modal/notepad-generic-alert-modal.component';
import {NotepadRepositoryService} from './notepad-repository.service';
import { NotepadContentViewerModalComponent } from './notepad-content-viewer-modal/notepad-content-viewer-modal.component';
import {SearchFiltersModule} from 'fuse-core/components';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';

const routes: Routes = [
    {
        path: 'notepad',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: NotepadListComponent,
            },
            {
                path: ':notepadId/edit',
                component: NotepadContentEditorComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCardModule,
        BrowserAnimationsModule,
        FuseSharedModule,
        SharedModule,
        SearchFiltersModule,
        MatChipsModule,
        MatDialogModule,
    ],
    declarations: [
        NotepadDataEditorModalComponent,
        NotepadCardComponent,
        NotepadListComponent,
        NotepadContentEditorComponent,
        NotepadGenericAlertModalComponent,
        NotepadContentViewerModalComponent,
    ],
})
export class NotepadModule {
    private static isMenuSet = false;

    constructor(private dynamicNavigation: DynamicNavigationService,
                private communicationCenter: CommunicationCenterService,
                private authService: AuthenticationService) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<NotepadModule> {
        return {
            ngModule: NotepadModule,
            providers: [
                NotepadService,
                NotepadRepositoryService
            ]
        };
    }
    private postAuthentication(): void {
        if (!NotepadModule.isMenuSet) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',
            });

            this.dynamicNavigation.addChildTo('level0', 'tools', {
                'id': 'notepadList',
                'title': 'notepadList',
                'translate': 'notepad.title',
                'type': 'item',
                'url': '/notepad/list',
                'weight': 1
            });
            NotepadModule.isMenuSet = true;
        }
    }

    private postLogout(): void {
        NotepadModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'notepadList');
    }
}