import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {CommunicationCenterService} from '@modules/communication-center';
import {filter, map, mapTo, mergeMap, take} from 'rxjs/operators';
import {OpenEditorData} from '../models/open-editor-data.interface';
import {combineLatest, from, Observable, of, Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {LessonsService} from '../../services/lessons.service';
import {ModelSchema, Structures} from 'octopus-model';
import {LessonEditionSettingsInterface} from '../models/lesson-edition-settings.interface';
import {modulesSettings} from '../../../../../../settings';
import {ActivitiesService} from '../../../activities.service';
import {GenericPluginsService, PluginType} from '../../../services/generic-plugins.service';
import {
    ToolEditorComponent,
    ToolEditorDataInterface
} from '../../../editor-components/tool-editor/tool-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {EditableActivity} from '../models/editable-activity.class';
import {EditableToolActivity} from '../models/editable-tool-activity.class';
import {EditableLesson} from '../models/editable-lesson.class';
import {EditableMultimediaActivity} from '../models/editable-multimedia-activity.class';
import {MultimediaPage} from '../models/multimedia-page.class';
import {ActivityCreationService} from "@modules/activities/core/activity-creation.service";

// Warn don't forget to update LessonEditionSettingsInterface if you change this setting
const settingsStructure: ModelSchema = new ModelSchema({
    activateNewEditor: Structures.boolean(false)
});

@Injectable({
    providedIn: 'root'
})
export class LessonEditionService {

    private settings: LessonEditionSettingsInterface;

    /**
     * If true, the current instance can open the new editor, the url of the old or new editor are different.
     */
    get canNewLaunchEditor(): boolean {
        return this.settings.activateNewEditor;
    }

    private activitiesSvc = inject(ActivitiesService);
    private activityCreationSvc = inject(ActivityCreationService);
    private communicationCenter = inject(CommunicationCenterService);
    private dialog = inject(MatDialog);
    private genericPluginsSvc = inject(GenericPluginsService);
    private lessonsSvc = inject(LessonsService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private router = inject(Router);

    constructor() {
        this.settings = <LessonEditionSettingsInterface>settingsStructure.filterModel(modulesSettings.activities);
        this.listenCommunicationCenterToOpenEditor().subscribe();
    }

    /**
     * Listen the communication center to open the editor from any module
     * @private
     */
    private listenCommunicationCenterToOpenEditor(): Observable<boolean> {
        return this.communicationCenter.getRoom('lessons').getSubject('openEditor').pipe(
            mergeMap((lessonToEditData: number | string | OpenEditorData) => {
                /** the data from communication center could be the id of the lesson or a complex object (@link OpenEditorData)  */
                if (['number', 'string'].includes(typeof lessonToEditData)) {
                    return this.openEditor(<number | string>lessonToEditData);
                } else {
                    return this.openEditor((<OpenEditorData>lessonToEditData).id, (<OpenEditorData>lessonToEditData).extendedPath, (<OpenEditorData>lessonToEditData).exitUrl);
                }
            })
        );
    }

    /**
     * Open the editor from any location
     * @param id Unique identifier of the lesson granule to edit
     * @param extendedPath Some path to add to the route, used to support the old editor
     */
    public openEditor(id: string | number, extendedPath: string[] = [], exitUrl?): Observable<boolean> {
        // For now, there is an old and a new editor, a setting say which one we could use
        if (this.canNewLaunchEditor) {
            return from(this.router.navigate(['lessons', id.toString(), 'editor'], {state: {url: exitUrl}}));
        } else {
            return this.openOldEditor(id, extendedPath, exitUrl);
        }
    }

    /**
     * Open the old editor, we need to keep it and maintain it
     * @param id Unique identifier of the lesson granule to edit
     * @param extendedPath Some path to add to the route
     * @private
     */
    private openOldEditor(id: string | number, extendedPath: string[] = [], exitUrl?): Observable<boolean> {
        const obs = this.lessonsSvc.getLesson(id.toString()) === null
            ? this.lessonsSvc.getLessonObs(id.toString())
            : from([this.lessonsSvc.getLesson(id.toString())]);

        return obs.pipe(
            mergeMap((lesson: DataEntity) => {
                if (this.lessonsConfigurationService.settings.lessonStep) {
                    this.lessonsSvc.setCurrentLessonId = id;
                    return this.router.navigate(['lessons', id, 'edit', 'stepto'], {
                        queryParams: {lessonId: id, activityId: lesson.get('reference')[0].id, stepIndex: 0},
                        state: {url: exitUrl}
                    });
                } else {
                    return this.router.navigate(['lessons', id, 'edit', ...extendedPath], {state: {url: exitUrl}});
                }
            })
        );
    }

    /**
     * load pre filtered activities and show them in modal // TODO pourquoi on a cette méthode qui fait rien ?
     * @returns {any}
     */
    public showActivities(typologiesIds: number[] = [], useLessonSearchEndpoint?: boolean, options?: Partial<{
        initialValues: { [key: string]: any },
        editableLesson: EditableLesson;
        keepLastFilters: Subject<{ [key: string]: any }>;
    }>): Observable<DataEntity> {
        return this.activitiesSvc.showSelectActivitiesModal(typologiesIds, useLessonSearchEndpoint, options);
    }

    /**
     * allows you to retrieve the types of activities that you can select to add to the lesson
     * @returns {string[]}
     */
    public getTypesOfActivities(): string[] {
        return this.lessonsConfigurationService.settings.activitiesTypesUserCanUse;
    }

    public getContentTypeIcon(type): object {
        return this.activitiesSvc.getIconInformation(type);
    }

    /**
     * Open the tool modal editor, receive data about it, use it to create a tool activity granule and return it
     */
    public getNewToolActivity(): Observable<EditableToolActivity> {
        const getTools = () => this.genericPluginsSvc.getPluginsByType(PluginType.lessonTool);

        return this.dialog.open(ToolEditorComponent, {data: {getTools}}).beforeClosed().pipe(
            filter((defaultValues: ToolEditorDataInterface) => !!defaultValues),
            map((defaultValues: ToolEditorDataInterface) => {
                return new EditableToolActivity({
                    toolType: defaultValues.tool.toolIdentifier,
                    title: defaultValues.title,
                    instruction: defaultValues.instruction,
                    granuleType: TypologyLabel.tool,
                    type: 'DefaultValues'
                });
            })
        );
    }

    public editToolActivity(editableActivity: EditableToolActivity): Observable<EditableActivity> {
        const getTools = () => this.genericPluginsSvc.getPluginsByType(PluginType.lessonTool);
        const tool = getTools().find(t => t.toolIdentifier === editableActivity.toolType);
        return this.dialog.open(ToolEditorComponent, {data: {getTools, tool, title: editableActivity.title, instruction: editableActivity.instruction}}).beforeClosed().pipe(
            filter((defaultValues: ToolEditorDataInterface) => !!defaultValues),
            map((defaultValues: ToolEditorDataInterface) => {
                editableActivity.instruction = defaultValues.instruction;
                editableActivity.title = defaultValues.title;
                editableActivity.toolType = defaultValues.tool.toolIdentifier;
                return editableActivity;
            })
        );
    }

    /**
     * update entity lesson reference and save editable lesson with all activities in it.
     * @param {EditableLesson} editableLesson
     * @returns {Observable<{lesson: DataEntity; activities: DataEntity[]}>}
     */
    public saveEditableLesson(editableLesson: EditableLesson): Observable<{ lesson: DataEntity, activities: DataEntity[] }> {
        if (editableLesson.activities.length) {
            if (this.lessonsConfigurationService.settings.saveLessonContentOptions.saveContent) {
                return combineLatest(editableLesson.activities.map(editableActivity => this.saveGranuleActivity(editableActivity))).pipe(
                    mergeMap((activities) => {
                            if (editableLesson.granuleLessonEntity.get('reference') && editableLesson.granuleLessonEntity.get('reference').length) {
                                return combineLatest(editableLesson.granuleLessonEntity.get('reference')
                                    .map((act: { id: string, label: string }) => this.activitiesSvc.removeUnusedActivities(act, activities)))
                                    .pipe(mapTo(activities));
                            } else {
                                return of(activities);
                            }
                        }
                    ),
                    mergeMap((activities: DataEntity[]) => {
                        return this.saveEditableLessonContent(editableLesson, activities);
                    })
                );
            } else {
                return this.saveEditableLessonContent(editableLesson, editableLesson.activities.map((act: EditableActivity) => act.granuleActivityEntity));
            }
        } else {
            // editableLesson.activities is empty => [] no combineLatest needed;
            return this.saveEditableLessonContent(editableLesson, []);
        }
    }

    /**
     * edit lesson reference with activities child and save granule sublesson(parent)
     * @param editableLesson
     * @param activities
     * @returns {Observable<{lesson: DataEntity; activities: DataEntity[]}>}
     */
    public saveEditableLessonContent(editableLesson, activities): Observable<{ lesson: DataEntity, activities: DataEntity[] }> {
        const lessonId: string | number = editableLesson.granuleLessonEntity.get('lesson_id');
        return this.lessonsSvc.saveLessonReference(lessonId, activities.filter((act: DataEntity) => !!act)).pipe(
            mergeMap((lessonReference: DataEntity) => {
                return this.lessonsSvc.saveLessonGranule(editableLesson.granuleLessonEntity);
            }),
            mergeMap((granuleLesson: DataEntity) => {
                // TODO: lesson's player keep activities in cache and we need to change remove that cache if we edit activities
                this.activitiesSvc.resetActivitiesInCache();
                return of({lesson: granuleLesson, activities: activities});
            })
        );
    }

    /**
     * save granules activities in editableLesson
     * @param editableActivity
     */
    public saveGranuleActivity(editableActivity: EditableActivity): Observable<DataEntity> {
        const titleAndInstruction = {
            title: editableActivity.title ? editableActivity.title : '',
            instruction: editableActivity.instruction ? editableActivity.instruction : ''
        };

        if (editableActivity.format === 'activity') {
            if (!!editableActivity.granuleActivityEntity) {
                return this.activitiesSvc.saveGranuleActivity(titleAndInstruction,
                    editableActivity.granuleActivityEntity,
                    editableActivity.original);
            } else {
                if (editableActivity.type === 'Tool') {
                    const editableToolActivity = <EditableToolActivity>editableActivity;
                    return this.activityCreationSvc.createToolActivity(editableToolActivity.type,
                        {instruction: editableToolActivity.instruction, config: {tool: editableToolActivity.toolType}},
                        {title: editableToolActivity.title}
                    );
                }
                if (editableActivity.type === 'MULTI') {
                    let multimediaPages: Observable<DataEntity>[] = [];
                    if ((<EditableMultimediaActivity>editableActivity).pages.length) {
                        multimediaPages = (<EditableMultimediaActivity>editableActivity).pages.map((page) => {
                            return this.createActivityMultimedia(editableActivity, page);
                        });
                    }

                    return this.activitiesSvc.createSubLessonMultimedia(editableActivity.title, multimediaPages);
                }
                // Todo: if new type of activity without granule activity, add it here
                return of(null);
            }
        } else if (editableActivity.format === 'lesson') {
            if (editableActivity.type === 'MULTI') {
                return this.saveActivityMultimedia(editableActivity);
            } else {
                return this.activitiesSvc.saveSubLessonActivities(titleAndInstruction, editableActivity.granuleActivityEntity, editableActivity.original);
            }
        }

    }

    /**
     * edit activities multimedia in sublesson multimedia
     * @param {EditableActivity} editableActivity
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>[]}
     */
    private multimediaActivitiesEdited(editableActivity: EditableActivity, activities: DataEntity[]): Observable<DataEntity>[] {
        const multimediaActivities = (<EditableMultimediaActivity>editableActivity).pages
            .map((multimediasPage, index) => {
                if (!!activities[index]) {
                    const oldResources = activities[index].get('reference').activity_content[0].granule;
                    const activityContent = this.activitiesSvc.generateDataEntity(
                        activities[index].get('reference').activity_content[0],
                        'media',
                        activities[index].get('reference').activity_content[0].id);

                    return this.activitiesSvc.saveMediaFromPageMultimedia(multimediasPage, activityContent, oldResources).pipe(
                        mergeMap(() => {
                            return this.activitiesSvc.loadGenericEntity('activity', activities[index].get('reference').id)
                                .pipe(
                                    mergeMap((reference: DataEntity) => {
                                        reference.set('instruction', editableActivity.instruction);
                                        return reference.save(true).pipe((
                                            mergeMap((reference: DataEntity) => activities[index].save(true))
                                        ));
                                    })
                                );
                        })
                    );
                } else {
                    return this.createActivityMultimedia(editableActivity, multimediasPage);
                }
            });

        return multimediaActivities;
    }

    /**
     * create activity (sublesson) multimedias skeleton (reference and activity_content empty
     * @param {EditableActivity} editableActivity
     * @param {MultimediaPage} page
     * @returns {Observable<DataEntity>}
     */
    public createActivityMultimedia(editableActivity: EditableActivity, page: MultimediaPage): Observable<DataEntity> {
        return this.activityCreationSvc.createMultimediaActivity(
            {
                instruction: editableActivity.instruction
            },
            {
                title: editableActivity.title
            }
        ).pipe(
            mergeMap((granuleActivityMedia: DataEntity) => {
                return this.activitiesSvc.loadActivityInterface(granuleActivityMedia.get('reference').id)
                    .pipe(
                        take(1),
                        mergeMap((activityRef: DataEntity) => {
                            if (page) {
                                return this.activitiesSvc.saveMediaFromPageMultimedia(page, activityRef);
                            } else {
                                // if !page the activity multimedia created is empty
                                return of(null);
                            }
                        }),
                        mapTo(granuleActivityMedia)
                    );
            })
        );
    }

    /**
     * update activity multimedia and remove activities unused
     * @param {EditableActivity} editableActivity
     * @returns {Observable<DataEntity>}
     */
    private saveActivityMultimedia(editableActivity: EditableActivity): Observable<DataEntity> {
        if (editableActivity.granuleActivityEntity.get('reference') && editableActivity.granuleActivityEntity.get('reference').length) {
            const multimediasActivitiesObs: Observable<DataEntity>[] = editableActivity.granuleActivityEntity.get('reference')
                .map((act) => this.activitiesSvc.loadActivitiesFromId(act.id).pipe(take(1)));

            return combineLatest(multimediasActivitiesObs)
                .pipe(
                    mergeMap((activities: DataEntity[]) => this.editSublessonMultimediaContent(editableActivity, activities))
                );
        } else {
            return this.editSublessonMultimediaContent(editableActivity, []);
        }

    }

    /**
     *sort the activities to keep and the one to delete and save sublesson Multimedia
     * @param {EditableActivity} editableActivity
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>}
     */
    private editSublessonMultimediaContent(editableActivity: EditableActivity, activities: DataEntity[]): Observable<DataEntity> {
        let activitiesToKeep: DataEntity[] = [];
        let activitiesToRemove: DataEntity[] = activities.slice(); // remove all activities in the page by default
        if (!!(<EditableMultimediaActivity>editableActivity).pages.length) {
            activitiesToKeep = activities.slice(0, (<EditableMultimediaActivity>editableActivity).pages.length);
            activitiesToRemove = activities.slice(activitiesToKeep.length, (<EditableMultimediaActivity>editableActivity).deletedPages.length);
        }
        const obsActivitiesRemoved = activitiesToRemove.map((act) => {
            return act.remove();
            // Todo: if we need to remove metadatas, reference, activity_content, granule resource..
            // return this.activitiesSvc.removeGranuleActivityMultimedia(act);
        });

        return this.saveSubLessonMultimediaAndRemoveUnusedContent(editableActivity, activitiesToKeep)
            .pipe(
                mergeMap((granuleSublessonMulti) => {
                    if (!!obsActivitiesRemoved.length) {
                        return combineLatest(obsActivitiesRemoved).pipe(
                            mapTo(granuleSublessonMulti)
                        );
                    } else {
                        return of(granuleSublessonMulti);
                    }
                })
            );

    }

    /**
     * save sublesson multimedia and remove unused content
     * @param {EditableActivity} editableActivity
     * @param {DataEntity[]} activitiesToKeep
     * @returns {Observable<DataEntity>}
     */
    private saveSubLessonMultimediaAndRemoveUnusedContent(editableActivity: EditableActivity, activitiesToKeep: DataEntity[]): Observable<DataEntity> {
        const multimediasActivitiesObs: Observable<DataEntity>[] = this.multimediaActivitiesEdited(editableActivity, activitiesToKeep);
        if (!!multimediasActivitiesObs.length) {
            return combineLatest(multimediasActivitiesObs).pipe(
                mergeMap((multimediasActivities: DataEntity[]) => {
                    return this.updateSubLessonMultimedia(editableActivity, multimediasActivities);
                })
            );
        } else {
            return this.updateSubLessonMultimedia(editableActivity, []);
        }
    }

    /**
     * update sublesson Multimedia with the child activities created or edited
     * @param {EditableActivity} editableActivity
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>}
     */
    private updateSubLessonMultimedia(editableActivity: EditableActivity, activities: DataEntity[]): Observable<DataEntity> {
        const metadatas: DataEntity = <DataEntity>editableActivity.granuleActivityEntity.getEmbed('metadatas');
        let prerequisitesObs = of({});
        if (metadatas.get('title') !== editableActivity.title) { // si on a besoin de plus de modification, on a besoin d'une autre méthode :)
            metadatas.set('title', editableActivity.title);
            prerequisitesObs = metadatas.save();
        }
        return prerequisitesObs.pipe(
            mergeMap(() => this.activitiesSvc.editSubLessonMultimedia(
                editableActivity.granuleActivityEntity,
                activities,
                editableActivity.granuleActivityEntity.get('lesson_id'))
            )
        );
    }

    /**
     * play multimedia "ghost" (does not yet exist) activities in sublesson Multimedia
     * @param {MultimediaPage[]} pages
     */
    public playMultimediaSublesson(editableActivity: EditableActivity): void {
        const activities = (<EditableMultimediaActivity>editableActivity).pages
            .map((page, index) => this.setActivityMultimediaForPreview(editableActivity.title, editableActivity.instruction, page, index));
        this.activitiesSvc.internalLaunchPreview(null, activities);
    }

    /**
     * play child activities unit for preview
     * @param {MultimediaPage} page
     */
    public playMultimediaActivity(title: string, instruction: string, page: MultimediaPage): void {
        const activity = this.setActivityMultimediaForPreview(title, instruction, page);
        this.activitiesSvc.internalLaunchPreview(null, [activity]);
    }

    /**
     * set the ghost activities with data from "page" multimedias
     * @param {MultimediaPage} page
     * @param ghostId
     * @returns {DataEntity}
     */
    public setActivityMultimediaForPreview(title: string, instruction: string, page: MultimediaPage, ghostId?): DataEntity {
        // ghostId can be undefined, its use is only for the navigation in sublesson Multimedia
        const activity = this.activitiesSvc.generateDataEntity({
                id: ghostId,
                type: 'MULTI',
                title,
                instruction,
                page: page.second ? [page.first, page.second] : [page.first],
                ghost: true,
            },
            'granule');

        return activity;
    }

}