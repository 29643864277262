import {COMMA, E, ENTER} from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {map} from 'rxjs/operators';

import {AccountManagementProviderService} from '@modules/account-management';
import {AuthenticationService} from '@modules/authentication';
import {CorpusMetadataInterface} from '@modules/corpus';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {HttpClient} from '@angular/common/http';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {defaultApiURL} from 'app/settings';
import { DataEntity } from 'octopus-connect';

interface Group {
    id: number;
    name: string;
    workgroupname: string;
}

type Skill = DataEntity;
type Difficulty = DataEntity;
type EducationalLevel = DataEntity;

interface Chip {
    label: string;
}

interface UploadedFile {
    id: number;
    filename: string;
    uri: string;
}

@Component({
    selector: 'app-resource-upload-modal-step2',
    templateUrl: './resource-upload-modal-step2.component.html',
    styleUrls: ['./resource-upload-modal-step2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceUploadModalStep2Component implements OnInit, OnDestroy {
    public name = '';
    public scolomDate = '';
    public author = '';
    public source = '';
    public description = '';
    private subTheme = '';
    public groups: Group[] = [];
    public skills: Skill[] = [];
    public difficulty: Difficulty[] = [];
    private indexation: any[] = [];
    public educationalLevels: EducationalLevel[] = [];
    private groupsName: string[] = [];
    public concepts: any[] = [];
    public pdfFile: File = null;
    private allSkills: Skill[] = [];
    private allDifficulty: Difficulty[] = [];
    private allEducationalLevel: EducationalLevel[] = [];
    public chips: Chip[];
    public removable = true;
    public tagModified = false;
    public uploading = false;
    private pdfUploaded: UploadedFile[] = null;
    public uploadedFileId: string;
    public uploadedFileLabel: string;
    public isImportPdfInError = false;
    public autoOrderFields = false;
    public fields: string[] = [];
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    readonly concepts$ = this.corpusService
        .getConcepts()
        .pipe(
            map((dataEntity) =>
                dataEntity.entities.map((entities) => entities.attributes)
            )
        );
    private unsubscribeInTakeUntil = new Subject<void>();
    public conceptSelectedByUser = false;

    @Output() fileUploaded: EventEmitter<any[]> = new EventEmitter<
        any[]
    >();
    @Output() error: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    detailsValidated: EventEmitter<CorpusMetadataInterface> =
        new EventEmitter<CorpusMetadataInterface>();
    @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

    @Input() mode = 'creation';
    @Input() resource: CorpusRessource;

    constructor(
        public authService: AuthenticationService,
        public dialogRef: MatDialogRef<ResourceUploadModalStep2Component>,
        public corpusService: CorpusService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.chips = [];
        // if true fields use order of field in setting array to move fields in html using a directive for
        this.autoOrderFields = this.corpusService.settings.assetFieldsAutoOrder;
        this.setListOfFields();
    }

    private setListOfFields(): void {
        const role = this.authService.accessLevel;
        this.fields = this.corpusService.settings.assetFields[role];
        if (this.fields === undefined) {
            this.fields = this.corpusService.settings.assetFields['default'];
        }
    }

    ngOnInit(): void {
        if (!this.corpusService.skills) {
            // get granule formats
            this.corpusService.loadSkills().subscribe((_entities) => {
                this.allSkills = this.corpusService.skills;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceSkills();
                }
            });
        } else {
            this.allSkills = this.corpusService.skills;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceSkills();
            }
        }

        if (!this.corpusService.difficulty) {
            // get granule formats
            this.corpusService.loadDifficulty().subscribe((_entities) => {
                this.allDifficulty = this.corpusService.difficulty;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceDifficulty();
                }
            });
        } else {
            this.allDifficulty = this.corpusService.difficulty;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceDifficulty();
            }
        }

        if (!this.corpusService.educationalLevel) {
            // get granule formats
            this.corpusService.loadEducationalLevel().subscribe((_entities) => {
                this.allEducationalLevel = this.corpusService.educationalLevel;
                if (this.mode === 'edition' && this.resource) {
                    this.getResourceEducationnalLevel();
                }
            });
        } else {
            this.allEducationalLevel = this.corpusService.educationalLevel;
            if (this.mode === 'edition' && this.resource) {
                this.getResourceEducationnalLevel();
            }
        }

        if (this.mode === 'edition' && this.resource) {
            this.author = this.resource.sourceAuthor;
            this.chips = this.resource.ressourceEntity.get('metadatas').indexation;
            this.description = this.resource.description;
            this.name = this.resource.title;
            this.scolomDate = this.resource.scolomDate;
            this.concepts = this.resource.concepts;
            this.source = this.resource.source;
            this.tagModified = false; // init variable to false on modal load
            if (this.resource.files) {
                this.uploadedFileId = this.resource.files[0].id;
                this.uploadedFileLabel = this.resource.files[0].filename;
            }
            this.getResourceWorkgroups();
        }
    }

    displayConcept(concept): string {
        return concept.name;
    }

    selectConcept(concept: MatAutocompleteSelectedEvent) {
        this.conceptSelectedByUser = true;
        this.concepts[0] = concept.option.value;
    }

    /** @deprecated use MediaService.createMedia instead */
    public dropped(file: NgxFileDropEntry): void {
        const droppedFile = file[0].fileEntry as FileSystemFileEntry;
        this.uploading = true;

        droppedFile.file((file) => {
            if (file.type !== 'application/pdf') {
                this.isImportPdfInError = true;
                this.uploading = false;
                this.changeDetector.detectChanges();
                return;
            }

            this.pdfFile = file;
            this.isImportPdfInError = false;

            const formData = new FormData();
            formData.append('file', file);

            this.http
                .post<any>(`${defaultApiURL}api/file-upload`, formData, {
                    headers: {
                        'access-token': this.accountManagementProvider.userAccessToken,
                    },
                })
                .subscribe((res) => {
                    this.uploading = false;

                    this.changeDetector.detectChanges();
                    this.pdfUploaded = [
                        {
                            id: +res['data'][0][0]['id'],
                            filename: res['data'][0][0]['label'],
                            uri: res['data'][0][0]['uri'],
                        },
                    ];
                    this.uploadedFileId = res['data'][0][0]['id'];

                    this.fileUploaded.emit([
                        +res['data'][0][0]['id'],
                        res['data'][0][0]['filemime'],
                    ]);
                });
        });
    }

    validateDetails() {
        this.indexation = this.chips;

        if (this.mode === 'edition' && this.resource) {
            this.getResourceWorkgroupsName();
            this.resource.title = this.name;
            this.resource.concepts = +this.concepts[0]?.lessons ? [+this.concepts[0]?.lessons] : [];
            this.resource.subTheme = this.subTheme;
            this.resource.scolomDate = this.scolomDate;
            this.resource.description = this.description;
            this.resource.sourceAuthor = this.author;
            this.resource.source = this.source;
            this.resource.groups = this.groups;
            this.resource.skills = this.skills.map((x) => +x.id);
            this.resource.difficulty = this.difficulty.map((x) => +x.id);
            this.resource.educationalLevel = this.educationalLevels.map((x) => +x.id);
            this.resource.groupsName = this.groupsName;
            this.resource.indexation = this.indexation;
            this.getResourceDifficultyName();
            this.getResourceEducationnalLevelName();
            this.getResourceSkillsName();
            this.getResourceIndexationName();
            this.resource.save().subscribe(() => {
                this.resource.concepts = this.concepts;
            });
        }

        this.detailsValidated.emit({
            description: this.description,
            title: this.name,
            concepts: this.concepts.length && [+this.concepts[0].lessons],
            scolomDate: this.scolomDate,
            'source-author': this.author,
            source: this.source,
            groups: this.groups,
            skills: this.skills.map((x) => +x.id),
            difficulty: this.difficulty.map((x) => +x.id),
            indexation: this.indexation,
            educationalLevel: this.educationalLevels.map((x) => +x.id),
            language: '',
            files:
                (this.pdfUploaded && this.pdfUploaded[0].id
                    ? this.pdfUploaded[0].id
                    : null) as any,
        });
    }

    get metadataAreCreating(): boolean {
        return this.corpusService.metadataAreCreating;
    }

    get allWorkgroups() {
        return this.corpusService.getWorkgroups();
    }

    public get skillsList() {
        return this.allSkills;
    }

    public get difficultyList() {
        return this.allDifficulty;
    }

    public get educationalLevelList() {
        return this.allEducationalLevel;
    }

    getResourceWorkgroups(): void {
        this.groups = [];
        const resourceGroups = this.resource.groups;
        this.groups.push(
            ...this.allWorkgroups.filter((globalGroup) => {
                return resourceGroups.some(
                    (resourceGroup) => +resourceGroup.id === +globalGroup.id
                );
            })
        );
    }

    getResourceWorkgroupsName() {
        this.groupsName = [];
        const resourceGroups = this.resource.ressourceEntity.get('groupsName');
        if (!resourceGroups || (!resourceGroups.length && this.groups.length > 0)) {
            this.groupsName.push(...this.groups.map((group) => group.workgroupname));
        } else {
            this.groupsName.push(
                ...this.allWorkgroups.filter(
                    (group) => resourceGroups.indexOf(group.workgroupname) > -1
                )
            );
        }
    }

    getResourceSkills(): void {
        this.skills = [];
        let resourceSkills;
        if (this.resource.skills) {
            if (typeof this.resource.skills[0] === 'object') {
                resourceSkills = this.resource.skills.map((x) => x.id);
            } else {
                resourceSkills = this.resource.skills.map((x) => x.toString());
            }
            this.skills.push(
                ...this.allSkills.filter(
                    (x) => resourceSkills.indexOf(x.id.toString()) > -1
                )
            );
        }
    }

    getResourceSkillsName(): void {
        this.getResourceSkills();
        this.resource.skillsString = this.skills
            .map((x) => x.attributes.label)
            .join(', ');
    }

    getResourceEducationnalLevel(): void {
        this.educationalLevels = [];

        if (this.resource.educationalLevel) {
            let resourceEducationalLevel = [];
            if (typeof this.resource.educationalLevel[0] === 'object') {
                resourceEducationalLevel = this.resource.educationalLevel.map(
                    (x) => x.id
                );
            } else {
                resourceEducationalLevel = this.resource.educationalLevel.map((x) =>
                    x.toString()
                );
            }
            this.educationalLevels.push(
                ...this.allEducationalLevel.filter(
                    (x) => resourceEducationalLevel.indexOf(x.id.toString()) > -1
                )
            );
        }
    }

    getResourceEducationnalLevelName(): void {
        this.getResourceEducationnalLevel();
        this.resource.educationalLevelString = this.educationalLevels
            .map((x) => x.attributes.label)
            .join(', ');
    }

    getResourceIndexationName(): void {
        this.resource.indexationString = this.resource.indexation
            .map((x) => x.label)
            .join(', ');
    }

    getResourceDifficulty(): void {
        this.difficulty = [];
        let resourceDifficulty;

        if (this.resource.difficulty) {
            if (typeof this.resource.difficulty[0] === 'object') {
                resourceDifficulty = this.resource.difficulty.map((x) => x.id);
            } else {
                resourceDifficulty = this.resource.difficulty.map((x) => x.toString());
            }
            this.difficulty.push(
                ...this.allDifficulty.filter(
                    (x) => resourceDifficulty.indexOf(x.id.toString()) > -1
                )
            );
        }
    }

    getResourceDifficultyName(): void {
        this.getResourceDifficulty();
        this.resource.difficultyString = this.difficulty
            .map((x) => x.attributes.label)
            .join(', ');
    }

    displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let assetField = this.corpusService.settings.assetFields[role];
        if (assetField === undefined) {
            assetField = this.corpusService.settings.assetFields['default'];
        }
        return assetField.indexOf(name) > -1;
    }

    displayRequiredField(name: string): boolean {
        return this.corpusService.settings.assetRequiredFields.indexOf(name) > -1;
    }

    displayTextEditorField(name: string): boolean {
        return this.corpusService.settings.assetTextEditorFields.indexOf(name) > -1;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            if (
                this.chips.filter(function (e) {
                    return e.label === value;
                }).length > 0
            ) {
                /* check if chips array contains the element we're looking for */
            } else {
                // add tag
                this.chips.push({label: value.trim()});
                this.tagModified = true;
                // this.entityForm.get('tagModified').setValue(this.tagModified);
            }
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag): void {
        const index = this.chips.indexOf(tag);

        if (index >= 0) {
            this.chips.splice(index, 1);
            this.tagModified = true;
            // this.entityForm.get('tagModified').setValue(this.tagModified);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}