import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, mapTo, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BdDataEditorModalComponent} from '@modules/bdtool/core/bd-data-editor-modal/bd-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {BdGenericAlertModalComponent} from '@modules/bdtool/core/bd-generic-alert-modal/bd-generic-alert-modal.component';
import {IBdFormData, BdRepositoryService} from '@modules/bdtool/core/bd-repository.service';
import {BdContentViewerModalComponent} from '@modules/bdtool/core/bd-content-viewer-modal/bd-content-viewer-modal.component';
import {v4 as uuidv4} from 'uuid';
import {defaultApiURL} from '../../../settings';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface BdDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on bd creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface IBdFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the bd business rules of the application
 */
export class BdService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an bd edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onBdDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};
    /**
     * Obtain the function to load lessons. This method is the container and undefined, it's filled in the constructor by calling activities-module
     */
    private loadPaginatedLessons: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>;
    private getAllowedRoleIdsForModelsCreation;
    private shareableModel;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private bdRepoSvc: BdRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadPaginatedLessonsCallback')
            .subscribe((callback: (type?: string, roles?: number[], searchValue?, filterOptions?: {}) => Observable<DataEntity[]>) => {
                this.loadPaginatedLessons = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('getAllowedRoleIdsForModelsCreationCallback')
            .subscribe((callback) => {
                this.getAllowedRoleIdsForModelsCreation = callback;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('shareableModelCallback')
            .subscribe((callback) => {
                this.shareableModel = callback;
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a bd from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('bd')
            .getSubject('execute')
            .pipe(
                mergeMap((args: BdDataCommunicationCenterInterface) =>
                    this.createOrEditBdIfAlreadyExistForLesson(args.lesson.id).pipe(
                        map(bd => args.onComplete.next(bd))
                    )
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `bds` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedBds(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.bdRepoSvc.getPaginatedBds(options)),
            take(1)
        );
    }

    /**
     * Open and return the bd data editor modal.
     *
     * - It's the same way to create a note ({@link goToBdDataCreation}) but the save method passed to the modal is used to patch bd
     *
     * @param bd Used to defined which bd to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     */
    public goToBdDataEdition(bd: DataEntity): MatDialogRef<BdDataEditorModalComponent, DataEntity | null> {
        return this.goToBdDataAndContentEditors({
            saveBd: (data) => this.bdRepoSvc.updateBd(bd.id, data),
            defaultValues: {
                title: _.get(bd.get('reference'), 'activity_content[0].content.title'),
                associatedLessonId: _.get(bd.get('reference'), 'activity_content[0].associated_nodes[0].id'),
            }
        });
    }

    /**
     * Open and return the bd data editor modal.
     *
     * - It's the same way to edit a note ({@link goToBdDataCreation}) but the save method passed to the modal is used to create bd*
     */
    public goToBdDataCreation(defaultValues?: IBdFormData, options?: IBdFormOptions): MatDialogRef<BdDataEditorModalComponent, DataEntity | null> {
        return this.goToBdDataAndContentEditors({
            saveBd: (data) => this.bdRepoSvc.createBd(data),
            defaultValues: defaultValues,
            options: options
        });
    }

    /**
     * Redirect to the list of current user's bd
     */
    public goToBdList(): Promise<boolean> {
        return this.router.navigate(['bd', 'list']);
    }

    /**
     * Obtain the lesson available to be associated to a bd
     * An lesson is available because
     * - User have access to it
     * - No current user's bd are already associated to it
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociableLessons(): Observable<DataEntity[]> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            mergeMap(currentUser => {
                const allModelsWithoutBd$ = this.getLessons({
                    filter: {
                        'removeBdAttachedNode': true,
                        'role': this.getAllowedRoleIdsForModelsCreation(), // get role used for models creation
                        'model': this.shareableModel()
                    }
                });

                const allMyLessonsWithoutBd$ = this.getLessons({
                    filter: {
                        'author': currentUser.id,
                        'removeBdAttachedNode': true,
                    }
                });

                return combineLatest([
                    allModelsWithoutBd$,
                    allMyLessonsWithoutBd$
                ]);
            }),
            map(([notSharedLessons, sharedLessons])  => [...notSharedLessons, ...sharedLessons])
        );
    }

    /**
     * Obtain the lesson already associated to a bd o current user
     *
     * @remarks: the backend limit the result to 50 item, if we need more, we need to make it paginated
     */
    public getAssociatedLessons(): Observable<DataEntity[]> {
        return this.getLessons({
            filter: {
                'removeBdAttachedNode': false
            }
        });
    }

    /**
     * Obtain the all lessons
     * @param filterOptions
     */
    public getLessons(filterOptions: {}): Observable<DataEntity[]> {
        const mergedOptions = _.merge({
            filter: {
                'multi_step': 0
            }
        }, filterOptions);

        return this.loadPaginatedLessons('all', null, '', mergedOptions);
    }

    /**
     * Ask user if he confirm the bd deletion and, if it's ok, delete it
     * @param id of the bd granule
     */
    public deleteBd(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'bd.ask_before_delete_alert'}};
        return this.dialog.open(BdGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.bdRepoSvc.destroyBd(id))
        );
    }

    /**
     * Display the bd content as a read-only modal
     * @param id of the bd Granule
     */
    displayBd(id: number | string): void {
        this.dialog.open(BdContentViewerModalComponent, {data: {bd$: this.bdRepoSvc.getBd(id)}});
    }

    /**
     * Open the bd editor modal and if there are a bd given on closed go to bd content editor
     * @param data The saveBd method receive the form data TODO give an interface of form data here
     */
    private goToBdDataAndContentEditors(
        data: { saveBd: (data) => Observable<DataEntity>; defaultValues: IBdFormData; options?: IBdFormOptions}
    ): MatDialogRef<BdDataEditorModalComponent, DataEntity | null> {
        const modalData = _.merge({
            availableLessons$: this.getAssociableLessons(),
            getLessonById: (id: string | number) => this.getLessonById(id),
        }, data);

        const dialogRef = this.dialog.open(BdDataEditorModalComponent, {
            data: modalData
        });

        dialogRef.afterClosed().pipe(
            // If is bdGranule empty is because the modal is closed without want to create/edit the bd
            filter(bdGranule => !!bdGranule),
            tap(bdGranule => {
                this.goToBdContentEditor(bdGranule, {queryParams: {
                        assetsEndpoint: defaultApiURL + 'api/assets_bd',
                        bdID: bdGranule.id,
                        ...this.activatedRoute.snapshot.queryParams
                    }, skipLocationChange: false});
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the bd content editor page.
     *
     * @param bdGranule
     * @param navigationExtras some data to pass by the url, show {@link onBdDataEditionCompleteSubject} for example
     */
    private goToBdContentEditor(bdGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['bd-tool', bdGranule.id, 'edit'], navigationExtras);
    }

    /**
     * Obtain a lesson from the activities module
     * @param id unique identifier of the lesson DataEntity
     */
    private getLessonById(id: string | number): Observable<DataEntity> {
        const lesson$ = new ReplaySubject<Observable<DataEntity>>(1);

        this.communicationCenter
            .getRoom('lessons')
            .next('getLesson', {
                lessonId: id,
                callbackSubject: lesson$
            });

        return lesson$.pipe(
            mergeMap(obs => obs)
        );
    }

    /**
     * Generic way to open a note hypothetically already created
     * @param lessonId unique identifier of lesson granule used to identify if a bd is alrealdy associated to this lesson.
     *  Open the bd editor if true, the bd creator if false but with the lesson associated by default
     */
    private createOrEditBdIfAlreadyExistForLesson(lessonId: number | string): Observable<DataEntity> {
        const onCompleteSubject = new ReplaySubject<DataEntity>(1);
        const onCompleteSubjectId: string = uuidv4();
        this.onBdDataEditionCompleteSubject[onCompleteSubjectId] = onCompleteSubject;

        return this.getCurrentUserPaginatedBds({filter: {associated_node: lessonId}}).pipe(
            // We load the bd from the lesson id associated
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable.pipe(
                take(1)
            )),
            map(collection => collection.entities),
            mergeMap(entities => {
                if (entities.length > 1) {
                    throw new Error('a user must have only one note by lesson');
                } else if (entities.length === 0) {
                    // If no bd, we creating it
                    return this.goToBdDataCreation({associatedLessonId: lessonId}, {associatedLessonId: {disable: true}}).afterClosed();
                } else {
                    // Else we reload it but from another endpoint to get all datas
                    return this.bdRepoSvc.getBd(entities[0].id);
                }
            }),
            // If the user cancel the bd creation there are no bd
            filter(bd => !!bd),
            // whether it existed or not, we are modifying the content now
            tap((bd: DataEntity) => this.goToBdContentEditor(bd, {queryParams: {
                onComplete: onCompleteSubjectId,
                assetsEndpoint: defaultApiURL + 'api/assets_bd',
                bdID: bd.id,
                ...this.activatedRoute.snapshot.queryParams
                }, skipLocationChange: false})),
            mergeMap(() => onCompleteSubject)
        );
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }
}