import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {CollectionOptionsInterface, DataEntity} from 'octopus-connect';
import {CompassService} from 'fuse-core/services/compass.service';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, map, mergeMap, startWith, take, tap} from 'rxjs/operators';
import {combineLatest, shareReplay} from 'rxjs';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {CompassExploreEntity, CompassThemeEntity} from 'fuse-core/types/compass';

enum filter {
    diagnostics = 'diagnostics',
    skills = 'skills',
    levels = 'levels',
    difficulty = 'difficulty',
    peda = 'peda',
    orga = 'orga',
    explore = 'explore',
}

@Component({
    selector: 'app-diagnostic',
    templateUrl: './compass-themes.component.html'
})

export class CompassThemesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
    @ViewChild('levelInput') levelInput: ElementRef<HTMLInputElement>;

    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);

    private exploreEntityId = this.activatedRoute.snapshot.params.id;
    public exploreEntity$ = this.compassService.loadExploreEntity(this.exploreEntityId).pipe(take(1), shareReplay(1));

    public isFiltersActive = false;
    public themes: CompassThemeEntity[] = [];
    public displayLoader = true;
    public separatorKeysCodes: number[] = [ENTER, COMMA];
    public levelCtrl: FormControl = new FormControl();
    public filterCtrl = new FormControl();
    public filterType: filter = filter.diagnostics;

    public levels: DataEntity[] = [];
    private levelsFromApi: DataEntity[] = [];
    private diagnostics: DataEntity[] = [];
    private diagnosticsFromApi: DataEntity[] = [];
    private skills: DataEntity[] = [];
    private skillsFromApi: DataEntity[] = [];
    private difficulty: DataEntity[] = [];
    private difficultyFromApi: DataEntity[] = [];
    private pedaFromApi: DataEntity[] = [];
    private peda: DataEntity[] = [];
    private orgaFromApi: DataEntity[] = [];
    private orga: DataEntity[] = [];

    public entitiesFiltered = this.filterCtrl.valueChanges.pipe(
        startWith(null),
        map((item: DataEntity | null) => (item ? this.filter(item, this[`${this.filterType}FromApi`]) : this[`${this.filterType}FromApi`].slice())),
    );

    public levelsFiltered = this.levelCtrl.valueChanges.pipe(
        startWith(null),
        map((level: DataEntity | null) => (level ? this.filter(level, this.levelsFromApi) : this.levelsFromApi.slice())),
    );

    public get entitiesForFilter(): DataEntity[] {
        return this[this.filterType] || [];
    }

    /******************** LIFECYCLE ********************/

    ngOnInit() {
        this.exploreEntity$.pipe(
            mergeMap((exploreEntity) => {
                return combineLatest([
                    this.compassService.getEducationalLevels(),
                    this.compassService.loadDiagnostics(),
                    this.compassService.loadSkills(),
                    this.compassService.getDifficulties(),
                    this.compassService.getPedagoList(),
                    this.compassService.getOrganisationList(),
                    this.loadThemes(exploreEntity),
                ])
                    .pipe(
                        take(1),
                        tap(([levels, diagnostics, skills, difficulty, pedagoList, organisationList, themes]) => {
                            this.filterType = this.toFilter(exploreEntity.get('key'));
                            this.levelsFromApi = levels.slice();
                            this.diagnosticsFromApi = diagnostics.slice();
                            this.skillsFromApi = skills.slice();
                            this.difficultyFromApi = difficulty.slice();
                            this.pedaFromApi = pedagoList.slice();
                            this.orgaFromApi = organisationList.slice();
                            this.themes = themes;

                            // Déclenche les filtres pour alimenter les autocomplete aux clics
                            this.filterCtrl.setValue(null);
                            this.levelCtrl.setValue(null);

                            // Si le back-end ne renvoie pas de filtres, on les cache
                            this.isFiltersActive = this[`${this.filterType}FromApi`].length > 0;
                        }),
                        tap(() => {
                            this.displayLoader = false;
                        }),
                    );
            })
        ).subscribe();
    }

    /******************** LOGIC ********************/

    /**
     * cette fonction charge les themes en fonction des filtres
     */
    private loadThemes(exploreEntity ?: CompassExploreEntity) {
        this.displayLoader = true;
        const filterOptions: CollectionOptionsInterface = {filter: {}, page: 1};
        if (this.diagnostics?.length > 0) {
            filterOptions.filter['diagnostic'] = this.diagnostics.map((diagnostic) => diagnostic.id);
        } else if (exploreEntity?.get('diagnostic')) {
            filterOptions.filter['diagnostic'] = exploreEntity.get('diagnostic');
        }
        if (this.skills?.length > 0) {
            filterOptions.filter['skills'] = this.skills.map((skill) => skill.id);
        } else if (exploreEntity?.get('skills')) {
            filterOptions.filter['skills'] = exploreEntity.get('skills');
        }
        if (this.difficulty?.length > 0) {
            filterOptions.filter['difficulty'] = this.difficulty.map((difficulty) => difficulty.id);
        } else if (exploreEntity?.get('difficulty')) {
            filterOptions.filter['difficulty'] = exploreEntity.get('difficulty');
        }
        if (this.peda?.length > 0) {
            filterOptions.filter['peda'] = this.peda.map((pedago) => pedago.id);
        } else if (exploreEntity?.get('peda')) {
            filterOptions.filter['peda'] = exploreEntity.get('peda');
        }
        if (this.orga?.length > 0) {
            filterOptions.filter['orga'] = this.orga.map((organisation) => organisation.id);
        } else if (exploreEntity?.get('orga')) {
            filterOptions.filter['orga'] = exploreEntity.get('orga');
        }

        if (this.levels?.length > 0) {
            filterOptions.filter['levels'] = this.levels.map((level) => level.id);
        } else if (exploreEntity?.get('levels')) {
            filterOptions.filter['levels'] = exploreEntity.get('levels');
        }
        if (exploreEntity) {
            filterOptions.filter['explore'] = exploreEntity.id;
        }
        return this.compassService.loadThemes(filterOptions)
            .pipe(
                take(1),
                tap(themes => this.themes = themes),
                tap(() => this.displayLoader = false)
            );
    }

    private filter<T extends DataEntity>(value: T | string, arr: T[]): T[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else {
            filterValue = value ? value.get('label').toLowerCase() : '';
        }

        return arr.filter(item => item.get('label').toLowerCase().includes(filterValue));
    }


    private refreshThemes() {
        this.exploreEntity$.pipe(
            tap(() => this.displayLoader = true),
            debounceTime(250),
            mergeMap(exploreEntity => this.loadThemes(exploreEntity)),
            take(1)
        ).subscribe();
    }

    private toFilter(filterLabel: string) {
        const backProofFilter = filterLabel === 'diagnostic' ? filter.diagnostics : filterLabel;
        return filter[backProofFilter as keyof typeof filter];
    }


    public getThemeQueryParams(exploreEntity: CompassExploreEntity, theme: CompassThemeEntity) {
        const qp = {
            fromUrl: this.router.url.split('?')[0],
            backLinkName: exploreEntity.get('label'),
        }

        if (Object.keys(this.activatedRoute.snapshot.queryParams).length) {
            qp['fromQueryParams'] = JSON.stringify(this.activatedRoute.snapshot.queryParams);
        }

        return qp;
    }

    public getBackQueryParams() {
        return this.activatedRoute.snapshot.queryParams.fromQueryParams ? JSON.parse(this.activatedRoute.snapshot.queryParams.fromQueryParams) : null
    }

    public getBackUrl() {
        return this.activatedRoute.snapshot.queryParams.fromUrl ?? '/boussole';
    }

    /******************** EVENTS ********************/

    /**
     * cette fonction permet de réinitialiser les filtres
     */
    public onResetFiltersClick() {
        this[this.filterType] = [];
        this.filterCtrl.setValue(null);
        this.diagnostics = [];
        this.skills = [];
        this.difficulty = [];
        this.levels = [];
        this.levelCtrl.setValue(null);
        this.refreshThemes();
    }

    /**
     * cette fonction permet de sélectionner une entité dans la recherche
     */
    public onThemeFilterSelected(event: MatAutocompleteSelectedEvent) {
        const entityMatching: DataEntity = this[`${this.filterType}FromApi`].find((entity) => entity.get('label') === event.option.viewValue);
        const entityAlreadySelected = this[this.filterType].find((entity) => entity.get('label') === event.option.viewValue);
        if (entityMatching !== undefined && entityAlreadySelected === undefined) {
            this[this.filterType].push(entityMatching);
        }

        this.filterInput.nativeElement.value = null;
        this.filterCtrl.setValue(null);
        this.refreshThemes();
    }

    /**
     * alimente le filtre de recherche par entité
     * @param event
     */
    onThemeFilterInputSubmit(event: MatChipInputEvent) {
        const value = (event.value || '').trim();
        const entityMatching: DataEntity = this[this.filterType].find((item) => item.get('label') === value);
        // Add our entity
        if (entityMatching !== undefined) {
            this[this.filterType].push(entityMatching);
        }

        // Clear the input value
        event.chipInput!.clear();
        this.filterCtrl.setValue(null);
    }

    /**
     * cette fonction permet de supprimer une entité dans la recherche
     */
    onThemeFilterRemoveClick(entityToRemove: DataEntity) {
        const index = this[this.filterType].findIndex((item) => item.get('label') === entityToRemove.get('label'));

        if (index >= 0) {
            this[this.filterType].splice(index, 1);
        }
        this.refreshThemes();
    }

    /**
     * cette fonction permet de sélectionner un niveau scolaire dans la recherche
     * @param event
     */
    public onLevelSelected(event: MatAutocompleteSelectedEvent) {
        const levelMatching: DataEntity = this.levelsFromApi.find((level) => level.get('label') === event.option.viewValue);
        const levelAlreadySelected = this.levels.find((level) => level.get('label') === event.option.viewValue);
        if (levelMatching !== undefined && levelAlreadySelected === undefined) {
            this.levels.push(levelMatching);
        }
        this.levelInput.nativeElement.value = null;
        this.levelCtrl.setValue(null);
        this.refreshThemes();
    }

    /**
     * alimente le filtre de recherche par niveau scolaire
     * @param event
     */
    onLevelInputSubmit($event: MatChipInputEvent) {
        const value = ($event.value || '').trim();
        const levelMatching: DataEntity = this.levels.find((level) => level.get('label') === value);
        // Add our level
        if (levelMatching !== undefined) {
            this.levels.push(levelMatching);
        }

        // Clear the input value
        $event.chipInput!.clear();
        this.levelCtrl.setValue(null);
    }

    /**
     * cette fonction permet de supprimer un niveau scolaire dans la recherche
     * @param level
     */
    onLevelRemoveClick(level: DataEntity) {
        const index = this.levels.findIndex((item) => item.get('label') === level.get('label'));

        if (index >= 0) {
            this.levels.splice(index, 1);
        }

        this.refreshThemes();
    }

}