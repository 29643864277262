import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GroupsListingService} from '../services/groups-listing.service';
import {fuseAnimations} from 'fuse-core/animations';
import {UntypedFormControl} from '@angular/forms';
import {FuseGroupsFormDialogComponent, FuseGroupsFormDialogData} from './groups-form/groups-form.component';
import {MatDialog} from '@angular/material/dialog';
import {DataSource} from '@angular/cdk/table';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {Subscription} from 'rxjs';
import {GroupsListingOptions} from '@modules/groups-management/core/models/groups-listing-options';

/**
 * @deprecated a component need to call the configuration Service to know the configuration
 */
const settingsStructure: ModelSchema = new ModelSchema({
    showGlobalAddButton: Structures.boolean(true),
    useComponentIdentifierForContext: Structures.boolean(false),
});

@Component({
    selector: 'module-groups-listing',
    templateUrl: './groups-listing.component.html',
    styleUrls: ['./groups-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class GroupsListingComponent implements OnInit, OnDestroy {
    @Input() displayedColumns: string[];
    @Input() dataSource: DataSource<any>;
    @Input() addEntity: FuseGroupsFormDialogData;
    @Input() joinLearner: FuseGroupsFormDialogData; // to join a learner to a group or a workgroup
    @Input() editEntity: FuseGroupsFormDialogData;
    @Input() archiveEntity: FuseGroupsFormDialogData;
    @Input() deArchiveEntity: FuseGroupsFormDialogData;
    @Input() activateMetacognitionEntity: FuseGroupsFormDialogData;
    @Input() deactivateMetacognitionEntity: FuseGroupsFormDialogData;
    @Input() deleteEntity: FuseGroupsFormDialogData;
    @Input() customActions: FuseGroupsFormDialogData[];
    @Input() options: GroupsListingOptions;
    @Input() isAddingInlineLearner = false;
    @Input() isAddingInlineLearnerRowAddButton = true;
    @Input() groupNameClassroomSelected = ''; // use to force filter on a classroom
    @Input() groupNameSelected = ''; // use to force filter on a group
    @Input() isAddingLearnerFromGroupOrWorkgroup = false;
    @Input() nicknameContextAllowed = false;
    @Input() displayedFilters: string[] = []; // filter to show will change in regard of who call it
    hasSelectedEntity: boolean;
    context: string;
    searchInput: UntypedFormControl;
    dialogRef: any;
    onSelectedEntityChangedSubscription: Subscription;

    /**
     * @deprecated a component need to call the configuration Service to know the configuration
     */
    public settings: { [key: string]: any };
    selectedIcon = 'student_add';

    isStudent: boolean;

    constructor(
        private groupsService: GroupsListingService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.groupsManagement);
        this.searchInput = new UntypedFormControl('');
    }

    newEntity(): void {
        this.dialogRef = this.dialog.open(FuseGroupsFormDialogComponent, {
            panelClass: 'entity-form-dialog',
            data: this.addEntity
        });

        this.dialogRef.afterClosed();
    }

    ngOnInit(): any {
        //ctz-setting
        if (this.settings?.useComponentIdentifierForContext) {
            this.context = this.route.component['componentIdentifier'];
        } else {
            this.context = this.route.component.name;
        }

        if (this.authService.hasLevel(['learner'])) {
            this.isStudent = true;
        }
        this.route.data.subscribe(data => {
            this.selectedIcon = data['icon'];
        });


        this.onSelectedEntityChangedSubscription =
            this.groupsService.onSelectedEntityChanged
                .subscribe(selectedEntity => {
                    this.hasSelectedEntity = selectedEntity.length > 0;
                });

        this.searchInput.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchText => {
            this.groupsService.onSearchTextChanged.next(searchText);
        });
    }

    ngOnDestroy(): any {
        if (this.onSelectedEntityChangedSubscription) {
            this.onSelectedEntityChangedSubscription.unsubscribe();
        }

    }
}