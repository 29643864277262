
import {take} from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {IdeasWallService} from '../ideas-wall.service';
import {DataEntity} from 'octopus-connect';
import {Observable, Subscription} from 'rxjs';
import {IdeasWallDdService} from '../ideas-wall-dd.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-ideas-wall-main-view',
    templateUrl: './ideas-wall-main-view.component.html'
})
export class IdeasWallMainViewComponent implements OnInit {

    private wallId: number;

    ideas: DataEntity[];
    categories: DataEntity[];

    ideasGroups: number[];
    categoriesGroups: number[];

    filteredIdeas: DataEntity[];
    filteredCategories: DataEntity[];

    ideasSubscription: Subscription;
    categoriesSubscription: Subscription;

    @ViewChild('categoriesContainer') categoriesContainer: ElementRef;

    wallName = '';
    iconIdeaFilter: string;
    iconCategorieFilter: string;

    constructor(
        private route: ActivatedRoute,
        private wallService: IdeasWallService,
        private dragAndDrop: IdeasWallDdService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {

        this.dragAndDrop.mainView = this;

        this.route.params.subscribe((params: Params) => {
            this.wallId = +params['wallid'];

            const obs: Observable<DataEntity> = this.wallService.getWallAndSetItAsCurrent(this.wallId);

            obs.subscribe(entity => {
                this.wallName = entity.get('name');
                this.wallService.currentIdeasFilterMode = entity.get('ideasMode') || 'collective';
                this.wallService.currentCategoriesFilterMode = entity.get('categoriesMode') || 'collective';

                this.wallService.currentTeacherIdeasFilterMode = entity.get('teacherIdeasMode') || 'collective';
                this.wallService.currentTeacherCategoriesFilterMode = entity.get('teacherCategoriesMode') || 'collective';

                if (this.ideas) {
                    this.setIdeasFilters();
                }

                if (this.categories) {
                    this.setCategoriesFilter();
                }
            });

            obs.pipe(take(1)).subscribe(entity => {
                this.wallService.currentWall = entity;

                if (this.ideasSubscription) {
                    this.ideasSubscription.unsubscribe();
                    this.ideasSubscription = null;
                }

                if (this.categoriesSubscription) {
                    this.categoriesSubscription.unsubscribe();
                    this.categoriesSubscription = null;
                }

                this.ideasSubscription = this.wallService.loadIdeas().subscribe(entities => {
                    this.ideas = entities.sort((entity1, entity2) => entity1.get('position') - entity2.get('position')).slice();
                    this.wallService.completeIdeasList = this.ideas;

                    // groupes d'idées
                    if (this.isTeacher) {

                    }

                    this.setIdeasFilters();
                });

                this.categoriesSubscription = this.wallService.loadCategories().subscribe(entities => {
                    this.categories = entities.sort((entity1, entity2) => entity1.get('position') - entity2.get('position')).slice();
                    this.wallService.completeCategoriesList = this.categories;

                    // groupes de catégories


                    this.setCategoriesFilter();
                });
            });


        });
    }

    setIdeasFilters(): void {
        if (this.ideasFilterMode === 'collective') {
            this.filteredIdeas = this.ideas;
            this.iconIdeaFilter = 'contacts';
        } else if (this.ideasFilterMode === 'individual') {
            this.filteredIdeas = this.ideas.filter(idea => idea.get('uid') === this.currentUser.id);
            this.iconIdeaFilter = 'individual';
        } else if (this.ideasFilterMode === 'group' && !this.isTeacher) {
            this.filteredIdeas = this.ideas.filter(idea => this.wallService.isInMyGroup(idea));
            this.iconIdeaFilter = 'groupes';
        } else {
            this.filteredIdeas = this.ideas.slice();
        }

        const wallGroups: number[] = (this.wallService.currentWall.get('ideasDisplayedGroups') || []).map(g => +g);
        if (this.isTeacher && this.ideasFilterMode === 'group') {
            this.iconIdeaFilter = 'groupes';
            this.filteredIdeas = this.filteredIdeas.filter(ideaEntity => {
                if (+ideaEntity.get('uid') === +this.wallService.currentUser.id) {
                    return false;
                }

                const groups: number[] = ideaEntity.get('userGroups').map(g => +g);
                for (const group of wallGroups) {
                    if (groups.indexOf(group) !== -1) {
                        return true;
                    }
                }

                return false;
            });
        }
    }

    setCategoriesFilter(): void {

        if (this.categoriesFilterMode === 'collective') {
            this.iconCategorieFilter = 'contacts';
            this.filteredCategories = this.categories;
        } else if (this.categoriesFilterMode === 'individual') {
            this.iconCategorieFilter = 'individual';
            this.filteredCategories = this.categories.filter(category => category.get('uid') === this.currentUser.id);
        } else if (this.categoriesFilterMode === 'group' && !this.isTeacher) {
            this.iconCategorieFilter = 'groupes';
            this.filteredCategories = this.categories.filter(category => this.wallService.isInMyGroup(category));
        } else {
            this.filteredCategories = this.categories.slice();
        }

        const wallGroups: number[] = (this.wallService.currentWall.get('categoriesDisplayedGroups') || []).map(g => +g);

        if (this.isTeacher && this.categoriesFilterMode === 'group') {
            this.iconCategorieFilter = 'groupes';
            this.filteredCategories = this.filteredCategories.filter(categoryEntity => {
                if (+categoryEntity.get('uid') === +this.wallService.currentUser.id) {
                    return false;
                }

                const groups: number[] = categoryEntity.get('userGroups').map(g => +g);

                for (const group of wallGroups) {
                    if (groups.indexOf(group) !== -1) {
                        return true;
                    }
                }

                return false;
            });
        }
    }

    get displayMode(): string {
        return this.wallService.currentDisplayMode;
    }

    set displayMode(value: string) {
        this.wallService.currentDisplayMode = value;
    }

    get currentUser(): DataEntity {
        return this.wallService.currentUser;
    }

    get ideasFilterMode(): string {
        if (this.lockedIdeas) {
            return 'collective';
        } else {
            return this.wallService.isTeacher ? this.wallService.currentTeacherIdeasFilterMode : this.wallService.currentIdeasFilterMode;
        }

    }

    set ideasFilterMode(value: string) {
        this.wallService.currentIdeasFilterMode = value;
        this.setIdeasFilters();
    }

    get categoriesFilterMode(): string {
        if (this.lockedCategories) {
            return 'collective';
        } else {
            return this.wallService.isTeacher ? this.wallService.currentTeacherCategoriesFilterMode : this.wallService.currentCategoriesFilterMode;
        }
    }

    set categoriesFilterMode(value: string) {
        this.wallService.currentCategoriesFilterMode = value;
        this.setCategoriesFilter();
    }

    get displayIdeas(): boolean {
        return this.displayMode === 'ideas' || this.displayMode === 'mixed';
    }

    get displayCategories(): boolean {
        return this.displayMode === 'categories' || this.displayMode === 'mixed';
    }

    get placeholderHeight(): number {
        return this.dragAndDrop.overPlaceholderHeight;
    }

    get overCategoriesContainer(): boolean {
        return this.dragAndDrop.overCategoriesContainer;
    }

    displayRemoteController(evt: PointerEvent): void {
        this.wallService.remoteControllerDisplay = !this.wallService.remoteControllerDisplay;
        evt.stopPropagation();
        evt.preventDefault();
    }

    get isTeacher(): boolean {
        return this.wallService.isTeacher;
    }

    get teacherMode(): string {
        return this.wallService.currentTeacherMode;
    }

    set teacherMode(value: string) {
        this.wallService.currentTeacherMode = value;
        this.setCategoriesFilter();
        this.setIdeasFilters();
    }

    get locked(): boolean {
        return this.wallService.locked;
    }

    get lockedIdeas(): boolean {
        return this.wallService.currentWall.get('locked') || this.wallService.currentWall.get('locked_item');
    }

    get lockedCategories(): boolean {
        return this.wallService.currentWall.get('locked') || this.wallService.currentWall.get('locked_category');
    }

    hideRemote(): void {
        this.wallService.remoteControllerDisplay = false;
    }

    get remoteDisplay(): boolean {
        return this.wallService.remoteControllerDisplay;
    }
}