import {take, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {HttpClient} from '@angular/common/http';
import {LoadTemplateDataPipe} from "fuse-core/pipes/load-template-data.pipe";
import {CustomBlockBasicPage} from "fuse-core/components/basic-page/custom-blocks/custom-block.model";
import {FieldBlock} from "fuse-core/components/basic-page/custom-blocks/field-block/field-block.model";
import {ButtonBlock} from "fuse-core/components/basic-page/custom-blocks/button-block/button-block.model";
import {LoadTemplateComponentPipe} from "fuse-core/pipes/load-template-component.pipe";

const {detect} = require('detect-browser');

@Component({
    selector: 'app-modal-page',
    templateUrl: './modal-page.component.html',
    styleUrls: ['./modal-page.component.scss']
})
export class ModalPageComponent implements OnInit, OnDestroy {

    public alias: string;
    public icon: string;
    private unsubscribeInTakeUntil = new Subject<void>();
    public page: DataEntity;
    public blocks: CustomBlockBasicPage[] = [];
    public nextFieldBlock: ButtonBlock;
    public downloading = false;
    public isToaster: false;
    private timer: any;
    private time: 10000;
    public labelConfirmDialog: string;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ModalPageComponent>,
        private translate: TranslateService,
        private loadTemplateDataPipe: LoadTemplateDataPipe,
        private loadTemplateComponentPipe: LoadTemplateComponentPipe,
        private domSanitizer: DomSanitizer,
        private http: HttpClient,
        private pageService: BasicPageService
    ) {
        if (data && data.alias) {
            this.alias = data.alias;

            // TODO based on mock data, update when using real data
            if (this.alias.indexOf('page/') === 0) {
                this.alias = this.alias.substring(5);
            }
        }

        if (data && data.icon) {
            this.icon = data.icon;
        }

        if (data && data.isToaster) {
            this.isToaster = data.isToaster;
            this.timer = setTimeout(() => {
                clearTimeout(this.timer);
                this.dialogRef.close();
            }, data.time ? data.time : this.time);
        }

        if (data && data.labelConfirmDialog){
            this.labelConfirmDialog = data.labelConfirmDialog;
        }


    }

    ngOnInit(): void {
        if (this.alias) {
            this.getPage();

            this.translate.onLangChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
                this.getPage();
            });
        }
    }

    getPage(): void {
        this.pageService.loadPage(this.alias).subscribe((page) => {
            this.page = page;

            this.loadTemplateComponentPipe
                .transform(this.page.get('body'))
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((components) => {
                    this.page.attributes['components'] = components;
                });

            this.loadTemplateDataPipe
                .transform(this.page.get('body'))
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((bodyWithData) => {
                    this.page.attributes['body'] = this.domSanitizer.bypassSecurityTrustHtml(bodyWithData);
                });

            this.page.get('pages').forEach((subpage) => {
                this.loadTemplateDataPipe
                    .transform(subpage.body)
                    .pipe(takeUntil(this.unsubscribeInTakeUntil))
                    .subscribe((bodyWithData) => {
                        subpage.body = this.domSanitizer.bypassSecurityTrustHtml(bodyWithData);
                    });
            });

            this.blocks = this.page.get('blocks');
            const fieldGroups = {};
            let fieldBlock: FieldBlock;
            let fieldBlockArray: FieldBlock[];

            if (this.blocks) {
                this.blocks.forEach((block) => {
                    if (block.type === 'field') {
                        fieldBlock = block as FieldBlock;
    
                        if (!fieldGroups[fieldBlock.group]) {
                            fieldGroups[fieldBlock.group] = [];
                        }
    
                        fieldBlockArray = fieldGroups[fieldBlock.group];
                        if (fieldBlockArray.length > 0) {
                            fieldBlockArray[fieldBlockArray.length - 1].next = fieldBlock;
                        }
                        fieldBlock.next = null;
    
                        fieldBlockArray.push(fieldBlock);
                    }
                });
    
                for (let group in fieldGroups) {
                    fieldBlockArray = fieldGroups[group];
                    for (let i = 1; i < fieldBlockArray.length; i += 1) {
                        this.blocks.splice(this.blocks.indexOf(fieldBlockArray[i]), 1);
                    }
                }
    
                this.nextFieldBlock = {
                    type: 'button',
                    text: 'generic.onboarding_next_field',
                    button: false,
                }
            }
        });
    }

    public hasActions(): boolean {
        return !!this.page?.get('document') || !!this.labelConfirmDialog || this.blocks?.length > 0;
    }

    downloadDocument(): void {
        this.downloading = true;

        this.http
            .get(this.page.get('document'), {responseType: 'blob'})
            .subscribe((data: Blob) => {
                const browser = detect();
                const link = document.createElement('a');
                let pdfData;

                link.target = '_blank';
                link.download = this.page.get('document').substr(this.page.get('document').lastIndexOf('/') + 1);
                link.setAttribute('visibility', 'hidden');

                if (['ie', 'edge'].indexOf(browser.name) === -1) {
                    pdfData = window.URL.createObjectURL(data);
                    link.href = pdfData;
                } else {
                    link.href = this.page.get('document');
                }

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                if (pdfData) {
                    window.URL.revokeObjectURL(pdfData);
                }

                this.downloading = false;
            });
    }

    public closeModal(callback?: () => void) {
        if (callback) {
            this.dialogRef.afterClosed().pipe(take(1))
                .subscribe(() => {
                    callback();
                });
        }

        this.dialogRef.close();
    }

    public hasNext(block: FieldBlock) {
        return block.type === 'field' && !!block.next;
    }

    public nextOrCloseModal(block: FieldBlock) {
        if (block.next) {
            this.blocks.splice(this.blocks.indexOf(block), 1, block.next);
        } else {
            this.closeModal();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}