import {ModuleWithProviders, NgModule, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {Roles} from 'shared/models';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {OctopusConnectModule} from 'octopus-connect';
import {ActivitiesComponent} from '@modules/activities/core/activities.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {ActivitiesListComponent} from './activities-list/activities-list.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {DragulaModule} from 'ng2-dragula';
import {FlagService} from 'shared/flag.service';
import {ActivityServicesModule} from '@modules/activities/core/services/activity-services.module';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {DialogComponent} from './shared-components/dialog/dialog.component';
import {ArchiveService} from 'shared/archive.service';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from 'shared/shared.module';
import {ChaptersModule} from 'fuse-core/components/chapters-selection/chapters.module';
import {TagsModule} from 'fuse-core/components/tags-selection/tags.module';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';
import {TraceService} from 'shared/trace.service';
import {SearchFiltersModule} from 'fuse-core/components';
import {ActivitiesHomeComponent} from '@modules/activities/core/activities-home/activities-home.component';
import {ActivitiesListEducationalLevelComponent} from '@modules/activities/core/activities-list-educational-level/activities-list-educational-level.component';
import {LessonCardComponent} from '@modules/activities/core/activities-home/lesson-card/lesson-card.component';
import {AssignmentsListComponent} from '@modules/activities/core/activities-home/assignments-list/assignments-list.component';
import {ConceptCardComponent} from '@modules/activities/core/activities-home/concept-card/concept-card.component';
import {MyProgressComponent} from '@modules/activities/core/my-progress/my-progress/my-progress.component';
import {MyCurrentProgressComponent} from '@modules/activities/core/my-progress/my-current-progress/my-current-progress.component';
import {MyStatisticProgressComponent} from '@modules/activities/core/my-progress/my-statistic-progress/my-statistic-progress.component';
import {MyCurrentProgressThumbnailComponent} from '@modules/activities/core/my-progress/my-current-progress-thumbnail/my-current-progress-thumbnail.component';
import {RewardPageComponent} from '@modules/activities/core/reward-page/reward-page.component';
import {EndScreenScoreComponent} from '@modules/activities/core/end-screen-score/end-screen-score.component';
import {RewardUnlockedComponent} from '@modules/activities/core/reward-unlocked/reward-unlocked.component';
import {ContinueBarComponent} from '@modules/activities/core/activities-home/continue-bar/continue-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '@modules/activities/core/models/rules';
import { NewLearnerStartLessonComponent } from './onboarding/popups/new-learner-start-lesson/new-learner-start-lesson.component';
import { OldLearnerStartLessonsComponent } from './onboarding/popups/old-learner-start-lessons/old-learner-start-lessons.component';
import { OldLearnerStartSubLessonsComponent } from './onboarding/popups/old-learner-start-sub-lessons/old-learner-start-sub-lessons.component';
import { NewLearnerStartSubLessonsComponent } from './onboarding/popups/new-learner-start-sub-lessons/new-learner-start-sub-lessons.component';
import {FeedbackDialogComponent} from '@modules/activities/core/feedback-dialog/feedback-dialog.component';
import {MatSliderModule} from '@angular/material/slider';
import {DictionaryComponent} from '@modules/activities/core/dictionary/dictionary.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { LanguageLabComponent } from './language-lab/language-lab.component';
import { VoiceRecordingModalComponent } from './language-lab/voice-recording-modal/voice-recording-modal.component';
import { DictionaryKeyboardComponent } from "@modules/activities/core/dictionary/app-dictionary-keyboard/dictionary-keyboard.component";
import {LanguageLabCardComponent} from '@modules/activities/core/language-lab/language-lab-card/language-lab-card.component';
import {LanguageLabCardPlayerComponent} from '@modules/activities/core/language-lab/language-lab-card-player/language-lab-card-player.component';
import {ProgressBarGenericModule} from 'fuse-core/components/progress-bar-generic/progress-bar-generic.module';
import {DialogResumeComponent} from '@modules/activities/core/language-lab/dialog-resume/dialog-resume.component';
import {DialogResumeActionMenuComponent} from '@modules/activities/core/language-lab/dialog-resume-action-menu/dialog-resume-action-menu.component';
import {ClosingPopUpComponent} from '@modules/activities/core/language-lab/closing-pop-up/closing-pop-up.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FeedbackDialogByStepsComponent} from '@modules/activities/core/feedback-dialog-by-steps/feedback-dialog-by-steps.component';

const routes: Routes = [
    {
        path: 'activities',
        canActivate: [IsUserLogged],
        component: ActivitiesComponent,
        children: [
            {
                path: 'list',
                component: ActivitiesListComponent
            },
            {
                path: 'dictionary',
                canActivate: [IsUserLogged],
                component: DictionaryComponent
            },
            {
                path: 'language-lab',
                canActivate: [IsUserLogged],
                component: LanguageLabComponent
            }
        ]
    },
    {
        path: 'my-progress',
        canActivate: [IsUserLogged],
        component: MyProgressComponent
    },

];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        OctopusConnectModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        DragulaModule.forRoot(),
        ActivityServicesModule.forRoot(),
        PlayersModule,
        ActivitiesPipesModule,
        ChaptersModule,
        TagsModule,
        EditorsModule,
        SearchFiltersModule,
        MatProgressBarModule,
        MatSliderModule,
        InfiniteScrollModule,
        ProgressBarGenericModule,

    ],
    declarations: [
        ActivitiesComponent,
        DialogComponent,
        EndScreenScoreComponent,
        ActivitiesHomeComponent,
        ActivitiesListEducationalLevelComponent,
        LessonCardComponent,
        AssignmentsListComponent,
        ConceptCardComponent,
        MyCurrentProgressComponent,
        MyStatisticProgressComponent,
        MyCurrentProgressThumbnailComponent,
        RewardPageComponent,
        RewardUnlockedComponent,
        ContinueBarComponent,
        MyProgressComponent,
        NewLearnerStartLessonComponent,
        OldLearnerStartLessonsComponent,
        OldLearnerStartSubLessonsComponent,
        NewLearnerStartSubLessonsComponent,
        FeedbackDialogComponent,
        FeedbackDialogByStepsComponent,
        DictionaryComponent,
        DictionaryKeyboardComponent,
        LanguageLabComponent,
        LanguageLabCardComponent,
        LanguageLabCardComponent,
        DialogResumeComponent,
        VoiceRecordingModalComponent,
        LanguageLabCardPlayerComponent,
        DialogResumeActionMenuComponent,
        ClosingPopUpComponent
    ],
    exports: [RouterModule, EndScreenScoreComponent],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }]

})
export class ActivitiesModule {

    private static isMenuSet = false;

    private dynamicNavigation = inject(DynamicNavigationService)
    private authService = inject(AuthenticationService)
    private communicationCenter = inject(CommunicationCenterService)
    private lessonsConfigurationService = inject(LessonsConfigurationService)
    private authorizationService = inject(AuthorizationService)

    constructor(
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

    }

    static forRoot(): ModuleWithProviders<ActivitiesModule> {
        return {
            ngModule: ActivitiesModule,
            providers: [
                ArchiveService,
                FlagService,
                TraceService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        ActivitiesModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'tools');
        this.dynamicNavigation.clearMenuItem('level0', 'activities');
    }

    private postAuthentication(): void {
        const role = this.authService.accessLevel as Roles;

        if (!ActivitiesModule.isMenuSet &&
            (this.authService.hasLevel(['trainer', 'manager', 'administrator']) || this.lessonsConfigurationService.settings.accessMatrix.activitiesListing.view.includes(role))
        ) {
            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'activities',
                'title': 'activities',
                'translate': 'activities.title',
                'type': 'item',
                'icon': 'activity',
                'url': '/activities/list'
            });

            this.dynamicNavigation.registerModuleMenu('level0', {
                'id': 'tools',
                'title': 'Outils',
                'translate': 'generic.tools',
                'type': 'collapse',
                'icon': 'outils2',
            });

            if ( this.authorizationService.currentUserCan<boolean>(SyncRules.AccessFormsModels) ) {
                this.dynamicNavigation.addChildTo('level0', 'tools', {
                    'id': 'param-child',
                    'title': 'Modèles de formulaires de séances',
                    'translate': 'activities.forms_model_title',
                    'type': 'item',
                    'url': '/forms/list',
                    'weight': 1
                });
            }

            ActivitiesModule.isMenuSet = true;
        }
    }
}