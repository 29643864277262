import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-ideas-wall-edition-modal',
    templateUrl: './ideas-wall-edition-modal.component.html',
    styleUrls: ['./ideas-wall-edition-modal.component.scss']
})
export class IdeasWallEditionModalComponent implements OnInit {

    ideaForm: UntypedFormGroup;
    name: string;
    text: string;
    phase: string;

    lessonsTitles: { id: number, label: string }[] = [];
    idLessonTitleSelected: number = null;
    sessionSteps: { id: number, label: string }[] = [];
    idSessionStepSelected: number = null;

    fieldsAllowed: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<IdeasWallEditionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private formBuilder: UntypedFormBuilder,
    ) {
        this.text = this.data['text'] || '';
        this.name = this.data['name'] || '';
        this.phase = this.data['phase'];
        this.lessonsTitles = this.data['lessonsTitles'];
        this.sessionSteps = this.data['sessionSteps'];
        this.idLessonTitleSelected = this.data['idLessonTitleSelected'] || '';
        this.idSessionStepSelected = this.data['idSessionStepSelected'] || '';
        this.fieldsAllowed = this.data['fieldsAllowed'];

        this.ideaForm = this.formBuilder.group({
            text: [this.text],
            name: [this.name],
            lessonsTitles: [this.idLessonTitleSelected.toString()],
            sessionSteps: [this.idSessionStepSelected.toString()]
        });
    }

    ngOnInit(): void {

    }

    validate(): void {
        this.dialogRef.close({
            name: this.name,
            text: this.text,
            idLessonTitle: this.ideaForm.value.lessonsTitles,
            idSessionStep: this.ideaForm.value.sessionSteps
        });
    }

    /**
     * check roles and field allowed for the current role if no rules for the current role use the default value
     * @param name: field to check in setting if exist or not
     */
    public displayField(name: string): boolean {
        return this.fieldsAllowed.indexOf(name) > -1;
    }
}