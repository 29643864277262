// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-resource-upload-modal-step3, app-chapter-selector {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/resource-upload-modal-step3/resource-upload-modal-step3.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/corpus/core/components/resource-upload-modal-step3/resource-upload-modal-step3.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;ACCJ","sourcesContent":["app-resource-upload-modal-step3, app-chapter-selector {\n    margin-top: 20px;\n}","app-resource-upload-modal-step3, app-chapter-selector {\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
