import {Component, inject, OnInit} from '@angular/core';
import {CompassService} from 'fuse-core/services/compass.service';
import {ActivatedRoute} from '@angular/router';
import {take, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {DataEntity} from 'octopus-connect';
import {MatDialog} from '@angular/material/dialog';
import {ReadPdfComponent} from 'fuse-core/components/read-pdf/read-pdf.component';
import {ModalWrapperMediaComponent} from '../modal-wrapper-media/modal-wrapper-media.component';

export interface Media {
    filemime: string;
    filename: string;
    title: string;
    url: string;
}

@Component({
    selector: 'app-compass-resource',
    templateUrl: './compass-resource.component.html',
})
export class CompassResourceComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    private compassService = inject(CompassService);
    private dialog = inject(MatDialog);
    private activatedRoute = inject(ActivatedRoute);

    public displayLoader = true;
    public resource: DataEntity;
    public goBackLinkName = '';

    /****** Lifecycle Hooks ******/

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.goBackLinkName = params.selectedThemeTitle;
            this.initialize(params.id);
        });
    }

    /****** Logic ******/

    public isMediaImage(media: Media): boolean {
        return ['png', 'jpg', 'jpeg', 'gif'].includes(media.filemime.split('/')[1]);
    }

    public isMediaVideo(media: Media): boolean {
        return media.filemime.includes('mp4');
    }

    public isMediaPdf(media: Media): boolean {
        return media.filemime.includes('pdf');
    }

    public getResourceType(resource: DataEntity, useSlug?: boolean): string {
        const key = useSlug ? 'slug' : 'name';
        if (resource.get('type') && resource.get('type')[0] && resource.get('type')[0][key]) {
            return resource.get('type')[0][key];
        }
        return '';
    }

    private initialize(id: string): void {
        this.displayLoader = true;
        this.compassService.loadResourceById(id)
            .pipe(
                take(1),
                tap((resource) => this.resource = resource),
                tap(() => this.displayLoader = false)
            )
            .subscribe();
    }

    public getBackQueryParams() {
        return this.activatedRoute.snapshot.queryParams.fromQueryParams ? JSON.parse(this.activatedRoute.snapshot.queryParams.fromQueryParams) : null;
    }

    public getBackUrl() {
        return this.activatedRoute.snapshot.queryParams.fromUrl ?? '/boussole';
    }

    /****** Event Handlers ******/

    onOpenPdfClick(media: Media): void {
        this.dialog.open(ReadPdfComponent, {
            data: {
                url: media.url
            },
            width: '80%',
            height: '80%',
            panelClass: 'read-pdf-dialog'
        });
    }

    onOpenVideoClick(media: Media): void {
        this.dialog.open(
            ModalWrapperMediaComponent,
            {
                data: {
                    controlsList: 'nodownload',
                    controls: true,
                    video: {
                        uri: media.url,
                        filemime: media.filemime,
                        subtitles: null
                    },
                    title: media.title
                },
                width: '80%',
                panelClass: 'shared-video-dialog'
            }
        );
    }

    onOpenImageClick(media: Media): void {
        this.dialog.open(
            ModalWrapperMediaComponent,
            {
                data: {
                    title: media.title,
                    image: {
                        uri: media.url,
                        filemime: media.filemime
                    }
                },
                width: '80%',
                panelClass: 'shared-image-dialog'
            }
        );
    }
}