import {take, takeUntil} from 'rxjs/operators';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommunicationCenterService} from '@modules/communication-center';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {AuthenticationService} from '@modules/authentication';
import {DataEntity} from 'octopus-connect';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {Router, RouterModule, Routes} from '@angular/router';
import {AssignationComponent} from './components/assignation/assignation.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'shared/shared.module';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {FollowedListComponent} from './components/followed-list/followed-list.component';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {FlagService} from 'shared/flag.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AssignationService} from '@modules/assignation/core/services/assignation.service';
import {AssignmentComponentsService} from '@modules/assignation/core/services/assignment-components.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {WidgetAssignmentsLessonComponent} from '@modules/assignation/core/components/widget/widget-assignments-lesson/widget-assignments-lesson.component';
import {AssignmentWarningModalComponent} from './components/assignment-warning-modal/assignment-warning-modal.component';
import {
    WidgetAssignmentsLearnersWithoutActiveAssignmentComponent
} from './components/widget/widget-assignments-learners-without-active-assignment/widget-assignments-learners-without-active-assignment.component';
import {FollowedTabsComponent} from './components/followed-tabs/followed-tabs.component';
import {FollowedLogBookComponent} from './components/followed-log-book/followed-log-book.component';
import {SearchFiltersModule} from 'fuse-core/components';
import {FollowedFilterComponent} from './components/followed-filter/followed-filter.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AssignmentAdaptativePopinComponent} from './components/assignment-adaptative-popin/assignment-adaptative-popin.component';
import {AssignationAuthorizationService} from '@modules/assignation/core/services/assignation-authorization.service';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/assignation/core/models/rules';
import {Observable, Subject} from 'rxjs';
import {SelfAssignmentsListComponent} from './components/self-assignments-list/self-assignments-list.component';
import {GenericAssignmentsListComponent} from './components/generic-assignments-list/generic-assignments-list.component';
import {NoAssignmentComponent} from './onboarding/components/no-assignment/no-assignment.component';
import {NoAssignmentForLongTimeAgoNewsComponent} from './onboarding/components/no-assignment-for-long-time-ago-news/no-assignment-for-long-time-ago-news.component';
import {LetsTryAssignmentComponent} from './onboarding/components/lets-try-assignment/lets-try-assignment.component';
import { RemoveMultipleAssignmentsConfirmComponent } from './components/remove-multiple-assignments-confirm/remove-multiple-assignments-confirm.component';
import { DynamicWrapperComponent } from './components/followed-list/dynamic-wrapper/dynamic-wrapper.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AssignationGroupsListComponent } from './components/assignation-groups-list/assignation-groups-list.component';
import { AssignationGroupsDetailComponent } from './components/assignation-groups-detail/assignation-groups-detail.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { AssignationDetailComponent } from './components/assignation-groups-detail/assignation-detail/assignation-detail.component';
import { UsersavesListComponent } from './components/usersaves-list/usersaves-list.component';
import { MyActivitiesWelcomeComponent } from './onboarding/components/my-activities-welcome/my-activities-welcome.component';
import {AssignationRoutes} from "@modules/assignation/core/assignation.routes";
import { AssignmentByStepsComponent } from './components/assignment-by-steps/assignment-by-steps.component';
import { AssignmentSelectSeancesComponent } from './components/assignment-by-steps/assignment-select-seances/assignment-select-seances.component';
import { AssignmentSelectAssignatedComponent } from './components/assignment-by-steps/assignment-select-assignated/assignment-select-assignated.component';
import { AssignmentSettingsChoiceComponent } from './components/assignment-by-steps/assignment-settings-choice/assignment-settings-choice.component';
import { AssignmentAddRulesValidationComponent } from './components/assignment-by-steps/assignment-add-rules-validation/assignment-add-rules-validation.component';
import { AssignmentManageDatesComponent } from './components/assignment-by-steps/assignment-manage-dates/assignment-manage-dates.component';
import { AssignmentSelectOneSeanceComponentComponent } from './components/assignment-by-steps/assignment-select-one-seance-component/assignment-select-one-seance-component.component';

const routes: Routes = [
    {
        path: 'followed',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: GenericAssignmentsListComponent
            },
            {
                path: 'tab',
                component: FollowedTabsComponent,
                children: [
                    {
                        path: AssignationRoutes.Self,
                        component: SelfAssignmentsListComponent
                    },
                    {
                        path: AssignationRoutes.List,
                        component: GenericAssignmentsListComponent
                    },
                    {
                        path: AssignationRoutes.Closed,
                        component: GenericAssignmentsListComponent,
                        data: {
                            isAssignationClosedActive: true
                        }
                    },
                    {
                        path: AssignationRoutes.UserSaves,
                        component: UsersavesListComponent
                    },
                    {
                        path: AssignationRoutes.GroupsList,
                        component: AssignationGroupsListComponent
                    },
                    {
                        path: AssignationRoutes.GroupsListDetail,
                        component: AssignationGroupsDetailComponent
                    },
                    {
                        path: AssignationRoutes.LogBook,
                        component: FollowedLogBookComponent
                    },
                    {
                        path: '**',
                        redirectTo: '',
                        pathMatch: 'full'
                    }
                ]
            },
            {
                path: 'assignment',
                children: [
                    {
                        path: '',
                        loadChildren: () => import('@modules/activities/core/lessons/lessons.module').then(m => m.LessonsModule)
                    }
                ]
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'list'
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        CommonSharedModule,
        NgxMaterialTimepickerModule,
        MatProgressSpinnerModule,
        SearchFiltersModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatButtonToggleModule,
        CarouselModule
    ],
    declarations: [
        AssignationComponent,
        FollowedListComponent,
        WidgetAssignmentsLessonComponent,
        AssignmentWarningModalComponent,
        WidgetAssignmentsLearnersWithoutActiveAssignmentComponent,
        FollowedTabsComponent,
        FollowedLogBookComponent,
        FollowedFilterComponent,
        NoAssignmentForLongTimeAgoNewsComponent,
        AssignmentAdaptativePopinComponent,
        SelfAssignmentsListComponent,
        GenericAssignmentsListComponent,
        NoAssignmentComponent,
        LetsTryAssignmentComponent,
        RemoveMultipleAssignmentsConfirmComponent,
        AssignationGroupsListComponent,
        AssignationGroupsDetailComponent,
        AssignationDetailComponent,
        DynamicWrapperComponent,
        UsersavesListComponent,
        MyActivitiesWelcomeComponent,
        AssignmentByStepsComponent,
        AssignmentSelectSeancesComponent,
        AssignmentSelectAssignatedComponent,
        AssignmentSettingsChoiceComponent,
        AssignmentAddRulesValidationComponent,
        AssignmentManageDatesComponent,
        AssignmentSelectOneSeanceComponentComponent
    ],
    exports: [
        RouterModule,
        WidgetAssignmentsLessonComponent,
        WidgetAssignmentsLearnersWithoutActiveAssignmentComponent
    ],
    providers: [
        FollowedFilterComponent
    ]
})
export class AssignationModule {
    private static isMenuSet = false;
    private onLogout$: Subject<void> = new Subject<void>();

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private authorization: AuthorizationService,
        private assignationAuthorizationService: AssignationAuthorizationService,
        private communicationCenter: CommunicationCenterService,
        private assignationService: AssignationService,
        private router: Router
    ) {
        this.assignationAuthorizationService.activeRulesOnstartUp();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'NEW_ASSIGNATION',
                icon: 'activity',
                text: 'assignment.notification_new_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        userName: data['userName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    this.assignationService.loadAndGetAssignmentById(data['id']).pipe(
                        take(1))
                        .subscribe((assignment: DataEntity) => {
                            this.assignationService.launchAssignment(assignment);

                            const node = assignment.get('assignated_node');
                            const route = [];

                            if (data['project']) {
                                route.push(...['projects', data['project'], 'tools']);
                            }

                            route.push(...['followed', 'assignment', `${node.type}s`, node.id, 'player']);

                            this.router.navigate(route);
                        });
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .next('widgetAssignmentsLessonComponent', WidgetAssignmentsLessonComponent);

        this.communicationCenter
            .getRoom('assignation')
            .next('WidgetAssignmentsLearnersWithoutActiveAssignmentComponent', WidgetAssignmentsLearnersWithoutActiveAssignmentComponent);
    }


    static forRoot(): ModuleWithProviders<AssignationModule> {

        return {
            ngModule: AssignationModule,
            providers: [
                AssignationService,
                AssignmentComponentsService,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        AssignationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'followed');
        this.dynamicNavigation.clearMenuItem('level0', 'followed-tab');
        this.onLogout$.next();
        this.onLogout$.complete();
    }

    private postAuthentication(): void {
        this.onLogout$ = new Subject<void>();

        if (!AssignationModule.isMenuSet) {
            this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.ReadAssignments)
                .pipe(takeUntil(this.onLogout$))
                .subscribe((isAuthorized) => {
                    if (isAuthorized) {
                        this.dynamicNavigation.registerModuleMenu('level0', {
                            'id': 'followed',
                            'title': 'Assignations',
                            'translate': 'assignment.title',
                            'type': 'item',
                            'icon': 'assignment',
                            'url': '/followed'
                        });

                        this.dynamicNavigation.registerModuleMenu('level0', {
                            'id': 'followed-tab',
                            'title': 'Assignations',
                            'translate': 'assignment.title',
                            'type': 'item',
                            'icon': 'assignment',
                            'url': '/followed/tab'
                        });
                    } else {
                        this.dynamicNavigation.clearMenuItem('level0', 'followed');
                        this.dynamicNavigation.clearMenuItem('level0', 'followed-tab');
                    }

                    AssignationModule.isMenuSet = true;
                });
        }
    }
}