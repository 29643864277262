// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 100%;
  height: 100%;
  min-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-grid/activity-edition-point-img-grid.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/activity-edition/core/ui/typologies-form/activity-edition-point-img/activity-edition-point-img-grid/activity-edition-point-img-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;ACCJ","sourcesContent":[".grid-container {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(4, 1fr);\n    width: 100%;\n    height: 100%;\n    min-height: 300px;\n}\n",".grid-container {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: repeat(4, 1fr);\n  width: 100%;\n  height: 100%;\n  min-height: 300px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
