import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {TypologiesService} from '@modules/activities/core/typologies/typologies.service';
import {takeUntil, take} from 'rxjs/operators';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {AuthenticationService} from '@modules/authentication';
import {ChaptersSelectionComponent} from '@fuse/components/chapters-selection/chapters-selection.component';
import {CollectionOptionsInterface} from 'octopus-connect';
import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataCollection, DataEntity} from 'octopus-connect';
import {UntypedFormControl} from '@angular/forms';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import { MatDialog} from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';
import {Subject, Subscription, Observable} from 'rxjs';
import {TagsSelectionModalWrapperComponent} from 'fuse-core/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component';
import {TranslateService} from '@ngx-translate/core';
import {Roles} from 'shared/models';
import {defaultLoginRoute} from '../../../../settings';
import {isEmpty} from 'shared/utils';
import {CollectionPaginator} from 'octopus-connect';
import {localizedDate} from 'shared/utils/datetime';

@Component({
    selector: 'fuse-app-activities-list',
    templateUrl: './activities-list.component.html',
})

export class ActivitiesListComponent implements OnInit, OnDestroy {


    @ViewChild('typeSelect') typeSelect: MatSelect;
    @ViewChild('titleInput') titleInput: MatInput;

    public authService = inject(AuthenticationService);
    public lessonsService = inject(LessonsService);
    private activityService = inject(ActivitiesService);
    private dialog = inject(MatDialog);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private typologiesService = inject(TypologiesService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);

    public allControls: any;
    public allTypes = [];
    public buttonDisabled: boolean;
    public checkboxes: {};
    public countEntities = 0;
    public dataSource = new MatTableDataSource();
    public displayedColumns;
    public displayedFiltersIcons;
    public methods: any;
    public pageIndex = 0;
    public pageRangeOptions = [10];
    public selectAll = false;
    public tags: any;
    public tagsSelected: object = {concepts: [], skills: [], chapters: []};

    private dialogRef: any;
    private displayedFilters: string[] = [];
    private allowedActivityTypes: string[] = [];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
        range: 10
    };
    private ressources;
    private ressourcesSubscription: Subscription;
    private selectedActivities: DataEntity[] = [];
    private selectedResources: DataEntity[] = [];
    private unsubscribeInTakeUntil = new Subject<void>();
    private eventCheckboxes: any[] = [];

    ngOnInit(): void {
        this.activityService.licensingMethods.pipe(take(1)).subscribe((methods) => {
            const noLicensingMethods = this.activityService.licensingSettings
                && this.activityService.licensingSettings.restrict.includes('activities-list')
                && methods.length === 0;

            if (this.authService.isTrainer() && noLicensingMethods) {
                this.router.navigate(['/licensing-restricted']);
            }
        });

        this.buttonDisabled = true;
        this.displayedFiltersIcons = this.lessonsConfigurationService.settings.displayedFiltersIcons;
        const role = this.authService.accessLevel as Roles;
        if (this.authService.isAtLeastTrainer() || this.lessonsConfigurationService.settings.accessMatrix.activitiesListing.view.includes(role)  ) {
            this.activityService.clearSelection();

            this.activityService.onSelectedResourcesChanged.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((data: any) => {
                    this.selectedActivities = [];
                    this.selectedResources = data.resources;
                    this.checkboxes = {};

                    if (this.lessonsConfigurationService.settings.multiSelectionForActivitiesList) {
                        for (const activity in data.resources) {
                            if (data.resources.hasOwnProperty(activity)) {
                                this.selectedActivities.push(data.resources[activity]);
                                this.checkboxes[data.resources[activity].id] = true;
                            }
                        }
                    } else {
                        if (data.resources.length) {
                            // uncheck previous selected checkboxe because we have unique selection
                            if (data.event && !this.eventCheckboxes.find((event) => event.source.id === data.event.source.id)) {
                                this.eventCheckboxes.push(data.event);
                                for (const event of this.eventCheckboxes) {
                                    if (event.source.id !== data.event.source.id) {
                                        event.source.checked = false;
                                    }
                                }
                            }
                            this.selectedActivities = data.resources[0];
                            this.checkboxes[data.resources[0].id] = true;
                        }
                    }

                    this.buttonDisabled = isEmpty(this.checkboxes);
                });

            this.allowedActivityTypes = this.lessonsConfigurationService.settings.allowedActivityTypes;

            if (this.lessonsConfigurationService.settings.filters[this.authService.accessLevel]) {
                this.displayedFilters = this.lessonsConfigurationService.settings.filters[this.authService.accessLevel];
            } else {
                this.displayedFilters = this.lessonsConfigurationService.settings.filters['default'];
            }

            this.allControls = {
                typeFilter: {
                    type: 'typology',
                    control: new UntypedFormControl('')
                },
                titleFilter: {
                    type: 'urlExtension',
                    control: new UntypedFormControl('')
                },
                keywordsFilter: {
                    type: 'indexation',
                    control: new UntypedFormControl('')
                },
                sourceFilter: {
                    type: 'source',
                    control: new UntypedFormControl('')
                },
                usedFilter: {
                    type: 'used',
                    control: new UntypedFormControl('')
                },
            };

            this.loadTypes();
            // this.ressourcesSubscription = this.refreshList();

            this.getMethods();

            this.translate.onLangChange.subscribe(() => {
                this.loadTypes();
                this.getMethods();
                this.refreshList();
            });
        } else {
            this.router.navigate([defaultLoginRoute]);
        }
    }

    ngOnDestroy(): void {
        this.activityService.setSelectionMode();
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private loadTypes() {
        this.typologiesService.loadActivityTypes()
            .subscribe(collection => {
                if (collection.entities[0]) {
                    this.allTypes = collection.entities[0].get('activityTypes');
                    if (this.allowedActivityTypes.length > 0) {
                        const allowedTypesArray = [];
                        this.allTypes.forEach(type => {
                            const label = type['label'];
                            if (type) {
                                if (this.allowedActivityTypes.indexOf(label) > -1) {
                                    allowedTypesArray.push(type);
                                }
                            }
                        });
                        this.allTypes = allowedTypesArray;
                        this.applyFilters('all', 'typology');
                    }
                    this.ressourcesSubscription = this.refreshList();
                }
            });
    }

    public displayFilters(name: string): boolean {
        return this.displayedFilters.indexOf(name) > -1;
    }

    private getMethods(): void {
        this.activityService.getMethods().pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.methods = data.entities;
                }
            });
    }

    private applyFilters(event, type): void {
        if (type === 'urlExtension') {
            if (event.value === '') {
                delete this.optionsInterface.urlExtension;
                delete this.optionsInterface.filter['title'];
            } else {
                this.optionsInterface.filter['title'] = this.optionsInterface.urlExtension = event.value;
            }
        } else if (type === 'typology') {
            if (event.value === 'all' || !event.value) {
                delete this.optionsInterface.urlExtension;
                if (this.allowedActivityTypes.length > 0) {
                    this.optionsInterface.filter['typology'] = this.allTypes.map(entity => +entity.id);
                } else {
                    delete this.optionsInterface.filter[type];
                }
            } else {
                this.optionsInterface.filter['title'] = this.optionsInterface.urlExtension = event.value;
                this.optionsInterface.filter['typology'] = this.optionsInterface.urlExtension = event.value;
            }
        } else {
            if (event.value === 'all' || !event.value) {
                delete this.optionsInterface.filter[type];
                if (type === 'typology') {
                    if (this.allowedActivityTypes) {
                        this.optionsInterface.filter['typology'] = this.allTypes.map(entity => +entity.id);
                    }
                }
            } else {
                this.optionsInterface.filter[type] = event.value;
            }
        }
    }

    public blurElementRef(): void {
        this.typeSelect.close();
        this.titleInput.focus();
    }

    public launchSearch(): void {
        for (const field in this.allControls) {
            this.applyFilters({value: this.allControls[field].control.value}, this.allControls[field].type);
        }

        this.noCloneFilter();

        if (this.ressourcesSubscription) {
            this.ressourcesSubscription.unsubscribe();
        }

        this.ressourcesSubscription = this.refreshList();
    }

    /**
     * apply or not noClone Filter in regard of settings
     */
    private noCloneFilter(): void {
        if (this.lessonsConfigurationService.settings.activitiesNoClone) {
            this.optionsInterface.filter['activitiesNoClone'] = true;
        }
    }

    public resetTags(type: string): void {
        this.tagsSelected[type] = [];
        this.applyFilters({value: ''}, type);
        this.launchSearch();
    }

    public tagsPopUp(type): void {
        let component;
        let data;
        if (type === 'chapters') {
            component = ChaptersSelectionComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                methods: this.methods,
                loadChapters: (event) => this.activityService.getChapters(event),
                chaptersChanged: this.activityService.chaptersChanged
            };
        } else {
            component = TagsSelectionModalWrapperComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                tags: this.tags,
                loadTags: (event) => this.activityService.getTags(event),
                tagsChanged: this.activityService.tagsChanged

            };
        }

        this.dialogRef = this.dialog.open(component, {
            panelClass: 'entity-form-dialog',
            data: data
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tagsSelected[type] = result;
                this.applyFilters({value: result.map(o => o.id).join(',')}, type);
                this.launchSearch();
            }
        });
    }

    private refreshList(): Subscription {
        this.noCloneFilter();
        if (this.lessonsConfigurationService.settings.filterListWithInListBoolean){
            this.optionsInterface.filter['inList'] = 1;
        }

        return this.activityService.loadPaginatedActivities(this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resourcesAndPaginator: {entities: [], paginator: CollectionPaginator}) => {
                this.activityService.activities = resourcesAndPaginator.entities;
                this.selectAll = false;
                this.activityService.onSelectedResourcesChanged.next({resources: this.selectedResources, event: null});
                this.ressources = resourcesAndPaginator.entities;
                if (!resourcesAndPaginator.entities) {
                    return;
                }

                if (this.lessonsConfigurationService.settings.columns[this.authService.accessLevel]) {
                    this.displayedColumns = this.lessonsConfigurationService.settings.columns[this.authService.accessLevel];
                } else {
                    this.displayedColumns = this.lessonsConfigurationService.settings.columns['default'];
                }

                this.dataSource.data = resourcesAndPaginator.entities;

                this.setPaginator();

                this.selectAll = true;

                resourcesAndPaginator.entities.some((activity: DataEntity) => {
                    if (!this.checkboxes[activity.id]) {
                        this.selectAll = false;
                        return true;
                    }
                });
            });
    }

    private setPaginator(): void {
        if (this.activityService.activitiesPaginated.paginator) {
            this.countEntities = this.activityService.activitiesPaginated.paginator.count;
            this.pageIndex = this.activityService.activitiesPaginated.paginator.page - 1;
        }
    }

    public checkUsed(values): boolean {
        if (values && values.length > 0 && values.indexOf(this.activityService.userData.id) > -1) {
            return true;
        }
        return false;
    }

    public formatArrayToJoinString(values, key = 'label'): string {
        if (values && values.length > 0) {
            const vals = values.map(val => val[key]);
            return vals.join(',');
        }
        return '';
    }

    public onPaginateChange(event): void {
        this.activityService.activitiesPaginated.paginator.page = event.pageIndex + 1;
    }

    public get inSelectionMode(): boolean {
        return !!this.activityService.activitiesSelection;
    }

    /**
     * Whether we can add activities to lesson from listing or not, depending on setting
     * returns boolean
     */
    public get canAddToLesson(): boolean {
        return this.lessonsConfigurationService.settings.addFromActivities;
    }

    public addToLesson(): void {
        // TODO: plus disponible depuis le nouvel editeur
        // this.lessonsService.setActivitiesOfSelectedLesson(this.selectedResources);
        // if (this.lessonsConfigurationService.canOverrideStepMetadatasInLesson) {
        //     /**
        //      *unique selection, we have only one resource in this.selectedResources.
        //      * we load the selected activity granule (data different from endpoint "granule_search")
        //      */
        //     this.activityService.loadActivitiesFromId(this.selectedResources[0].id.toString())
        //         .pipe(
        //             mergeMap((granule) => this.activityService.openEditStepComponent(granule)),
        //             mergeMap(dialog => dialog.afterClosed()),
        //             filter(data => !!data),
        //             map((data) => {
        //                 // after close modal, store title and instruction
        //                 this.activityService.metadatasUsedForOverride.push(data);
        //                 this.router.navigateByUrl(this.activityService.activitiesSelection);
        //             })
        //         ).subscribe();
        // } else {
        //     this.router.navigateByUrl(this.activityService.activitiesSelection);
        // }

    }

    public onSelectedChange(activity: DataEntity, event?): void {
        this.activityService.toggleActivitySelection(activity, undefined, event);
    }

    public get selectedCount(): number {
        return this.activityService.selectionCount;
    }

    public updateCheck(): void {
        this.ressources.forEach((activity: DataEntity) => {
            this.activityService.toggleActivitySelection(activity, this.selectAll);
        });
    }

    /**
     * load activity or subLesson'activities for preview
     * @param {DataEntity} entity
     * @returns {any}
     */
    public launchPreview(entity: DataEntity): Observable<void>  {
        return this.activityService.internalLaunchPreview(entity.id, null, null, entity.get('title'));
    }

    public localeDate(date: number): string {
        return localizedDate(date);
    }

    public displayedFiltersLength(type: string): string {
        const tagsLength = this.tagsSelected[type].length;
        if (tagsLength) {
            return tagsLength.toString();
        }

        return 'generic.all';
    }

    public launchLessonListingModal(activityId): void {
        // TODO pas etre compatible avec le nouvel editeur
    }

    public getContentTypeIcon(data: DataEntity) {
        return this.activityService.getContentTypeIcon(data, 'activity');
    }

    public roundRate(rate: number | string) {
        if (rate !== '-') {
            return Math.round(+rate * 10) / 10;
        }

        return rate;
    }
}