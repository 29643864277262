import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {brand} from '../../../../app/settings';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class BlockHandlerService {

    constructor(private router: Router,
                private domSanitizer: DomSanitizer,
                private globalConfigurationService: GlobalConfigurationService) {
    }


    onLinkClick(event: MouseEvent, callback?: () => void) {
        const target = event.target as HTMLElement;
        let href: string | null = null;

        if (target.tagName.toLowerCase() === 'a') {
            href = target.getAttribute('href');
        } else if (target.tagName.toLowerCase() === 'area') {
            href = target.getAttribute('href');
        }

        if (href && !href.startsWith('http')) {
            event.preventDefault();
            this.router.navigateByUrl(href);

            if (callback) {
                callback();
            }
        }
    }

    public clean(value) {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }

    public getBlockPath(link: string, extraDataUrl = ''): string {
        if (!link) {
            return '';
        }
        let l = link.split('?')[0];
        //ctz-setting
        if (this.globalConfigurationService.getExtraDataUrlAllowed() && extraDataUrl) {
            l = l + extraDataUrl;
        }
        return l;
    }

    public getBlockQueryParams(link: string): any {
        if (!link) {
            return null;
        }

        const queryString = link.split('?')[1];
        if (!queryString) {
            return null;
        }

        return queryString.split('&').reduce((params, param) => {
            const [key, value] = param.split('=');
            params[key] = value;
            return params;
        }, {});
    }
}
