// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profile .content {
  flex: 1;
}
#profile .content mat-tab-group {
  height: 100%;
}
#profile .content mat-tab-group .mat-tab-body-wrapper {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/account-management/core/profile/profile.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/account-management/core/profile/profile.component.scss"],"names":[],"mappings":"AAGI;EACI,OAAA;ACFR;ADIQ;EACI,YAAA;ACFZ;ADIY;EACI,YAAA;ACFhB","sourcesContent":["@import \"@fuse/scss/fuse\";\n\n#profile {\n    .content {\n        flex: 1;\n\n        mat-tab-group {\n            height: 100%;\n\n            .mat-tab-body-wrapper {\n                flex-grow: 1;\n            }\n        }\n    }\n}\n","#profile .content {\n  flex: 1;\n}\n#profile .content mat-tab-group {\n  height: 100%;\n}\n#profile .content mat-tab-group .mat-tab-body-wrapper {\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
