import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BdService} from '../bd.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {BdRepositoryService} from '@modules/bdtool/core/bd-repository.service';
import {DomSanitizer} from '@angular/platform-browser';
import {defaultApiURL, defaultURL, brand} from '../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {BDToolService} from 'bdtool';

@Component({
    selector: 'app-bd-content-editor',
    templateUrl: './bd-content-editor.component.html',
})
export class BdContentEditorComponent implements OnInit, AfterViewChecked {
    /**
     * Bd title used as header
     */
    public bdTitle = '';

    /**
     * Current bd granule (from granule endpoint)
     */
    private bdGranule: DataEntity = null;
    public bdId: string | number;
    public assetsEndpoint: string;

    constructor(private activatedRoute: ActivatedRoute,
                private dialog: MatDialog,
                private bdSvc: BdService,
                private bdRepoSvc: BdRepositoryService,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private translate: TranslateService,
                private bdToolService: BDToolService) {
    }

    /**
     * Defined if the component a ready
     */
    public get isLoadingDone(): boolean {
        return this.bdGranule !== null;
    }

    ngOnInit(): void {
        const translatedTexts = {
            'bd.add_title': this.translate.instant('bd.add_title'),
            'bd.add_strip': this.translate.instant('bd.add_strip'),
            'bd.add_page': this.translate.instant('bd.add_page'),
            'bd.add_name': this.translate.instant('bd.add_name'),
            'bd.trash': this.translate.instant('bd.trash'),
            'bd.down': this.translate.instant('bd.down'),
            'bd.up': this.translate.instant('bd.up'),
            'bd.flip': this.translate.instant('bd.flip'),
            'bd.text': this.translate.instant('bd.text'),
            'bd.bubbles': this.translate.instant('bd.bubbles'),
            'bd.items': this.translate.instant('bd.items'),
            'bd.characters': this.translate.instant('bd.characters'),
            'bd.backgrounds': this.translate.instant('bd.backgrounds'),
            'generic.close': this.translate.instant('generic.close'),
            'generic.save': this.translate.instant('generic.save'),
            'generic.save_changes_no': this.translate.instant('generic.save_changes_no'),
            'generic.save_changes_yes': this.translate.instant('generic.save_changes_yes'),
            'generic.save_changes': this.translate.instant('generic.save_changes'),
            'generic.print': this.translate.instant('generic.print'),
        };
        this.bdToolService['texts'] = translatedTexts; // Direct assignment for simplicity
    }

    ngAfterViewChecked(): void {
        this.route.params.pipe(
            mergeMap(params => this.bdRepoSvc.getBd(params['bdId'])),
            tap(bdGranule => {
                this.initialize(bdGranule);
            })
        ).subscribe();
        if(this.isLoadingDone){
            this.redirect();
        }
    }

    /**
     * Close the component by redirecting to the bdList or by emit on the `Complete Subject`.
     * To access to the emit subject, the component need to have a subject unique id.
     * This unique id is used by the {@link BdService.onBdDataEditionCompleteSubject} to reference the subject.
     *
     * @remaks Sometimes, like by reloading component from nowhere but with the `onComplete queryParams` the on complete subject id can be not associated to a subject.
     */
    public redirect(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    if (this.bdSvc.onBdDataEditionCompleteSubject.hasOwnProperty(params['onComplete'])) {
                        this.bdSvc.onBdDataEditionCompleteSubject[params['onComplete']].next(this.bdGranule);
                        return;
                    }
                }

                this.bdSvc.goToBdList();
            })
        ).subscribe();
    }

    /**
     * Reset the component with default values defined by the granule.
     * Different than reset the content.
     * HACK there are no login in the iframe, the give it the current user token for the v1
     * @param bdGranule
     */
    private initialize(bdGranule: DataEntity): void {
        this.bdGranule = bdGranule;
        this.bdTitle = this.bdGranule.get('metadatas').title;
        this.bdId = this.bdGranule.get('reference').activity_content[0].id;
        // this.assetsEndpoint = defaultApiURL + 'api/assets_bd';
        this.assetsEndpoint = '/assets/' + brand + '/bd/assets_bd.json';
        // const token = localStorage.getItem('http_accessToken');
        // this.urlToBd = this.sanitizer.bypassSecurityTrustResourceUrl(environment.bdUrl + bdId + '?token=' + token.slice(1, -1));
    }
}