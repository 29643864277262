// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/getting-started/getting-started.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/@fuse/components/getting-started/getting-started.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;ACCJ","sourcesContent":["button {\n    margin-left: 8px;\n}","button {\n  margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
