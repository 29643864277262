// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100%;
}
:host > .header {
  flex: 0 1 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/corpus/core/components/corpus-sidenavs/corpus-main/corpus-main.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/corpus/core/components/corpus-sidenavs/corpus-main/corpus-main.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;ACCJ;ADCI;EACI,cAAA;EACA,4CAAA;ACCR","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    flex: 1 0 auto;\n    height: 100%;\n\n    > .header {\n        flex: 0 1 auto;\n        border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n    }\n}\n",":host {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  height: 100%;\n}\n:host > .header {\n  flex: 0 1 auto;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
