import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {delay, filter, map, take, tap} from 'rxjs/operators';
import {OctopusConnectService} from 'octopus-connect';
import {ConditionsService} from 'fuse-core/services/conditions.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '@modules/authentication';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';

const LOCAL_STORAGE_IDENTIFIER = 'onboarding-seen';

/**
 * @deprecated To be removed once all instances have switched to the new configuration system
 */
export interface HelpPageTable {
    [myRegExp: string]: string;
}

interface HelpPageConfiguration {
    url: string,
    pageId: string,
    condition?: string,
    auto?: boolean,
    forceOpen?: boolean,
    dependency?: string | HelpPageConfiguration,
}

interface HelpConfiguration {
    [pageURL: string]: HelpPageConfiguration[];
}

interface LocalStorageCacheInterface {
    [userId: string]: string[];
}

@Injectable({
    providedIn: 'root'
})

export class ButtonHelpService {
    private href = '';
    /**
     * @deprecated To be removed once all instances have switched to the new configuration system
     * @private
     */
    private mappingHelpPages: HelpPageTable;
    private configuration: HelpConfiguration = {};
    private setupConfiguration: HelpPageConfiguration[] = [];
    private newsConfiguration: HelpPageConfiguration[] = [];
    private urlRegExps: { url: string, regexp: RegExp }[] = [];
    private currentHelpPage: HelpPageConfiguration;
    private previousHelpPage: HelpPageConfiguration;
    private currentSetupPage: HelpPageConfiguration;
    private currentNewsPage: HelpPageConfiguration;
    private currentDialogRef: MatDialogRef<ModalPageComponent>;
    private seenPages: string[] = [];
    private user: UserDataEntity = null;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private conditionsService: ConditionsService,
        private communicationCenter: CommunicationCenterService,
        private octopusConnect: OctopusConnectService,
        private authService: AuthenticationService,
        public globalConfigurationService: GlobalConfigurationService,
    ) {
        this.getMappingHelpPages().subscribe((i) => this.mappingHelpPages = i);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((user: UserDataEntity) => {
                if (user) {
                    this.user = user;
                    this.postAuthentication();
                } else {
                    this.user = null;
                    this.postLogout();
                }
            });


        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.getCurrentHelpPage();
            });

        this.conditionsService.conditionUpdate$
            .subscribe(() => {
                this.getCurrentHelpPage();
            });
    }

    /**
     * Logic executed once user data is loaded
     * @private
     */
    private postAuthentication() {
        combineLatest([this.loadSeenPages(), this.getHelpPagesConfiguration()])
            .pipe(map(([seen, configuration]) => configuration))
            .subscribe((configuration: HelpConfiguration) => {
                this.configuration = configuration;

                this.processConfiguration();
                this.generateURLRegExps();

                this.getCurrentSetup();
                // this.getCurrentNews(); is executed in getCurrentSetup if there is no setup page
                // this.getCurrentHelpPage(); is executed in getCurrentNews if there is no news page
            });
    }

    /**
     * Logic executed once user data is cleared
     * @private
     */
    private postLogout() {
        if (this.currentDialogRef) {
            this.currentDialogRef.close(false);
        }

        this.mappingHelpPages = null;
        this.configuration = {};
        this.urlRegExps = [];
        this.currentHelpPage = null;
        this.previousHelpPage = null;
        this.currentSetupPage = null;
        this.currentNewsPage = null;

        this.seenPages = [];
    }

    /**
     * Opens a {@link MatDialog} with the current help page if available.
     * Records the page as seen when closed.
     */
    public openCurrentHelpPage(): void {
        if (this.currentDialogRef) {
            if (this.previousHelpPage !== this.currentHelpPage) {
                this.closeCurrentHelpPage();
            }
        }

        if (this.globalConfigurationService.getUseHelpPageCitizenLogic()) {
            this.openCurrentHelpPageCitizenLogic();
        } else {
            if (this.hasHelpPage() && !this.currentDialogRef) {
                this.currentDialogRef = this.dialog
                    .open(ModalPageComponent, {
                        backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                        panelClass: ['onboard-dialog'],
                        data: {
                            alias: this.currentHelpPage?.url || this.getHelpBasicPageAlias(),
                            icon: 'onboarding_header',
                        },
                        restoreFocus: false,
                    });

                this.currentDialogRef.afterClosed()
                    .subscribe((saveClose = true) => {
                        this.currentDialogRef = null;

                        if (this.currentHelpPage?.auto && saveClose) {
                            this.setSeenPage(this.currentHelpPage.pageId);
                        }
                    });
            }
        }
    }

    private openCurrentHelpPageCitizenLogic() {
        if (this.authService.isGAR() && this.currentHelpPage?.url === 'help_groups') {
            this.communicationCenter
                .getRoom('group')
                .getSubject('chooseGroup')
                .pipe(
                    take(1),
                    tap((component) => {
                        this.currentDialogRef = this.dialog
                            .open(component, {
                                data: this.authService.userData
                            });
                    }),
                )
                .subscribe();
        } else {
            this.currentDialogRef = this.dialog
                .open(ModalPageComponent, {
                    backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                    panelClass: ['onboard-dialog'],
                    data: {
                        alias: this.currentHelpPage?.url || this.getHelpBasicPageAlias(),
                        icon: 'onboarding_header',
                    },
                    restoreFocus: false,
                });

            this.currentDialogRef.afterClosed()
                .subscribe((saveClose = true) => {
                    this.currentDialogRef = null;
                    if (this.currentHelpPage?.auto && saveClose) {
                        this.setSeenPage(this.currentHelpPage.pageId);
                    }
                });
        }
    }

    public closeCurrentHelpPage(): void {
        if (this.currentDialogRef) {
            this.currentDialogRef.close(false);
        }
    }

    private openCurrentSetupPage(): void {
        if (this.currentSetupPage && !this.currentDialogRef) {
            this.currentDialogRef = this.dialog
                .open(ModalPageComponent, {
                    backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                    panelClass: ['onboard-dialog'],
                    data: {
                        alias: this.currentSetupPage.url,
                    },
                    restoreFocus: false,
                });

            this.currentDialogRef.afterClosed()
                .subscribe(() => {
                    this.currentDialogRef = null;

                    this.setSeenPage(this.currentSetupPage.pageId);
                    this.currentSetupPage = null;

                    this.getCurrentSetup();
                });
        }
    }

    private openCurrentNewsPage(): void {
        if (this.currentNewsPage && !this.currentDialogRef) {
            this.currentDialogRef = this.dialog
                .open(ModalPageComponent, {
                    backdropClass: ['cdk-overlay-dark-backdrop', 'backdrop-blur'],
                    panelClass: ['onboard-dialog'],
                    data: {
                        alias: this.currentNewsPage.url,
                    },
                    restoreFocus: false,
                });

            this.currentDialogRef.afterClosed()
                .subscribe(() => {
                    this.currentDialogRef = null;

                    this.setSeenPage(this.currentNewsPage.pageId);
                    this.currentNewsPage = null;
                });
        }
    }

    /**
     * Whether there is a help page currently available or not
     */
    public hasHelpPage(): boolean {
        return !!this.currentHelpPage || !!this.getHelpBasicPageAlias();
    }

    /**
     * Loads the configuration for help button and maps it to the local type
     * @private
     */
    private getHelpPagesConfiguration(): Observable<HelpConfiguration> {
        return this.octopusConnect.loadCollection('page-table')
            .pipe(
                map(collection => {
                    const configuration: HelpConfiguration = {};
                    collection.entities.forEach((entity) => {
                        if (entity.get('roles').some((role: string) => this.user.get('role').includes(+role))) {
                            if (entity.get('url') === '*' || entity.get('url') === '{news}') {
                                this.processNewsConfiguration(entity.get('config'));
                                return;
                            }

                            if (entity.get('url') === '{setup}') {
                                this.processSetupConfiguration(entity.get('config'));
                                return;
                            }

                            if (!configuration[entity.get('url')]) {
                                configuration[entity.get('url')] = [];
                            }

                            configuration[entity.get('url')].push(...entity.get('config'));
                        }
                    });
                    return configuration;
                }),
                take(1)
            );
    }

    /**
     * Process data for the help configuration
     * @private
     */
    private processConfiguration(): void {
        const pages: HelpPageConfiguration[] = [];

        for (const url in this.configuration) {
            pages.push(...this.configuration[url]);
        }

        pages.forEach((page) => {
            const dependency = pages.find((otherPage) => otherPage.url === page.dependency);
            if (dependency) {
                page.dependency = dependency;
            }
        });
    }

    private processSetupConfiguration(configuration: HelpPageConfiguration[]): void {
        this.setupConfiguration = configuration;
    }

    private processNewsConfiguration(configuration: HelpPageConfiguration[]): void {
        this.newsConfiguration = configuration;
    }

    /**
     * Lists all the internal URLs and generates the corresponding regexps to be cached
     * @private
     */
    private generateURLRegExps(): void {
        const urlList = Object.keys(this.configuration);
        this.urlRegExps = [];

        urlList.forEach((url) => {
            this.urlRegExps.push({
                url: url,
                regexp: new RegExp(url)
            });
        });
    }

    /**
     * Retrieve a help page, if any, based on the current URL
     * @private
     */
    private getCurrentHelpPage(): void {
        this.href = this.router.url;
        this.previousHelpPage = this.currentHelpPage;
        this.currentHelpPage = null;

        for (const {url, regexp} of this.urlRegExps) {
            if (regexp.test(this.href)) {
                this.selectHelpPage(this.configuration[url]);
                break;
            }
        }
    }

    private getCurrentSetup(pages = this.setupConfiguration): void {
        if (pages.length > 0) {
            const setupPageIndex = pages.findIndex((page) => !this.isPageSeen(page.pageId));

            if (setupPageIndex > -1) {
                const setupPage = pages[setupPageIndex];
                this.checkConditionAndDependency$(setupPage).pipe(take(1))
                    .subscribe((results) => {
                        if (results.every(result => result)) {
                            this.currentSetupPage = setupPage;
                            this.openCurrentSetupPage();
                        } else {
                            this.getCurrentSetup(pages.slice(setupPageIndex + 1));
                        }
                    });
            } else {
                this.getCurrentNews();
            }
        } else {
            this.getCurrentNews();
        }
    }

    private getCurrentNews(): void {
        if (this.newsConfiguration.length > 0) {
            const newsPage = this.newsConfiguration.at(-1);

            if (!this.isPageSeen(newsPage.pageId)) {
                this.currentNewsPage = newsPage;
                this.openCurrentNewsPage();
            } else {
                this.getCurrentHelpPage();
            }
        }
    }

    /**
     * Sets the given page as the current help page and auto opens it if needed
     * @param page
     * @private
     */
    private setCurrentHelpPage(page: HelpPageConfiguration) {
        this.currentHelpPage = page;

        if ((page.auto && !this.isPageSeen(page.pageId)) || page.forceOpen) {
            this.openCurrentHelpPage();
        }
    }

    /**
     * Selects a page from a list based on meeting a dependency and a condition if set
     * @param pages
     * @private
     */
    private selectHelpPage(pages: HelpPageConfiguration[]): void {
        if (pages.length > 0) {
            this.checkConditionAndDependency$(pages[0]).pipe(take(1))
                .subscribe((results) => {
                    if (results.every(result => result)) {
                        this.setCurrentHelpPage(pages[0]);
                    } else {
                        this.selectHelpPage(pages.slice(1));
                    }
                });
        }
    }

    /**
     * Checks the dependency and condition of a page, defaulting to true when not set
     * @param page
     * @private
     */
    private checkConditionAndDependency$(page: HelpPageConfiguration): Observable<boolean[]> {
        const observables: Observable<boolean>[] = [];

        if (page.dependency) {
            observables.push(this.checkDependency$(page.dependency));
        } else {
            observables.push(of(true));
        }

        if (page.condition) {
            observables.push(this.conditionsService.check$(page.condition));
        } else {
            observables.push(of(true));
        }

        return combineLatest(observables);
    }

    /**
     * Recursively check the dependency on a page. The dependency is met when the page's condition is not met
     * or when there is no condition.
     * @param page
     * @private
     */
    private checkDependency$(page: string | HelpPageConfiguration): Observable<boolean> {
        if (typeof page !== 'string') {
            return this.checkConditionAndDependency$(page)
                .pipe(
                    take(1),
                    map(([dependency, condition]) => {
                        return dependency && ((page.condition && !condition) || (!page.condition && condition));
                    })
                );
        }

        return of(true);
    }

    /**
     * Load cache data of seen help pages for the current user
     * @private
     */
    private loadSeenPages(): Observable<void> {
        return new Observable<void>((observer) => {
            if (this.user) {
                this.seenPages = this.getLocalStorageCache()[this.user.id] || [];
                this.octopusConnect
                    .loadCollection('page-views')
                    .pipe(
                        take(1),
                        map(collection => collection.entities.find(entity => entity.id === this.user.id))
                    )
                    .subscribe({
                        next: (pageViews) => {
                            this.seenPages = pageViews?.get('viewed');
                            this.saveSeenPagesCache();
                            observer.next();
                        },
                        error: () => {
                            observer.next();
                        }
                    });
            } else {
                observer.next();
            }
        });
    }

    /**
     * Sets the given page as seen for the current user and saves this information
     * @param id Path or alias for the page
     * @private
     */
    private setSeenPage(id: string): void {
        if (id && !this.isPageSeen(id)) {
            this.seenPages.push(id);
            this.saveSeenPagesCache();

            // Use createEntity to force a simple POST on the endpoint with the given data instead of
            // a DataEntity.save that would post on page-views/<userId> and is not supported by the back
            this.octopusConnect
                .createEntity('page-views', {viewed: this.seenPages})
                .subscribe();
        }
    }

    /**
     * Whether the page has already been recorded as seen or not
     * In the case of a null id, the page is considered as not seen
     * @param id Path or alias for the page
     * @private
     */
    private isPageSeen(id: string): boolean {
        return id && this.seenPages.includes(id);
    }

    /**
     * Save seen pages for current user in local storage
     * @private
     */
    private saveSeenPagesCache(): void {
        if (this.user) {
            const cache = this.getLocalStorageCache();
            cache[this.user.id] = this.seenPages;
            this.setLocalStorageCache(cache);
        }
    }

    /**
     * Retrieve the local cache for seen pages
     * @private
     */
    private getLocalStorageCache(): LocalStorageCacheInterface {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_IDENTIFIER) || '{}') as LocalStorageCacheInterface;
    }

    /**
     * Store the local cache for seen pages
     * @param cache
     * @private
     */
    private setLocalStorageCache(cache: LocalStorageCacheInterface): void {
        localStorage.setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(cache));
    }

    // Legacy code -----------------------------------------------------------------------------------------------------
    /**
     * @deprecated To be removed once all instances have switched to the new configuration system
     * @private
     */
    private getMappingHelpPages(): Observable<HelpPageTable> {
        return this.octopusConnect.loadCollection('variables/instance')
            .pipe(
                filter(collection => collection.entities.length > 0),
                map(collection => collection.entities[0].get('helpPages')),
                take(1)
            );
    }

    /**
     * @deprecated To be removed once all instances have switched to the new configuration system
     */
    public getHelpBasicPageAlias(): string | void {
        this.href = this.router.url;
        const regArr = Object.keys((this.mappingHelpPages || {}));

        for (let i = 0; i < regArr.length; i++) {
            const reg = new RegExp(regArr[i]);
            if (reg.test(this.href)) {
                return this.mappingHelpPages[regArr[i]];
            }
        }
    }
}