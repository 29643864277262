import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompassService} from 'fuse-core/services/compass.service';
import {map, take, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {usingLoading} from "shared/utils/rxjs";

@Component({
    selector: 'app-compass',
    templateUrl: './compass.component.html',
})
export class CompassComponent extends AutoUnsubscribeTakeUntilClass {

    private compassService = inject(CompassService);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);

    private themesAreLoading = true;
    private exploreDataIsLoading = true;
    private compassHelperIsLoading = true;


    public themes$ = this.compassService.loadThemes().pipe(take(1), usingLoading(isLoading => this.themesAreLoading = isLoading));
    public themesPromoted$ = this.themes$.pipe(map(themes => themes.filter(theme => theme.get('promoted') === '1')));
    public exploreData$ = this.compassService.loadExploreData().pipe(take(1), usingLoading(isLoading => this.exploreDataIsLoading = isLoading));
    public compassHelper$ = this.compassService.fetchCompassHomepage().pipe(take(1), usingLoading(isLoading => this.compassHelperIsLoading = isLoading))

    public isLoading() {
        return this.compassHelperIsLoading || this.exploreDataIsLoading || this.themesAreLoading;
    }

    public getQueryParams() {
        const qp = {
            fromUrl: this.router.url.split('?')[0] ,
        }

        if (Object.keys(this.activatedRoute.snapshot.queryParams).length) {
            qp['fromQueryParams'] = JSON.stringify(this.activatedRoute.snapshot.queryParams);
        }

        return qp;
    }
}

