import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ButtonListDialogData {
    title: string,
    list: {
        label: string, value: string, disabled?: boolean
    }[]
}

@Component({
    selector: 'app-button-list-dialog',
    templateUrl: './button-list-dialog.component.html',
    styleUrls: ['./button-list-dialog.component.scss']
})
export class ButtonListDialogComponent {

    @Input() noModalContext?: boolean;
    @Input() inputData?: ButtonListDialogData;
    @Output() outputEvent?: EventEmitter<{
        label: string, value: string
    }>;
    constructor(
        public ref: MatDialogRef<ButtonListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ButtonListDialogData) {
    }

    public selectedItem(item: { label: string, value: string }): void {
        if (this.noModalContext) {
            this.outputEvent.next(item)
        } else {
            this.ref.close(item.value);
        }
    }

    public close(): void {
        this.ref.close();
    }

    public get dataInjected(): ButtonListDialogData {
        return this.inputData ? this.inputData : this.data;
    }

    public isBtnDisabled(btn: {
        label: string, value: string, disabled?: boolean
    }): boolean {
        return btn.disabled;
    }
}