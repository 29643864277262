import {Component, OnDestroy} from '@angular/core';
import {ContestService} from '@modules/contest';
import {ActivatedRoute} from '@angular/router';
import {Contest} from '@modules/contest/core/model/contest';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataCollection, DataEntity} from 'octopus-connect';
import {Observable} from 'rxjs/index';
import {SyncRules} from '@modules/contest/core/model/rules';
import {AuthorizationService} from '@modules/authorization';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-contest-ranking',
    templateUrl: './contest-ranking.component.html'
})
export class ContestRankingComponent implements OnDestroy {

    dataSource = new MatTableDataSource();
    private destroyed$ = new Subject<void>();
    public contest: Contest;
    public groups: DataEntity[];
    public selectedGroup = null;
    columnsToDisplay = ['rank', 'label', 'progress'];
    private canCompeteInContest: boolean;


    constructor(
        private contestService: ContestService,
        private route: ActivatedRoute,
        private communicationCenter: CommunicationCenterService,
        private authorization: AuthorizationService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.canCompeteInContest = this.authorization.currentUserCan(SyncRules.CompeteInContest);
                }
            });

        this.route.parent.params.subscribe(params => {
            if (params['id']) {
                this.contestService
                    .getContestById(params['id'])
                    .subscribe((contest: Contest) => {
                        this.contest = contest;
                        this.initialize();
                    });
            }
        });
    }

    public get canCompete(): boolean {
        return this.canCompeteInContest;
    }

    private initialize(): void {
        if(!this.contest.collective) {
            this.getRank();
        } else {
            this.getGroups().pipe(
                takeUntil(this.destroyed$)
            ).subscribe(
                (groups) => {
                    if (groups.entities && groups.entities.length > 0) {
                        this.groups = groups.entities;
                        this.selectedGroup = this.groups[0].id;
                        this.getRank();
                    }
                }
            );
        }
    }

    public changeGroup(event): void {
        this.getRank();
    }

    getRank() {
        this.getRanking().pipe(
            takeUntil(this.destroyed$)
        ).subscribe(
            (ranking) => {
                if (ranking.entities) {
                    const data = [];
                    ranking.entities.forEach((group: DataEntity) => {
                        data.push({
                            rank: group.get('rank'),
                            label: group.get('label'),
                            progress: group.get('progress'),
                            currentUserRank: group.get('userRank')
                        });
                    });
                    this.dataSource.data = data;
                }
            }
        );
    }

    getRanking(): Observable<DataCollection> {
        if(this.contest.collective) {
            return this.contestService.getRanking(this.contest.id, this.selectedGroup);
        } else {
            return this.contestService.getRanking(this.contest.id);
        }
    }

    getGroups(): Observable<DataCollection> {
        return this.contestService.getContestProgression(this.contest.id);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}