// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  min-width: 0;
}
:host .theme-options-button {
  position: absolute;
  top: 160px;
  right: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  border-radius: 0;
  margin: 0;
  pointer-events: auto;
  opacity: 0.9;
  z-index: 998;
}
:host .theme-options-button mat-icon {
  animation: rotating 3s linear infinite;
}
:host .theme-options-button:hover {
  opacity: 1;
}
:host .theme-options-sidebar {
  width: 360px;
  min-width: 360px;
  max-width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;ACDJ;ADGI;EACI,kBAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,oBAAA;EACA,YAAA;EACA,YAAA;ACDR;ADGQ;EACI,sCAAA;ACDZ;ADIQ;EACI,UAAA;ACFZ;ADMI;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;ACJR","sourcesContent":["@import \"@fuse/scss/fuse\";\n\n:host {\n    position: relative;\n    display: flex;\n    flex: 1 1 auto;\n    width: 100%;\n    height: 100%;\n    min-width: 0;\n\n    .theme-options-button {\n        position: absolute;\n        top: 160px;\n        right: 0;\n        width: 48px;\n        height: 48px;\n        line-height: 48px;\n        text-align: center;\n        cursor: pointer;\n        border-radius: 0;\n        margin: 0;\n        pointer-events: auto;\n        opacity: .90;\n        z-index: 998;\n\n        mat-icon {\n            animation: rotating 3s linear infinite;\n        }\n\n        &:hover {\n            opacity: 1;\n        }\n    }\n\n    .theme-options-sidebar {\n        width: 360px;\n        min-width: 360px;\n        max-width: 360px;\n    }\n}",":host {\n  position: relative;\n  display: flex;\n  flex: 1 1 auto;\n  width: 100%;\n  height: 100%;\n  min-width: 0;\n}\n:host .theme-options-button {\n  position: absolute;\n  top: 160px;\n  right: 0;\n  width: 48px;\n  height: 48px;\n  line-height: 48px;\n  text-align: center;\n  cursor: pointer;\n  border-radius: 0;\n  margin: 0;\n  pointer-events: auto;\n  opacity: 0.9;\n  z-index: 998;\n}\n:host .theme-options-button mat-icon {\n  animation: rotating 3s linear infinite;\n}\n:host .theme-options-button:hover {\n  opacity: 1;\n}\n:host .theme-options-sidebar {\n  width: 360px;\n  min-width: 360px;\n  max-width: 360px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
