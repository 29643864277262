import {take, map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings, defaultApiURL} from '../../../settings';
import {DataEntity, DataCollection, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {Observable, Subscription} from 'rxjs';
import {CreatureCollection, Creature, BadgeType} from './definitions';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management';
import {TranslateService} from '@ngx-translate/core';
import {RewardsDialogComponent} from '@modules/gamification/core/rewards-dialog/rewards-dialog.component';
import {Router} from '@angular/router';
import {ConfigService} from './config.service';
import {AuthenticationService} from '@modules/authentication';

@Injectable({
    providedIn: 'root'
})
export class GamificationService {

    private badgesSubscription: Subscription;
    private badgesTypes: DataEntity[];
    public userPoints: number;
    public isShowPopup: boolean;
    public activeTab = 'universes';
    public originalAccessoriesBeforeBuyingAnotherOne = new Array<DataEntity>(); // list of accessories before buying one permit to return back if new one is not save
    /**
     * Cache of accessories used to remember which accessory are currently bought
     */
    public accessoriesCache: { [p: string]: DataEntity } = {};
    private _buyPopupInfo: {
        hidden: boolean,
        badge: DataEntity,
        buyCallback: (data: DataEntity) => void
    } = {
        hidden: true,
        badge: null,
        buyCallback: null
    };

    public badges: CreatureCollection;
    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    private _userProfile: DataEntity;
    private isFirstTime = false;

    constructor(
        private communicationCenter: CommunicationCenterService,
        private connector: OctopusConnectService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private router: Router,
        private config: ConfigService,
        private authService: AuthenticationService,
    ) {

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this._userProfile = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        // show rewards after completing an activity
        this.communicationCenter.getRoom('gamification').getSubject('showRewards')
            .subscribe((res: {
                feedback: string,
                title: string,
                callback: (mode: string) => void,
                coins: number,
                userSave: DataEntity,
                image: string,
                showNextButton: boolean
            }) => {
                this.showRewards(res.callback, this.isFirstTime, res.userSave, res.image, res.showNextButton);
            });
    }

    /**
     * open rewards modal
     */
    public showRewards(callback: (mode: string) => void, firstTimeExperience = false, userSave: DataEntity, image: string, showNextButton = true): void {
        // if whe use error message that mean help is not open by button but by communication center
        // after having a bad answer who have a comment associate
        const dialogConfig = new MatDialogConfig();
        // ctz-setting if not citizen  navigate to achievement
        if (this.config.getShowRewardNavigateToAchievement() && firstTimeExperience && this.authService.isLearner()) {
            dialogConfig.data = {
                titleDialog: 'assignment.followed_diary_first_time',
                labelExit: 'achievement.go_to_my_city',
                // Inutile pour la modale mais essentielle pour ne pas casser le code
                labelNext: 'achievement.dummy',
                bodyDialog: 'gamification.reward.accessories_first_time',
                coinsName: 'generic.credits',
                userSave,
                image,
                firstTimeExperience
            };
            // On force le callback pour etre sur de rediriger l'utilisateur et respecter le onboarding
            callback = () => this.router.navigate(['achievement/my-city']);
        } else {
            dialogConfig.data = {
                titleDialog: 'assignment.followed_diary',
                labelExit: 'activities.title_modal_quit',
                labelNext: 'generic.next',
                bodyDialog: 'gamification.reward.accessories',
                coinsName: 'generic.credits',
                userSave,
                image,
                firstTimeExperience
            };
        }

        this.translate.get(dialogConfig.data.titleDialog).subscribe((translation: string) => dialogConfig.data.titleDialog = translation);
        this.translate.get(dialogConfig.data.labelExit).subscribe((translation: string) => dialogConfig.data.labelExit = translation);
        this.translate.get(dialogConfig.data.labelNext).subscribe((translation: string) => dialogConfig.data.labelNext = translation);
        this.translate.get(dialogConfig.data.bodyDialog).subscribe((translation: string) => dialogConfig.data.bodyDialog = translation);
        this.translate.get(dialogConfig.data.coinsName).subscribe((translation: string) => dialogConfig.data.coinsName = translation);
        // ctz-settings
        dialogConfig.data.authorizeExit = this.config.getAuthorizeExitUseOnlyShowNextButtonRules() ? showNextButton : (firstTimeExperience === false && showNextButton);
        // ctz-settings
        if (this.config.getAddRewardClass()) {
            dialogConfig.panelClass = 'rewards-dialog-wrapper';
        } else {
            dialogConfig.panelClass = 'help_close_modal';
            dialogConfig.backdropClass = 'backdrop-blur';
        }
        dialogConfig.disableClose = true;

        window.setTimeout(() => {
            const dialogRef = this.dialog.open(RewardsDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe((data) => {
                callback(data);
            });
        }, 2500);

    }

    private postLogout(): void {
        this.isFirstTime = false;
    }

    private postAuthentication(): void {
        if (this.config.isShowRewardsEnabled()) {
            this.connector.listen('reward')
                .subscribe((reward: DataEntity) => {
                    this.translate.get(reward.get('body')).subscribe((translation) => {
                        const body = translation.replace('{{params}}', reward.get('params'));
                        this.openRewardDialog({
                            titleDialog: '',
                            bodyDialog: body,
                            labelTrueDialog: 'OK'
                        });
                    });
                    this.loadBadges();
                });
        }

        this.communicationCenter
            .getRoom('achievement')
            .getSubject('isNewUser$')
            .pipe(
                mergeMap((isNewUser$: Observable<boolean>) => isNewUser$)
            )
            .subscribe((isFirstTime) => {
                this.isFirstTime = isFirstTime;
            });
    }

    public loadBadges(force?): void {
        this.getUserPoints();
        this.badges = new CreatureCollection(this);
        this.translate.onLangChange.subscribe(() => {
            this.badges.loadCreatures(true);
            this.badges.loadUniverses(true);
        });
        this.badges.loadCreatures(force);
        this.badges.loadUniverses(force);
    }

    private openRewardDialog(config: object): void {
        this.dialog.open(FuseConfirmDialogComponent, {data: config});
    }

    openBuyPopup(badge: DataEntity, buyCallback: (data: DataEntity) => void): void {
        if (!badge) {
            return;
        }
        this._buyPopupInfo = {
            badge,
            hidden: false,
            buyCallback
        };
    }

    closeBuyPopup(): void {
        this._buyPopupInfo = {
            hidden: true,
            badge: null,
            buyCallback: null
        };
    }

    buyBadge(): Promise<DataEntity> {
        return new Promise(async (resolve, reject) => {
            if (!this._buyPopupInfo.badge) {
                reject('No badge provided');
            }
            const checkedBadge = await this.getBadge(this._buyPopupInfo.badge.id);
            if (this.userPoints < checkedBadge.attributes.price) {
                reject('Too expensive');
            } else {
                checkedBadge.attributes.unLocked = true;
                this._buyPopupInfo.badge.attributes.unLocked = true;
                this.userPoints -= checkedBadge.attributes.price;
                checkedBadge.save(true).subscribe(res => {
                    this.badges.updateBadge(res, true, true);
                    this.getUserPoints();
                    this._buyPopupInfo.buyCallback(res);
                    resolve(res);
                }, err => {
                    reject(err);
                });
            }
        });
    }

    async setSelectedCreatureOrAccessory(collection: DataEntity[], badge: DataEntity, valueToSet: boolean): Promise<DataEntity> {
        if (badge.attributes.unLocked === false) {
            throw new Error('Badge locked');
        }

        if (valueToSet === true) {
            const previous = collection.filter(
                b => b.attributes.selected === true &&
                    (!b.attributes.stuffType && !badge.attributes.stuffType || b.attributes.stuffType.id === badge.attributes.stuffType.id)
            ).map(b => {
                b.attributes.selected = false;
                return new Promise<DataEntity>((resolve, reject) => {
                    b.save().subscribe(res => {
                        this.badges.updateBadge(res);
                        resolve(res);
                    }, err => {
                        reject(err);
                    });
                });
            });
            await Promise.all(previous);
        }
        badge.attributes.selected = valueToSet;
        return new Promise<DataEntity>((resolve, reject) => {
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    setName(badge: DataEntity, name: string): Promise<DataEntity> {
        return new Promise((resolve, reject) => {
            if (!name) {
                reject('Empty name');
            }
            badge.attributes.label = name;
            badge.save().subscribe(res => {
                this.badges.updateBadge(res);
                resolve(res);
            }, err => {
                reject(err);
            });
        });
    }

    get buyPopupInfo(): {
        hidden: boolean;
        badge: DataEntity;
    } {
        return this._buyPopupInfo;
    }

    getBadges(name?: BadgeType, parent?: string): Observable<DataCollection> {
        if (this.badgesSubscription) {
            this.badgesSubscription.unsubscribe();
        }
        if (name) {
            if (this.badgesTypes) {
                const badgeTypeId = this.badgesTypes.find(bt => bt.attributes.name === name).id;
                if (!badgeTypeId) {
                    return;
                }
                const filter = {
                    type: badgeTypeId,
                    parent
                };
                if (!parent) {
                    delete filter.parent;
                }
                return this.connector.loadCollection('badges', filter);
            } else {
                return this.getBadgeTypeId(name).pipe(
                    mergeMap(badgeTypeId => {
                        if (!badgeTypeId) {
                            return;
                        }
                        const filter = {
                            type: badgeTypeId,
                            parent
                        };
                        if (!parent) {
                            delete filter.parent;
                        }
                        return this.connector.loadCollection('badges', filter);
                    })
                );
            }
        } else {
            return this.connector.loadCollection('badges');
        }
    }

    public saveBadge(badge: DataEntity): Observable<DataEntity> {
        return badge.save(true);
    }

    getBadge(id: string | number): Promise<DataEntity> {
        return new Promise<DataEntity>((resolve, reject) => {
            this.connector.loadEntity('badges', this._buyPopupInfo.badge.id).subscribe(badge => {
                resolve(badge);
            }, err => {
                reject(err);
            });
        });
    }

    getBadgeTypeId(name: string): Observable<any> {
        return this.connector.loadCollection('badges-type').pipe(map(res => {
            this.badgesTypes = res.entities;
            return res.entities.find(e => e.attributes.name === name).id;
        }));
    }

    getUserPoints(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.connector.loadCollection('user-points').subscribe(res => {
                this.userPoints = res.entities[0].attributes.points;
                resolve(res.entities[0].attributes.points);
            }, err => {
                reject(err);
            });
        });
    }

    /** @deprecated use MediaService.createMedia instead */
    uploadImage(creature: Creature, imageData: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            const f = await fetch(imageData);
            const blob = await f.blob();

            const formData = new FormData();
            formData.append('file', blob, 'avatar.png');

            this.http
                .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe((fileUploadRes) => {
                    if (fileUploadRes && fileUploadRes.data && fileUploadRes.data[0] && fileUploadRes.data[0][0] && fileUploadRes.data[0][0].id) {
                        creature.creature.set('fid', fileUploadRes.data[0][0].id);
                        creature.creature.set('userImage', fileUploadRes.data[0][0].uri);
                        creature.creature.set('userImageFid', fileUploadRes.data[0][0].id);
                        creature.creature.save().subscribe(async creatureUpdateRes => {
                            if (creature.creature.get('selected') === true) {
                                const newCreatureAdterAvatar = await this.setAvatar(creature);
                                resolve(newCreatureAdterAvatar);
                            } else {
                                resolve(creatureUpdateRes);
                            }
                        }, err => {
                            reject(err);
                        });
                    } else {
                        reject('Empty uploaded file data');
                    }
                }, err => {
                    reject(err);
                });
        });
    }

    setAvatar(creature: Creature): Promise<Creature> {
        return new Promise<Creature>(async (resolve, reject) => {

            if (!this._userProfile) {
                reject('No profile data');
            }

            this._userProfile.set('_picture', creature.creature.get('userImageFid'));
            this._userProfile.save().subscribe(async userProfileUpdateRes => {
                creature.creature = await this.setSelectedCreatureOrAccessory(this.badges.creatures.map(cr => cr.creature), creature.creature, true);
                resolve(creature);

                this.communicationCenter
                    .getRoom('account-management')
                    .next('refreshUser', true);
            }, err => {
                reject(err);
            });
        });
    }

    startPersonalisation(): void {
        this.connector.loadEntity('badges', 'start').pipe(take(1));
    }
}