import {Injectable} from '@angular/core';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../app/settings';

const settingsStructureNotification: ModelSchema = new ModelSchema({
    show: Structures.boolean(false),
});

const settingsStructureFeatureAccess: ModelSchema = new ModelSchema({
    useSelectedLangAsDefault: Structures.boolean(false),
    subscribeToLanguageChange: Structures.boolean(true),
    addIndexToSkiptocontent: Structures.boolean(false),
    setRootFilter: Structures.boolean(false),
    initClearFiltersOnFormChanges: Structures.boolean(true),
    removeChapterOfTypeContest: Structures.boolean(false),
    useExtraDataUrl: Structures.boolean(false),
    useBlockPathAlone: Structures.boolean(false),
    toolbarUseCtzVersion: Structures.boolean(false),
    useHelpPageCitizenLogic: Structures.boolean(false),
    useRootFilter: Structures.boolean(false),
    passOnlyIdToRoomLessons: Structures.boolean(false),
    extraDataUrlAllowed: Structures.boolean(false),
    iconFromPlayLessonInActivityCard:Structures.string('play'),
});


interface NotificationSettings {
    show: boolean;
}

interface FeatureSettings {
    displayRole: boolean;
    showTechnicalProblemMessage: boolean;
    helpMenu: {
        default: false
    },
    showWebAppBtn: false,
    institutionsGroupAccess: true,
    translationAccess: {
        show: true,
        withFlag: true
    },
    feedbackMenu: { default: false },
    feedbackMenuAsGar: { default: false },
    showLangChoiceWhenPlayLesson: false,
    useSelectedLangAsDefault: false,
}


@Injectable({
    providedIn: 'root'
})
export class GlobalConfigurationService {
    private readonly privateSettings: any;
    private readonly privateSettingsFeatureAccess: any;

    constructor() {
        this.privateSettings = settingsStructureNotification.filterModel(modulesSettings.notification) as NotificationSettings;
        this.privateSettingsFeatureAccess = settingsStructureFeatureAccess.filterModel(modulesSettings.featureAccess) as FeatureSettings;
    }

    public get isNotificationEnabled(): boolean {
        return this.privateSettings.show;
    }

    public getUseSelectedLangAsDefault(): boolean {
        return this.privateSettingsFeatureAccess.useSelectedLangAsDefault;
    }

    public getsubscribeToLanguageChange(): boolean {
        return this.privateSettingsFeatureAccess.subscribeToLanguageChange;
    }

    public getAddIndexToSkiptocontent(): boolean {
        return this.privateSettingsFeatureAccess.addIndexToSkiptocontent;
    }

    public getSetRootFilter(): boolean {
        return this.privateSettingsFeatureAccess.setRootFilter;
    }

    public getInitClearFiltersOnFormChanges(): boolean {
        return this.privateSettingsFeatureAccess.initClearFiltersOnFormChanges;
    }

    public getRemoveChapterOfTypeContest(): boolean {
        return this.privateSettingsFeatureAccess.removeChapterOfTypeContest;
    }

    public getUseExtraDataUrl(): boolean {
        return this.privateSettingsFeatureAccess.useExtraDataUrl;
    }

    public getUseBlockPathAlone(): boolean {
        return this.privateSettingsFeatureAccess.useBlockPathAlone;
    }

    public getToolbarUseCtzVersion(): boolean {
        return this.privateSettingsFeatureAccess.toolbarUseCtzVersion;
    }

    public getUseHelpPageCitizenLogic(): boolean {
        return this.privateSettingsFeatureAccess.useHelpPageCitizenLogic;
    }

    public getUseRootFilter(): boolean {
        return this.privateSettingsFeatureAccess.useRootFilter;
    }

    public getPassOnlyIdToRoomLessons(): boolean {
        return this.privateSettingsFeatureAccess.passOnlyIdToRoomLessons;
    }

    public getExtraDataUrlAllowed(): boolean {
        return this.privateSettingsFeatureAccess.extraDataUrlAllowed;
    }

    public getIconFromPlayLessonInAcitivityCard(): string {
        return this.privateSettingsFeatureAccess.iconFromPlayLessonInActivityCard;
    }
}
