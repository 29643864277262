import {inject, Injectable} from '@angular/core';
import {CompassDifficultyEntity, CompassExploreEntity, CompassResourceEntity, CompassThemeEntity} from 'fuse-core/types/compass';
import {CollectionOptionsInterface, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {HomePageService} from "fuse-core/components/home-page/home-page.service";

@Injectable({
    providedIn: 'root'
})
export class CompassService {
    private octopusConnect = inject(OctopusConnectService);
    private homePageService = inject(HomePageService)

    private fetchHomepages = this.homePageService.loadHomePage;
    public fetchCompassHomepage = () => this.fetchHomepages().pipe(
        map( homepages => homepages.entities),
        map(homepages => homepages.find(h => h.get('alias') === "home/compass-helper")),
    );

    /**
     * cette fonction permet de charger les themes
     * @param filterOptions
     */
    loadThemes(filterOptions?: CollectionOptionsInterface) {
        const defaultFilterOptions: CollectionOptionsInterface = {
            page: 1
        };
        const themesPaginated: PaginatedCollection = this.octopusConnect.paginatedLoadCollection('thematique-boussole', filterOptions ? _.merge(defaultFilterOptions, filterOptions) : defaultFilterOptions);
        return themesPaginated.collectionObservable.pipe(map(collection => collection.entities as CompassThemeEntity[]));
    }

    /**
     * cette fonction permet de charger les ressources
     * @param filterOptions
     */
    loadResources(filterOptions?: CollectionOptionsInterface) {
        return this.octopusConnect.loadCollection('ressource-boussole', {id: filterOptions.filter.id})
            .pipe(map(collection => collection.entities as CompassResourceEntity[]));
    }

    /** Attention, ce n'est pas des educationalLevel, mais des level-boussole */
    public getEducationalLevels(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('level-boussole')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public getDifficulties(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('difficulty-boussole')
            .pipe(map(collection => <CompassDifficultyEntity[]> collection.entities));
    }
    public getPedagoList(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('pedago')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public getOrganisationList(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('organisation')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    /**
     * cette fonction permet de charger les donnees de l'exploration
     */
    public loadExploreData() {
        return this.octopusConnect.loadCollection('explore')
            .pipe(map(collection => <CompassExploreEntity[]> collection.entities));
    }

    /**
     * cette fonction permet de charger les diagnostics
     */
    public loadDiagnostics(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('diagnostic')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }
    public loadSkills(): Observable<TypedDataEntityInterface<{}>[]> {
        return this.octopusConnect.loadCollection('skills-boussole')
            .pipe(map(collection => <TypedDataEntityInterface<{}>[]> collection.entities));
    }

    public loadResourceById(id: string) {
        return this.octopusConnect.loadEntity('ressource-boussole', id) as Observable<CompassResourceEntity>;
    }

    public loadExploreEntity(id: string | number) {
        return this.octopusConnect.loadEntity('explore', id) as Observable<CompassExploreEntity>;
    }

    public loadThemeById(themeId: string) {
        return this.octopusConnect.loadEntity('thematique-boussole', themeId) as Observable<CompassThemeEntity>;
    }
}
