import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {DataEntity} from 'octopus-connect';
import {map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-mindmap-content-viewer-modal',
    templateUrl: './mindmap-content-viewer-modal.component.html',
})
export class MindmapContentViewerModalComponent implements OnInit {
    public isLoading = true;
    public text: string;
    public mindmapTitle: string;
    private mindmapGranule: DataEntity;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: { mindmap$: Observable<DataEntity> },
        public selfDialogRef: MatDialogRef<MindmapContentViewerModalComponent>) {
    }

    ngOnInit(): void {
        this.modalData.mindmap$.pipe(
            tap(mindmapGranule => {
                this.mindmapGranule = mindmapGranule;
                this.text = this.mindmapGranule.get('reference').activity_content[0].text;
                this.mindmapTitle = this.mindmapGranule.get('metadatas').title;
                this.isLoading = false;
            })
        ).subscribe();
    }

    public close(): void {
        this.selfDialogRef.close();
    }
}