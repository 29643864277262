import {
    Component, inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {AuthenticationService} from '@modules/authentication';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {HttpClient} from '@angular/common/http';
import {ResourceShareToGroupsModalComponent} from '../../resource-share-to-groups-modal/resource-share-to-groups-modal.component';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {fuseAnimations} from 'fuse-core/animations';

@Component({
    selector: 'fuse-corpus-details-sidenav',
    templateUrl: './corpus-details.component.html',
    styleUrls: ['./corpus-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CorpusDetailsSidenavComponent implements OnInit, OnDestroy {
    @Input() public mode: 'readonly' | 'normal' = 'normal';
    public isShareableCommunity: boolean;
    public selected: CorpusRessource;
    public sharedCommunity: boolean;
    public videoShortImage: string;
    public videoThumbnailTime = 5;
    public currentVid: string;

    private unsubscribeInTakeUntil = new Subject<void>();

    public corpusService = inject(CorpusService)
    public authService = inject(AuthenticationService)
    private dialog = inject(MatDialog)
    private http = inject(HttpClient)
    private translate = inject(TranslateService)

    ngOnInit() {
        this.corpusService.onFileSelected
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap(() => this.selected = null),
                filter((selected) => !!selected),
                map((selected) => {
                    this.selected = selected;
                    this.prepareSelectedResource(selected);
                })
            )
            .subscribe();
    }

    /**
     * Do some work for display the selected element like set the thumbnail
     * @param selected
     */
    private prepareSelectedResource(selected: CorpusRessource) {
        if (
            this.selected.hasOwnProperty('format') &&
            this.selected.format === 'video'
        ) {
            let poster = '';

            // use poster if thumbnail exists
            if (this.selected.ressourceEntity.get('metadatas').thumbnail) {
                poster =
                    ' poster="' +
                    this.selected.ressourceEntity.get('metadatas').thumbnail.uri +
                    '" preload="none"';
            }

            // build video tag
            this.currentVid =
                '<video' +
                poster +
                '><source src="' +
                this.selected.ressourceEntity.get('reference').uri +
                '#t=' +
                this.videoThumbnailTime +
                '" type="' +
                this.selected.ressourceEntity.get('reference').filemime +
                '"> Your browser does not support HTML5 video. </video>';
        }

        this.isShareableCommunity =
            this.mode !== 'readonly' &&
            !!this.selected.ressourceEntity &&
            this.corpusService.settings.shareableResourceToCommunity;
        this.sharedCommunity =
            !!this.selected.ressourceEntity &&
            !!this.selected.ressourceEntity.get('shared');

        if (selected.videoShortImage) {
            const url = selected.videoShortImage;
            if (url.includes('vimeo')) {
                // TODO this kind of call should be done in services.
                this.http.get(url).subscribe((res) => {
                    this.videoShortImage = res['thumbnail_url'];
                });
            } else if (selected.videoShortImage !== 'noThumb') {
                this.videoShortImage = selected.videoShortImage;
            }
        }
    }

    ngOnDestroy() {
        this.corpusService.onFileSelected.next(null);
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public get haveCustomDate() {
        const customDate = this.selected.metadatasEntity.get('scolomDate');
        return customDate !== undefined && customDate !== null && customDate.length;
    }

    getDataSelected(field: string) {
        if (this.selected && this.selected.metadatasEntity) {
            if (field === 'source-author') {
                if (this.selected.metadatasEntity.attributes[field] !== null) {
                    return this.selected.metadatasEntity.attributes[field];
                } else {
                    return false;
                }
            }
            if (
                this.selected.metadatasEntity.attributes &&
                this.selected.metadatasEntity.attributes[field] &&
                this.selected.metadatasEntity.attributes[field].length > 0
            ) {
                return this.selected.metadatasEntity.attributes[field];
            } else {
                if (field === 'source' || field === 'source-author') {
                    return '';
                }
            }
        }
        return false;
    }

    displayField(name: string) {
        return this.corpusService.settings.detailsFields.indexOf(name) > -1;
    }

    public openShareToClassesDialog(corpusRessource: CorpusRessource) {
        this.dialog.open(ResourceShareToGroupsModalComponent, {
            data: {resource: corpusRessource.ressourceEntity},
        });
    }

    public shareToCommunity(evt: MatSlideToggleChange) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: 'corpus.confirm_share_community_resource',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.cancel',
        };

        if (!evt.checked) {
            dialogConfig.data.bodyDialog =
                'corpus.confirm_not_share_community_resource';
        }

        for (const term in dialogConfig.data) {
            this.translate
                .get(dialogConfig.data[term])
                .subscribe(
                    (translation: string) => (dialogConfig.data[term] = translation)
                );
        }

        const confirmDialogRef = this.dialog.open(
            FuseConfirmDialogComponent,
            dialogConfig
        );
        confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.corpusService.editGranule(this.selected.ressourceEntity, {
                    shared: evt.checked ? '1' : '0',
                });
                this.sharedCommunity = evt.checked;
            } else {
                evt.source.checked = !evt.checked;
                this.sharedCommunity = !evt.checked;
            }
        });
    }

    get chapters() {
        if (this.selected) {
            const chapters = this.selected.metadatasEntity.get('chapters')
                ? this.selected.metadatasEntity.get('chapters')
                : [];
            return chapters.map((chapter) => {
                if (chapter.attributes) {
                    return {
                        id: chapter.id,
                        label: chapter.get('label'),
                    };
                }

                return chapter;
            });
        }

        return [];
    }

    get concepts() {
        if (this.selected) {
            const concepts = this.selected.metadatasEntity.get('concepts')
                ? this.selected.metadatasEntity.get('concepts')
                : [];
            return concepts.map((concept) => {
                if (concept && concept.attributes) {
                    return {
                        id: concept.id,
                        label: concept.get('label'),
                    };
                }

                return concept;
            });
        }

        return [];
    }

    /**
     * if 0 or 6 learner can show media
     * if 5 it s only trainer
     * @param role
     */
    public learnerAllowedToConsult(role: number): boolean {
        return role === 0 || role === 6;
    }

    public canShare(_selected: CorpusRessource) {
        const level = this.authService.accessLevel;
        const setting = this.corpusService.settings.shareableResourceToGroups;

        const isSettingOk = setting.hasOwnProperty(level) ? setting[level] : setting['default']
        const isModeOk = this.mode === 'normal';

        return isSettingOk && isModeOk;
    }
}