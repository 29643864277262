import {Component, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';

@Component({
    templateUrl: 'only-lesson-lessons-list.component.html',
    selector: 'app-only-lesson-lessons-list',
    encapsulation: ViewEncapsulation.None,
})
export class OnlyLessonLessonsListComponent implements OnInit, OnDestroy {
    public lessonsService = inject(LessonsService)
    public lessonsConfigurationService = inject(LessonsConfigurationService)

    ngOnInit(){
        if (this.lessonsService.isUserManager) {
            this.lessonsService.isShareableModel = !!this.lessonsConfigurationService.settings.shareableModel;
        } else {
            this.lessonsService.isShareableCommunity = true;
        }
    }

    ngOnDestroy() {
        // TODO devrait etre passé dans le composant enfant en arguments
        this.lessonsService.isShareableCommunity = false;
        this.lessonsService.isShareableModel = false;
    }
}

