import {Structures} from 'octopus-model';

export const activitiesModuleSettings = {
    accessMatrix: Structures.object(),
    actionButtonsInMultiMatrix: Structures.object({
        default: []
    }),
    activitiesBroadcastLifeCycle: Structures.boolean(false),
    activitiesDisplayedColumns: Structures.array(['title']),
    activitiesNoClone: Structures.boolean(false),
    activitiesTypesUserCanUse: Structures.array([]),
    activityOptionsEditable: Structures.array(['titleAndInstruction']),
    addFromActivities: Structures.boolean(true),
    addRootFilter: Structures.boolean(false),
    allowedActivityTypes: Structures.array([]),
    allowedAssignmentSpecificView: Structures.boolean(false),
    allowedMediaTypeCombination: Structures.array([
        ['image', 'audio'],
        ['image', 'text'],
        ['video', 'text'],
        ['audio', 'text'],
    ]),
    allowedRolesForModelsAssignation: Structures.array([]), // WARN : An assignment of model make the model cannot be deleted or updated
    allowedRolesForModelsCreation: Structures.array(['manager']),
    allowedRolesForAutoAssignmentCreation: Structures.array(['trainer', 'manager', 'administrator']),
    allowedThumbnailExtensions: Structures.array([
        'image/jpg',
        'image/jpeg',
        'image/png',
    ]),
    allowErrorReporting: Structures.boolean(false),
    applyFilterLessonAssignedOnly: Structures.boolean(false),
    autoCorrection: Structures.boolean(false),
    autoReadableActivities: Structures.object({default: []}),
    availableAddButtons: Structures.object({
        default: ['activity', 'divider', 'resource']
    }),
    bannerInfoAddUsages: Structures.object(),
    buttons: Structures.object(null),
    canOverrideStepMetadatasInLesson: Structures.boolean(false),
    canSelectActivities: Structures.boolean(false),
    cardFieldsForPreview: Structures.array(['description']),
    cardLayout: Structures.string('card-simple-course'),
    closeButtonPng: Structures.boolean(false),
    columns: Structures.object({
        default: [
            'checkbox',
            'type',
            'title',
            'author',
            'level',
            'difficulty',
            'changed',
            'actions',
        ],
    }),
    displayArchiveOptionToolbar: Structures.boolean(false),
    displayBtnRetryInSummarySubLesson: Structures.boolean(true),
    displayCreateLessonHelper: Structures.boolean(false),
    displayDetailsBlockInSummarySubLesson: Structures.boolean(true),
    displayedFiltersIcons: Structures.boolean(false),
    displayFeedback: Structures.boolean(false),
    displayFeedbacks: Structures.boolean(false),
    displayFullscreenButton: Structures.boolean(true),
    displayGradeBlockInSummarySubLesson: Structures.boolean(false),
    displayLearnerInfo: Structures.boolean(true),
    displayOptionalLessonRecapButton: Structures.boolean(true),
    displayQuizBtnInCard: Structures.boolean(false),
    displayTipsInListOfActivities: Structures.boolean(false),
    dynamicRewardMilestones: Structures.array([]),
    enableTTS: Structures.boolean(false),
    exitLessonAfterConfirm: Structures.boolean(false),
    extraActionAfterUserSave: Structures.boolean(false),
    feedbackButtons: Structures.array(),
    filterListWithInListBoolean: Structures.boolean(false),
    filters: Structures.object({
        default: ['title', 'keywords', 'type'],
    }),
    filtersEditorActivitiesList: Structures.array(),
    filtersToClearOnSearchFormChange: Structures.array([]),
    filterTitleToTranslate: Structures.string(''),
    filtertoApplyOnLessonsByUrl: Structures.array([]),
    forceUrl: Structures.boolean(false),
    forceUrlByDefaultWhenBackToLessonList: Structures.boolean(false),
    getAssignatedUserUid: Structures.boolean(false),
    grade: Structures.boolean(),
    gradeCalculation: Structures.object(),
    hiddenActivityPreview: Structures.boolean(true),
    hideAddButtonLessonForCommunity: Structures.boolean(false),
    hideAddButtonLessonForModel: Structures.boolean(false),
    hideAddButtonLessonList: Structures.boolean(false),
    hideHeader: Structures.boolean(false),
    hideReview: Structures.boolean(false),
    hideTopBarForSpecificActivitiesTypes: Structures.array([]),
    hideUserActionButtons: Structures.boolean(false),
    hideUserActionButtonsByRole: Structures.array([]),
    hideUserActionButtonsWhenDisabled: Structures.boolean(false),
    idFilterToUseInPeriodListMyLessonButton: Structures.string('2683,2681,4270'),
    imageFullscreenButton: Structures.boolean(false),
    initialiseIframeUrl: Structures.boolean(false),
    initLoadLessonFilter: Structures.array(['author']),
    isAccessibleFormsModels: Structures.boolean(false),
    isActivitiesListMustBeDisplayed: Structures.boolean(false),
    isAddLessonCardNewStyle: Structures.boolean(false),
    isArrowNavigationEnabled: Structures.array([]),
    isDisplayingRewards: Structures.boolean(false),
    isInitializingToTrueAfterReset: Structures.boolean(false),
    isInstanceMustCheckLicense: Structures.boolean(false),
    isLessonDuplicationEnabled: Structures.object({default: false, trainer: true, manager: true, administrator: true}),
    isLessonEditionEnabled: Structures.object({default: false, trainer: true, manager: true, administrator: true}),
    isLessonPreviewAccessible: Structures.object({default: false}),
    isListFormatStepper: Structures.boolean(false), // generic stepper use mat-select list or button
    isModalConfirmBeforeCloseExo: Structures.boolean(false),
    isOnlyModelLesson: Structures.boolean(false),
    isOnboardingEnabled: Structures.boolean(false),
    isPercentileDisplayAllowed: Structures.boolean(true),
    isRecapAtEndOfLessonEnabled: Structures.boolean(true),
    isStepperAllowedAllStep: Structures.boolean(false),
    isTtsEnabled: Structures.boolean(true),
    isWrapperForCheckbox: Structures.boolean(false),
    latexKeyboard: Structures.boolean(false),
    lessonDialogFields: Structures.object({
        default: ['title', 'educationnalLevel', 'method', 'tags', 'description'],
    }),
    lessonDialogRequiredFields: Structures.object({
        default: ['title'],
    }),
    lessonMetadataDialogFields: Structures.object({default: []}),
    lessonStep: Structures.object(),
    levels: Structures.object({primary: [], secondary: []}),
    listenActivatedRouteToManageContest: Structures.boolean(false),
    loadPaginatedLessonsUseCitizenCodeLogic: Structures.boolean(false),
    loadSubActivitiesOfAllSubLesson: Structures.boolean(true),
    menu: Structures.object({
        models: false,
    }),
    menuLessonsDeployable: Structures.object({default: []}),
    multiSelectionForActivitiesList: Structures.boolean(true),
    navigateInPreviousStep: Structures.boolean(true), // can navigate in previous step
    navigationInStepsAllowed: Structures.boolean(true), // Can navigate in stepper
    numberOfTryBeforeDisplayCorrection: Structures.number(null),
    openDialogInfoAfterDuplicateLesson: Structures.object({default: false}),
    openLessonInApp: Structures.boolean(false),
    openReviewPopupPassExtraDataIdLessonAndActivity: Structures.boolean(false),
    periodBackGround: Structures.array([{id: '4119', background: 'english--background'}, {id: '4068', background: 'spanish--background'}]),
    plugins: Structures.object({}),
    progress: Structures.boolean(),
    recommendationDisplayedColumns: Structures.array([]),
    rolesCanShowBannerInfo: Structures.array(),
    routeOnExitLesson: Structures.array(['lessons', 'list']), // route by default when exit lessons some instance need another one
    saveLessonContentOptions: Structures.object({
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: true,
    }),
    saveOnDestroy: Structures.array([]),
    searchFields: Structures.object({
        default: {
            default: ['educationnalLevel', 'concepts', 'thematics', 'bookmarks', 'autocorrection', 'title', 'launchSearch', 'countEntities']
        }
    }),
    setAssignmentBeforeInit: Structures.boolean(false),
    setConceptFilterInRegardOfSelectedRootFilter: Structures.boolean(false),
    selectedTabIndex: Structures.number(0),
    setAnswerWithUserSave: Structures.boolean(true),
    shareableModel: Structures.number(0),
    shouldExitIfNoAssignment: Structures.boolean(false),
    shouldSetDefaultOptionsOnActivityList: Structures.boolean(false),
    shouldShowLaunchButton: Structures.boolean(false),
    shouldStartOnFullScreen: Structures.boolean(false),
    showActivityQuestionIndex: Structures.boolean(false),
    showActivityRewardsOnStorylineCompleted: Structures.boolean(false),
    showActivityTitle: Structures.boolean(false),
    showAddLessonButtonCard: Structures.boolean(false),
    showBasePlatformPathButton: Structures.boolean(false),
    showGenericProgressBar: Structures.object({default: {default: false}}),
    showHelp: Structures.boolean(false),
    showIconTitleLesson: Structures.boolean(false),
    showInfoToTeacherAfterAssignment: Structures.boolean(false),
    showLessonTitle: Structures.boolean(true),
    showLessonWithNoSubTheme: Structures.boolean(true),
    showMultiZoneProgressBar: Structures.object({default: {default: false}}),
    showRewardPageAfterSubLessonEnded: Structures.boolean(false),
    showSubInstruction: Structures.boolean(false),
    showVideoMarkersNoteSubtitle: Structures.boolean(true),
    showVideoWithMarkersLabel: Structures.boolean(true),
    stepper: Structures.object({
        default: {
            default: {
                align: 'start',
                position: 'left',
                showRecap: true,
                stepValidationNeedSave: true,
            }
        }
    }),
    stepperLoadStatus: Structures.boolean(false),
    stepTitlePrefixPosition: Structures.boolean(false),
    symbolsForLatexKeyboard: Structures.object(null),
    typeActivitiesToSkip: Structures.array(null),
    urlVideoException: Structures.array([]),
    useNicknameInsteadOfUsername: Structures.boolean(false),
    useSummaryPageInsteadOfRecap: Structures.boolean(false),
    videoMarkerTextareaAutosize: Structures.boolean(false),
    warnings: Structures.boolean(false),
} as const;