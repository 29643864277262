// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: flex;
  flex-direction: column;
}
form * {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/error-reporting/error-reporting-modal/error-reporting-modal.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/@fuse/components/error-reporting/error-reporting-modal/error-reporting-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;ACCJ;ADCI;EACI,WAAA;ACCR","sourcesContent":["form {\n    display: flex;\n    flex-direction: column;\n\n    * {\n        width: 100%;\n    }\n}\n","form {\n  display: flex;\n  flex-direction: column;\n}\nform * {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
