import * as _ from 'lodash-es';

import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {
    Component, ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import {
    DataCollection,
    DataEntity,
    OctopusConnectService,
} from 'octopus-connect';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import {ReplaySubject, Subject} from 'rxjs';
import {filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';

import {AuthenticationService} from '@modules/authentication';
import {ChaptersSelectionComponent} from '@fuse/components/chapters-selection/chapters-selection.component';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {FlagService} from 'shared/flag.service';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {LessonsSelectionComponent} from 'fuse-core/components/lessons-selection/lessons-selection.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatInput} from '@angular/material/input';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelect} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MediaUploadService} from '@modules/corpus/core/media-upload.service';
import {Subscription} from 'rxjs';
import {TagsSelectionModalWrapperComponent} from 'fuse-core/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl} from '@angular/forms';
import {fuseAnimations} from 'fuse-core/animations';
import {isEmpty} from 'shared/utils';

@Component({
    selector: 'fuse-corpus-file-list',
    templateUrl: './corpus-file-list.component.html',
    styleUrls: ['./corpus-file-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CorpusFileListComponent implements OnInit, OnDestroy {
    @ViewChild('typeSelect') typeSelect: MatSelect;
    @ViewChild('titleInput') titleInput: MatInput;
    @ViewChild('scrollToTopContent', {static: true}) scrollToTopContent: ElementRef;

    @Input('corpusId') corpusId: string;

    @Input() urlFilters: {
        [key: string]: string;
    };
    ressourcesSubscription: Subscription;
    corpusSubscription: Subscription;
    ressources: CorpusRessource[];
    files: CorpusRessource[];
    checkboxesList: {};
    public ELEMENT_DATA = [];
    dataSource = new MatTableDataSource();
    uniqueTitles;
    uniqueAuthors;
    displayedColumns: string[] = [];
    displayFiltersIcons;
    rolesCanShowBannerInfo: string[];
    selected: CorpusRessource;
    items: any[];
    types: string[];
    icon;
    // https://github.com/angular/material2/issues/10205
    private paginator: MatPaginator;
    private sort: MatSort;
    public listOfType: { id: string; label: string }[] = [];
    @Input('itemId') itemId: string = null;

    allControls: object = {};

    resourcesIndex: { [key: number]: CorpusRessource } = {};

    selectedActivities: CorpusRessource[] = [];

    @Input('selection') selection: CorpusRessource[] = [];
    @Input('mode') mode = 'normal';
    private refreshCorpusObservable: Subscription;
    private corpusLoaded = false;
    private isSearchResult = false;

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
    }

    @ViewChild(MatPaginator, {static: true}) set matPaginator(
        mp: MatPaginator
    ) {
        this.paginator = mp;
    }

    selectedResources: CorpusRessource[] = [];

    selectedLesson: DataEntity;
    dialogText: object;

    @ViewChildren(MatCheckbox) checkBoxes: QueryList<MatCheckbox>;

    @Output('selectionChanged') selectionChanged: EventEmitter<CorpusRessource[]> = new EventEmitter<CorpusRessource[]>();

    displayedFilters: string[] = [];
    public tagsSelected: object = {concepts: [], skills: [], chapters: []};

    public methods: any;

    public difficultyList: any;

    public levelsList: any;

    public conceptsList: any;

    public skillsList: any;

    public tags: any;

    public settings: { [key: string]: any };

    public dialogRef: MatDialogRef<any>;

    private unsubscribeInTakeUntil = new Subject<void>();

    public videoData: DataEntity;

    private queryParams: any;
    buttonDisabled: boolean;
    // by default no overide of text use existant code for moment to avoid regression
    public customFieldName: { field: string; value: string }[] = [
        {field: 'title', value: 'generic.title'},
        {field: 'author', value: ''},
        {
            field: 'group',
            value: '',
        },
        {field: 'type', value: ''},
    ];

    constructor(
        public corpusService: CorpusService,
        public authService: AuthenticationService,
        private route: ActivatedRoute,
        private mediaUploadService: MediaUploadService,
        private router: Router,
        private communicationCenter: CommunicationCenterService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private flagService: FlagService,
        private octopusConnect: OctopusConnectService
    ) {
        // Todo à simplifier pour utiliser this.route
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => {
                    if (route.queryParams) {
                        return route.queryParams;
                    } else {
                        return null;
                    }
                })
            )
            .subscribe((params: Params) => {
                if (params['itemId']) {
                    this.itemId = params['itemId'];
                }
            });

        this.route.queryParams.subscribe((params) => {
            this.queryParams = params;
        });

        // TODO find a better way to distinct corpus
        if (this.corpusService.settings.userCorpus) {
            if (this.router.url.indexOf('/projects/') > -1) {
                // check if we are inside a project
                this.mode = 'normal';
            } else {
                if (authService.isTrainer()) {
                    if (
                        this.router.url.indexOf(
                            '/user/' + this.corpusService.currentUser.id
                        ) > -1
                    ) {
                        // check if we are in user corpus
                        this.mode = 'normal';
                    } else {
                        this.mode = 'readonly';
                    }
                }
                if (authService.isAdministrator() || authService.isManager()) {
                    this.mode = 'normal';
                }
            }
        }
    }

    /**
     build url to return in activity edited in lesson editor with steps
     use callback() to return if callback exist or use query params.
     */
    public goBackToActivity(): void {
        if (this.lessonEditorWithStepConfig.callback) {
            this.lessonEditorWithStepConfig.callback();
        } else {
            if (this.queryParams['lessonId'] && this.queryParams['activityId']) {
                // TODO brancher au nouvel editeur
            } else {
                // TODO Better to use communication center
                this.router.navigate(['lessons', 'list']);
            }
        }
    }

    /**
     * active filter when in settings we have set value
     * that mean user can see only some type of file like video in corpus
     */
    private addFilterForced(): void {
        this.settings.filterFormatForced.forEach((filterFormatForcedKey) => {
            this.corpusService.filters['format'] = filterFormatForcedKey;
        });
    }

    public get inSelectionMode(): boolean {
        return !!this.corpusService.corpusSelection;
    }

    public get displayAddToLesson(): boolean {
        return (
            this.settings.addToLesson &&
            this.lessonEditorWithStepConfig &&
            !this.lessonEditorWithStepConfig.mode
        );
    }

    public get lessonEditorWithStepConfig(): any {
        return this.corpusService.lessonEditorWithStepConfig;
    }

    addToActivity(): void {
        this.communicationCenter
            .getRoom('activities')
            .next('addResourceToActivity', this.selected);
        // this.router.navigateByUrl(this.corpusService.corpusSelection);
    }

    public get displayFilterCheckboxOption(): boolean {
        return this.displayedColumns && this.displayedColumns.includes('checkbox');
    }

    /**
     * get selected ressource to send it at the parent => CorpusDisplayWrapperComponent
     */
    public addToLessonThumbnail(): void {
        this.corpusService.dataForModalOpening.callBack(
            this.selected.ressourceEntity
        );
    }

    public addToLesson(): void {
        this.lockRessource();
        this.communicationCenter
            .getRoom('activities')
            .next('addResources', this.selectedResources);
        this.router.navigateByUrl(this.corpusService.corpusSelection);
    }

    /**
     * locked ressource if ressource is link in lesson
     * because delete ressource not permit if exist in one or many lesson
     */
    private lockRessource(): void {
        try {
            this.selectedResources.forEach((entity: any) => {
                const entityTemp = new DataEntity(
                    'granule',
                    entity.attributes,
                    this.octopusConnect,
                    entity.id
                );
                // local entity cannot be save directy endpoint is read only
                entity.set('locked', true);
                entityTemp.set('locked', true);
                // save in back
                entityTemp.save().subscribe(() => {
                });
            });
        } catch (ex) {
            console.error('error when try locking ressource for delete' + ex);
        }
    }

    public get selectedCount(): number {
        return this.selectedResources.length;
    }

    onPaginateChange(event): void {
        this.corpusService.paginatedCollection.paginator.page = event.pageIndex + 1;
        this.scrollToTopContent.nativeElement.scrollTop = 0;
    }

    updateSelection(resources: CorpusRessource[]): void {
        this.checkboxesList = {};
        resources.forEach((resource) => {
            this.checkboxesList[resource.id] = true;
        });
    }

    public onMultiSelectionChange(resource: CorpusRessource): void {
        this.corpusService.toggleResourceSelection(resource.ressourceEntity);
    }

    ngOnInit(): void {

        this.communicationCenter
            .getRoom('activities')
            .getSubject('editActivityOver')
            .subscribe(() => {
                this.router.navigateByUrl(this.corpusService.corpusSelection);
            });

        this.corpusService.filters = {};
        this.corpusService.clearSelection(!this.selection);

        if (this.urlFilters?.titleFilter) {
            this.corpusService.filters['title'] = this.urlFilters['titleFilter'];
        }

        if (this.urlFilters?.conceptsFilter) {
            this.corpusService.filters['concepts'] = this.urlFilters['conceptsFilter'];
        }

        if (this.urlFilters?.thematicsFilter) {
            this.corpusService.filters['thematics'] = this.urlFilters['thematicsFilter'];
        }

        if (this.urlFilters?.educationnalLevelFilter) {
            this.corpusService.filters['level'] = this.urlFilters['educationnalLevelFilter'];
        }

        this.settings = this.corpusService.settings;
        this.displayFiltersIcons = this.settings.displayFiltersIcons;
        this.rolesCanShowBannerInfo = this.settings.rolesCanShowBannerInfo;
        // set the list of type for filter search because list not coming form back like other fields
        this.listOfType = this.settings.mediaTypes.map((type) => {
            return {id: type, label: type};
        });

        this.corpusService.onSelectedResourcesChanged
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources: CorpusRessource[]) => {
                this.selectedActivities = [];
                this.selectedResources = resources;
                this.checkboxesList = {};

                for (const activity in resources) {
                    if (resources.hasOwnProperty(activity)) {
                        this.selectedActivities.push(resources[activity]);
                        this.checkboxesList[resources[activity].id] = true;
                    }
                }

                this.selectionChanged.emit(resources);
                this.buttonDisabled = isEmpty(this.checkboxesList);
            });

        this.getMethods();
        this.getDifficulty();
        this.getLevelsList();
        this.getSkills();
        this.getConcepts();
        this.translate.onLangChange.subscribe(() => {
            this.getMethods();
        });

        if (this.settings.filters[this.authService.accessLevel]) {
            this.displayedFilters =
                this.settings.filters[this.authService.accessLevel];
        } else {
            this.displayedFilters = this.settings.filters['default'];
        }


        this.addFilterForced();
        this.noCloneFilter();

        if (!this.corpusId) {
            this.route.params.subscribe((params) => {
                if (params['id']) {
                    this.corpusId = params['id'];
                } else {
                    this.corpusId = this.corpusService.settings.globalCorpus;
                }
                this.launch();
            });
        } else {
            this.launch();
        }

        this.allControls = {
            searchTitleControl: {
                type: 'title',
                control: new UntypedFormControl(''),
            },
            searchAuthorControl: {
                type: 'author:name',
                control: new UntypedFormControl(''),
            },
            searchGroupControl: {
                type: 'group:name',
                control: new UntypedFormControl(''),
            },
            searchTypeControl: {
                type: 'format',
                control: new UntypedFormControl(''),
            },
            searchDifficultyControl: {
                type: 'difficulty_id',
                control: new UntypedFormControl(''),
            },
            searchLevelsControl: {
                type: 'level',
                control: new UntypedFormControl(''),
            },
            searchSkillsControl: {
                type: 'skills',
                control: new UntypedFormControl(''),
            },
            searchBookmarksControl: {
                type: 'bookmarks',
                control: new UntypedFormControl(''),
            },
            searchConceptsControl: {
                type: 'concepts',
                control: new UntypedFormControl(''),
            },
        };

        this.updateCheck(true, this.selection);

        this.refreshCorpusObservable = this.corpusService.refreshCorpus.subscribe(
            () => {
                // emit from service launch refresh corpus list after deleted item
                this.refreshCorpus();
            }
        );
        this.initCustomFieldTerm();
    }


    public blurElementRef(): void {
        this.typeSelect.close();
        this.titleInput.focus();
    }

    getMethods(): void {
        this.corpusService
            .getMethods()
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.methods = data.entities;
                }
            });
    }

    getDifficulty(): void {
        this.corpusService
            .getDifficulty()
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.difficultyList = data.entities;
                }
            });
    }

    getLevelsList(): void {
        this.corpusService
            .getLevels()
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.levelsList = data.entities;
                }
            });
    }

    getConcepts(): void {
        this.corpusService
            .getConcepts()
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.conceptsList = data.entities;
                }
            });
    }

    getSkills(): void {
        this.corpusService
            .getSkills()
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.skillsList = data.entities;
                }
            });
    }

    ngOnDestroy(): void {
        this.corpusService.resetDataForModalOpening();
        this.selectedResources = []; // empty selected elements
        this.corpusService.setSelectionMode();
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
        this.refreshCorpusObservable.unsubscribe();
    }

    public getConceptsAsString(metadatas: DataEntity): string {
        const concepts = metadatas.get('concepts') || [];
        return concepts.map((concept) => concept.label || '').join(', ') || '';
    }

    public getConcept(metadatas: DataEntity): string {
        const concepts = metadatas.get('concepts');
        return (concepts && concepts.length > 0 && concepts[0]?.label) ? concepts[0]?.label : '';
    }

    public getContentTypeIcon(data): any {
        // row.format, row.referenceEntity.attributes['filemime']
        let format: string = null;
        let filemime: string = null;

        if (data.format) {
            format = data.format;
        }
        if (data.referenceEntity.attributes) {
            filemime = data.referenceEntity.attributes.filemime;
        }

        let iconObj = {
            name: 'close',
            translate: 'generic.other',
        };

        if (format) {
            switch (format) {
                case 'media':
                case 'audio':
                case 'video':
                case 'document':
                case 'image':
                    switch (filemime) {
                        case 'image/png':
                        case 'image/jpg':
                        case 'image/jpeg':
                        case 'image/gif':
                            iconObj = {
                                name: 'photo',
                                translate: 'generic.photo',
                            };
                            break;
                        case 'audio/mp3':
                        case 'audio/mpeg':
                            iconObj = {
                                name: 'audio',
                                translate: 'generic.audio',
                            };
                            break;
                        case 'video/mp4':
                        case 'video/mpeg':
                            iconObj = {
                                name: 'video',
                                translate: 'generic.video',
                            };
                            break;
                        case 'application/pdf':
                            iconObj = {
                                name: 'document',
                                translate: 'generic.document',
                            };
                            break;
                        default:
                            iconObj = {
                                name: 'photo',
                                translate: 'generic.photo',
                            };
                            break;
                    }
                    break;
                case 'videoUrl':
                    iconObj = {
                        name: 'video',
                        translate: 'generic.video',
                    };
                    break;
                case 'url':
                    switch (filemime) {
                        case 'image/png':
                        case 'image/jpg':
                        case 'image/jpeg':
                        case 'image/gif':
                            iconObj = {
                                name: 'photo',
                                translate: 'generic.photo',
                            };
                            break;
                        case 'audio/mp3':
                        case 'audio/mpeg':
                            iconObj = {
                                name: 'video',
                                translate: 'generic.video',
                            };
                            break;
                        case 'video/mp4':
                        case 'video/mpeg':
                            iconObj = {
                                name: 'video',
                                translate: 'generic.video',
                            };
                            break;
                        case 'application/pdf':
                            iconObj = {
                                name: 'document',
                                translate: 'generic.document',
                            };
                            break;
                        default:
                            iconObj = {
                                name: 'link',
                                translate: 'generic.link',
                            };
                            break;
                    }
                    break;
                case 'activity':
                    iconObj = {
                        name: 'activity',
                        translate: 'generic.activities',
                    };
                    break;
                case 'lesson':
                    this.icon = 'activities';
                    iconObj = {
                        name: 'lessons',
                        translate: 'activities.title_lessons',
                    };
                    break;
                default:
                    break;
            }
        } else {
            iconObj = null;
        }

        return iconObj;
    }

    launch(): void {
        this.corpusService.onFilesChanged
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((files: CorpusRessource[]) => {
                this.files = files;

                if (this.selection.length) {
                    this.checkboxesList = {};
                    files.forEach((file) => {
                        this.checkboxesList[file.id] = false;
                        this.selection.forEach((resource) => {
                            this.checkboxesList[resource.id] = true;
                        });
                    });
                } else {
                    this.corpusService.onSelectedResourcesChanged.next(
                        _.clone(this.selectedResources)
                    );
                }

                this.corpusService.selectAll = true;

                files.some((file: CorpusRessource) => {
                    if (!this.checkboxesList[file.id]) {
                        this.corpusService.selectAll = false;
                        return true;
                    }
                });
            });

        this.corpusService.onFileSelected
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((selected) => {
                if (selected && Object.keys(selected).length > 0) {
                    this.selected = selected;
                }
            });

        if (this.ressourcesSubscription) {
            this.ressourcesSubscription.unsubscribe();
        }

        if (this.corpusSubscription) {
            this.corpusSubscription.unsubscribe();
        }

        this.setFilterInCaseOfRoute();
        this.ressourcesSubscription = this.refreshCorpus();
    }

    /**
     * if route is /corpus/pdf apply filter to show only pdf
     */
    private setFilterInCaseOfRoute(): void {
        if (this.corpusService.isDocumentTypePdf()) {
            this.applyFilters({value: 'document'}, 'format');
        }
    }

    launchSearch(): void {
        for (const field in this.allControls) {
            if (this.allControls.hasOwnProperty(field)) {
                this.applyFilters(
                    {value: this.allControls[field].control.value},
                    this.allControls[field].type
                );
            }
        }
        this.ressourcesSubscription.unsubscribe();
        // filter on only pdf in case of route corpus/pdf/
        this.setFilterInCaseOfRoute();
        this.addFilterForced();
        this.noCloneFilter();
        this.ressourcesSubscription = this.refreshCorpus();
    }

    /**
     * generate filter to use in backend request in regard of fields content
     * @param optionsInterface : filter to apply to backend request
     */
    public launchSearchApp(
        optionsInterface: CollectionOptionsInterface = {
            filter: {},
            page: 1,
            range: 12,
        }
    ): void {
        this.isSearchResult = true;
        for (const field in this.allControls) {
            if (this.allControls.hasOwnProperty(field)) {
                this.applyFilters(
                    {value: this.allControls[field].control.value},
                    this.allControls[field].type
                );
            }
        }

        if (optionsInterface.urlExtension) {
            this.applyFilters({value: optionsInterface.urlExtension}, 'title');
        }

        // for maintain all old code like before we push filter of search barre filter like other here
        for (const key in optionsInterface.filter) {
            this.applyFilters({value: optionsInterface.filter[key]}, key);
        }

        this.ressourcesSubscription.unsubscribe();
        this.addFilterForced();
        this.setFilterInCaseOfRoute();
        this.noCloneFilter();
        this.ressourcesSubscription = this.refreshCorpus();
    }

    refreshCorpus(): Subscription {
        if (
            this.lessonEditorWithStepConfig &&
            this.lessonEditorWithStepConfig.userCorpus
        ) {
            return this.loadCorpus(this.corpusService.currentUser.id.toString());
        } else {
            return this.loadCorpus();
        }
    }

    private loadCorpus(corpusId?, range?, filter?, sort?, origin?): Subscription {
        return this.corpusService
            .loadCorpusRessources(corpusId, range, filter, sort, origin)
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources: CorpusRessource[]) => {
                this.corpusService.onFilesChanged.next(resources);
                resources.forEach((resource: CorpusRessource) => {
                    this.resourcesIndex[resource.id] = resource;
                });
                this.ressources = resources;
                let ressourceIndex = 0;
                if (!resources) {
                    return;
                }
                this.ELEMENT_DATA = resources;

                // if a specific item is required
                if (this.itemId) {
                    this.ELEMENT_DATA.some((resource: CorpusRessource, index) => {
                        if (+resource.id === +this.itemId) {
                            ressourceIndex = index;
                            return true;
                        }
                    });
                }

                if (this.settings.columns[this.authService.accessLevel]) {
                    this.displayedColumns = _.clone(
                        this.settings.columns[this.authService.accessLevel]
                    );
                } else {
                    this.displayedColumns = _.clone(this.settings.columns['default']);
                }

                if (
                    !this.corpusService.accessMatrix['global'].selectAll.includes(
                        this.authService.accessLevel
                    ) &&
                    this.corpusId === this.corpusService.settings.globalCorpus
                ) {
                    const index: number = this.displayedColumns.indexOf('checkbox');
                    if (index > -1) {
                        this.displayedColumns.splice(index, 1);
                    }
                }

                this.dataSource.data = this.ELEMENT_DATA;

                const titles = resources.map((data) => data.title);
                this.uniqueTitles = titles.filter((x, i, a) => x && a.indexOf(x) === i);
                this.uniqueTitles.sort();

                if (!this.uniqueAuthors) {
                    const authors = resources.map((data) =>
                        data.ressourceEntity.get('owner-name')
                    );
                    this.uniqueAuthors = authors.filter(
                        (x, i, a) => x && a.indexOf(x) === i
                    );
                    this.uniqueAuthors.sort();
                }

                /* select first row on init */
                this.selected = this.ELEMENT_DATA[ressourceIndex];
                this.corpusService.onFileSelected.next(this.selected);

                if (
                    this.displayedColumns[0] === 'checkbox' &&
                    !this.corpusService.dataForModalOpening.showCheckBox
                ) {
                    this.displayedColumns.splice(0, 1);
                }

                this.corpusLoaded = true;
            });
    }

    onSelect(selected): void {
        this.corpusService.onFileSelected.next(selected);
    }

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    isSelected(row: CorpusRessource): boolean {
        return this.selected && this.selected.id === row.id;
    }

    updateCheck(keep: boolean = null, resources?: CorpusRessource[]): void {
        const toCheck = resources || this.ressources;

        toCheck.forEach((resource: CorpusRessource) => {
            this.corpusService.toggleResourceSelection(
                resource.ressourceEntity,
                (keep !== null && keep) || this.corpusService.selectAll
            );
        });
    }

    applyFilters(event, type): void {
        if (event.value === 'allf' || event.value === 'all' || event.value === '') {
            delete this.corpusService.filters[type];
        } else {
            this.corpusService.filters[type] = event.value;
        }
    }

    openResourceCreationModal(): void {
        this.mediaUploadService.openResourceCreationModal();
    }

    openResourceEditionModal(resource: CorpusRessource): void {
        this.mediaUploadService.openResourceEditionModal(resource);
    }

    checkAccessLevel(action: string): boolean {
        return this.corpusService.checkAccessLevel(this.selected, action);
    }

    /**
     * check if for each row there s at least one item menu allowed if not remove 3 dot menu.
     * @param entity : one row in table
     */
    public checkAtLeastOneAccessMenu(entity: CorpusRessource): boolean {
        const editItem = this.checkAccessLevel('edit') && this.mode !== 'readonly';
        const deleteItem =
            this.checkAccessLevel('delete') &&
            this.mode !== 'readonly' &&
            !!entity.locked;
        const printItem =
            this.checkAccessLevel('print') &&
            (entity.format === 'image' || entity.format === 'document');
        let downloadItem = false;
        try {
            downloadItem =
                entity.ressourceEntity &&
                entity.ressourceEntity.attributes.reference.filesize.length > 0 &&
                this.checkAccessLevel('download') &&
                this.mode !== 'readonly';
        } catch (ex) {
            // nothing already at false to manage attribute undefined ressource entity undefined etc...
        }
        return editItem || deleteItem || printItem || downloadItem;
    }

    displayFilters(name: string): boolean {
        return this.displayedFilters.indexOf(name) > -1;
    }

    public displayedFiltersLength(type: string): string {
        const tagsLength = this.tagsSelected[type].length;
        if (tagsLength) {
            return tagsLength.toString();
        }

        return 'generic.all';
    }

    resetTags(type: string): void {
        this.tagsSelected[type] = [];
        this.applyFilters({value: ''}, type);
        this.launchSearch();
    }

    tagsPopUp(type): void {
        let component;
        let data;
        if (type === 'chapters') {
            component = ChaptersSelectionComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                methods: this.methods,
                loadChapters: (event) => this.corpusService.loadChapters(event),
                chaptersChanged: this.corpusService.chaptersChanged,
            };
        } else {
            component = TagsSelectionModalWrapperComponent;
            data = {
                data: this.tagsSelected[type],
                type: type,
                tags: this.tags,
                loadTags: (event) => this.corpusService.getTags(event),
                tagsChanged: this.corpusService.tagsChanged,
            };
        }

        this.dialogRef = this.dialog.open(component, {
            panelClass: 'entity-form-dialog',
            data: data,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tagsSelected[type] = result;
                this.applyFilters({value: result.map((o) => o.id).join(',')}, type);
                this.launchSearch();
            }
        });
    }

    public get showButtonOptions(): boolean {
        return (
            this.settings &&
            this.settings.globalCorpus === this.corpusId &&
            this.settings.showButtonOptions
        );
    }

    public launchLessonListingModal(activityId): void {
        let data;

        data = {
            activities: this.selectedActivities,
            currentUserRoles: this.corpusService.currentUser.attributes['role'],
            allowedRoleIdsForModelsCreation:
                this.corpusService.getAllowedRoleIdsForModelsCreation(),
        };

        this.dialogRef = this.dialog.open(LessonsSelectionComponent, {
            data: data,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.selectedLesson = result.lessons[0];

                // add activities
                this.communicationCenter
                    .getRoom('activities')
                    .next('updateCurrentLesson', this.selectedLesson);

                this.communicationCenter
                    .getRoom('activities')
                    .next('addResources', result.activities);

                this.communicationCenter
                    .getRoom('activities')
                    .next(
                        'saveFromModalToLesson',
                        this.selectedLesson.attributes.reference
                    );

                // confirmation modal
                this.dialogText = {
                    title: 'generic.add_to_lesson',
                    yes: 'activities.lesson.dialog_edit',
                    message: 'generic.added_activities',
                };
                for (const field in this.dialogText) {
                    if (this.dialogText.hasOwnProperty(field)) {
                        this.translate
                            .get(this.dialogText[field])
                            .subscribe(
                                (translation: string) => (this.dialogText[field] = translation)
                            );
                    }
                }

                const dialogConfig = new MatDialogConfig();

                dialogConfig.data = {
                    titleDialog: this.dialogText['title'],
                    panelClass: 'entity-form-dialog',
                };

                dialogConfig.data.bodyDialog =
                    this.dialogText['message'] +
                    '<br>' +
                    this.selectedLesson.attributes.metadatas.title;
                dialogConfig.data.labelTrueDialog = this.dialogText['yes'];

                const confirmDialogRef = this.dialog.open(
                    FuseConfirmDialogComponent,
                    dialogConfig
                );

                confirmDialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.communicationCenter
                            .getRoom('lessons')
                            .next('openEditor', this.selectedLesson.id);
                    }
                });
            }
        });
    }

    updateCheckBookmarks(event): any {
        this.launchSearch();
    }

    flagEntity(entity, entityType: string, flag: string): void {
        this.flagService.flagEntity(entity.ressourceEntity, entityType, flag);

        if (entity.bookmarks === true) {
            entity.bookmarks = false;
        } else {
            entity.bookmarks = true;
        }
    }

    getColumnTerms(field): string {
        if (this.corpusService.corpusTerms && this.corpusService.corpusTerms.columns[field]) {
            return (
                this.corpusService.corpusTerms &&
                this.corpusService.corpusTerms.columns[field]
            );
        } else if (field === 'type' && !(this.corpusService.corpusTerms && this.corpusService.corpusTerms.columns[field])) {
            return 'generic.type_global_corpus';
        }


    }

    /**
     * init all custom field if exist with the good value
     * use old code to init to avoid regression
     */
    private initCustomFieldTerm(): void {
        this.customFieldName.forEach((customFields) => {
            if (this.getColumnTerms(customFields.field)) {
                customFields.value = this.getColumnTerms(customFields.field);
            }
        });
    }

    /**
     * check if we show create corpus helper
     * @returns {boolean}
     */
    public get displayCreateCorpusHelper(): boolean {
        return (
            this.mode === 'normal' &&
            this.corpusService.settings.displayCreateCorpusHelper
        );
    }

    /**
     * check if we show community corpus helper
     * @returns {boolean}
     */
    public get displayCommunityCorpusHelper(): boolean {
        return this.router.url.includes('community');
    }

    /**
     * check if corpus is empty and display helper
     * @returns {boolean}
     */
    public get displayEmptyCorpusHelper(): boolean {
        return (!this.isSearchResult && this.corpusLoaded && this.ELEMENT_DATA.length === 0 &&
            this.corpusService.settings.displayEmptyCorpusHelper && this.authService.isAtLeastTrainer()
        );
    }

    /**
     * play help video
     */
    public playHelpVideo(): void {
        const dialogConfig = new MatDialogConfig();

        this.translate
            .get('generic.help.video')
            .subscribe((translation: string) => {
                dialogConfig.data = {
                    titleDialog: translation,
                    panelClass: ['help-corpus-dialog', 'wide-dialog'],
                };
            });

        dialogConfig.data.bodyDialog =
            '<video controls>' +
            '<source src="' +
            this.videoUrlToLaunch() +
            '" type="video/mp4">' +
            'Your browser does not support HTML5 video.' +
            '</video>';

        const dialogRef = this.dialog.open(
            FuseConfirmDialogComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                window.open('uri', '_blank');
            }
        });
    }

    /**
     * return the video help url in regard of lang and place
     */
    public videoUrlToLaunch(): string {
        let url = '';
        // defensive programming if error due to setting not exisiting do nothing normal if instance doesn't use this field
        try {
            if (this.videoData) {
                if (this.videoData.get('helpCorpusVideo')[this.translate.currentLang]) {
                    url =
                        this.videoData.get('helpCorpusVideo')[this.translate.currentLang];
                }
            }
        } catch (ex) {
            // nothing url doesn't exist from most instance just return '';
        }
        return url;
    }

    /**
     * apply or not noClone Filter in regard of settings
     */
    private noCloneFilter(): void {
        if (this.settings.corpusNoClone) {
            this.corpusService.filters['corpusNoClone'] = true;
        }
    }

    public getCorpusSituation(): string {
        let suffix = 'corpus_';
        if (this.router.url.indexOf('/user/') > -1) {
            return suffix + 'class';
        } else {
            return suffix + 'global';
        }
    }

    public displayVisibility(): boolean {
        return this.corpusService.settings.displayVisibility;
    }
}