// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `navbar.horizontal-style-1 {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/navbar/horizontal/style-1/style-1.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/layout/components/navbar/horizontal/style-1/style-1.component.scss"],"names":[],"mappings":"AAMI;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;ACLR","sourcesContent":["navbar-horizontal-style-1 {\n\n}\n\nnavbar {\n\n    &.horizontal-style-1 {\n        display: flex;\n        flex-direction: column;\n        flex: 1 1 auto;\n        width: 100%;\n        height: 56px;\n        max-height: 56px;\n        min-height: 56px;\n    }\n}","navbar.horizontal-style-1 {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  width: 100%;\n  height: 56px;\n  max-height: 56px;\n  min-height: 56px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
