import { Component } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
  selector: 'app-alert-learners-come-from-sso-news',
  templateUrl: './alert-learners-come-from-sso-news.component.html',
  styleUrls: ['./alert-learners-come-from-sso-news.component.scss']
})
export class AlertLearnersComeFromSsoNewsComponent {

  constructor(
      private snackBar: MatSnackBar,
      private communicationCenter: CommunicationCenterService,
  ) { }

    public understood($event): void {
        $event.preventDefault();
        this.snackBar.dismiss();
        this.communicationCenter
            .getRoom('GroupsManagementNews').next('alertLearnersComeFromSsoUnderstand', true);
        // listened to by the GroupsManagementNewsService to remove the news
    }
}