import {Component, Input, OnInit} from '@angular/core';
import {WidgetProjectService} from '@modules/dashboard/core/widgets/widget-project/widget-project.service';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import {ReplaySubject} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-dashwidget-project',
    templateUrl: './widget-project.component.html',
    styleUrls: ['./widget-project.component.scss'],
    animations   : fuseAnimations
})
export class WidgetProjectComponent implements OnInit {
    @Input('projectsSubscription') projectsSubscription: ReplaySubject<any>;
    displayedColumns = ['name', 'groups'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();
    resource: boolean;


    constructor(
        public authService: AuthenticationService,
        private widgetProjectService: WidgetProjectService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (this.authService.hasLevel(['learner'])){
            this.displayedColumns = ['name'];
        }

        this.projectsSubscription.subscribe((resources) => {
            this.dataSource.data = resources.slice(0, 3);
            this.resource = resources && resources.length;
        });
    }

    public goToProject(project): void {
        this.router.navigate(['/projects', project.id, 'dashboard']);
    }


    public get align(): string {
        if (!this.empty) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return !this.resource;
    }

    public get getTermsByRole(): string {
        return  this.authService.isAtLeastTrainer() ? 'dashboard.project_empty_widget_trainer' : 'dashboard.project_empty_widget_learner';
    }


}