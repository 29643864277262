import {Injectable} from '@angular/core';
import {SettingsCardsDisplayInterface} from 'fuse-core/components/card/settings-cards-display.interface';
import {ModelSchema, Structures} from 'octopus-model';
import {Roles, RolesOrDefault} from 'shared/models';
import {getSettingByRole} from 'shared/utils/settings';
import {SwitchSettingsType} from 'shared/utils/settings/switch-settings.type';
import {modulesSettings} from '../../../app/settings';

const RawSettings = {
    lessonPageMenuActions: Structures.object({
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            manager: ['duplicate'],
            default: ['duplicate']
        }
    }),
    menuLinks: Structures.object({
        lesson: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign', 'duplicate'],
            default: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'assign']
        },
        model: {
            administrator: ['add', 'edit', 'editOwn', 'delete', 'deleteOwn', 'duplicate'],
            manager: ['duplicate'],
            default: ['duplicate']
        }
    }),
    cardFields: Structures.object({
        default: ['title', 'menu', 'lessonStats', 'keywords', 'keywords-chip-list', 'metadatas', 'assigned']
    }),
    cardDisplay: Structures.object({
        displayLessonBreadcrumb: Structures.object({usages: false, concepts: false, chapters: false, skills: false}),
        showButtonThemeLesson: Structures.boolean(false),
        showButtonBookmarks: Structures.boolean(true),
        isTextButton: Structures.boolean(false)
    }),
    cardDefaultHeader: Structures.string(),
    cardPlayOpenMenu: Structures.boolean(false),
    chooseSubLessonFeatureActive: Structures.object({default: false}),
    displayMenuWithPreviewOption: Structures.boolean(false),
    difficultyDisplaySteps: Structures.number(0),
    isLaunchLessonAskComment: Structures.boolean(false),
    isLaunchLessonAskModalActive: Structures.object({default: false}),
    isLaunchLessonAskTitle: Structures.boolean(false),
    launchLessonCommentMaxLength: Structures.number(null),
    multiPdfDownload: Structures.boolean(false),
    playButtonUseIcon: Structures.boolean(false),
    hidePlayPreviewFromFavoritesPage: Structures.boolean(false),
    allowedMultiPdfDownloadFromFavoritePage: Structures.boolean(false),
    viewLessonPage: Structures.boolean(false), // allow to show lesson page
    moveActionsButtonInMenu: Structures.object({
        default: {
            default: false,
            lesson: false,
        }
    }), // Display all button in menu button except bookmark and open lesson page
    displayOnlyMediaIconsType: Structures.boolean(false), // Display only icon
    roleForbiddenToDownloadPdfLesson: Structures.array([]),
    isPdfMustBeOpenedDirectly: Structures.boolean(false),
    isEditOrDisableButtonActive: Structures.object({default: false}),
    showCardHeaderMenu: Structures.boolean(true),
    useCurrentRouterUrlFromOriginPath: Structures.boolean(false),
    addExtraDataUrl: Structures.boolean(false),
    navigateOnInit: Structures.boolean(true),
    focusOnFileAfterViewInit: Structures.boolean(false),
    useCtzBackUrlLogic: Structures.boolean(false),
    usefilterUsageAutonomie: Structures.boolean(false),
    checkAssignableState: Structures.boolean(true),
    downloadLessonPdfMethodIsAllowed: Structures.boolean(true),
    openLessonPageListPdfAllowed: Structures.boolean(false),
    openCurrentPdfAllowed: Structures.boolean(false),
    pdfDonwnloadIcon: Structures.string('download-pdf'),
    playPreviewIcon: Structures.string('play-preview'),
    playMetadataIconAllowed: Structures.boolean(true),
    playAsIConIcon: Structures.string('play'),
    playAsLabelIcon: Structures.string('generic.lesson.play'),
    playAsLabelInActionMenuDynamicClass: Structures.boolean(false),
    buttonAssignIconConditionNotUseShowButtonAssign: Structures.boolean(false),
    openLessonPageUseOnlyDatacard: Structures.boolean(false),
    rootFilterSubscribeToSelectedValue: Structures.boolean(false),
    useIsLaunchLessonAskModalActiveWithoutParams: Structures.boolean(false),
    previewOption:Structures.object({preview: true}),

};

const settingsStructure: ModelSchema = new ModelSchema(RawSettings);

@Injectable({
    providedIn: 'root'
})
export class CardsConfigurationService {

    private settings = <SwitchSettingsType<typeof RawSettings>>settingsStructure.filterModel(modulesSettings.cards);

    public menuLinks() {
        return this.settings.menuLinks as Record<'lesson' | 'model', Partial<Record<Roles, string[]>> & Record<'default', string[]>>;
    }

    public cardFields() {
        return this.settings.cardFields;
    }

    public cardDisplay() {
        return this.settings.cardDisplay as Partial<SettingsCardsDisplayInterface>;
    }

    public cardDefaultHeader() {
        return this.settings.cardDefaultHeader;
    }

    public cardPlayOpenMenu() {
        return this.settings.cardPlayOpenMenu;
    }

    public chooseSubLessonFeatureActive() {
        return this.settings.chooseSubLessonFeatureActive;
    }

    public displayMenuWithPreviewOption() {
        return this.settings.displayMenuWithPreviewOption;
    }

    public difficultyDisplaySteps() {
        return this.settings.difficultyDisplaySteps;
    }

    public isLaunchLessonAskComment() {
        return this.settings.isLaunchLessonAskComment;
    }

    public isLaunchLessonAskModalActive(role: Roles) {
        const setting = this.settings.isLaunchLessonAskModalActive as { [k in RolesOrDefault]: boolean };
        return getSettingByRole(setting, role);
    }

    public isLaunchLessonAskTitle() {
        return this.settings.isLaunchLessonAskTitle;
    }

    public launchLessonCommentMaxLength() {
        return this.settings.launchLessonCommentMaxLength;
    }

    public multiPdfDownload() {
        return this.settings.multiPdfDownload;
    }

    public hidePlayPreviewFromFavoritesPage() {
        return this.settings.hidePlayPreviewFromFavoritesPage;
    }

    public allowedMultiPdfDownloadFromFavoritePage() {
        return this.settings.allowedMultiPdfDownloadFromFavoritePage;
    }

    public viewLessonPage() {
        return this.settings.viewLessonPage;
    }

    public moveActionsButtonInMenu() {
        return this.settings.moveActionsButtonInMenu;
    }

    public displayOnlyMediaIconsType() {
        return this.settings.displayOnlyMediaIconsType;
    }

    public roleForbiddenToDownloadPdfLesson(): Roles[] {
        return this.settings.roleForbiddenToDownloadPdfLesson;
    }

    public isPdfMustBeOpenedDirectly() {
        return this.settings.isPdfMustBeOpenedDirectly;
    }

    public isEditOrDisableButtonActive(accessLevel: Roles) {
        return this.settings.isEditOrDisableButtonActive[accessLevel];
    }

    public lessonPageMenuActions() {
        return this.settings.lessonPageMenuActions as Record<'lesson' | 'model', Partial<Record<Roles, string[]>> & Record<'default', string[]>>;
    }

    public isShowCardHeaderMenu(): boolean {
        return this.settings.showCardHeaderMenu;
    }

    public isplayButtonUseIcon(): boolean {
        return this.settings.playButtonUseIcon;
    }

    /**ctz-settings**/
    public useCurrentRouterUrlFromOriginPath(): boolean {
        return this.settings?.useCurrentRouterUrlFromOriginPath;
    }

    public addExtraDataUrl(): boolean {
        return this.settings.addExtraDataUrl;
    }

    public navigateOnInit(): boolean {
        return this.settings.navigateOnInit;
    }

    public focusOnFileAfterViewInit(): boolean {
        return this.settings.focusOnFileAfterViewInit;
    }

    public useCtzBackUrlLogic(): boolean {
        return this.settings.useCtzBackUrlLogic;
    }

    public usefilterUsageAutonomie(): boolean {
        return this.settings.usefilterUsageAutonomie;
    }

    public checkAssignableState(): boolean {
        return this.settings.checkAssignableState;
    }


    public downloadLessonPdfMethodIsAllowed(): boolean {
        return this.settings.downloadLessonPdfMethodIsAllowed;
    }

    public openLessonPageListPdfAllowed(): boolean {
        return this.settings.openLessonPageListPdfAllowed;
    }

    public openCurrentPdfAllowed(): boolean {
        return this.settings.openCurrentPdfAllowed;
    }

    public pdfDonwnloadIcon(): string {
        return this.settings.pdfDonwnloadIcon;
    }

    public playPreviewIcon(): string {
        return this.settings.playPreviewIcon;
    }

    public playMetadataIconAllowed(): boolean {
        return this.settings.playMetadataIconAllowed;
    }

    public playAsIConIcon(): string {
        return this.settings.playAsIConIcon;
    }

    public playAsLabelIcon(): string {
        return this.settings.playAsLabelIcon;
    }

    public playAsLabelInActionMenuDynamicClass(): boolean {
        return this.settings.playAsLabelInActionMenuDynamicClass;
    }

    public buttonAssignIconConditionNotUseShowButtonAssign(): boolean {
        return this.settings.buttonAssignIconConditionNotUseShowButtonAssign;
    }

    public openLessonPageUseOnlyDatacard(): boolean {
        return this.settings.openLessonPageUseOnlyDatacard;
    }

    public rootFilterSubscribeToSelectedValue(): boolean {
        return this.settings.rootFilterSubscribeToSelectedValue;
    }

    public useIsLaunchLessonAskModalActiveWithoutParams(): boolean {
        return this.settings.useIsLaunchLessonAskModalActiveWithoutParams;
    }

    public previewOption(): any{
        return this.settings.previewOption;
    }

    /**end ctz-settings**/
}
