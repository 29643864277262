import {Component, Input, OnInit} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {IdeasWallService} from '../ideas-wall.service';
import {IdeasWallEditionModalComponent} from '../ideas-wall-edition-modal/ideas-wall-edition-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ideas-wall-item',
    templateUrl: './ideas-wall-item.component.html',
    styleUrls: ['./ideas-wall-item.component.scss']
})
export class IdeasWallItemComponent implements OnInit {

    @Input('wall') wall: DataEntity;
    locked: boolean;

    public displayMatMenuIcon: boolean;
    public lockedOptionEnabled: boolean;

    constructor(
        private wallsService: IdeasWallService,
        private dialog: MatDialog,
        private translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.locked = this.wall.get('locked') || false;
        this.lockedOptionEnabled = this.wallsService.settings.lockedOptionEnabled;
        this.displayMatMenuIcon = this.wallsService.settings.displayMatMenuIcon;
    }

    deleteWall(): void {
        const dialogConfig = new MatDialogConfig();
        let dialogYes = '';
        let dialogNo = '';
        let dialogDelete = '';

        this.translate.get('generic.yes').subscribe((translation: string) => dialogYes = translation);
        this.translate.get('generic.no').subscribe((translation: string) => dialogNo = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => dialogDelete = translation);

        dialogConfig.data = {
            bodyDialog: dialogDelete,
            labelTrueDialog: dialogYes,
            labelFalseDialog: dialogNo
        };

        this.dialog.open(FuseConfirmDialogComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                this.wall.remove();
            }
        });
    }

    /**
     * launch the modal in edit mode
     */
    public editWall(): void {
        if (this.wallsService.fieldsAllowedIdeaWallModal().indexOf('steps') > -1) {
            const subscribe = this.wallsService.loadStepList().subscribe(jobDone => {
                this.loadIdeasWallEditModal();
                subscribe.unsubscribe();
            });
        } else {
            this.loadIdeasWallEditModal();
        }
    }

    /**
     * load modal for editing wall item
     */
    private loadIdeasWallEditModal(): void {
        this.dialog.open(IdeasWallEditionModalComponent, {
            data: {
                name: this.wall.get('name') || '',
                text: this.wall.get('description'),
                phase: 'edition',
                lessonsTitles: this.wallsService.lessons,
                idLessonTitleSelected: this.wall.get('attached_nodes') ? this.wall.get('attached_nodes')[0].id : null,
                sessionSteps: this.wallsService.steps,
                idSessionStepSelected: this.wall.get('idea_wall_category') ? this.wall.get('idea_wall_category').id : null,
                fieldsAllowed: this.wallsService.fieldsAllowedIdeaWallModal(),
            }
        }).beforeClosed().subscribe((resp: Object) => {
            if (resp !== undefined) {
                this.wall.set('name', resp['name']);
                this.wall.set('description', resp['text']);
                this.wall.set('attached_nodes', [resp['idLessonTitle']]);
                this.wall.set('idea_wall_category', resp['idSessionStep']);
                this.wall.save();
            }
        });
    }

    deactivateWall(): void {
        this.wall.set('locked', true);
        this.wall.save();
    }

    activateWall(): void {
        this.wall.set('locked', false);
        this.wall.save();
    }

    getCreationDate(): string {
        const date = new Date(this.wall.get('created') * 1000);
        return date.toLocaleDateString((navigator.languages && navigator.languages[0]) || navigator.language);
    }

    isMine(): boolean {
        return +this.wall.get('uid') === +this.wallsService.currentUser.id;
    }
}