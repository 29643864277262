import {CommonModule} from '@angular/common';
import {inject, ModuleWithProviders, NgModule} from '@angular/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {RouterModule} from '@angular/router';
import {ProgressBarGenericModule} from '@fuse/components/progress-bar-generic/progress-bar-generic.module';
import {LessonsInChaptersListComponent} from '@modules/activities/core/activities-home/lessons-in-chapters-list/lessons-in-chapters-list.component';
import {PeriodListComponent} from '@modules/activities/core/activities-home/period-list/period-list.component';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {ActivitiesDirectiveModule} from '@modules/activities/core/directive/activities-directive.module';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';
import {CommunityListComponent} from '@modules/activities/core/lessons/lessons-list/community-list/community-list.component';
import {EditLessonComponent} from '@modules/activities/core/lessons/lessons-list/edit-lesson/edit-lesson.component';
import {NeoComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/add-lesson-button-morphable/button-types/neo/neo.component';
import {LessonsTabComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/lessons-tab.component';
import {SequencesTabComponent} from '@modules/activities/core/lessons/lessons-list/sequences-tab/sequences-tab.component';
import {UserLessonsComponent} from '@modules/activities/core/lessons/lessons-list/user-lessons/user-lessons.component';
import {LessonsRecapComponent} from '@modules/activities/core/lessons/lessons-recap/lessons-recap.component';
import {LessonsComponent} from '@modules/activities/core/lessons/lessons.component';
import {LessonActivitiesResolver} from '@modules/activities/core/lessons/resolvers/lesson-activities.resolver';
import {routes} from '@modules/activities/core/lessons/routes';
import {LessonAuthorizationService} from '@modules/activities/core/lessons/services/lesson-authorization.service';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {AsyncRules} from '@modules/activities/core/models/rules';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {UsageEntity, UsagesService} from '@modules/activities/core/services/usages.service';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {ThemesModule} from '@modules/activities/core/themes/themes.module';
import {AuthorizationService} from '@modules/authorization';
import {CommunicationCenterService} from '@modules/communication-center';
import {SearchFiltersModule} from 'fuse-core/components';
import {ButtonListDialogComponent} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {FuseLessonsModule} from 'fuse-core/components/lessons-selection/fuse-lessons.module';
import {UserReviewModule} from 'fuse-core/components/user-review/user-review.module';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {FuseNavigationItem} from 'fuse-core/types';
import {NgChartsModule} from 'ng2-charts';
import {DragulaModule} from 'ng2-dragula';
import {NgxFileDropModule} from 'ngx-file-drop';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {DataEntity} from 'octopus-connect';
import {Observable, Subject} from 'rxjs';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {FlagService} from 'shared/flag.service';
import {DynamicNavigationService} from '../../../../navigation/dynamic-navigation.service';
import {LessonPageComponent} from './lesson-page/lesson-page.component';
import {EditLessonDialogComponent} from './lessons-list/edit-lesson-dialog/edit-lesson-dialog.component';
import {FormPlayerComponent} from './lessons-list/forms-tab/form-player/form-player.component';
import {LauncherButtonComponent} from './lessons-list/launcher/launcher-button/launcher-button.component';
import {LauncherComponent} from './lessons-list/launcher/launcher/launcher.component';
import {LessonActivitiesComponent} from './lessons-list/lesson-activities/lesson-activities.component';
import {LessonMetadataDialogComponent} from './lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {LessonsListComponent} from './lessons-list/lessons-list.component';
import {AddLessonButtonMorphableComponent} from './lessons-list/lessons-tab/add-lesson-button-morphable/add-lesson-button-morphable.component';
import {OldSchoolComponent} from './lessons-list/lessons-tab/add-lesson-button-morphable/button-types/old-school/old-school.component';
import {DeleteLessonWarningComponent} from './lessons-list/lessons-tab/delete-lesson-warning/delete-lesson-warning.component';
import {EditLessonWarningComponent} from './lessons-list/lessons-tab/edit-lesson-warning/edit-lesson-warning.component';
import {OnlyLessonLessonsListComponent} from './lessons-list/only-lesson-lessons-list/only-lesson-lessons-list.component';
import {OnlyModelLessonsListComponent} from './lessons-list/only-model-lessons-list/only-model-lessons-list.component';
import {RecommendationComponent} from './lessons-list/recommendation/recommendation.component';
import {ListOfActivitiesComponent} from './list-of-activities/list-of-activities.component';
import {SummarySubLessonComponent} from './summary-sub-lesson/summary-sub-lesson.component';
import {LessonsConsultedComponent} from './widget/lessons-consulted/lessons-consulted.component';
import {LessonsCreatedComponent} from './widget/lessons-created/lessons-created.component';
import {LessonsFavoritesComponent} from './widget/lessons-favorites/lessons-favorites.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PlayersModule,
        DragulaModule.forRoot(),
        MatStepperModule,
        ActivitiesDirectiveModule,
        InfiniteScrollModule,
        FuseLessonsModule,
        MatDialogModule,
        MatCheckboxModule,
        CommonSharedModule,
        EditorsModule,
        ThemesModule,
        SearchFiltersModule,
        ProgressBarGenericModule,
        NgChartsModule,
        NgxFileDropModule,
        ActivitiesPipesModule,
        MatProgressBarModule,
        CarouselModule,
        UserReviewModule,
        MatBottomSheetModule
    ],
    declarations: [
        LessonsComponent,
        LessonsListComponent,
        SequencesTabComponent,
        LessonsTabComponent,
        FormPlayerComponent,
        LessonsRecapComponent,
        EditLessonDialogComponent,
        LessonActivitiesComponent,
        LessonMetadataDialogComponent,
        LessonsFavoritesComponent,
        EditLessonComponent,
        OnlyLessonLessonsListComponent,
        OnlyModelLessonsListComponent,
        LessonsConsultedComponent,
        LessonsCreatedComponent,
        CommunityListComponent,
        DeleteLessonWarningComponent,
        EditLessonWarningComponent,
        RecommendationComponent,
        LauncherComponent,
        LauncherButtonComponent,
        SummarySubLessonComponent,
        ListOfActivitiesComponent,
        LessonPageComponent,
        UserLessonsComponent,
        PeriodListComponent,
        LessonsInChaptersListComponent,
        AddLessonButtonMorphableComponent,
        OldSchoolComponent,
        NeoComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: []
})
export class LessonsModule {
    private static isMenuSetLessons = false;
    private onLogout$ = new Subject<void>();


    private dynamicNavigation = inject(DynamicNavigationService);
    private authorization = inject(AuthorizationService);
    private authorizationService = inject(LessonAuthorizationService);
    private communicationCenter = inject(CommunicationCenterService);
    private config = inject(LessonsConfigurationService);
    private usagesService = inject(UsagesService);

    private autonomyUsage: UsageEntity;
    private collectiveUsage: UsageEntity;

    get onlyModels(): FuseNavigationItem {
        return {
            'id': 'onlyModels',
            'icon': 'transition',
            'title': 'onlyModels',
            'translate': 'generic.models',
            'type': 'item',
            'url': '/lessons/list/models',
            'displayIcon': this.config.settings.menu['models']
        };
    }

    get onlyCollective(): FuseNavigationItem {
        return {
            'id': 'onlyCollective',
            'icon': 'transition',
            'title': 'onlyCollective',
            'translate': 'generic.collective',
            'type': 'item',
            'url': '/lessons/list/search',
            'queryParams': {'usage': this.collectiveUsage?.id},
            'displayIcon': this.config.settings.menu['models']
        };
    }

    get onlyIndividuals(): FuseNavigationItem {
        return {
            'id': 'onlyIndividuals',
            'icon': 'transition',
            'title': 'onlyIndividuals',
            'translate': 'generic.individual',
            'type': 'item',
            'url': '/lessons/list/search',
            'queryParams': {'usage': this.autonomyUsage?.id},
            'displayIcon': this.config.settings.menu['models']
        };
    }

    get onlyLessons(): FuseNavigationItem {
        return {
            'id': 'onlyLessons',
            'title': 'onlyLessons',
            'translate': 'generic.my_lessons',
            'type': 'item',
            'url': `/lessons/list/lessons`
        };
    }

    get onlyCommunity(): FuseNavigationItem {
        return {
            'id': 'onlyCommunity',
            'title': 'onlyCommunity',
            'translate': 'generic.community',
            'type': 'item',
            'url': '/lessons/list/community'
        };
    }

    get recommendation(): FuseNavigationItem {
        return {
            'id': 'recommendation',
            'title': 'recommendation',
            'translate': 'generic.recommendation',
            'type': 'item',
            'icon': 'recommendation',
            'url': '/lessons/list/recommendation'
        };
    }

    get userLessons(): FuseNavigationItem {
        return {
            'id': 'userLessons',
            'title': 'userLessons',
            'translate': 'activities.title_user_lessons',
            'type': 'item',
            'url': '/lessons/list/user-lessons'
        };
    }


    get allLessonsList(): FuseNavigationItem[] {
        return [
            this.onlyLessons,
            this.onlyModels,
            this.onlyCollective,
            this.onlyIndividuals,
            this.onlyCommunity,
            this.recommendation,
            this.userLessons
        ];
    }

    constructor() {
        this.authorizationService.activeRulesOnStartup();

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsConsultedComponent', LessonsConsultedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsCreatedComponent', LessonsCreatedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsFavoritesComponent', LessonsFavoritesComponent);

        this.communicationCenter
            .getRoom('recommendation')
            .next('recommendationListComponent', RecommendationComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('buttonListComponent', ButtonListDialogComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsTabComponent', LessonsTabComponent);

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'FEEDBACK_FORM',
                icon: 'activity',
                text: 'assignment.notification_feedback_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });


        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'LEARNER_ANSWER_FORM_SENDED',
                icon: 'activity',
                text: 'assignment.notification_learner_answer_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });
    }

    static forRoot(): ModuleWithProviders<LessonsModule> {

        return {
            ngModule: LessonsModule,
            providers: [
                LessonsService,
                LessonActivitiesResolver,
                // ResolverForFormPlayer,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
        LessonsModule.isMenuSetLessons = false;
        this.onLogout$.next();
        this.onLogout$.complete();
        this.clearDynamicMenu();
    }

    private clearDynamicMenu(): void {
        LessonsModule.isMenuSetLessons = false;
        this.dynamicNavigation.clearMenuItem('level0', 'Lessons');
        this.dynamicNavigation.clearMenuItem('level0', 'lessonsDeployable');

        this.allLessonsList.forEach((navigationItem) => {
            this.dynamicNavigation.clearMenuItem('level0', navigationItem.id);
        });
    }

    private getDeployableSubLessonItems(): FuseNavigationItem[] {
        return this.config.getDeployableChildrenLessonItems(this.allLessonsList);
    }

    private postAuthentication(): void {
        this.onLogout$ = new Subject<void>();

        if (!LessonsModule.isMenuSetLessons) {
            this.usagesService.getUsages()
                .pipe(
                    tap((usagesCollection) => {
                        this.autonomyUsage = usagesCollection.entities.find((usageEntity) =>
                            usageEntity.get('label') === this.usagesService.autonomyUsageLabel);
                        this.collectiveUsage = usagesCollection.entities.find((usageEntity) =>
                            usageEntity.get('label') === this.usagesService.collectiveUsageLabel);
                        ;
                    }),
                    mergeMap(() => this.authorization.currentUserCan<Observable<boolean>>(AsyncRules.AccessLessonList)),
                    takeUntil(this.onLogout$),
                    tap((isAuthorized) => {
                        if (isAuthorized === false) {
                            this.clearDynamicMenu();
                        } else {
                            this.dynamicNavigation.refreshModuleMenu('level0', {
                                'id': 'Lessons',
                                'title': 'Lessons',
                                'translate': 'activities.title_lessons',
                                'type': 'item',
                                'icon': 'lessons',
                                'url': '/lessons/list'
                            });

                            this.dynamicNavigation.refreshModuleMenu('level0', [
                                {
                                    'id': 'lessonsDeployable',
                                    'title': 'lessonsDeployable',
                                    'translate': 'activities.title_lessons',
                                    'type': 'collapse',
                                    'icon': 'lessons',
                                    'children': this.getDeployableSubLessonItems()
                                },
                                ...this.allLessonsList
                            ]);

                            this.usagesService.getUsageByLabel(this.usagesService.collectiveUsageLabel).subscribe();

                            LessonsModule.isMenuSetLessons = true;
                        }
                    })
                ).subscribe();
        }
    }
}