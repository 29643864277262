import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {Router} from '@angular/router';
import {brand} from '../../../../../settings';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {combineLatest, ReplaySubject, Subject} from 'rxjs';

import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {DataEntity} from 'octopus-connect';
import * as _ from 'lodash-es';
import {CustomTooltipModalContentSettings, CustomTooltipSetting} from '@fuse/components/graph/graph-details-modal/custom-tooltip.setting';
import {Observable} from 'rxjs/internal/Observable';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {merge} from 'lodash-es';
import {ChartEvent} from 'chart.js';

@Component({
    selector: 'app-lessons-recap',
    templateUrl: './lessons-recap.component.html'
})
export class LessonsRecapComponent implements OnInit, OnDestroy {
    public brand: string = brand;
    public lesson: LessonGranuleEntity;
    public title: string;
    public description: string;
    public label: string;
    public answerStatus: unknown[] = [];
    public hideReview = false;
    public dynamicRewardMilestone: number = null;
    public graphReady = false;
    public graphConfigObs: Subject<void> = new Subject<void>();

    private unsubscribeInTakeUntil = new Subject<void>();
    private assignmentId: string;

    private userSaves: DataEntity[] = [];
    private activities: DataEntity[] = [];
    public customSettingsWrapper: CustomTooltipSetting = <CustomTooltipSetting> {};
    private modalContent: CustomTooltipModalContentSettings[][] = [];
    public infoSettings: ReplaySubject<CustomTooltipSetting> = new ReplaySubject<CustomTooltipSetting>();
    public radarChartData: unknown;
    public radarChartOptions: unknown;
    public radarChartLabels: unknown;
    public radarChartType: unknown;
    public chartColors = [];
    public showTooltip = false;
    private selectedPoint: {
        index: number;
        dataIndex: number;
    };
    @ViewChild('chart') chart: ElementRef;
    private activitiesArray: (DataEntity & {badge: boolean | undefined, label: string | undefined})[];


    public activitiesService = inject(ActivitiesService)
    private router = inject(Router)
    private userActionsService = inject(UserActionsService)
    private lessonsService = inject(LessonsService)
    private communicationCenter = inject(CommunicationCenterService)
    private lessonsConfigurationService = inject(LessonsConfigurationService)

    private initializeGraph(): void {
        const userSavesSorted = this.userSaves.sort((a, b) => {
            return +b.get('changed') - +a.get('changed');
        });
        const data = this.activities.map((activity) => {
            const userSaveOfActivity = userSavesSorted.find((userSave: DataEntity) => +userSave.get('granule')[0] === +activity.id);
            const content = JSON.parse(userSaveOfActivity.get('userActivity').entitySave.content);
            return content.map((item) => {
                const answerSelected = item.answers.find(answer => !!answer.select);
                return {
                    lesson: activity.get('metadatas').title,
                    label: item.question,
                    answer: answerSelected && answerSelected.answer,
                    value: item.answers.findIndex((answer) => !!answer.select) + 1
                };
            });
        });

        // TODO: options du graph à définir dans les settings
        this.radarChartOptions = {
            responsive: true,
            legend: {
                display: true,
                position: 'top',
                labels: {
                    fontSize: 12,
                    fontColor: '#15213F',
                    boxWidth: 40,
                    usePointStyle: false,
                }
            },
            scale: {
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 5
                }
            },
            tooltips: {
                enabled: false,
                // Tooltip Element
                custom: (tooltipModel) => {
                    // Tooltip Element
                    const clonedModel = _.cloneDeep(tooltipModel);

                    let arrowDirection = 'arrow-on-top'; // arrow above by default
                    const tooltipWidth = 280;
                    const tooltipHeight = 300; // Arbitrary height of the tooltip
                    const chartWidth = this.chart.nativeElement.offsetWidth;
                    const chartHeight = this.chart.nativeElement.offsetHeight;
                    const chartTopLimit = chartHeight - tooltipHeight;
                    const chartLeftLimit = tooltipWidth / 2;
                    const chartRightLimit = chartWidth - (tooltipWidth / 2);

                    if ( clonedModel.caretY > chartTopLimit ) { // point is under the line
                        arrowDirection = 'arrow-on-bottom'; // arrow under
                    }
                    if ( clonedModel.caretX > chartRightLimit ) {
                        arrowDirection += ' arrow-on-right'; // arrow on right
                    }
                    if ( clonedModel.caretX < chartLeftLimit ) {
                        arrowDirection += ' arrow-on-left'; // arrow on left of tooltip
                    }

                    this.mergeCustomWrapperData({
                        tooltipY: clonedModel.caretY,
                        tooltipX: clonedModel.caretX,
                        type: arrowDirection,
                    });
                }
            },
            layout: {
                padding: {
                    left: 50,
                    right: 50,
                    top: 25,
                    bottom: 50
                }
            },
        };
        this.radarChartLabels = data[0].map((item) => item.label);

        this.radarChartData = data.map((answerData, index) => {
            return {
                data: answerData.map((answer) => answer.value),
                label: [this.activities[index].get('metadatas').title],
                type: 'radar',
            };
        });
        this.radarChartType = 'radar';
        this.chartColors = [
            {
                backgroundColor: 'transparent',
                borderColor: '#44ADA8',
                pointBackgroundColor: '#44ADA8',
            },
            {
                backgroundColor: 'transparent',
                borderColor: '#5FD855',
                pointBackgroundColor: '#5FD855',
            },
            {
                backgroundColor: 'transparent',
                borderColor: '#ADD800',
                pointBackgroundColor: '#ADD800',
            },
            {
                backgroundColor: 'transparent',
                borderColor: '#418F82',
                pointBackgroundColor: '#418F82',
            }
        ];
        this.modalContent = data.map((item) => {
            return item.map((result) =>  {
                return {
                    header: `<label>${result.lesson}</label>`,
                    content: `<label>${result.answer} : ${result.value}</label>`
                };
            });
        });
        this.graphReady = true;
    }

    onChartClick($event: { event?: ChartEvent; active?: unknown[] }): void {
        if ($event.active.length > 0) {
            const chart = $event.active[0]['_chart'];
            const activePoints = chart.getElementAtEvent($event.event);
            if ( activePoints.length > 0) {
                // get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]._index;
                const clickedElementDataIndex = activePoints[0]._datasetIndex;

                if (this.selectedPoint && this.selectedPoint.index === clickedElementIndex && this.selectedPoint['dataIndex'] === clickedElementDataIndex) {
                    this.selectedPoint = null;
                    this.showTooltip = false;
                    return;
                } else {
                    this.selectedPoint = {
                        index: clickedElementIndex,
                        dataIndex: clickedElementDataIndex,
                    };

                    this.mergeCustomWrapperData({
                        modalContent: this.modalContent[clickedElementDataIndex][clickedElementIndex],
                    });
                    this.infoSettings.next(this.settingsWrapper);
                    this.showTooltip = true;
                }
            }
        }
    }

    private mergeCustomWrapperData(data: Partial<CustomTooltipSetting>): void{
        this.customSettingsWrapper = {..._.cloneDeep(this.customSettingsWrapper), ..._.cloneDeep(data)};
    }

    public get settingsWrapper(): CustomTooltipSetting {
        return _.cloneDeep(this.customSettingsWrapper);
    }

    ngOnInit(): void {
        this.activitiesArray = this.activitiesService.activitiesArray.map(a => merge({}, a, {badge: undefined, label: undefined}));
        this.hideReview = this.lessonsConfigurationService.settings.hideReview;

        if (!this.lessonsService.isLessonLaunched()) {
            console.error('No mode to run lesson in');
        }

        if (this.lessonsService.isAssignmentWithMetacognition()) {
            this.lessonsService.loadUserSaves(this.lessonsService.currentAssignment.id.toString()).pipe(
                tap((userSaves: DataEntity[]) => this.userSaves = userSaves),
                mergeMap((userSaves: DataEntity[]) => {
                    const userSavesSortedByActivityId = userSaves.map((useSave: DataEntity) => useSave.get('granule')[0]);
                    const combine: Observable<DataEntity>[] = _.uniq(userSavesSortedByActivityId)
                        .map((activityId: string) => this.activitiesService.loadActivitiesFromId(activityId));
                    return combineLatest(combine).pipe(
                        tap((activities: DataEntity[]) => this.activities = activities.filter((activity: DataEntity) => activity.get('metadatas').typology.label === 'GCM')),
                        tap(() => this.initializeGraph())
                    );
                }),
            ).subscribe();
        }

        if (this.lessonsService.currentAssignment) {
            this.assignmentId = this.lessonsService.currentAssignment.id.toString();
        }

        if (this.activitiesService.currentLesson) {
            this.lesson = this.activitiesService.currentLesson;
        } else {
            this.activitiesService.pushLessonFromAssignment.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(data => {
                    this.lesson = data;
                    this.activitiesService.currentLesson = data;
                    this.initValuesInView();
                });
        }

        this.communicationCenter
            .getRoom('assignation')
            .next('event', {id: this.assignmentId, event: 'end'});

        this.initValuesInView();
        this.processLabels();

        this.setAnswerStatus();

        this.setDynamicReward();
    }

    initValuesInView(): void {
        if (this.lesson) {
            this.title = this.lesson.attributes.metadatas.title;
            this.description = this.lesson.attributes.metadatas.description;

            if (Array.isArray(this.lesson.attributes.metadatas.lessonType) && this.lesson.attributes.metadatas.lessonType.length) {
                this.label = this.lesson.attributes.metadatas.lessonType[0].label || null;
            }
        }
    }

    private processLabels(): void {
        let activityCount = 0;

        // activity devrait etre un dataentity, mais label & badge n'existent pas sur DataEntity. Donc on force a any
        this.activitiesArray.forEach((activity) => {
            switch (activity.type) {
                case 'audio':
                case 'document':
                case 'image':
                case 'media':
                case 'text':
                case 'url':
                case 'video':
                case 'videoUrl':
                    activity.label = 'M';
                    activity.badge = false;
                    break;
                case 'divider':
                    activity.label = 'i';
                    activity.badge = false;
                    break;
                default:
                    activityCount += 1;
                    activity.label = activityCount.toString();
                    activity.badge = true;
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }


    get isLessonValidated(): boolean {
        return this.lessonsService.isLessonValidated();
    }

    get isLessonTest(): boolean {
        return this.lessonsService.isLessonTest();
    }

    get isLessonTraining(): boolean {
        return this.lessonsService.isLessonTraining();
    }

    get isLessonEvaluation(): boolean {
        return this.lessonsService.isLessonEvaluation();
    }

    get isLessonCorrected(): boolean {
        return this.lessonsService.isLessonCorrected();
    }

    public get isLessonEvaluationPending(): boolean {
        return this.isLessonEvaluation && !this.isLessonValidated && !this.isLessonCorrected;
    }

    public get isLessonEvaluationValidated(): boolean {
        return this.isLessonEvaluation && this.isLessonValidated && !this.isLessonCorrected;
    }

    public get isLessonEvaluationCorrected(): boolean {
        return this.isLessonEvaluation && this.isLessonCorrected;
    }

    public get displayGrade(): boolean {
        if (this.lessonsService.isAtLeastTrainer()) {
            return this.isLessonEvaluation;
        }

        return this.isLessonEvaluationCorrected;
    }

    public get displayOptionalLessonRecapButton(): boolean {
        return this.lessonsConfigurationService.settings.displayOptionalLessonRecapButton;
    }

    public get isUserTrainer(): boolean {
        return this.lessonsService.isAtLeastTrainer();
    }

    public get loading(): boolean {
        return this.lessonsService.savingAssignment;
    }

    public isStatusCorrect(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 1;
    }

    public isStatusMissing(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 2 || this.answerStatus[index] === 4;
    }

    public isStatusWrong(index: number): boolean {
        return this.isActivity(index) && this.answerStatus[index] === 3;
    }

    public isStatusAnswered(index: number): boolean {
        return !this.isActivity(index) || this.isStatusCorrect(index) || this.isStatusWrong(index);
    }

    public isActivity(index: number): boolean {
        return this.activitiesArray[index].type === 'activity';
    }

    private setAnswerStatus(): void {
        if (this.activitiesArray) {
            this.activitiesArray.forEach((item, index) => {
                const value = this.activitiesService.activityAnswerResult[index];
                this.answerStatus[index] = (value !== undefined && value) ? this.activitiesService.activityAnswerResult[index] : 2;
            });
        }
    }

    public restartPlay(event, clearData): void {
        let obs: ReplaySubject<boolean> = new ReplaySubject();
        if (clearData) {
            obs = this.activitiesService.resetActivityAnswerData();
        } else {
            obs.next(true);
        }

        obs.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((value: boolean) => {
            if (value) {
                this.communicationCenter
                    .getRoom('assignation')
                    .next('event', {id: this.assignmentId, event: 'restart'});

                this.activitiesService.playScreenStatus = 0;
                this.activitiesService.loadFirstActivity(true);
                this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
                this.lessonsService.lessonButtonClicked.next(true);
            }
        });
    }

    public goBack($event: MouseEvent): void {
        $event.stopPropagation();
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(this.activitiesArray.length - 1, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public gotoListPage($event: MouseEvent): void {
        $event.stopPropagation();
        this.activitiesService.resetArrayindex();
        this.router.navigate([this.router.routerState.snapshot.url.split('/')[1], 'list']);
    }

    public gotoCurrentPage($event: MouseEvent, activityIndex: number): void {
        $event.stopPropagation();
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(activityIndex, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public completeMyHomeWork(): void {
        this.activitiesService.playScreenStatus = 0;
        const activityNotCompleteIndex = this.answerStatus.findIndex(answer => answer === 2);
        const activitytoGo = activityNotCompleteIndex > -1 ? activityNotCompleteIndex : 0;
        this.activitiesService.loadActivityByStep(activitytoGo, true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public validateAnswer($event: MouseEvent): void {
        $event.stopPropagation();
        if (this.lessonsService.isAssignmentWithMetacognition() &&
            this.userSaves.find((userSave) => userSave.get('created') === userSave.get('changed'))) {
            console.error('Lesson in metacognition not finished');
        } else {
            this.communicationCenter
                .getRoom('assignment')
                .next('changeState', {id: this.assignmentId, state: 'closed'});
        }
    }

    public onMyHomeWork($event: MouseEvent): void {
        $event.stopPropagation();
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadFirstActivity(true);
        this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
        this.lessonsService.lessonButtonClicked.next(true);
    }

    public stepIcon(index): string {
        let iconName = 'step_warning';
        if ((this.isStatusAnswered(index) && this.isLessonEvaluation && !this.isLessonEvaluationCorrected) || !this.isActivity(index)) {
            iconName = 'step_validated';
        } else if (this.isLessonEvaluationCorrected || !this.isLessonEvaluation) {
            switch (this.answerStatus[index]) {
                case 1:
                    iconName = 'step_success';
                    break;
                case 2:
                    iconName = 'step_warning';
                    break;
                case 3:
                    iconName = 'step_error';
                    break;
                default:
                    iconName = 'step_warning';
                    break;
            }
        }
        return iconName;
    }

    getGrade(): string {
        if (this.lessonsService.currentAssignment) {
            const assignation = this.lessonsService.currentAssignment;
            if (assignation) {
                if (this.lessonsService.isLessonEvaluation() &&
                    (this.lessonsService.isAtLeastTrainer() || this.lessonsService.isLessonCorrected())
                ) {
                    const grade = +assignation.get('grade');

                    const newGrade = Math.min(20, Math.round(grade * 10) / 10);

                    return newGrade.toString() + '/20';
                }
            }
            return '';
        }
    }

    /**
     * Return true if the recap must display the little colored flag
     */
    public showFlag(): boolean {
        // TODO may be use a settings but no need today, we put the flag by default, expect only if there are a dynamic reward
        return this.showDynamicReward() === false;
    }

    /**
     * Return true if the recap must display a dynamic reward (image + label defined by the assignment grade)
     */
    public showDynamicReward(): boolean {
        return this.lessonsService.dynamicRewardIsDisplayed();
    }

    /**
     * Initialize the dynamic reward values if needed
     * @private
     */
    private setDynamicReward(): void {
        if (this.showDynamicReward() === false) {
            return;
        }

        const grade = this.lessonsService.currentAssignment.get('grade');
        const milestones = this.lessonsService.getDynamicRewardMilestones();
        this.dynamicRewardMilestone = null;
        for (const milestone of milestones.sort()) {
            if (grade < milestone) {
                break;
            }
            this.dynamicRewardMilestone = milestone;
        }

        if (this.dynamicRewardMilestone === null) {
            throw Error('grade are under the smallest value of the dynamic reward scale');
        }
    }
}
