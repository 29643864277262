import {provideHttpClient} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialogRef} from '@angular/material/dialog';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, Router, withDebugTracing} from '@angular/router';
import {AccountManagementModule} from '@modules/account-management';
import {AssignationModule} from '@modules/assignation';
import {AuthenticationModule} from '@modules/authentication';
import {BdModule} from '@modules/bdtool';
import {BrowserTestModule} from '@modules/browser-test';
import {CommunicationCenterModule, CommunicationCenterService} from '@modules/communication-center';
import {ConfigurationModule} from '@modules/configuration';
import {CorpusModule} from '@modules/corpus';
import {DashboardModule} from '@modules/dashboard';
import {DataVisualizationModule} from '@modules/data-visualization';
import {FaqModule} from '@modules/faq';
import {GamecodeModule} from '@modules/gamecode';
import {GraphAssignationModule} from '@modules/graph-assignation';
import {GroupsManagementModule} from '@modules/groups-management';
import {LicensingModule} from '@modules/licensing';
import {MindmapModule} from '@modules/mindmap';
import {NotepadModule} from '@modules/notepad';
import {NotificationModule} from '@modules/notification';
import {TimelineModule} from '@modules/timeline';
import {CompassModule} from 'fuse-core/compass/compass.module';
import {FuseModule} from 'fuse-core/fuse.module';
import {DatacardService} from 'shared/datacard.service';
import {EventService} from 'shared/event.service';
import {TranslateModule} from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import {Angulartics2Module} from 'angulartics2';
import {NgHcaptchaModule} from 'ng-hcaptcha';
import {RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
import {OctopusConnectModule} from 'octopus-connect';
import {IsAllowedBySettings, IsDefaultOrDefaultLoginRoute, IsUserAnonymous, IsUserAuthorisedByRoleGuard, IsUserLogged} from '../../../../src/app/guards';
import {IsUserNotLogged} from '../../../../src/app/guards/is-not-user-logged.class';
import {HeaderModule} from '../../../../src/app/header/header.module';
import {NavigationModule} from '../../../../src/app/navigation/navigation.module';
import {layoutConfig} from '../../../../src/app/settings/isc_lycee';
import {SvgIconList} from '../../../../src/app/themes/isc_lycee/svgIconList';
import {TralaTranslationLoaderService} from '../../../../src/app/trala-translation-loader.service';

import {routes} from './app.routes';
import {ActivitiesModule, LessonsModule, ThemesModule} from '@modules/activities';
import {IscLyceeOctopusConfig} from './isc-lycee.octopus-config';
import { ServiceInstantiator } from './service-instantiator.class';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes,
            // withDebugTracing(),
        ),
        provideAnimations(),
        provideHttpClient(),
        importProvidersFrom(
            AccountManagementModule.forRoot(),
            ActivitiesModule.forRoot(),
            Angulartics2Module.forRoot(),
            AssignationModule.forRoot(),
            AuthenticationModule.forRoot(),
            BdModule.forRoot(),
            BrowserTestModule.forRoot({
                desktopSize: {
                    minWidth: 1024,
                    minHeight: 768,
                },
                browsersBlackList: [
                    {
                        name: 'ie',
                        minVersion: '11',
                    },
                    {
                        name: 'chrome',
                        minVersion: '72',
                    },
                    {
                        name: 'firefox',
                        minVersion: '65',
                    },
                    {
                        name: 'edge',
                        minVersion: '16',
                    },
                    {
                        name: 'safari',
                        minVersion: '10',
                    },
                ],
            }),
            CommunicationCenterModule.forRoot(),
            CompassModule.forRoot(),
            ConfigurationModule.forRoot(),
            CorpusModule.forRoot(),
            DashboardModule.forRoot(),
            DataVisualizationModule.forRoot(),
            FaqModule.forRoot(),
            FuseModule.forRoot(layoutConfig),
            GamecodeModule.forRoot(),
            GraphAssignationModule.forRoot(),
            GroupsManagementModule.forRoot(),
            HeaderModule.forRoot(),
            LessonsModule.forRoot(),
            LicensingModule.forRoot(),
            MindmapModule.forRoot(),
            NavigationModule.forRoot(),
            NgHcaptchaModule.forRoot({
                siteKey: '8858d7eb-82d8-425e-9d74-e114ceca737b',
                // languageCode: 'de' // optional, will default to browser language
            }),
            NotepadModule.forRoot(),
            ThemesModule.forRoot(),
            TimelineModule.forRoot(),
            NotificationModule.forRoot(),
            OctopusConnectModule.forRoot(IscLyceeOctopusConfig),
            TranslateModule.forRoot(),
        ),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        IsDefaultOrDefaultLoginRoute,
        IsUserLogged,
        IsUserNotLogged,
        IsUserAuthorisedByRoleGuard,
        IsUserAnonymous,
        IsAllowedBySettings,
        TralaTranslationLoaderService,
        SvgIconList,
        CommunicationCenterService,
        DatacardService,
        EventService,
        ServiceInstantiator,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6Le-rdsUAAAAADbacQJe_sNQg1jxPsCsdqNcBvb1',
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: MatBottomSheetRef,
            useValue: {}
        }
    ]
};
