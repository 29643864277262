import {Component, ElementRef, Inject, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {isArray} from 'lodash-es';

export interface MatDialogData {
    controlsList?: string;
    oncontextmenu?: string;
    controls?: boolean;
    muted?: string;
    video?: {
        uri: string;
        filemime: string;
        subtitles: {
            [key: string]: string;
        }
    };
    title?: string;
}
@Component({
  selector: 'app-shared-video',
  templateUrl: './shared-video.component.html',
  styleUrls: ['./shared-video.component.scss']
})
export class SharedVideoComponent implements OnInit, OnChanges {
    @Input() controlsList?: string = undefined;
    @Input() oncontextmenu?: string = undefined;
    @Input() controls = false;
    @Input() muted: string = undefined;
    @Input() public video?: {
        uri: string;
        filemime?: string;
        subtitles?: {
            [key: string]: string;
        }
    };
    private source!: string;
    private filemime: string;
    private subtitles: { label: string; lang: string; url: string | SafeResourceUrl }[] = [];
    private initialMutedState: boolean;

    @ViewChild('myVideo') private videoElement: ElementRef;

  constructor(public sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) public data?: MatDialogData) {}

  ngOnInit(): void {
      if (this.data?.controlsList) {
          this.controlsList = this.data.controlsList;
      }
      if (this.data?.controls) {
          this.controls = this.data.controls;
      }
      if (this.data?.muted) {
          this.muted = this.data.muted;
      }
      if (this.data?.video) {
          this.video = this.data.video;
          this.source = this.video.uri;
          this.filemime = this.video.filemime;
      }
      if (this.data?.oncontextmenu) {
          this.oncontextmenu = this.data.oncontextmenu;
      }
  }

  ngOnChanges(): void {
        this.source = this.video.uri;
        this.filemime = this.video.filemime;
        if (this.video?.subtitles) {
            this.subtitles = Object.keys(this.video.subtitles).map(lang => {
                return {
                    lang,
                    url: this.sanitizer.bypassSecurityTrustResourceUrl(this.video.subtitles[lang]),
                    label: 'video.subtitles_label_' + lang,
                };
            });
        }
  }

    ngAfterViewInit(): void {
        const video = this.videoElement.nativeElement as HTMLVideoElement;
        this.initialMutedState = video.muted;
        video.addEventListener("volumechange", () => {
            // block mute button effect if activities is set muted
            if( video.muted === false && this.initialMutedState === true ) {
                video.muted = this.initialMutedState;
            }
        });
    }

    public onContextMenu(event: MouseEvent): boolean {
        event.preventDefault();
        return false;
    }

}