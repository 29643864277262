import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
 import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {CorpusService} from '@modules/corpus/core/corpus.service';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {HttpClient} from '@angular/common/http';
import {defaultApiURL} from '../../../../../settings';
import { MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'app-resource-upload-modal-step1',
    templateUrl: './resource-upload-modal-step1.component.html',
    styleUrls: ['./resource-upload-modal-step1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None

})
export class ResourceUploadModalStep1Component implements OnInit {

    public files: NgxFileDropEntry[] = [];
    @Output('fileUploaded') fileUploaded: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output('urlValidated') urlValidated: EventEmitter<string> = new EventEmitter<string>();
    @Output('canceled') canceled: EventEmitter<void> = new EventEmitter<void>();
    @Output('error') error: EventEmitter<void> = new EventEmitter<void>();
    sentUrl = '';

    uploading = false;
    public allowedExtensions: string;
    public fileFormatAllowedInExplorer: string = '';
    private _urlFileUpload: string = defaultApiURL + 'api/file-upload';
    /**
     * content allowed to be upload and urlBegining if limited * => all url
     */
    public uploadAllowed: {
        localFile: boolean,
        url: boolean,
        urlAllowed: string
    } = {localFile: true, url: true, urlAllowed: '*'};

    constructor(
        public dialogRef: MatDialogRef<ResourceUploadModalStep1Component>,
        private corpusService: CorpusService,
        private http: HttpClient,
        private accountManagementProvider: AccountManagementProviderService,
        private changeDetector: ChangeDetectorRef,
        private router: Router,
    ) {
        this.allowedExtensions = this.corpusService.settings.allowedExtensions;
    }

    ngOnInit(): void {
        this.setAllowedUploadContent();
        this.setUploadRulesPdfRoute();
        this.setFileFormatAllowed();
    }

    /**
     * set the upload content to show if pdf route exist because in infohunter two corpus exist
     * one with only pdf and another whith only youtube url
     */
    private setUploadRulesPdfRoute(): void {
        if (this.corpusService.isDocumentTypePdf()) {
            this.uploadAllowed = {localFile: true, url: false, urlAllowed: ''};
        }
    }

    /**
     * for use with exisiting whitelist now she is always with all data
     */
    private setFileFormatAllowed(): void {
        this.fileFormatAllowedInExplorer = this.corpusService.filesWhiteList.join(',');
    }

    /**
     * content allowed to be upload and the urlBegining if not limited '*' => all url (ex: InfoHunter = only youtube url)
     */
    private setAllowedUploadContent(): void {
        this.uploadAllowed.localFile = this.corpusService.settings.uploadAllowed.localFile;
        this.uploadAllowed.url = this.corpusService.settings.uploadAllowed.url;
        this.uploadAllowed.urlAllowed = this.corpusService.settings.uploadAllowed.urlAllowed;
    }

    /**
     * check if url to include is allowed ex: infoHunter only https://youtube.com/... are allowed '*' = all url
     */
    private isAllowedUrl(): boolean {
        if (this.uploadAllowed.urlAllowed === '*') {
            return true;
        } else {
            return this.sentUrl.includes(this.uploadAllowed.urlAllowed);
        }
    }

    /**
     * disable or not validate button following rules
     */
    public isValidateDisable(): boolean {
        return (this.uploading || this.sentUrl === '' || !this.isAllowedUrl());
    }

    public dropped(files: NgxFileDropEntry[]): void {
        this.files = files;
        for (const droppedFile of files) {

            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.uploadFile(file);
                });
            } else {
                this.error.emit();
            }
        }
    }

    public fileOver(event): void {
    }

    public fileLeave(event): void {
    }

    validateUrl(): void {
        this.urlValidated.emit(this.sentUrl);
    }

    cancel(): void {
        this.canceled.emit();
    }

    /** @deprecated use MediaService.createMedia instead */
    uploadFile(file: File): void {
        if (this.corpusService.filesWhiteList.indexOf(file.type) !== -1 && this.fileFormatAllowed(file.name)) {
            const formData = new FormData();
            formData.append('file', file);

            this.uploading = true;
            this.changeDetector.detectChanges();

            this.http
                .post<any>(this._urlFileUpload, formData, {headers: {'access-token': this.accountManagementProvider.userAccessToken}})
                .subscribe(res => {
                    this.uploading = false;
                    this.fileUploaded.emit([+res['data'][0][0]['id'], res['data'][0][0]['filemime']]);
                });
        }
    }

    onFileChanged(evt: Event): void {
        // control to block user if change type manualy from pdf to other
        if (this.fileFormatAllowed(evt.target['files'][0].name)) {
            this.uploadFile(evt.target['files'][0]);
        }
    }

    /**
     * @param filePath : path of file to upload
     * to block upload exemple only .pdf
     */
    private fileFormatAllowed(filePath: string): boolean {
        const extension = filePath.substr(filePath.lastIndexOf('.') + 1);
        return this.allowedExtensions.includes(extension.toLowerCase());
    }
}