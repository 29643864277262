export const mainMenu = {
    displayMenu: {
        learner: {
            level0: [
                'home',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                    icon: 'lessons',
                },
                {
                    name: 'corpus',
                    translate: {
                        parent: {
                            default: 'corpus.title_generic_learner_label',
                        },
                    },
                },
                {
                    name: 'tools',
                    translate: {
                        parent: {
                            learner: 'navigation.tools_learner_label',
                            default: 'generic.tools'
                        }
                    },
                    children: ['notepadList', 'mindmapList'],
                },
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
            ],
        },
        parent: {
            level0: [
                'home',
                {
                    name: 'corpus-global',
                    translate: {
                        parent: {
                            default: 'corpus.title_generic_parent_label',
                        },
                    },
                },
                {
                    name: 'root_formations',
                    icon: 'activity',
                },
                {
                    name: 'Lessons',
                    translate: {
                        parent: {
                            parent: 'navigation.title_lessons_parent_label',
                            default: 'activities.title_lessons',
                        },
                    },
                },
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback'
            ],
        },
        trainer: {
            level0: [
                'boussole',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                {
                    name: 'root_decouvrir-ressources',
                    icon: 'for_further',
                },

                'Lessons',
                {
                    name: 'tools',
                    children: [
                        'notepadList',
                        'mindmapList'
                    ],
                },
                {
                    name: 'corpus',
                    translate: {
                        parent: {
                            default: 'corpus.title_generic_trainer_label',
                        },
                    },
                },
                {
                    name: 'root_formations',
                    icon: 'activity',
                },
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback',
            ],
        },
        default: {
            level0: [
                'home',
                {
                    name: 'parameter',
                    translate: {
                        parent: {
                            learner: 'generic.my_classes_learner',
                            default: 'navigation.groups-management',
                        },
                        childs: {
                            'param-child-groups': {
                                learner: 'generic.my_class',
                                default: 'groups-management.classes',
                            },
                            'param-child-workgroups': {
                                learner: 'generic.my_workgroups',
                                default: 'groups-management.workgroups',
                            },
                        },
                    },
                },
                'Lessons',
                {
                    name: 'followed-tab',
                    translate: {
                        parent: {
                            learner: 'assignment.title_my_activity',
                            default: 'assignment.title',
                        },
                    },
                },
                // followed with tab include followed list and diary log use only in mathia for moment
                {
                    name: 'informations-legales',
                    class: ['menu-break'],
                },
                'feedback',
            ],
        },
    },
};