export const achievements = {
    isOnboardingEnabled: false,
    navLinks: {
        default: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            },
            /* not on new version of python
            {
                 path: 'leaderboard',
                 keyTranslate: 'achievement.leaderboard',
             }*/
        ],
        gar: [{
            path: 'my-profile',
            keyTranslate: 'achievement.my_profile',
        },
            {
                path: 'my-city',
                keyTranslate: 'achievement.my_city',
            }
        ]
    },
    isOpenBadgeActive: {default: true, gar: true},
    alwaysUseObs: true,
    listenRootFilterSelectedElem: true,
};
