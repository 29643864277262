import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {FuseSharedModule} from '@fuse/shared.module';

import {GamificationService} from './gamification.service';
import {GamificationComponent} from './gamification/gamification.component';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {SharedModule} from 'shared/shared.module';
import {GalleryThumbnailComponent} from './gallery-thumbnail/gallery-thumbnail.component';
import {AccessoryThumbnailComponent} from './accessory-thumbnail/accessory-thumbnail.component';
import {GalleryComponent} from './gallery/gallery.component';
import {MyAnimalsComponent} from './my-animals/my-animals.component';
import {AnimalCanvasComponent} from './animal-canvas/animal-canvas.component';
import {AnimalControlsComponent} from './animal-controls/animal-controls.component';
import {AccessoriesPopupComponent} from './accessories-popup/accessories-popup.component';
import {BuyPopupComponent} from './buy-popup/buy-popup.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AutofocusDirective} from './autofocus.directive';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {RewardsDialogModule} from '@modules/gamification/core/rewards-dialog/rewards-dialog.module';
import {AvatarPagePopupComponent} from './onboarding/avatar-page-popup/avatar-page-popup.component';
import {MatTooltipModule} from '@angular/material/tooltip';

const routes: Routes = [
    {
        path: 'avatars',
        canActivate: [IsUserLogged],
        component: GamificationComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        MatProgressSpinnerModule,
        RewardsDialogModule,
        RouterModule.forChild(routes)
    ],
    providers: [
    ],
    declarations: [
        GamificationComponent,
        GalleryThumbnailComponent,
        AccessoryThumbnailComponent,
        GalleryComponent,
        MyAnimalsComponent,
        AnimalCanvasComponent,
        AnimalControlsComponent,
        AccessoriesPopupComponent,
        BuyPopupComponent,
        AutofocusDirective,
        AvatarPagePopupComponent,
    ]
})
export class GamificationModule {
    private static isMenuSet = false;

    constructor(
        private dynamicNavigation: DynamicNavigationService,
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    static forRoot(): ModuleWithProviders<GamificationModule> {

        return {
            ngModule: GamificationModule,
            providers: [
                GamificationService
            ]
        };
    }

    private postLogout(): void {
        GamificationModule.isMenuSet = false;
        this.dynamicNavigation.clearMenuItem('level0', 'gamification');
    }

    private postAuthentication(): void {
        if (!GamificationModule.isMenuSet) {
            this.dynamicNavigation.refreshModuleMenu('level0', {
                'id': 'gamification',
                'title': 'Ma collection',
                'translate': 'navigation.gamification',
                'type': 'item',
                'icon': 'avatar',
                'url': '/avatars',
            });

            GamificationModule.isMenuSet = true;
        }
    }

}