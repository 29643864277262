import {Routes} from '@angular/router';
import {AppaireComponent} from '@modules/activities/core/player-components/appaire/appaire.component';
import {AudioComponent} from '@modules/activities/core/player-components/audio/audio.component';
import {AwarenessComponent} from '@modules/activities/core/player-components/awareness/awareness.component';
import {DemoChoiceComponent} from '@modules/activities/core/player-components/demo-choice/demo-choice.component';
import {DemoExternalComponent} from '@modules/activities/core/player-components/demo/demo-external.component';
import {DividerComponent} from '@modules/activities/core/player-components/divider/divider.component';
import {DrawLineComponent} from '@modules/activities/core/player-components/draw-line/draw-line.component';
import {ExternalComponent} from '@modules/activities/core/player-components/external/external.component';
import {FillInBlanksComponent} from '@modules/activities/core/player-components/fill-in-blanks/fill-in-blanks.component';
import {ImageZoningPlaySoundComponent} from '@modules/activities/core/player-components/image-zoning-play-sound/image-zoning-play-sound.component';
import {ImageZoningComponent} from '@modules/activities/core/player-components/image-zoning/image-zoning.component';
import {ImageComponent} from '@modules/activities/core/player-components/image/image.component';
import {InteractiveImageComponent} from '@modules/activities/core/player-components/interactive-image/interactive-image.component';
import {MediaComponent} from '@modules/activities/core/player-components/media/media.component';
import {MemoryComponent} from '@modules/activities/core/player-components/memory/memory.component';
import {MultimediaComponent} from '@modules/activities/core/player-components/multimedia/multimedia.component';
import {MultipleChoiceGridComponent} from '@modules/activities/core/player-components/multiple-choice-grid/multiple-choice-grid.component';
import {OrderMatchingComponent} from '@modules/activities/core/player-components/order-matching/order-matching.component';
import {OrdOnComponent} from '@modules/activities/core/player-components/ordon/ordon.component';
import {QcmComponent} from '@modules/activities/core/player-components/qcm/qcm.component';
import {QcuComponent} from '@modules/activities/core/player-components/qcu/qcu.component';
import {ShortAnswerComponent} from '@modules/activities/core/player-components/short-answer/short-answer.component';
import {SummaryActivityComponent} from '@modules/activities/core/player-components/summary-activity/summary-activity.component';
import {TextMatchingComponent} from '@modules/activities/core/player-components/text-matching/text-matching.component';
import {ToolComponent} from '@modules/activities/core/player-components/tool/tool.component';
import {TrueFalseComponent} from '@modules/activities/core/player-components/true-false/true-false.component';
import {VideoComponent} from '@modules/activities/core/player-components/video/video.component';
import {VoiceRecorderActivityComponent} from '@modules/activities/core/player-components/voice-recorder-activity/voice-recorder-activity.component';
import {WhatIsMissingAudioComponent} from '@modules/activities/core/player-components/what-is-missing-audio/what-is-missing-audio.component';
import {WhatIsMissingComponent} from '@modules/activities/core/player-components/what-is-missing/what-is-missing.component';

export const ActivitiesRoutes: Routes = [
    {
        path: 'qcm/:activityId',
        component: QcmComponent,
    },
    {
        path: 'qcu/:activityId',
        component: QcuComponent,
    },
    {
        path: 'pointimg/:activityId',
        component: ImageZoningComponent
    },
    {
        path: 'imgsoundzone/:activityId',
        component: ImageZoningPlaySoundComponent,
    },
    {
        path: 'truefalse/:activityId',
        component: TrueFalseComponent,
    },
    {
        path: 'ordon/:activityId',
        component: OrdOnComponent,
    },
    {
        path: 'orderingmatching/:activityId',
        component: OrderMatchingComponent,
    },
    {
        path: 'text-matching/:activityId',
        component: TextMatchingComponent,
    },
    {
        path: 'awareness/:activityId',
        component: AwarenessComponent,
    },
    {
        path: 'orderingmatching/:activityId',
        component: OrderMatchingComponent
    },
    {
        path: 'text-matching/:activityId',
        component: TextMatchingComponent
    },
    {
        path: 'fillinblanks/:activityId',
        component: FillInBlanksComponent,
    },
    {
        path: 'shortanswer/:activityId',
        component: ShortAnswerComponent,
    },
    {
        path: 'draw-line/:activityId',
        component: DrawLineComponent,
    },
    {
        path: 'draw-line/:activityId',
        component: DrawLineComponent
    },
    {
        path: 'appaire/:activityId',
        component: AppaireComponent,
    },
    {
        path: 'media/:activityId',
        component: MediaComponent,
    },
    {
        path: 'divider/:activityId',
        component: DividerComponent,
    },
    {
        path: 'audio/:activityId',
        component: AudioComponent,
    },
    {
        path: 'video/:activityId',
        component: VideoComponent,
    },
    {
        path: 'image/:activityId',
        component: ImageComponent,
    },
    {
        path: 'interactiveimage/:activityId',
        component: InteractiveImageComponent,
    },
    {
        path: 'summary/:activityId',
        component: SummaryActivityComponent,
    },
    {
        path: 'medias/:activityId',
        component: MultimediaComponent,
    },
    {
        path: 'external/:activityId',
        component: ExternalComponent,
    },
    {
        path: 'gcm/:activityId',
        component: MultipleChoiceGridComponent,
    },
    {
        path: 'tool/:activityId',
        component: ToolComponent,
    },
    {
        path: 'memory/:activityId',
        component: MemoryComponent,
    },
    {
        path: 'missing/:activityId',
        component: WhatIsMissingComponent,
    },

    {
        path: 'recording/:activityId',
        component: VoiceRecorderActivityComponent,
    },
    {
        path: 'imgsoundzone/:activityId',
        component: ImageZoningPlaySoundComponent,
    },
    {
        path: 'missingaudio/:activityId',
        component: WhatIsMissingAudioComponent,
    },
    {
        path: 'demo',
        children: [
            {
                path: '',
                component: DemoChoiceComponent,
            },
            {
                path: ':demoId',
                component: DemoExternalComponent,
            },
        ],
    },
];