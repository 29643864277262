import {AfterViewInit, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {AuthenticationService} from '@modules/authentication';
import {CardsConfigurationService} from 'fuse-core/components/card/cards-configuration.service';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {DataCardInterface} from 'fuse-core/components/card/data-card.interface';
import {combineLatest, Observable, of, shareReplay} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {AutoUnsubscribeTakeUntilClass, Roles} from 'shared/models';
import {map, filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules} from '@modules/activities/core/models/rules';
import {LessonGranuleEntity} from '@modules/activities/core/models';
import {SharedEntitiesService} from '@modules/activities/core/services/shared-entities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';

@Component({
    selector: 'app-lesson-page',
    templateUrl: './lesson-page.component.html'
})
export class LessonPageComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, AfterViewInit {

    public cardsService = inject(CardsService);
    private cardsConfigService = inject(CardsConfigurationService);
    private contextualService = inject(ContextualService);
    private ref = inject(ChangeDetectorRef);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private authorizationService = inject(AuthorizationService);
    private sharedEntitiesService = inject(SharedEntitiesService);
    private lessonsService = inject(LessonsService);
    private communicationCenter = inject(CommunicationCenterService);
    private authService = inject(AuthenticationService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    public datacard: DataCardInterface<LessonGranuleEntity>;
    public resource: LessonGranuleEntity;
    public currentUserRole = this.authService.accessLevel;

    public customOptions: OwlOptions = {
        autoWidth: true,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        navText: ['<', '>'],
        nav: false,
    };

    public learners = [];
    public classes = [];
    public workgroups = [];
    public isEditionAllowedForThisLesson$: Observable<boolean>;

    private originUrl: string;
    private selectorClassFocus: string;

    public selectedItem = -1;

    public get filesCount(): number {
        return this.resource?.get('metadatas')?.files?.length;
    }

    public get collectionName(): string {
        return this.resource?.get('metadatas')?.indexation[0]?.label;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {
        //ctz-setting
        if (this.lessonsConfigurationService.settings.addRootFilter) {
            this.communicationCenter
                .getRoom('root-filter')
                .getSubject('selected')
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(selectedCollection => {
                    this.selectedItem = selectedCollection;
                });
        }
        combineLatest([this.sharedEntitiesService.loadLearners(), this.sharedEntitiesService.loadClasses(), this.sharedEntitiesService.loadWorkgroups()])
            .pipe(
                tap(([learners, classes, workgroups]) => {
                    this.learners = learners;
                    this.classes = classes;
                    this.workgroups = workgroups;
                })
            )
            .subscribe();
        this.datacard = this.cardsService.currentDatacard;

        // if we don't have data, redirect to lessons/list/search to load our lesson and reopen it
        if (!this.datacard || +this.datacard.resource.id !== +this.route.snapshot.params['lessonId']) {
            let extraDataUrl = '';
            //ctz-settings
            if (this.cardsConfigService.addExtraDataUrl()) {
                if (this.router.url.includes('junior')) {
                    extraDataUrl = 'junior';
                }
                if (this.router.url.includes(('explorer'))) {
                    extraDataUrl = 'explorer';
                }
            }

            this.router.navigate(
                ['lessons', 'list', 'search'],
                {
                    'queryParams':
                        {
                            'id': this.route.snapshot.params['lessonId'],
                            'openLessonPage': 1,
                            'origin-url': this.route.snapshot.queryParams['origin-url'],
                            'extraDataJuniorCollege': extraDataUrl,

                        },
                    'skipLocationChange': true,
                });
        }

        if (this.datacard) {
            this.resource = this.datacard.resource;
            //ctz-settings false for ctz
            if (this.cardsConfigService.navigateOnInit()) {
                this.originUrl = this.route.snapshot.queryParams['origin-url'];
                this.selectorClassFocus = this.route.snapshot.queryParams['selectorClassFocus'];
                this.router.navigate(
                    [],
                    {
                        relativeTo: this.route,
                        queryParams: {'origin-url': null},
                        queryParamsHandling: 'merge',
                        replaceUrl: true,
                    });
            }
        }
        this.cardsService.setTypeOfCard();
        this.isEditionAllowedForThisLesson$ = this.isEditionAllowedForThisLesson().pipe(
            take(1),
            shareReplay(1)
        );
        this.ref.detectChanges();

        this.setupContextual();
    }

    ngAfterViewInit(): void {
        //ctz-settings
        if (this.cardsConfigService.focusOnFileAfterViewInit()) {
            this.communicationCenter
                .getRoom('doc-files')
                .getSubject('focus-first')
                .pipe(take(1))
                .subscribe(res => {
                    if (this.filesCount > 1 && res === true) {
                        const card = document.getElementsByClassName('card_0') as HTMLCollectionOf<HTMLElement>;
                        if (card.length > 0) {
                            card[0].focus();
                        }
                    }
                    this.communicationCenter
                        .getRoom('doc-files')
                        .getSubject('focus-first')
                        .next(false);
                });
        } else {
            if (this.selectorClassFocus) {
                const element = document.querySelector(this.selectorClassFocus);
                if (element) {
                    element.classList.add('focus');
                }
            }
        }
    }

    public goBackToPrevPage(): void {
        let url = '';
        //ctz-settings
        if (this.cardsConfigService.useCtzBackUrlLogic() && this.datacard?.originPath.includes('search')) {
            url = this.datacard?.originPath;
            url = 'lessons/list/models';
        } else {
            url = this.originUrl;
        }

        const urlParams = this.getParamsFromURI(url);
        const queryParams = {};

        urlParams.forEach((value, key) => {
            if ((this.lessonsConfigurationService.settings.addRootFilter && key !== 'id' && key !== 'openLessonPage') || (this.lessonsConfigurationService.settings.addRootFilter === false && key !== 'id' && key !== 'openLessonPage' && key !== 'origin-url')) {
                queryParams[key] = value;
            }
        });

        this.router.navigate([this.removeAllURLParameters(url)], {queryParams});
    }

    public getParamsFromURI(relativeUrl) {
        const [, paramString] = relativeUrl.split('?');
        return new URLSearchParams(paramString);
    }

    public removeAllURLParameters(url) {
        //prefer to use l.search if you have a location/link object
        const urlparts = url.split('?');
        if (urlparts.length >= 2) {
            return urlparts[0];
        }
        return url;
    }

    public downloadDoc(uri: string): void {
        this.cardsService.downloadDoc(uri);
    }

    public isFavorite(): boolean {
        return this.cardsService.isFavorite;
    }

    public bookmark(): void {
        this.cardsService.bookmark();
    }

    public playPreview(): void {
        this.cardsService.playPreview();
    }

    public assignToLearners(): void {
        this.datacard.openAssign(this.datacard.resource);
    }

    public isEditionActiveForThisLesson(): Observable<boolean> {
        return this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false), this.resource);
    }

    public playSession(): void {
        /** TODO faut simplifier ici :
         *   - soit on ne vient pas d'une modal précise et on appelle openAssignmentWithUserDataFormModal,
         *   - soit on vient de cette modal et on appelle play
         *
         *   Alors que play, qui devrait être l'unique appelé (c'est pas a LessonPage de savoir comment ouvrir une lesson) lui aussi ca faire ses tests pour appeler la meme chose que openAssignmentWithUserDataFormModal
         *
         *   Il faut refacto pour appeler uniquement play. Play va gérer le fait qu'il faille ouvrir la modale avant ou non. Parce que c'est pas la résponsabilité de se composant. Rien n'empeche de forcer l'ouverture de la modale dans play avec un argument.
         */
        // get type of modal to use old or new one
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('view').pipe(take(1)).subscribe(comp => {
            // Ne pas tester le nom de composant ! C'est tout sauf une bonne pratique. On ne doit pas essayer de deviner de contexte !
            if (comp.name === 'AssignmentByStepsComponent'
                || !this.cardsConfigService.isLaunchLessonAskModalActive((this.authService.accessLevel as Roles))) {
                this.cardsService.play(<any>this.datacard);
            } else if (this.cardsService.shouldSelectSubLesson()) {
                this.cardsService.openSubLessonSelectionModal()
                    .pipe(
                        take(1),
                        filter(subLessonId => !!subLessonId),
                        mergeMap((subLessonId) => this.lessonsService.loadLessonGranuleById(subLessonId).pipe(take(1))),
                        tap(subLesson => {
                            this.openAssignmentWithUserDataFormModal(subLesson, this.datacard);
                        })
                    )
                    .subscribe();
            } else {
                // ici play sans sous lessons ancienne modal
                this.openAssignmentWithUserDataFormModal(this.resource, this.datacard);
            }
        });
    }

    private openAssignmentWithUserDataFormModal(entity: LessonGranuleEntity, datacard: DataCardInterface<LessonGranuleEntity>): void {
        this.cardsService.openAssignmentWithUserDataFormModal(entity, datacard, this.learners, this.classes, this.workgroups);
    }

    public isAssignable$() {
        //ctz-settings
        return this.cardsConfigService.usefilterUsageAutonomie() ? of(this.resource?.get('usage').some(e => e.label === 'usage.autonomie') || this.resource?.get('usage').length === 0) : this.authorizationService.currentUserCan('assignment.assign-lesson', of(false), this.resource);
    }

    public isEditable(): Observable<boolean> | boolean {
        //ctz-settings
        return this.cardsConfigService.usefilterUsageAutonomie() ? this.resource?.get('usage').some(e => e.label === 'usage.autonomie') || this.resource?.get('usage').length === 0 : this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false), this.resource);
    }

    public isDuplicableAndEditable(): Observable<boolean> {
        return combineLatest([
            this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.EditLesson, of(false)),
            this.authorizationService.currentUserCan<Observable<boolean>>(AsyncRules.DuplicateLesson, of(false), this.resource)
        ]).pipe(
            map(([canEdit, canDuplicateThisLesson]) => canEdit && canDuplicateThisLesson)
        );
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPreview$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playPreview());
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.playSession());
        this.contextualService.actionLessonOpenAssign$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.assignToLearners());

        this.contextualService.conditionLessonHasDocument$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount > 0));
        //ctz-settings false for ctz
        if (this.cardsConfigService.checkAssignableState()) {
            combineLatest([this.contextualService.conditionLessonAssignable$, this.isAssignable$()])
                .pipe(takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(([callback, isAssignable]) => callback(isAssignable));
        } else {

        }


        this.contextualService.dataLessonDocumentCount$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.filesCount.toString()));
        this.contextualService.dataLessonCollectionName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.collectionName));
    }

    public onDeleteButtonClick($event: MouseEvent): void {
        $event.stopPropagation();
        this.datacard.deleteWithAssignments$(this.resource).subscribe(() => {
            this.goBackToPrevPage();
        });
    }


    private isModel(): boolean {
        return this.resource?.get('model');
    }

    public showEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.isOkayBylessonPageMenuActions('editOwnOrDisable', type);
        if (!allowedByConfig) {
            return of(false);
        }
        return this.isEditionActiveForThisLesson();
    }

    public showDuplicateAndEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.isOkayBylessonPageMenuActions('duplicateAndEdit', type);

        if (!allowedByConfig) {
            return of(false);
        }

        return this.isDuplicableAndEditable();
    }

    public showDuplicateIfNeededAndEditButton(): Observable<boolean> {
        const type = this.isModel() ? 'model' : 'lesson';
        const allowedByConfig = this.cardsService.isOkayBylessonPageMenuActions('duplicateIfNeededAndEdit', type);

        if (!allowedByConfig) {
            return of(false);
        }
        return combineLatest([this.isEditionActiveForThisLesson(), this.isDuplicableAndEditable()]).pipe(
            map(([isEditable, isDuplicableAndEditable]) => isEditable || isDuplicableAndEditable)
        );
    }

    public isPreviewAccessible() {
        return this.authorizationService.currentUserCan(AsyncRules.PreviewLesson, of(false));
    }

    public isEditionAllowedForThisLesson() {
        return this.communicationCenter.getRoom('assignment').getSubject('isEditable$').pipe(
            mergeMap((isEditable: (id) => Observable<boolean>) => isEditable(this.resource.id))
        );
    }


    public getIsRootFilter(): boolean {
        //ctz-settings
        return this.lessonsConfigurationService.settings.addRootFilter;
    }
}
