import {Component, Injectable} from '@angular/core';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {NewsInterface} from 'fuse-core/news/news.interface';
import {OldLearnerStartSubLessonsComponent} from './popups/old-learner-start-sub-lessons/old-learner-start-sub-lessons.component';
import {NewLearnerStartSubLessonsComponent} from './popups/new-learner-start-sub-lessons/new-learner-start-sub-lessons.component';
import {NewLearnerStartLessonComponent} from './popups/new-learner-start-lesson/new-learner-start-lesson.component';
import {OldLearnerStartLessonsComponent} from './popups/old-learner-start-lessons/old-learner-start-lessons.component';
import {DataEntity} from 'octopus-connect';
import {distinctUntilChanged, mergeMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

const ONLY_MODELS_LESSONS_URL_REGEX = /^\/lessons\/list\/models\?chapters=\d+$/;
const SUB_LESSONS_URL_REGEX = /^\/lessons\/\d+\/activities$/;

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    static newLearnerStartLessonsPopup: Partial<NewsInterface> = {
        channel: {
            snackbar: {
                acceptedUrlRegex: ONLY_MODELS_LESSONS_URL_REGEX
            }
        },
        component: NewLearnerStartLessonComponent as Component,
        id: 'newLearnerStartLessonsPopup',
    };

    static oldLearnerStartLessonsPopup: Partial<NewsInterface> = {
        channel: {
            snackbar: {
                acceptedUrlRegex: ONLY_MODELS_LESSONS_URL_REGEX
            }
        },
        component: OldLearnerStartLessonsComponent as Component,
        id: 'oldLearnerStartLessonsPopup',
    };

    static newLearnerStartSubLessonsPopup: Partial<NewsInterface> = {
        channel: {
            snackbar: {
                acceptedUrlRegex: SUB_LESSONS_URL_REGEX
            }
        },
        component: NewLearnerStartSubLessonsComponent as Component,
        id: 'newLearnerStartSubLessonsPopup',
    };

    static oldLearnerStartSubLessonsPopup: Partial<NewsInterface> = {
        channel: {
            snackbar: {
                acceptedUrlRegex: SUB_LESSONS_URL_REGEX
            }
        },
        component: OldLearnerStartSubLessonsComponent as Component,
        id: 'oldLearnerStartSubLessonsPopup',
    };

    private logout$ = new Subject<void>();

    constructor(
        private config: LessonsConfigurationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    private postAuthentication(): void {
        if (this.config.settings.isOnboardingEnabled) {
            this.communicationCenter.getRoom('achievement').getSubject('isNewUser$')
                .pipe(
                    mergeMap((isNewUser$: Observable<boolean>) => isNewUser$),
                    takeUntil(this.logout$),
                    distinctUntilChanged(),
                )
                .subscribe((isFirstTime) => {
                    if (isFirstTime) {
                        // celles-ci ne devraient pas arriver, mais on ne prend pas de risque
                        this.communicationCenter.getRoom('news').next('delete', [
                            OnboardingService.oldLearnerStartLessonsPopup,
                            OnboardingService.oldLearnerStartSubLessonsPopup
                        ]);

                        this.communicationCenter.getRoom('news').next('add', [
                            OnboardingService.newLearnerStartLessonsPopup,
                            OnboardingService.newLearnerStartSubLessonsPopup
                        ]);
                    } else {
                        this.communicationCenter.getRoom('news').next('delete', [
                            OnboardingService.newLearnerStartLessonsPopup,
                            OnboardingService.newLearnerStartSubLessonsPopup
                        ]);
                        this.communicationCenter.getRoom('news').next('add', [
                            OnboardingService.oldLearnerStartLessonsPopup,
                            OnboardingService.oldLearnerStartSubLessonsPopup
                        ]);
                    }
                });
        }
    }

    private postLogout(): void {
        this.communicationCenter.getRoom('news').next('delete', [
            OnboardingService.newLearnerStartLessonsPopup,
            OnboardingService.oldLearnerStartLessonsPopup,
            OnboardingService.newLearnerStartSubLessonsPopup,
            OnboardingService.oldLearnerStartSubLessonsPopup
        ]);
        this.logout$.next();
        this.logout$.complete();
        this.logout$ = new Subject<void>();
    }
}
