import {Routes} from '@angular/router';
import {ActivitiesHomeComponent} from '@modules/activities/core/activities-home/activities-home.component';
import {LessonsInChaptersListComponent} from '@modules/activities/core/activities-home/lessons-in-chapters-list/lessons-in-chapters-list.component';
import {PeriodListComponent} from '@modules/activities/core/activities-home/period-list/period-list.component';
import {ActivitiesListComponent} from '@modules/activities/core/activities-list/activities-list.component';
import {ShortAnswerMultiEditorComponent} from '@modules/activities/core/editor-components/short-answer-multi-editor/short-answer-multi-editor.component';
import {VideoEditorComponent} from '@modules/activities/core/editor-components/video-editor/video-editor.component';
import {CanLaunchOldEditorGuard} from '@modules/activities/core/lessons/editor/guards/can-launch-old-editor.guard';
import {LessonPageComponent} from '@modules/activities/core/lessons/lesson-page/lesson-page.component';
import {CanShowLessonListGuard} from '@modules/activities/core/lessons/lessons-list/can-show-lesson-list.guard';
import {CommunityListComponent} from '@modules/activities/core/lessons/lessons-list/community-list/community-list.component';
import {EditLessonComponent} from '@modules/activities/core/lessons/lessons-list/edit-lesson/edit-lesson.component';
import {LessonActivitiesComponent} from '@modules/activities/core/lessons/lessons-list/lesson-activities/lesson-activities.component';
import {LessonsListComponent} from '@modules/activities/core/lessons/lessons-list/lessons-list.component';
import {OnlyLessonLessonsListComponent} from '@modules/activities/core/lessons/lessons-list/only-lesson-lessons-list/only-lesson-lessons-list.component';
import {OnlyModelLessonsListComponent} from '@modules/activities/core/lessons/lessons-list/only-model-lessons-list/only-model-lessons-list.component';
import {RecommendationComponent} from '@modules/activities/core/lessons/lessons-list/recommendation/recommendation.component';
import {SequencesTabComponent} from '@modules/activities/core/lessons/lessons-list/sequences-tab/sequences-tab.component';
import {UserLessonsComponent} from '@modules/activities/core/lessons/lessons-list/user-lessons/user-lessons.component';
import {LessonsRecapComponent} from '@modules/activities/core/lessons/lessons-recap/lessons-recap.component';
import {LessonsComponent} from '@modules/activities/core/lessons/lessons.component';
import {ListOfActivitiesComponent} from '@modules/activities/core/lessons/list-of-activities/list-of-activities.component';
import {LessonActivitiesResolver} from '@modules/activities/core/lessons/resolvers/lesson-activities.resolver';
import {SummarySubLessonComponent} from '@modules/activities/core/lessons/summary-sub-lesson/summary-sub-lesson.component';
import {RewardPageComponent} from '@modules/activities/core/reward-page/reward-page.component';
import {CorpusDisplayEmbedComponent} from '@modules/activities/core/shared-components/corpus-display-embed/corpus-display-embed.component';
import {SummaryComponent} from '@modules/activities/core/shared-components/summary/summary.component';
import {IsUserLogged} from '../../../../guards';

export const routes: Routes = [
    {
        path: 'lessons',
        // canActivate: [IsUserLogged],
        children: [
            {
                path: 'home',
                component: ActivitiesHomeComponent,
            },
            {
                path: 'grade/:gradeId/concept/:conceptId',
                component: PeriodListComponent,
            },
            {
                path: 'grade/:gradeId/concept/:conceptId/chapter/:chapterId',
                component: LessonsInChaptersListComponent,
            },
            {
                path: 'list',
                canActivate: [CanShowLessonListGuard],
                children: [
                    {
                        path: '',
                        component: LessonsListComponent,
                    },
                    {
                        path: 'filters/:title',
                        component: LessonsListComponent
                    },
                    {
                        path: 'search',
                        component: LessonsListComponent
                    },
                    {
                        path: 'lessons',
                        component: OnlyLessonLessonsListComponent
                    },
                    {
                        path: 'models',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'algebra',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'geometry',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'community',
                        component: CommunityListComponent
                    },
                    {
                        path: 'recommendation',
                        component: RecommendationComponent
                    },
                    {
                        path: 'user-lessons',
                        component: UserLessonsComponent
                    }]
            },
            {
                path: ':lessonId',
                children: [
                    {
                        path: 'page',
                        component: LessonPageComponent
                    },
                    {
                        path: 'pagejunior',
                        component: LessonPageComponent // use only to permit open whith a direct link take care of root filter
                    },
                    {
                        path: 'pagecollege',
                        component: LessonPageComponent // use only to permit open whith a direct link take care of root filter
                    },
                    {
                        path: 'pageexplorer',
                        component: LessonPageComponent // use only to permit open whith a direct link take care of root filter
                    },
                    {
                        path: 'player',
                        component: LessonsComponent,
                        children: [
                            {
                                path: 'recap',
                                component: LessonsRecapComponent
                            },
                            {
                                path: 'reward',
                                component: RewardPageComponent
                            }, {
                                path: 'summary',
                                component: SummarySubLessonComponent
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/player-components/players.module').then(m => m.PlayersModule)
                            }
                        ]
                    },
                    {
                        path: 'edit',
                        canActivate: [CanLaunchOldEditorGuard],
                        children: [
                            {
                                path: '',
                                resolve: {
                                    lessonActivities: LessonActivitiesResolver
                                },
                                component: LessonActivitiesComponent
                            },
                            {
                                path: 'stepto',
                                children: [
                                    {
                                        path: '',
                                        component: EditLessonComponent,
                                        children: [
                                            {
                                                path: ':activityId',
                                                children: [
                                                    {
                                                        path: 'video',
                                                        component: VideoEditorComponent,
                                                    },
                                                    {
                                                        path: 'poll',
                                                        component: ShortAnswerMultiEditorComponent,
                                                    },
                                                    {
                                                        path: 'summary',
                                                        component: SummaryComponent,
                                                    },
                                                    {
                                                        path: 'resources',
                                                        component: CorpusDisplayEmbedComponent,
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'activities',
                                component: ActivitiesListComponent
                            },
                            {
                                path: 'resources',
                                component: CorpusDisplayEmbedComponent,
                            },
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/editor-components/editors.module').then(m => m.EditorsModule)
                            },
                        ]
                    },
                    {
                        path: 'editor',
                        children: [
                            {
                                path: '',
                                loadChildren: () => import('@modules/activities/core/lessons/editor/editor.module').then(m => m.EditorModule)
                            }
                        ]
                    },
                    {
                        path: 'activities',
                        component: ListOfActivitiesComponent
                    },
                    {
                        path: 'activities/:subLessonIdentifier', // On le double pour pouvoir forcer un des onglets du composant
                        component: ListOfActivitiesComponent
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'player'
                    }
                ]
            }
        ]
    },
    {
        path: 'sequences',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: SequencesTabComponent
            }
        ]
    }
];