import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-mobile-redirection-modal',
    templateUrl: './mobile-redirection-modal.component.html',
    styleUrls: ['./mobile-redirection-modal.component.scss']
})
export class MobileRedirectionModalComponent implements OnInit {

    constructor( @Inject(MAT_DIALOG_DATA) public data: {[key: string]: any},
                 private dialogRef: MatDialogRef<MobileRedirectionModalComponent>) { }

    ngOnInit(): void {
    }

    public close(): void {
        this.dialogRef.close(true);
    }

}