import {Injectable} from '@angular/core';
import {AuthorizationService} from '@modules/authorization';
import {AsyncRules, SyncRules} from '../models/rules';
import {GroupManagementConfigurationService} from './group-management-configuration.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {InstitutionGroup, Workgroup} from '../definitions';
import {InstitutionGroupService} from '@modules/groups-management';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {LicenseDataEntity, LicensesService} from '@modules/groups-management/core/services/licenses.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '@modules/authentication';
import {Roles} from 'shared/models/roles';

@Injectable({
    providedIn: 'root'
})
export class GroupManagementAuthorizationService {

    private reverseRoleMapping: { [roleNumber: number]: string } = {};

    private aboutInstitutionRules = [
        SyncRules.CreateInstitution,
        SyncRules.DeleteInstitution,
    ];

    // private aboutTrainerRules = [
    //     SyncRules.EditTrainer,
    //     SyncRules.DeleteTrainer,
    //     SyncRules.CreateTrainer,
    //     SyncRules.EditTrainerInstitutionManagerTrainerRights,
    // ];


    private aboutGroupRules = [
        SyncRules.CreateGroup,
        SyncRules.EditGroup,
        SyncRules.DeleteGroup,
        SyncRules.ArchiveGroup,
        SyncRules.SeeLearnersInGroup,
        SyncRules.AttachLearnerToGroup,
        SyncRules.ActiveMetacognitionOnGroup,
        SyncRules.BeAttachedToGroup,
        SyncRules.SeeOwnGroup,
        SyncRules.SeeAllGroup,
    ];

    // private extraActionsGroupRules = [
    //     SyncRules.CreateLearnerFromGroups,
    // ];

    private aboutWorkgroupRules = [
        SyncRules.CreateWorkgroup,
        SyncRules.EditWorkgroup,
        SyncRules.DeleteWorkgroup,
        SyncRules.ArchiveWorkgroup,
        SyncRules.SeeLearnersInWorkgroup,
        SyncRules.AttachLearnerToWorkgroup,
        SyncRules.BeAttachedToWorkgroup,
        SyncRules.SeeOwnWorkgroup,
        SyncRules.SeeAllWorkgroup,
    ];

    private aboutWorkgroupEditionRules = [
        SyncRules.EditWorkgroup,
        SyncRules.DeleteWorkgroup,
        SyncRules.ArchiveWorkgroup,
    ]

    private aboutLearnerRules = [
        SyncRules.CreateLearner,
        SyncRules.CreateLearnerFromGroups,
        SyncRules.EditLearner,
        SyncRules.DeleteLearner
    ];

    private atLeastTrainerRules = [
        ...this.aboutGroupRules,
        ...this.aboutWorkgroupRules,
        ...this.aboutLearnerRules,
        ...this.aboutInstitutionRules
    ]

    private userInstitutions: InstitutionGroup[] = [];

    // /**
    //  * Un prof avec le droit Admin peut :
    //  * - Créer une nouvelle classe au sein de l'établissement
    //  * - Créer un nouveau groupe au sein de l'établissement
    //  * - Créer un nouvel ENSEIGNANT au sein de l'établissement
    //  * - Créer un nouvel ADMINISTRATEUR au sein de l'établissement // Si t'as le droit d'editer les droits admin, t'as le droit de creer un admin
    //  * - Créer un nouvel ÉLÈVE au sein de l'établissement
    //  * - Créer un nouvel ÉLÈVE au sein d'un classe // Le besoin de creer un élève mais pas dans un classe n'existe pas, donc meme droit qu'au dessus
    //  * - Créer un nouvel ÉLÈVE au sein d'un groupe // idem
    //  * - Attacher un ÉLÈVE de l'établissement à une classe // Edit group
    //  * - Attacher un ÉLÈVE de l'établissement à un groupe // Edit WorkGroup
    //  * - Voir toutes les classes de l'établissement
    //  * - Voir tous les groupes de l'établissement
    //  * - Détacher un ÉLÈVE d'une classe // Edit group
    //  * - Détacher un ÉLÈVE d'un groupe // Edit WorkGroup
    //  * - Détacher un ÉLÈVE d'un établissement // Edit Institution
    //  * - Voir la page ÉTABLISSEMENT
    //  * - Modifier un ENSEIGNANT au sein de l'établissement
    //  * - Modifier un ADMINISTRATEUR au sein de l'établissement // Le droit de modifier un prof non admin n'existe pas, donc ce droit et le meme que modifier Enseignant
    //  * - Détacher un ENSEIGNANT au sein de l'établissement // Edit Institution
    //  * - Détacher un ADMINISTRATEUR au sein de l'établissement // Voir les deux dessus
    //  * - Activer ou désactiver le code établissement
    //  * - Activer ou désactiver les codes classe
    //  */
    // private allowedForDirectorsRules = [
    //     SyncRules.ActivateInstitutionsCode,
    //     SyncRules.SeeAllGroup,
    //     SyncRules.CreateGroup,
    //     SyncRules.EditGroup,
    //     SyncRules.DeleteGroup,
    //     SyncRules.CreateWorkgroup,
    //     SyncRules.EditWorkgroup,
    //     SyncRules.DeleteWorkgroup,
    //     SyncRules.SeeAllWorkgroup,
    //     SyncRules.CreateTrainer,
    //     SyncRules.EditTrainer,
    //     SyncRules.EditTrainerInstitutionManagerTrainerRights,
    //     SyncRules.CreateLearner,
    //     SyncRules.CreateLearnerFromGroups,
    //     SyncRules.EditLearner,
    //     SyncRules.DeleteLearner,
    //     SyncRules.BeAttachedToGroup,
    //     SyncRules.BeAttachedToWorkgroup,
    // ];

    private currentUserLicences: LicenseDataEntity[] = [];

    constructor(
        private authorizationService: AuthorizationService,
        private config: GroupManagementConfigurationService,
        private institutionService: InstitutionGroupService,
        private communicationCenter: CommunicationCenterService,
        private licensesService: LicensesService,
        private authService: AuthenticationService,
    ) {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList')
            .subscribe((institutionList: InstitutionGroup[]) => {
                this.userInstitutions = institutionList || [];
            });

        this.communicationCenter.getRoom('authentication')
            .getSubject('roles')
            .subscribe((roleMapping: { [roleIdentifier: string]: number }) => {
                this.reverseRoleMapping = Object.keys(roleMapping).reduce((obj, roleName) => {
                    const roleId = roleMapping[roleName];
                    obj[roleId] = roleName;
                    return obj;
                }, {});
            });

        this.licensesService.currentUserLicenses$.subscribe(licenses => this.currentUserLicences = licenses);
    }

    private atLeastManagerRoles: Roles[] = ['manager', 'administrator'];
    private atLeastDirectorRoles: Roles[] = ['director', ...this.atLeastManagerRoles];
    private atLeastTrainerRoles: Roles[] = ['trainer', ...this.atLeastDirectorRoles];

    activeRulesOnStartup(): void {
        Object.values(SyncRules).forEach((rule) => this.authorizationService.removeRule(rule));
        Object.values(AsyncRules).forEach((rule) => this.authorizationService.removeRule(rule));

        this.authorizationService.addRoleRule(SyncRules.AccessTrainersAndDirectorsManagementPanel, this.atLeastManagerRoles);
        this.authorizationService.addRoleRule(SyncRules.BeAttachedToInstitution, ['trainer', 'learner']);
        this.authorizationService.addRule(AsyncRules.AccessInstitutionInformation, (user) => this.isUserHasManagerRight$(user));
        this.authorizationService.addRule(AsyncRules.AccessInstitutionUsersManagement, (user) => this.isUserCanAccessAdminFeatures$(user));
        this.authorizationService.addRule(AsyncRules.ActivateGroupsCode, (user) => this.isAllowedToChangeGroupsCode$(user));
        this.authorizationService.addRule(SyncRules.SeeAllLearner, (user) => this.isAllowedToSeeAllLearner(user));

        this.authorizationService.addRoleRule(SyncRules.ActivateInstitutionsCode, this.atLeastDirectorRoles);

        Object.values(SyncRules).forEach((rule) => {
            if (!this.authorizationService.hasRule(rule)) {
                const defaultRuleCallback = (user) => {
                    // sinon on regarde les droits de l'utilisateur
                    if (this.authService.isGAR()) {
                        return this.config.rulesForCurrentGARUser().includes(rule);
                    } else if (this.atLeastTrainerRules.includes(rule)) {
                        return this.userIsAtLeastTrainer(user);
                    } else {
                        return false;
                    }
                };

                if (this.aboutWorkgroupEditionRules.includes(rule)) {
                    this.authorizationService.addRule(rule, (user, group: Workgroup|null) => {
                        if (group && 'locked' in group && group.locked === true) {
                            return false;
                        }
                        return defaultRuleCallback(user);
                    });
                } else if (rule === SyncRules.AttachLearnerToWorkgroup) {
                    this.authorizationService.addRule(rule, (user, _learner, group: Workgroup|null) => {
                        if (group && 'locked' in group && group.locked === true) {
                            return false;
                        }
                        return defaultRuleCallback(user);
                    });

                } else {
                    this.authorizationService.addRule(rule, defaultRuleCallback);
                }

            }
        });
    }

    private isUserHasManagerRight$(user): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList').pipe(
                map(() => {
                    if (this.userIsAtLeastDirector(user) || (!this.config.isAccessForAdminsFeaturesEnabledByRole() && this.userIsAtLeastTrainer(user))) {
                        return true;
                    }
                    return this.userIsAtLeastTrainer(user) && this.institutionService.isUserHasManagerRightsInHisInstitutions(user);
                })
            );
    }

    private isUserCanAccessAdminFeatures$(user): Observable<boolean> {
        return this.communicationCenter
            .getRoom('groups-management')
            .getSubject('institutionList').pipe(
                map(() => {
                    if (this.userIsAtLeastDirector(user) || (!this.config.isAccessForAdminsFeaturesEnabledByRole() && this.userIsAtLeastTrainer(user))) {
                        return true;
                    }

                    const hasInstitutionLicense = this.userInstitutions && this.userInstitutions
                        .some((institution: InstitutionGroup) => institution.license && institution.license.type === 'Institution');
                    return (this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Institution') || hasInstitutionLicense)
                            && this.institutionService.isUserHasManagerRightsInHisInstitutions(user);
                })
            );
    }

    private isAllowedToChangeGroupsCode$(user): Observable<boolean> {
            return this.communicationCenter
                .getRoom('groups-management')
                .getSubject('institutionList').pipe(
                    map(() => {
                        if (this.userIsAtLeastDirector(user) || (!this.config.isAccessForAdminsFeaturesEnabledByRole() && this.userIsAtLeastTrainer(user))) {
                            return true;
                        }
                        return this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Class') || (this.userInstitutions && this.userInstitutions
                            .some((institution: InstitutionGroup) => institution.license && institution.license.type === 'Class'));
                    })
                );
    }

    private userIsAtLeastDirector(user: UserDataEntity): boolean {
        const userRoleNames = user && user.get('role').map(id => this.reverseRoleMapping[id]).filter(str => !!str).map(str => str.toLowerCase());
        return this.atLeastDirectorRoles.some((role) => userRoleNames.includes(role));
    }
    private userIsAtLeastTrainer(user: UserDataEntity): boolean {
        const userRoleNames = user && user.get('role').map(id => this.reverseRoleMapping[id]).filter(str => !!str).map(str => str.toLowerCase());
        return this.atLeastTrainerRoles.some((role) => userRoleNames.includes(role));
    }

    private isAllowedToSeeAllLearner(user: UserDataEntity): boolean {
        return this.userIsAtLeastTrainer(user);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // TODO old code to handle rights for Ubolino (licenses, institutions), to remove at some point ?
    // -----------------------------------------------------------------------------------------------------------------
    // private activeInstitutionGivenRightsTrainersRulesWithLicenses(): void {
    //
    //     [
    //         ...this.aboutGroupRules,
    //         ...this.extraActionsGroupRules,
    //         ...this.aboutWorkgroupRules,
    //         ...this.aboutLearnerRules,
    //         ...this.aboutInstitutionRules,
    //         ...this.aboutTrainerRules
    //     ].forEach((rule) => {
    //         this.authorizationService.addRule(rule, (user, options: any[]) => {
    //             if (this.userIsAtLeastDirector(user)) {
    //                 return this.allowedForDirectorsRules.includes(rule);
    //             }
    //             const isAllowedByAnyLicenseInInstitution = this.isRuleAllowedByLicenseInInstitution(rule);
    //             const isAllowedByAnyLicense = this.isRuleAllowedByLicense(rule);
    //             const isAllowedByAnyRole = this.IsAllowedByManagerOrEduTrainer(rule, user, options);
    //             return (isAllowedByAnyLicense || isAllowedByAnyLicenseInInstitution) && isAllowedByAnyRole;
    //         });
    //     });
    // }

    /**
     * Check if the rule il allowed by the specific trainer role in the institution
     * the role need to be applied to the user if the rule is applied to the role
     * In this case return true, else false
     * @private
     */
    // private IsAllowedByManagerOrEduTrainer(rule: SyncRules, user: UserDataEntity, options: any[]): boolean {
    //     /** Un prof avec droit EDU peut :
    //      - Créer une nouvelle classe au sein de l'établissement
    //      - Créer un nouveau groupe au sein de l'établissement
    //      - Être attaché à une classe existante au sein de l'établissement
    //      - Être attaché à un groupe existant au sein de l'établissement
    //      - Voir les classes qu'il a crée
    //      - Voir les groupes qu'il a crée
    //      - Voir les classes auxquelles il est attaché
    //      - Voir les groupes auxquels il est attaché
    //      - Détacher des élèves de ses classes // Compris dans le EditGroup
    //      - Détacher des élèves de ses groupes // Compris dans le EditWorkGroup
    //      - Ajouter des élèves de l'établissement à ses classes // Compris dans le EditGroup
    //      - Ajouter des élèves de l'établissement à ses groupes // Compris dans le EditWorkGroup
    //      - TODO De plus, si l'autorisation 'code classe', il pourra voir son code classe pour le communiquer à ses élèves
    //      */
    //     const allowedForEducatorRules = [
    //         SyncRules.CreateGroup,
    //         SyncRules.CreateWorkgroup,
    //         SyncRules.BeAttachedToGroup,
    //         SyncRules.BeAttachedToWorkgroup,
    //         SyncRules.SeeOwnGroup,
    //         SyncRules.SeeOwnWorkgroup,
    //         SyncRules.EditGroup,
    //         SyncRules.EditLearner,
    //         SyncRules.EditWorkgroup,
    //     ];
    //     let asEducatorIamAllowed = false;
    //     if (this.institutionService.isUserHasEducatorRightsInHisInstitutions(user)) {
    //         if (rule === SyncRules.EditLearner) {
    //             if (!options || options.length === 0) {
    //                 throw new Error('Learner must be pass for testing this rule : ' + SyncRules.EditLearner);
    //             }
    //             asEducatorIamAllowed = this.isLearnerInMyGroupOrWorkgroup(user, options[0] as Learner);
    //         } else {
    //             asEducatorIamAllowed = allowedForEducatorRules.includes(rule);
    //         }
    //     }
    //     const asInstitutionManagerIamAllowed = this.allowedForDirectorsRules.includes(rule) && this.institutionService.isUserHasManagerRightsInHisInstitutions(user);
    //     if (this.aboutTrainerRules.includes(rule)) {
    //         return asInstitutionManagerIamAllowed;
    //     }
    //     // Ici tu as le droit à partir du moment où l'un de tes roles de donne ce droit. Il n'y a pas de role excluant un droit.
    //     return asEducatorIamAllowed || asInstitutionManagerIamAllowed;
    // }

    // private isLearnerInMyGroupOrWorkgroup(educator: UserDataEntity, learner: Learner): boolean {
    //     const educatorGroups: (string|number)[] = educator.get('groups');
    //     const learnerGroups = learner.groupsWithData;
    //     const learnerWorkgroups = learner.workgroupsWithData;
    //
    //     return [...learnerGroups, ...learnerWorkgroups].some(group => educatorGroups.includes(group.id.toString()));
    // }

    /**
     * Check if a rule is allowed by the class licence
     * The licence is set on the institution
     * Every user are influenced by the licence (if there is a licence)
     * if there is no class licence, return true
     * @private
     */
    // private isRuleAllowedByClassLicense(rule: SyncRules): boolean {
    //     /** Un prof avec une license classe est bridé * 1 classe * 35 élèves */
    //     const isUserLimitedByClassLicenseRules = [
    //         // Pour limiter les classes
    //         {rule: SyncRules.CreateGroup, resolve: () => this.getUserGroupsLength() < 1},
    //         // Pour limiter les élèves
    //         {rule: SyncRules.CreateLearner, resolve: () => this.getUserLearnersLength() < 35},
    //     ];
    //
    //     const classLicenceRule = isUserLimitedByClassLicenseRules.find(r => r.rule === rule);
    //     if (!!classLicenceRule) {
    //         return classLicenceRule.resolve();
    //     }
    //     // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
    //     return true;
    // }

    /**
     * Check if a rule is allowed by the Institution licence
     * The licence is set on the institution
     * Every user are influenced by the licence (if there is a licence)
     * if there is no institution licence, return true
     * @private
     */
    // private isRuleAllowedByInstitutionLicense(rule: SyncRules): boolean {
    //     /** Un prof avec une licence établissement est bridé * 5 admins * 100 profs * 2000 élèves => (donc 2000 classes)*/
    //     const isUserLimitedByInstitutionsLicenseRules = [
    //         // Pour limiter les profs de type admins
    //         {rule: SyncRules.EditTrainerInstitutionManagerTrainerRights, resolve: () => this.getInstitutionAdminTrainersLength() < 5},
    //         // Pour limiter les profs non admins
    //         {rule: SyncRules.CreateTrainer, resolve: () => this.getInstitutionNotAdminTrainersLength() < 100},
    //         // Pour limiter les classes
    //         {rule: SyncRules.CreateGroup, resolve: () => this.getInstitutionGroupsLength() < 2000},
    //     ];
    //
    //     const institutionLicenceRule = isUserLimitedByInstitutionsLicenseRules.find(r => r.rule === rule);
    //     // On teste d'abord si la licence bride cette règle
    //     if (!!institutionLicenceRule) {
    //         return institutionLicenceRule.resolve();
    //     }
    //
    //     // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
    //     return true;
    // }

    // private isRuleAllowedByFreeLicense(rule: SyncRules): boolean {
    //     /** Un prof avec une licence gratuite ne peux pas créer, editer ou supprimer d'eleve ou de group, il est limité ce qu'il a déjà */
    //     const isUserLimitedByFreeLicenseRules = [
    //         {rule: SyncRules.CreateLearner, resolve: () => false},
    //         {rule: SyncRules.EditLearner, resolve: () => false},
    //         {rule: SyncRules.DeleteLearner, resolve: () => false},
    //         {rule: SyncRules.CreateGroup, resolve: () => false},
    //         {rule: SyncRules.EditGroup, resolve: () => false},
    //         {rule: SyncRules.DeleteGroup, resolve: () => false},
    //     ];
    //
    //     const freeLicenceRule = isUserLimitedByFreeLicenseRules.find(r => r.rule === rule);
    //     // On teste d'abord si la licence bride cette règle
    //     if (!!freeLicenceRule) {
    //         return freeLicenceRule.resolve();
    //     }
    //
    //     // Si la règle n'est pas dans les règles de licence, alors la license l'autorise
    //     return true;
    // }

    /**
     * Check for each implemented licence if the licence allow the rule.
     * the licence is checked on the current institution
     * if there are no licence, return true
     * if there are a licence but not implemented, return false
     * @private
     */
    // private isRuleAllowedByLicense(rule: SyncRules): boolean {
    //
    //     const hasClassLicense = this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Class');
    //     const hasInstitutionLicense = this.currentUserLicences.some(l => l.get('type') && l.get('type').label === 'Institution');
    //     const hasFreeLicense = (hasClassLicense || hasInstitutionLicense) === false;
    //
    //     if (hasClassLicense) {
    //         return this.isRuleAllowedByClassLicense(rule);
    //     } else if (hasInstitutionLicense) {
    //         return this.isRuleAllowedByInstitutionLicense(rule);
    //     } else if (hasFreeLicense) {
    //         return this.isRuleAllowedByFreeLicense(rule);
    //     }
    //
    //     throw new Error('Should never append');
    // }

    /**
     * Check for each implemented licence if the licence allow the rule.
     * the licence is checked on the current institution
     * if there are no licence, return true
     * if there are a licence but not implemented, return false
     * @private
     */
    // private isRuleAllowedByLicenseInInstitution(rule: SyncRules): boolean {
    //
    //     const hasClassLicense = this.userInstitutions
    //         .some((institution: InstitutionGroup) => institution.license && institution.license.type === 'Class');
    //
    //     const hasInstitutionLicense = this.userInstitutions
    //         .some((institution: InstitutionGroup) => institution.license && institution.license.type === 'Institution');
    //
    //     const hasFreeLicense = (hasClassLicense || hasInstitutionLicense) === false;
    //
    //     if (hasClassLicense) {
    //         return this.isRuleAllowedByClassLicense(rule);
    //     } else if (hasInstitutionLicense) {
    //         return this.isRuleAllowedByInstitutionLicense(rule);
    //     } else if (hasFreeLicense) {
    //         return this.isRuleAllowedByFreeLicense(rule);
    //     }
    //
    //     throw new Error('Should never append');
    // }

    // private getInstitutionAdminTrainersLength(): number {
    //     return this.userInstitutions
    //         .map(i => i.admins.length)
    //         .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0);
    // }

    // private getInstitutionNotAdminTrainersLength(): number {
    //     return this.userInstitutions
    //         .map(i => i.userscounts.trainers)
    //         .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0) - this.getInstitutionAdminTrainersLength();
    // }
    //
    // private getInstitutionLearnersLength(): number {
    //     return this.userInstitutions
    //         .map(i => i.userscounts.learners)
    //         .reduce((globalSum, institutionSum) => globalSum + institutionSum, 0) - this.getInstitutionAdminTrainersLength();
    // }
    //
    // private getUserGroupsLength(): number {
    //     return this.groupService.getGroups().length;
    // }
    //
    // private getUserLearnersLength(): number {
    //     return (this.learnerService.getLearnerList() || []).length;
    // }
    //
    // private getInstitutionGroupsLength(): number {
    //     return (this.learnerService.getLearnerList() || []).length;
    // }
}
