import {mergeMap, map, take} from 'rxjs/operators';
import {Injectable, QueryList} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    Observable,
    Subscription,
    combineLatest,
    BehaviorSubject,
    ReplaySubject,
    Subject,
} from 'rxjs';
import {
    DataCollection,
    DataEntity,
    OrderCriteria,
    OrderDirection,
    PaginatedCollection,
    OctopusConnectService,
} from 'octopus-connect';
import {
    CorpusMetadataInterface,
    CorpusRessourceInterface,
    CorpusTextInterface,
    CorpusUrlInterface,
} from '@modules/corpus';
import {endpointsByFormatName} from '@modules/corpus/core/corpus-ressources-types.class';
import {CorpusRessource} from '@modules/corpus/core/corpus-ressource.class';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {HttpClient} from '@angular/common/http';
import {ModelSchema, Structures} from 'octopus-model';
import {SwitchSettingsType} from 'shared/utils/settings';
import {defaultApiURL, modulesSettings} from '../../../settings';
import {
    CommunicationCenterService,
    EventListener,
} from '@modules/communication-center';
import {CorpusSet} from '@modules/corpus/core/corpus-set.class';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {localizedDate, localizedTime} from 'shared/utils/datetime';
import {Corpus} from '@modules/corpus/core/corpus.class';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash-es';
import {FlagService} from 'shared/flag.service';
import {ResourceCreationSteps} from '@modules/corpus/core/resource-creation-steps.class';
import {AccountManagementProviderService} from '@modules/account-management';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CorpusSearchFilterInterface} from '@modules/corpus/core/corpusSearchFilter.interface';
import {CorpusMetadataFilesInterface} from '@modules/corpus/core/corpus-interfaces.interface';
import {FileReference, FileReferenceData} from 'shared/models/file-reference';

const RawSettings = {
    accessMatrix: Structures.object(),
    addToLesson: Structures.boolean(false),
    allowErrorReporting: Structures.boolean(false),
    allowedExtensions: Structures.string(),
    allowedExtensionsByFormat: Structures.object({
        image: ['png', 'jpg', 'jpeg', 'gif'],
        video: ['mpeg', 'mp4'],
        audio: ['mp3'],
    }),
    assetFields: Structures.object({
        default: [
            'name',
            'author',
            'source',
            'description',
            'groups',
            'concepts',
            'subTheme',
            'pdf',
        ],
    }),
    assetFieldsAutoOrder: Structures.boolean(false),
    assetRequiredFields: Structures.array(),
    assetTextEditorFields: Structures.array(),
    authorRolesGeneralCorpus: Structures.array(),
    authorRolesCommunityCorpus: Structures.array(),
    cardLayout: Structures.string('card-simple-course'),
    columns: Structures.object({
        default: Structures.array([
            'checkbox',
            'icon',
            'title',
            'ownerName',
            'group',
            'changedDate',
            'buttonsAction',
        ]),
    }),
    corpusNoClone: Structures.boolean(false),
    detailsFields: Structures.array(),
    displayCreateCorpusHelper: Structures.boolean(false),
    displayEmptyCorpusHelper: Structures.boolean(false),
    displayFiltersIcons: Structures.boolean(false),
    rolesCanShowBannerInfo: Structures.array(),
    fileMaxSize: Structures.string(),
    filesWhiteList: Structures.array(),
    filterUsePosition: Structures.boolean(false),
    filters: Structures.object({
        default: Structures.array(['title', 'keywords', 'type']),
    }),
    filterFormatForced: Structures.array([]),
    formatImg: Structures.string(),
    gettingStarted: Structures.object({}),
    globalCorpus: Structures.string(),
    helpCorpusVideo: Structures.object(),
    mediaTypes: Structures.array(),
    textEditor: Structures.object(),
    shareableResourceToCommunity: Structures.boolean(),
    shareableResourceToGroups: Structures.object({default: false, trainer: true}),
    showButtonOptions: Structures.boolean(true),
    searchFields: Structures.array(['title', 'type', 'launchSearch']),
    terms: Structures.object(),
    resourceCreationStepsMax: Structures.number(4),
    uploadAllowed: Structures.object({
        localFile: true,
        url: true,
        urlAllowed: '*',
    }),
    userCorpus: Structures.boolean(false),
    displayHeader: Structures.boolean(true),
    urlVideoException: Structures.array([]),
    displayVisibility: Structures.boolean(true),
    useOnlyFirstConcept: Structures.boolean(false),
};

const settingsStructure: ModelSchema = new ModelSchema(RawSettings);

const projectSettingsStructure: ModelSchema = new ModelSchema({
    accessProject: Structures.boolean(),
});

@Injectable()
export class CorpusService {
    static projectCreationListener: EventListener;

    public dataForModalOpening: {
        displayHeader: boolean;
        selectionMode: boolean;
        isMyCorpus: boolean;
        corpusFormatsAllowed: string[];
        showCheckBox: boolean;
        callBack: any;
    } = {
        displayHeader: true,
        selectionMode: false,
        isMyCorpus: false,
        corpusFormatsAllowed: [],
        showCheckBox: true,
        callBack: null,
    };

    // TODO le corpus service transcende toute l'application, donc il ne devrait pas posséder ça qui est une donnée d'une vue.
    paginatedCollection: PaginatedCollection;
    filters: any = {};
    formats: DataEntity[];
    difficulty: DataEntity[];
    skills: DataEntity[];
    concepts: DataEntity[];
    educationalLevel: DataEntity[];
    formatsSubscription: Subscription;
    difficultySubscription: Subscription;
    skillsSubscription: Subscription;
    educationalLevelSubscription: Subscription;
    onFilesChanged: BehaviorSubject<CorpusRessource[]> = new BehaviorSubject([]);
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    private currentCorpusId: string;
    public corpus: DataEntity;
    fileRelativeUrl: string;
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    dialogRedirectMessage: string;
    url: string;

    corpusSets: CorpusSet[] = [];
    private corpusSubscription: Subscription = null;

    public settings: SwitchSettingsType<typeof RawSettings>;
    public projectSettings: { [key: string]: any };
    private downloadScriptUrl: string = defaultApiURL + 'download.php';

    metadataAreCreating = false;

    isUserCorpus = false;

    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    private corpusSelected: DataEntity[] = [];
    public corpusSelection: string;
    selectedList: CorpusRessource[] = [];

    projectCreatedListener: EventListener;
    projectDeletionListener: EventListener;

    private workGroups: any[];
    private groups: any[];
    trainers: any[];
    public currentUser: DataEntity;
    private projects: any[];
    public selectedProject: any;

    filesWhiteList: string[] = [];

    public accessMatrix: { [key: string]: { [key: string]: string[] } } = {};

    public chapters: Array<object> = [];
    public chaptersChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public tags: Array<object> = [];
    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    userCanDelete: boolean;

    public licensingMethods = new ReplaySubject<object[]>(1);
    public licensingSettings: { [key: string]: any };

    public selectAll = false;
    /**
     * Event emitter for launch refresh of corpus list from other place than component
     * better way will be to refacto corpus-file-list.component big file in services
     */
    public refreshCorpus = new Subject<boolean>();

    public lessonEditorWithStepConfig: any;

    private roleNameToIdMapping: { string: number }[];
    private allowedRolesForModelsCreation: string[] = [];

    constructor(
        private accountManagementProvider: AccountManagementProviderService,
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private dynamicMenu: DynamicNavigationService,
        private http: HttpClient,
        public dialog: MatDialog,
        private translate: TranslateService,
        public authService: AuthenticationService,
        private router: Router,
        private flagService: FlagService,
    ) {
        // HACK - TODO charger automatiquement le access token dans le octopus connect (voir avec Christophe)
        this.octopusConnect
            .authenticated('http')
            .pipe(take(1))
            .subscribe(
                (userData: DataEntity) => {
                },
                () => {
                }
            );

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.settings = settingsStructure.filterModel(modulesSettings.corpus) as SwitchSettingsType<typeof RawSettings>;

        this.projectSettings = projectSettingsStructure.filterModel(
            modulesSettings.projectsManagement
        );

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('corpusSettings')
            .next(this.settings);

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('loadFormats')
            .subscribe(() => {
                this.loadFormats();
            });

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList')
            .subscribe((groups: any[]) => {
                this.workGroups = [];
                this.workGroups.push(...groups.filter((group) => !group.archived));

                if (this.projectSettings && this.projectSettings.accessProject) {
                    this.setSelectedProject(this.selectedProject);
                }
            });

        this.communicationCenter
            .getRoom('projects-management')
            .getSubject('projectsList')
            .subscribe((projects: any[]) => (this.projects = projects));

        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .subscribe((groups: any[]) => {
                this.groups = groups.filter((group) => !group.archived);
            });

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('selectionMode')
            .subscribe((backUrl: string) => {
                this.setSelectionMode(backUrl);
            });

        if (this.projectSettings && this.projectSettings.accessProject) {
            this.communicationCenter
                .getRoom('project-management')
                .getSubject('selectedProject')
                .subscribe((project) => {
                    this.selectedProject = project;
                    this.setSelectedProject(project);
                });
        }

        this.accessMatrix = this.settings.accessMatrix;

        this.filesWhiteList = this.settings.filesWhiteList;

        this.onSelectedResourcesChanged.subscribe((resources) => {
            this.selectedList = resources;
        });

        if (CorpusService.projectCreationListener) {
            CorpusService.projectCreationListener.stopListen();
        }

        CorpusService.projectCreationListener = this.communicationCenter
            .getRoom('projects-management')
            .addListener('projectCreated', (projectId: string) => {
                this.createCorpusSet(projectId, [
                    'projects-management.research_space',
                    'projects-management.lesson_space',
                ]);
            });

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('createResourceByFile')
            .subscribe(
                (data: {
                    file: File;
                    type: string;
                    inCorpus: boolean;
                    metadataInterface: CorpusMetadataInterface;
                    callback: (CorpusRessource) => {};
                }) => {
                    this.uploadFile(data.file).subscribe((uploadResult) => {
                        this.createRessource(
                            data.type,
                            data.inCorpus ? this.settings.globalCorpus : null,
                            uploadResult.id,
                            data.metadataInterface
                        ).subscribe((corpusResource) => {
                            data.callback(corpusResource);
                        });
                    });
                }
            );

        this.communicationCenter
            .getRoom('corpus')
            .getSubject('createTextResource')
            .subscribe(
                (data: {
                    inCorpus: boolean;
                    text: string;
                    metadataInterface: CorpusMetadataInterface;
                    callback: (CorpusRessource) => {};
                }) => {
                    this.createRessource(
                        'text',
                        data.inCorpus ? this.settings.globalCorpus : null,
                        {text: data.text},
                        data.metadataInterface
                    ).subscribe((corpusResource) => {
                        data.callback(corpusResource);
                    });
                }
            );

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .subscribe((roles) => {
                this.roleNameToIdMapping = roles;
            });

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('allowedRolesForModelsCreation')
            .subscribe((data: string[]) => {
                this.allowedRolesForModelsCreation = data ? data : [];
            });
    }

    /**
     * @deprecated use MediaService.createMedia instead
     *
     * Send the file to the backend.
     *
     * @remarks
     * No frontend control is done on file format, size, etc.
     * Make sure the checks are done before calling this method.
     *
     * @param file
     *
     * @return The id and the type of the uploaded file.
     */
    private uploadFile(file: File): Observable<FileReference> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http
            .post<FileReferenceData>(defaultApiURL + 'api/file-upload', formData, {
                headers: {
                    'access-token': this.accountManagementProvider.userAccessToken,
                },
            })
            .pipe(
                map((fileReference: FileReferenceData) => fileReference.data[0][0])
            );
    }

    setSelectedProject(project = null): void {
        if (project && this.workGroups) {
            this.workGroups = this.workGroups.filter(
                (workgroup) =>
                    !workgroup.archived &&
                    workgroup.projects.find((proj) => +proj === +project.id)
            );
        } else {
            const setObject: CorpusSet = this.corpusSets.find((corpusSet) => {
                const cp: Corpus = corpusSet.corpusArray.find((corpus) => {
                    return +corpus.id === +this.corpusId;
                });

                return cp && +cp.id === +this.corpusId;
            });

            if (setObject && setObject.project) {
                if (this.projects && this.projects.length) {
                    this.selectedProject = this.projects.find(
                        (proj) => +proj.id === +setObject.project
                    );
                }
                this.workGroups = this.workGroups.filter(
                    (workgroup) =>
                        !workgroup.archived &&
                        workgroup.projects.find((proj) => +proj === +setObject.project)
                );
            }
        }
    }

    /**
     * Get Workgroups entities (except archived or not affected to the current project) or empty array
     */
    public getWorkgroups(): any[] {
        if (this.workGroups) {
            return this.workGroups.slice();
        } else {
            return [];
        }
    }

    getCurrentCorpusSet(projectId: string): CorpusSet {
        return this.corpusSets.filter(
            (corpusSet: CorpusSet) => corpusSet.project === String(projectId)
        )[0];
    }

    private postLogout(): void {
        if (this.corpusSubscription) {
            this.corpusSubscription.unsubscribe();
            this.corpusSubscription = null;
        }
    }

    private postAuthentication(): void {
        this.communicationCenter
            .getRoom('licenses')
            .getSubject('methods')
            .subscribe((methods) => {
                this.licensingMethods.next(
                    methods.filter((method) => {
                        return method.get('uid') === this.currentUser.id;
                    })
                );
            });
        this.communicationCenter
            .getRoom('licenses')
            .getSubject('settings')
            .pipe(take(1))
            .subscribe((settings) => {
                this.licensingSettings = settings;
            });

        if (this.authService.hasLevel(['manager'])) {
            this.communicationCenter
                .getRoom('groups-management')
                .getSubject('trainersList')
                .subscribe((trainers: any[]) => (this.trainers = trainers));
        }

        if (this.projectCreatedListener) {
            this.projectCreatedListener.stopListen();
            this.projectCreatedListener = null;
        }

        if (this.projectDeletionListener) {
            this.projectDeletionListener.stopListen();
            this.projectDeletionListener = null;
        }

        if (!this.corpusSubscription) {
            this.projectDeletionListener = this.communicationCenter
                .getRoom('projects-management')
                .addListener('projectDeleted', (projectId: string) => {
                    this.corpusSets
                        .filter((corpusSet: CorpusSet) => corpusSet.project === projectId)
                        .forEach((corpusSet: CorpusSet) => corpusSet.remove());
                });

            this.corpusSubscription = this.loadCorpusSets().subscribe(
                (corpusSets: CorpusSet[]) => {
                    this.corpusSets = corpusSets;
                    this.communicationCenter
                        .getRoom('corpus')
                        .next('corpusSets', corpusSets);
                    if (this.projectSettings && this.projectSettings.accessProject) {
                        this.setSelectedProject();
                    }
                }
            );
        }
    }

    public get corpusId(): string {
        return this.router.url.includes(
            'corpus/user/' + this.authService.userData.id.toString()
        )
            ? this.authService.userData.id.toString()
            : this.settings.globalCorpus.toString();
    }

    public set corpusId(id: string) {
        if (id) {
            this.currentCorpusId = id;
        } else {
            this.currentCorpusId = this.settings.globalCorpus;
        }

        this.loadCorpus(this.currentCorpusId).subscribe((corpus: DataEntity) => {
            this.corpus = corpus;
            this.userCanDelete = this.checkAccessWithoutSelectedRessource();
        });
    }

    public setSelectionMode(backUrl: string = ''): void {
        this.corpusSelection = backUrl;
    }

    public clearSelection(notify = true): void {
        this.corpusSelected = [];

        if (notify) {
            this.onSelectedResourcesChanged.next(_.clone(this.corpusSelected));
        }
    }

    public toggleResourceSelection(resource: DataEntity, keep?: boolean): void {
        const index = this.corpusSelected.findIndex(
            (element: DataEntity) => +element.id === +resource.id
        );

        if (index > -1) {
            if (!keep) {
                this.corpusSelected.splice(index, 1);
            }
        } else {
            if (keep === undefined || keep) {
                this.corpusSelected.push(resource);
            }
        }

        this.onSelectedResourcesChanged.next(_.clone(this.corpusSelected));
    }

    getFormatId(formatName: string): number {
        if (this.formats) {
            for (const format of this.formats) {
                if (format.get('label') === formatName) {
                    return +format.id;
                }
            }
        }
    }

    loadFormats(): Observable<DataEntity[]> {
        if (this.formatsSubscription) {
            this.formatsSubscription.unsubscribe();
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect
            .loadCollection('granule-format')
            .pipe(map((collection) => collection.entities));
        this.formatsSubscription = obs.subscribe(
            (entities) => (this.formats = entities)
        );
        return obs;
    }

    loadDifficulty(): Observable<DataEntity[]> {
        if (this.difficultySubscription) {
            this.difficultySubscription.unsubscribe();
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect
            .loadCollection('difficulty')
            .pipe(map((collection) => collection.entities));
        this.difficultySubscription = obs.subscribe(
            (entities) => (this.difficulty = entities)
        );
        return obs;
    }

    loadEducationalLevel(): Observable<DataEntity[]> {
        if (this.educationalLevelSubscription) {
            this.educationalLevelSubscription.unsubscribe();
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect
            .loadCollection('educational_level')
            .pipe(map((collection) => collection.entities));
        this.educationalLevelSubscription = obs.subscribe(
            (entities) => (this.educationalLevel = entities)
        );
        return obs;
    }

    loadSkills(): Observable<DataEntity[]> {
        if (this.skillsSubscription) {
            this.skillsSubscription.unsubscribe();
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect
            .loadCollection('skills')
            .pipe(map((collection) => collection.entities));
        this.skillsSubscription = obs.subscribe(
            (entities) => (this.skills = entities)
        );
        return obs;
    }

    createCorpus(corpusName: string = ''): Observable<DataEntity> {
        return this.octopusConnect
            .createEntity('corpus', {
                name: corpusName,
            })
            .pipe(take(1));
    }

    loadCorpusSets(): Observable<CorpusSet[]> {
        return this.octopusConnect.loadCollection('corpus-set').pipe(
            map((collection) => {
                return collection.entities.map(
                    (entity) => new CorpusSet(this.dynamicMenu, this, entity)
                );
            })
        );
    }

    createCorpusSet(project: string, spaces: string[]): void {
        const corpusList: Observable<DataEntity>[] = [];

        spaces.forEach((space) => {
            corpusList.push(this.createCorpus(space));
        });

        combineLatest(...corpusList)
            .pipe(take(1))
            .subscribe((entities: DataEntity[]) => {
                // TODO voir avec christophe pour trigger un complete sur les observables de création ?
                // le problème de duplication est  là !

                this.octopusConnect.createEntity('corpus-set', {
                    'corpus-list': entities.map((entity) => +entity.id),
                    project: project,
                });
            });
    }

    loadCorpus(corpusId: string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('corpus', corpusId).pipe(take(1));
    }

    loadUserCorpusCollection(): Observable<DataEntity[]> {
        return this.octopusConnect
            .loadCollection('corpus')
            .pipe(map((collection) => collection.entities));
    }

    /**
     * Load resources
     * @param corpusId
     * @param range
     * @param filter
     * @param sort
     * @param origin
     *
     * @deprecated La fonction est devenue trop lourde à force de la rendre trop intelligente. la déprécier pour en etre de moins en moins dependant est plus simple qu'un refacto.
     * Elle ne devrait pas avoir à calculer le contexte dans lequel on l'appelle (comme tester l'url).
     * Si vous avez besoin d'appeler cette méthode elle qu'elle fonctionne tel quel, par de soucis utilisez là.
     * Si vous avez besoin de la modifier pour quelle fonctionne à un endroit en particulier alors il est temps de créer une nouvelle méthode.
     * l'idée est que oui, on peut utiliser une méthode qui mutualiser les appels au back pour chercher les ressources,
     * mais chaque test permettant de savoir si je dois écraser un filtre donné en argument de fonction est une erreur de conception.
     * Ce test doit être fait en amont, avant d'arriver ici. Voir les méthodes appelant {@link loadCorpusSearchResourcesGeneric}
     */
    loadCorpusRessources(
        corpusId = this.corpusId,
        range?,
        filter?,
        sort?,
        origin?,
        urlExtension?
    ): Observable<CorpusRessource[]> {
        // default range if not defined
        if (range === undefined) {
            range = 10;
        }

        let filters = {};

        if (origin !== 'widget') {
            filters = this.filters;
        }

        if (this.settings.corpusNoClone) {
            filters['corpusNoClone'] = true;
        }

        if (filter && filter.value) {
            filters[filter.label] = filter.value;
        }

        if (filters['title']) {
            urlExtension = filters['title'];
        }

        if (!corpusId) {
            filters['parent'] = this.settings.globalCorpus;
        } else {
            filters['parent'] = corpusId;
            // TODO find a better way to distinct corpus
            if (this.router.url.indexOf('/projects/') > -1) {
                // check if we are inside a project
                // console.log('project corpus');
                this.isUserCorpus = false;
            } else {
                // TODO ce n'est pas au service de savoir comment réagir en fonction de où on l'appelle.
                // c'est à celui qui lance l'appel de dire comment gérer.
                if (
                    (this.currentUser &&
                        this.router.url.indexOf('/user/' + this.currentUser.id) > -1) ||
                    this.dataForModalOpening.isMyCorpus ||
                    (this.lessonEditorWithStepConfig &&
                        this.lessonEditorWithStepConfig.userCorpus)
                ) {
                    // check if we are in user corpus in modal opening and in classic mode
                    this.isUserCorpus = true;
                    filters['author'] = this.currentUser.id;
                    filters['parent'] = this.currentUser.id;
                } else {
                    // general corpus
                    this.isUserCorpus = false;
                    filters['role'] = this.settings.authorRolesGeneralCorpus.slice(); // limit to some roles for general corpus

                    // if we are in community resource page
                    if (this.router.url.includes('community')) {
                        filters['shared'] = 1;
                        filters['role'] = this.settings.authorRolesCommunityCorpus.slice(); // limit to some roles for general corpus
                        delete filters['parent'];
                    }
                }
            }
            // corpus in modal case lamorim only allowed image in create lesson
            if (
                !!this.dataForModalOpening.corpusFormatsAllowed &&
                this.dataForModalOpening.corpusFormatsAllowed.length !== 0
            ) {
                filters['format'] =
                    this.dataForModalOpening.corpusFormatsAllowed.join(',');
            }
        }

        const options = {range: range, filter: _.clone(filters)};
        if (sort) {
            options['orderOptions'] = sort;
        }
        if (urlExtension) {
            options['urlExtension'] = urlExtension; // replace title filter by urlExtension to use global search on backoffice selected fields
            delete options.filter['title'];
        }

        this.paginatedCollection = this.octopusConnect.paginatedLoadCollection(
            'corpus_search',
            options
        );

        return this.paginatedCollection.collectionObservable.pipe(
            map((collection) =>
                collection.entities.map((entity) => new CorpusRessource(entity, this))
            )
        );
    }

    /**
     * Return the default corpus id(s) accessible to the user.
     * Should be the global corpus and the user corpus (if user is logged)
     * @private
     */
    private getDefaultCorpusId(): string[] {
        const corpusIds = [this.settings.globalCorpus];

        if (!!this.currentUser) {
            corpusIds.push(this.currentUser.id.toString());
        }

        return corpusIds;
    }

    /**
     * Get the last resources of the default(s) corpus by calling the generic resources loading but with the criteria 'created';
     * @param filters
     * @param sort
     */
    public loadLastCreatedCorpusResources(
        filters: Partial<CorpusSearchFilterInterface> = {},
        sort?: OrderCriteria[]
    ): Observable<CorpusRessource[]> {
        const defaultFilters: Partial<CorpusSearchFilterInterface> = {
            parent: this.getDefaultCorpusId(),
        };

        const defaultSortCriteria: OrderCriteria[] = [
            {field: 'created', direction: OrderDirection.DESC},
        ];

        return this.loadCorpusSearchResourcesGeneric(
            _.merge({}, defaultFilters, filters),
            _.merge([], defaultSortCriteria, sort)
        );
    }

    /**
     * Get the favorites resources of the default(s) corpus by calling the generic resources loaded but with the filter 'bookmarks';
     * @param filters
     * @param sort
     */
    public loadFavoritesCorpusResources(
        filters: Partial<CorpusSearchFilterInterface> = {},
        sort?: OrderCriteria[]
    ): Observable<CorpusRessource[]> {
        const defaultFilters: Partial<CorpusSearchFilterInterface> = {
            parent: this.getDefaultCorpusId(),
            bookmarks: true,
        };

        return this.loadCorpusSearchResourcesGeneric(
            _.merge({}, defaultFilters, filters),
            sort
        );
    }

    /**
     * Default method to load a resource from Corpus_Search endpoint. Keep it simple.
     * We should know every filters whatever the context.
     * The only intelligence to have is to change the filter format (like role string to role number) and the default values if not set.
     * The default values are the parent (globalCorpus) and the range (10)
     * @param filters If a filter is not implemented, add it to {@link CorpusSearchFilterInterface} before implement it here
     * @param sort
     */
    public loadCorpusSearchResourcesGeneric(
        filters: Partial<CorpusSearchFilterInterface> = {},
        sort?: OrderCriteria[]
    ): Observable<CorpusRessource[]> {
        const options: CollectionOptionsInterface = {filter: {}};

        // Valeurs par défaut si non définies
        options.filter.parent = _.get(filters, 'parent', [
            this.settings.globalCorpus,
        ]).join(',');
        options.range = _.get(filters, 'range', 10);

        if (!!sort) {
            options.orderOptions = sort;
        }

        if (filters.hasOwnProperty('author')) {
            options.filter.author = filters.author;
        }

        if (filters.hasOwnProperty('bookmarks')) {
            options.filter.bookmarks = filters.bookmarks;
        }

        if (filters.hasOwnProperty('corpusNoClone')) {
            options.filter.corpusNoClone = filters.corpusNoClone;
        }

        if (filters.hasOwnProperty('created')) {
            options.filter.created = filters.created;
        }

        if (filters.hasOwnProperty('format')) {
            throw Error('not implemented');
            // Todo change the format name by the format id;
            // options.filter.format = filters.format.join(',');
        }

        if (filters.hasOwnProperty('role')) {
            throw Error('not implemented');
            // Todo change the role name by the role id;
            // options.filter.role = filters.role.join(',');
        }
        if (filters.hasOwnProperty('shared')) {
            options.filter.shared = filters.shared ? 1 : 0; // je comprends pas pourquoi il faut parfois passer 1, parfois true;
        }

        if (filters.hasOwnProperty('title')) {
            options.urlExtension = filters.title; //
            delete options.filter['title'];// replace title filter by urlExtension to use global search on backoffice selected fields
        }

        if (this.settings.corpusNoClone) {
            options.filter.corpusNoClone = true;
        }

        this.paginatedCollection = this.octopusConnect.paginatedLoadCollection('corpus_search', options);

        return this.paginatedCollection
            .collectionObservable.pipe(
                map((collection) =>
                    collection.entities.map((entity) => new CorpusRessource(entity, this))
                )
            );
    }

    /**
     * Loads the 10 last consulted resources by the user
     * @returns Array of CorpusRessource resources ordered by last consulted
     */
    public loadConsultedResources(): Observable<CorpusRessource[]> {
        return this.octopusConnect.loadCollection('user-dashboard').pipe(
            map((collection: DataCollection) => {
                return collection.entities.flatMap((entity: DataEntity) => {
                    return entity.get('corpusWidget').map((resource) => {
                        const entity = new DataEntity(
                            'granule',
                            resource,
                            this.octopusConnect,
                            resource.id,
                            {
                                format: 'granule-format',
                                metadatas: 'metadatas',
                                reference: 'reference',
                            }
                        );
                        return new CorpusRessource(entity, this);
                    });
                });
            })
        );
    }

    loadCorpusRessourcesList(
        resourcesIds: number[]
    ): Observable<CorpusRessource[]> {
        return combineLatest(
            ...resourcesIds.map((id) => this.loadCorpusRessourceUnit(id))
        ).pipe(take(1));
    }

    loadCorpusRessourceUnit(resourceId: number): Observable<CorpusRessource> {
        return this.octopusConnect
            .loadEntity('granule', resourceId)
            .pipe(map((entity) => new CorpusRessource(entity, this)));
    }

    createTextEntity(textData: CorpusTextInterface): Observable<DataEntity> {
        return this.octopusConnect
            .createEntity('corpus-text-ressource', textData)
            .pipe(take(1));
    }

    createUrlEntity(urlData: CorpusUrlInterface): Observable<DataEntity> {
        return this.octopusConnect
            .createEntity('corpus-url-ressource', urlData)
            .pipe(take(1));
    }

    createRessourceMetadata(
        metadata: CorpusMetadataInterface
    ): Observable<DataEntity> {
        return this.octopusConnect
            .createEntity('metadatas', metadata)
            .pipe(take(1));
    }

    createCorpusRessource(
        ressourceData: CorpusRessourceInterface
    ): Observable<DataEntity> {
        const obs: Observable<DataEntity> = this.octopusConnect
            .createEntity('granule', ressourceData)
            .pipe(take(1));

        // Attention !
        // Spécifique aux savanturiers
        // et inutilement compliqué
        obs.subscribe((entity) => {
            let corpusIndex: number;

            // attention, peut provoquer des erreur si on n'est pas sur la structure savanturiers

            const setObject: CorpusSet = this.corpusSets.find((corpusSet) => {
                const cp: Corpus = corpusSet.corpusArray.find(
                    (corpus, index: number) => {
                        corpusIndex = index;
                        return +corpus.id === +this.corpusId;
                    }
                );

                return cp && +cp.id === +this.corpusId;
            });

            if (setObject && setObject.project) {
                const projectId: number = +setObject.project;

                const project = this.projects.find((elem) => elem.id === projectId);

                let usersIds: number[] = [];

                project.groupsIds.forEach((id) => {
                    const cg: any = this.groups.find((group) => group.id === id);
                    if (cg) {
                        usersIds.push(...cg.learnersIds);
                    }

                    const wg: any = this.workGroups.find((group) => group.id === id);
                    if (wg) {
                        usersIds.push(...wg.learnersIds);
                    }
                });

                usersIds = usersIds.filter((el, i, a) => i === a.indexOf(el));

                const data = {
                    author: this.currentUser ? this.currentUser.get('label') : '',
                    projectName: project.name,
                    corpusId: this.corpusId,
                    granuleId: entity.id,
                    projectId: projectId,
                };

                usersIds.forEach((id) => {
                    this.communicationCenter
                        .getRoom('notifications')
                        .next('sendNotification', {
                            recipient: id,
                            type:
                                corpusIndex === 0
                                    ? 'NEW_RESSOURCE_IN_RESEARCH_CORPUS'
                                    : 'NEW_RESSOURCE_IN_COURSE_CORPUS',
                            content: data,
                        });
                });
            }

            if (
                this.corpusId === this.settings.globalCorpus &&
                this.authService.hasLevel(['manager', 'administrator']) &&
                this.trainers
            ) {
                const usersIds = this.trainers.map((trainer) => trainer.id);

                const data = {
                    author: this.currentUser ? this.currentUser.get('label') : '',
                    corpusId: this.corpusId,
                    granuleId: entity.id,
                };

                this.communicationCenter
                    .getRoom('notifications')
                    .next('sendNotification', {
                        recipient: usersIds,
                        type: 'NEW_RESSOURCE_IN_GLOBAL_CORPUS',
                        content: data,
                    });
            }

            this.loadCorpusSets();
            this.loadCorpusRessources();
        });

        return obs;
    }

    // TODO rename createRessource by createResource and REMOVE PROP corpusId because it is unused
    createRessource(
        type: string,
        corpusId: string,
        data: CorpusTextInterface | CorpusUrlInterface | any,
        metadata: CorpusMetadataInterface,
        forCopy = false
    ): Observable<CorpusRessource> {
        let groups = [];

        // TODO : vérifier utilité de la donnée de groupe et gérer l'exception de manière générique
        if (this.authService.accessLevel === 'trainer' && type !== 'divider') {
            groups = metadata.groups ? metadata.groups.slice() : [];
            groups = groups.map((group) => group['id']);
        } else {
            groups = this.currentUser.get('groups');
        }
        delete metadata.groups;

        return this.createTags(metadata.indexation, forCopy).pipe(
            mergeMap((tags: DataEntity[]) => {
                const originalTags = metadata.indexation;
                metadata.indexation = tags.map((tag) => +tag.id);

                const metadataObs: Observable<DataEntity> =
                    this.createRessourceMetadata(metadata);

                metadata.indexation = originalTags;

                let dataObs: Observable<DataEntity>;

                switch (type) {
                    case 'text':
                        dataObs = this.createTextEntity(<CorpusTextInterface>data);
                        break;

                    case 'url':
                    case 'videoUrl':
                        dataObs = this.createUrlEntity(<CorpusUrlInterface>data);
                        break;
                }

                if (dataObs) {
                    const combined: Observable<DataEntity[]> = combineLatest(
                        metadataObs,
                        dataObs
                    ).pipe(take(1));
                    return combined.pipe(
                        mergeMap((entities: DataEntity[]) => {
                            return this.createCorpusRessource({
                                parent: this.corpusId,
                                format: this.getFormatId(type),
                                reference: +entities[1].id,
                                metadatas: +entities[0].id,
                                groups: groups,
                            });
                        }),
                        map((entity) => new CorpusRessource(entity, this))
                    );
                } else {
                    if (this.corpusId && type !== 'divider') {
                        return metadataObs.pipe(
                            mergeMap((entity: DataEntity) => {
                                return this.createCorpusRessource({
                                    parent: this.corpusId,
                                    format: this.getFormatId(type),
                                    reference: +data,
                                    metadatas: +entity.id,
                                    groups: groups,
                                });
                            }),
                            map((entity) => new CorpusRessource(entity, this))
                        );
                    } else {
                        return metadataObs.pipe(
                            mergeMap((entity: DataEntity) => {
                                return this.createCorpusRessource({
                                    format: this.getFormatId(type),
                                    reference: data, // data can be null if no uploaded media
                                    metadatas: +entity.id,
                                });
                            }),
                            map((entity) => new CorpusRessource(entity, this))
                        );
                    }
                }
            })
        );
    }

    getResourceReferenceEntity(resourceEntity): DataEntity {
        if (resourceEntity !== null) {
            const format: string = (<DataEntity>(
                resourceEntity.getEmbed('format')
            )).get('label');
            const endpointName: string = endpointsByFormatName[format];

            const data: Object = resourceEntity.get('reference');

            if (data !== null) {
                return new DataEntity(
                    endpointName,
                    data,
                    this.octopusConnect,
                    data['id']
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    openDialog(entity: any, checkBoxesList: QueryList<MatCheckbox> = null): void {
        // get translation
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (this.dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (this.dialogCancel = translation));
        this.translate
            .get('generic.delete')
            .subscribe((translation: string) => (this.dialogTitle = translation));

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };

        const checkboxes = document.getElementsByName('corpusCheckboxe');
        const checkboxesChecked = [];
        // loop over them all
        for (this.i = 0; this.i < checkboxes.length; this.i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[this.i]['checked']) {
                checkboxesChecked.push(checkboxes[this.i].id.replace('-input', ''));
            }
        }

        // Return the array if it is non-empty, or null
        // return checkboxesChecked.length > 0 ? checkboxesChecked : null;

        if (entity !== 'multiple') {
            // for 1 entity
            if (entity.locked) {
                // statut locked===true => delete not allowed => defensive programming if delete button exist
                return;
            }

            this.translate
                .get('generic.confim_delete_single_file')
                .subscribe(
                    (translation: string) => (this.dialogDeleteMessage = translation)
                );
            dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
            dialogConfig.data.labelTrueDialog = this.dialogYes;
            dialogConfig.data.labelFalseDialog = this.dialogCancel;

            const dialogRef = this.dialog.open(
                FuseConfirmDialogComponent,
                dialogConfig
            );

            dialogRef.afterClosed().subscribe((result) => {
                if (result === true) {
                    this.toggleResourceSelection(entity, null);
                    const entityTemp = new DataEntity(
                        'granule',
                        entity.ressourceEntity.attributes,
                        this.octopusConnect,
                        entity.id
                    );
                    entityTemp.remove().subscribe((res) => {
                        // refresh corpus list in corpus-file-list-component
                        this.refreshCorpus.next(true);
                    });
                }
            });
        } else {
            // for 1 or multiple entities
            if (checkboxesChecked.length > 0) {
                this.translate
                    .get('generic.confim_delete_multiple_files')
                    .subscribe(
                        (translation: string) => (this.dialogDeleteMessage = translation)
                    );
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                dialogConfig.data.labelTrueDialog = this.dialogYes;
                dialogConfig.data.labelFalseDialog = this.dialogCancel;

                const dialogRef = this.dialog.open(
                    FuseConfirmDialogComponent,
                    dialogConfig
                );

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        const removeObs: Observable<boolean>[] = [];

                        this.corpusSelected.forEach((resource: DataEntity) => {
                            resource.type = 'granule';
                            removeObs.push(resource.remove());
                        });

                        combineLatest(removeObs).subscribe((removals: boolean[]) => {
                            this.paginatedCollection.paginator.reload();
                            this.corpusSelected = [];
                            this.onSelectedResourcesChanged.next(this.corpusSelected);
                        });
                    }
                });
            } else {
                // no checked checkbox
                this.translate
                    .get('generic.confim_action_no_file')
                    .subscribe(
                        (translation: string) => (this.dialogDeleteMessage = translation)
                    );
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                // todo: useless code ?
                this.dialog.open(
                    FuseConfirmDialogComponent,
                    dialogConfig
                );
            }
        }
    }

    openFile(file: CorpusMetadataFilesInterface): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: file.filename,
        };

        dialogConfig.panelClass = 'resource-modal';

        // get translation
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (this.dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (this.dialogCancel = translation));
        this.translate
            .get('corpus.dialog_redirect_message')
            .subscribe(
                (translation: string) => (this.dialogRedirectMessage = translation)
            );

        // open file as link if attached to media
        this.url = file.uri;
        if (!/^(f|ht)tps?:\/\//i.test(this.url)) {
            this.url = 'http://' + this.url;
        }
        dialogConfig.data.bodyDialog =
            '<p>' +
            this.dialogRedirectMessage +
            ' <br></p><p><a href="' +
            this.url +
            '" title="' +
            file.uri +
            '" rel="nofollow noreferrer noopener" target="_blank">' +
            file.filename +
            '</a><br><br></p>';
        dialogConfig.data.labelTrueDialog = this.dialogYes;
        dialogConfig.data.labelFalseDialog = this.dialogCancel;

        if (this.settings.allowErrorReporting) {
            dialogConfig.data.showReportbutton = true;
            dialogConfig.data.resourceId = file.id;
        }

        const dialogRef = this.dialog.open(
            FuseConfirmDialogComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.url = file.uri;
                window.open(this.url, '_blank');
            }
        });
    }

    openRessource(entity: any): void {
        let flaggingId;

        if (typeof entity.ressourceEntity.get('consulted') === 'object') {
            flaggingId = entity.ressourceEntity.get('consulted').flagging_id;
        }

        this.flagService.updateFlagEntity(
            entity.ressourceEntity,
            'node',
            'consulted',
            flaggingId
        );

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: entity.metadatasEntity.get('title'),
        };

        dialogConfig.minHeight = '400px';
        dialogConfig.minWidth = '800px';
        dialogConfig.panelClass = 'resource-modal';

        // get translation
        this.translate
            .get('generic.yes')
            .subscribe((translation: string) => (this.dialogYes = translation));
        this.translate
            .get('generic.cancel')
            .subscribe((translation: string) => (this.dialogCancel = translation));
        this.translate
            .get('corpus.dialog_redirect_message')
            .subscribe(
                (translation: string) => (this.dialogRedirectMessage = translation)
            );

        switch (entity.format) {
            case 'divider':
            case 'media':
            case 'audio':
            case 'video':
            case 'document':
            case 'image':
                let dividerDescription = '';
                if (entity.format === 'divider') {
                    dividerDescription =
                        '<p>' + entity.metadatasEntity.get('description') + '</p>';
                }

                switch (entity.referenceEntity.get('type')) {
                    case 'image':
                        dialogConfig.data.bodyDialog =
                            dividerDescription +
                            '<img id="imagePlayer" #imagePlayer src="' + entity.ressourceEntity.attributes.reference.uri + '" ' +
                            'oncontextmenu="return false" alt="">';
                        break;

                    case 'video':
                        let poster = '';

                        // use poster if thumbnail exists
                        if (entity.ressourceEntity.get('metadatas').thumbnail) {
                            poster =
                                ' poster="' +
                                entity.ressourceEntity.get('metadatas').thumbnail.uri +
                                '" preload="none"';
                        }

                        const video = entity.ressourceEntity.attributes.reference;
                        const subtitles = [];
                        if (video?.subtitles) {
                            subtitles.push(...Object.keys(video.subtitles).map(lang => {
                                return {
                                    lang,
                                    url: video.subtitles[lang], // this.sanitizer.bypassSecurityTrustResourceUrl(video.subtitles[lang]),
                                    label: 'video.subtitles_label_' + lang,
                                };
                            }));

                            subtitles.forEach(subtitle =>
                                this.translate.get(subtitle.label).subscribe(translatedLabel => subtitle.label = translatedLabel)
                            );
                        }
                        dialogConfig.data.bodyDialog =
                            dividerDescription +
                            '<video id="videoPlayer" #videoPlayer crossorigin="anonymous" oncontextmenu="return false" controlsList="nodownload"  controls ' +
                            poster +
                            '>' +
                            '<source src="' +
                            video.uri +
                            '" type="' +
                            video.filemime +
                            '">' +
                            'Your browser does not support HTML5 video.'
                            + subtitles.map((subtitle) =>
                                `<track label="${subtitle.label}" src="${subtitle.url}" srclang="${subtitle.lang}" kind="subtitles">`
                            ).join()
                            + '</video>';
                        break;

                    case 'audio':
                        dialogConfig.data.bodyDialog =
                            dividerDescription +
                            '<audio id="audioPlayer" #audioPlayer controlsList="nodownload" oncontextmenu="return false" controls>' +
                            '<source src="' +
                            entity.ressourceEntity.attributes.reference.uri +
                            '" type="' +
                            entity.ressourceEntity.attributes.reference.filemime +
                            '">' +
                            'Your browser does not support the audio tag.' +
                            '</audio>';
                        break;

                    case 'document':
                        this.url = entity.ressourceEntity.attributes.reference.uri;
                        dialogConfig.data.bodyDialog =
                            dividerDescription +
                            '<p>' +
                            this.dialogRedirectMessage +
                            ' <br></p><p><a  id="documentLink" #documentLink href="' +
                            this.url +
                            '" title="' +
                            this.url +
                            '" rel="nofollow noreferrer noopener" target="_blank">' +
                            this.url +
                            '</a><br><br></p>';
                        dialogConfig.data.labelTrueDialog = '';
                        dialogConfig.data.labelFalseDialog = '';
                        break;
                }
                break;
            case 'url':
            case 'videoUrl':
                switch (entity.ressourceType) {
                    case 'video':
                        dialogConfig.data.bodyDialog =
                            '<div class="videoWrapper">' +
                            '<iframe id="iframeVideoPlayer" #iframeVideoPlayer width="560" height="315" src="' +
                            entity.videoEmbedUrl +
                            '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                            '</div>';
                        break;
                    case 'link':
                        this.url = entity.ressourceEntity.attributes.reference.url;
                        if (!/^(f|ht)tps?:\/\//i.test(this.url)) {
                            this.url = 'http://' + this.url;
                        }
                        dialogConfig.data.bodyDialog =
                            '<p>' +
                            this.dialogRedirectMessage +
                            ' <br></p><p><a id="urlLink" #urlLink href="' +
                            this.url +
                            '" title="' +
                            entity.ressourceEntity.attributes.reference.url +
                            '" rel="nofollow noreferrer noopener" target="_blank">' +
                            entity.ressourceEntity.attributes.reference.url +
                            '</a><br><br></p>';
                        dialogConfig.data.labelTrueDialog = '';
                        dialogConfig.data.labelFalseDialog = '';
                        break;
                }
                break;
        }

        if (this.settings.allowErrorReporting) {
            dialogConfig.data.showReportbutton = true;
            dialogConfig.data.resourceId = entity.id;
        }

        const dialogRef = this.dialog.open(
            FuseConfirmDialogComponent,
            dialogConfig
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                if (
                    entity.format === 'url' ||
                    (entity.format === 'media' &&
                        entity.referenceEntity.get('type') === 'document')
                ) {
                    if (entity.format === 'url') {
                        this.url = entity.ressourceEntity.attributes.reference.url;
                    }
                    if (
                        entity.format === 'media' &&
                        entity.referenceEntity.get('type') === 'document'
                    ) {
                        this.url = entity.ressourceEntity.attributes.reference.uri;
                    }
                    window.open(this.url, '_blank');
                }
            }
        });
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date: number): string {
        return localizedTime(date);
    }

    downloadFile(entity: any): void {
        this.fileRelativeUrl =
            entity.ressourceEntity.attributes.reference.uri.replace(
                defaultApiURL,
                ''
            );
        window.open(this.downloadScriptUrl + '?file=' + this.fileRelativeUrl);
    }

    checkAccessLevel(selected: CorpusRessource, action: string): boolean {
        if (this.corpus) {
            let roles: string[] = [];

            if (this.accessMatrix['global']) {
                if (this.accessMatrix['global'][action]) {
                    roles.push(...this.accessMatrix['global'][action]);
                }

                if (this.isUserCorpus) {
                    roles = [];
                    if (this.accessMatrix['userCorpus'][action]) {
                        roles.push(...this.accessMatrix['userCorpus'][action]);
                    }
                }

                if (
                    selected &&
                    this.authService.isMe(selected.ressourceEntity.get('owner')) &&
                    this.accessMatrix['global'][`${action}Own`]
                ) {
                    roles.push(...this.accessMatrix['global'][`${action}Own`]);
                }

                return this.authService.hasLevel(roles);
            } else {
                // TODO need to know if this.corpus.get('name') can be !== 'CorpusGlobal'
                const corpusName = this.corpus.get('name');
                for (const spaceName in this.accessMatrix) {
                    if (corpusName.indexOf(spaceName) > -1) {
                        if (this.accessMatrix[spaceName][action]) {
                            roles.push(...this.accessMatrix[spaceName][action]);
                        }

                        if (
                            selected &&
                            selected.ressourceEntity &&
                            this.authService.isMe(selected.ressourceEntity.get('owner')) &&
                            this.accessMatrix[spaceName][`${action}Own`]
                        ) {
                            roles.push(...this.accessMatrix[spaceName][`${action}Own`]);
                        }

                        return this.authService.hasLevel(roles);
                    }
                }
            }
        }

        return false;
    }

    public get gettingStarted(): string {
        if (this.corpus) {
            const corpusName = this.corpus.get('name');

            for (const spaceName in this.settings.gettingStarted) {
                if (
                    this.settings.gettingStarted.hasOwnProperty(spaceName) &&
                    corpusName.indexOf(spaceName) > -1
                ) {
                    return this.settings.gettingStarted[spaceName];
                }
            }
        }

        return '';
    }

    checkAccessWithoutSelectedRessource(): boolean {
        if (this.corpus) {
            const roles: string[] = [];
            if (this.corpusId === this.settings.globalCorpus) {
                if (this.accessMatrix['global']) {
                    if (this.accessMatrix['global']['delete']) {
                        roles.push(...this.accessMatrix['global']['delete']);
                    }
                    return this.authService.hasLevel(roles);
                }
            }

            const corpusName = this.corpus.get('name');

            for (const spaceName in this.accessMatrix) {
                if (corpusName.indexOf(spaceName) > -1) {
                    if (this.accessMatrix[spaceName]['delete']) {
                        roles.push(...this.accessMatrix[spaceName]['delete']);
                    }

                    return this.authService.hasLevel(roles);
                }
            }
        }

        return false;
    }

    /**   Chapter load   **/

    getMethods() {
        return this.octopusConnect.loadCollection('chapters', {parent: 'null'});
    }

    /**
     * Obtain Concepts from the server.
     * @return List of {@link DataEntity}
     */
    getConcepts() {
        return this.octopusConnect.loadCollection('concepts');
    }

    getDifficulty() {
        return this.octopusConnect.loadCollection('difficulty');
    }

    getLevels() {
        return this.octopusConnect.loadCollection('educational_level');
    }

    /**
     * Obtain Skills from the server. ( or history period )
     * @return List of {@link DataEntity}
     */
    getSkills(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('skills');
    }

    /**
     * Load chapters inside [chapters fields]{@link CorpusService#chapters}.
     * Loaded chapters are not {@link DataEntity}.
     * @param methodId Parent method identifier for load the children chapters only.
     */
    public loadChapters(methodId): void {
        this.getChapters(methodId).subscribe((data) => {
            this.chapters = [];
            for (const entity of data.entities) {
                this.chapters.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                    parent: entity.get('parent')[0],
                });
            }
            this.chaptersChanged.next(this.chapters);
        });
    }

    /**
     * Obtain chapters from the server.
     * @return List of {@link DataEntity}
     * @param methodId Parent method identifier for load the children chapters only.
     */
    public getChapters(methodId): Observable<DataCollection> {
        return this.octopusConnect
            .loadCollection('chapters', {parent: methodId})
            .pipe(take(1));
    }

    /*******    Tags    *******/
    getTags(type): void {
        this.octopusConnect.loadCollection(type).subscribe((data) => {
            this.tags = [];
            for (const entity of data.entities) {
                this.tags.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                });
            }
            this.tagsChanged.next(this.tags);
        });
    }

    public createTags(
        tags: any[],
        forCopy: boolean = false
    ): Observable<DataEntity[]> {
        if (tags && tags.length > 0) {
            // if indexation keywords
            let tagObsArray: Observable<DataEntity>[] = [];
            let tagObs;

            tags.forEach((chip) => {
                if (!chip.id || forCopy) {
                    // if tag not in DB
                    tagObs = this.octopusConnect.createEntity('tags', chip).pipe(take(1));
                    tagObs.subscribe((data: DataEntity) => {
                        // create tag
                        chip.id = data.id.toString(); // add id to object
                    });
                    tagObsArray.push(tagObs);
                }
            });

            if (tagObsArray.length !== 0) {
                return combineLatest(tagObsArray);
            } else {
                const placeholder = new ReplaySubject<DataEntity[]>(1);
                placeholder.next([]);
                return placeholder;
            }
        } else {
            const placeholder = new ReplaySubject<DataEntity[]>(1);
            placeholder.next([]);
            return placeholder;
        }
    }

    public goBackToForm(): void {
        // todo brancher nouvel editeur
    }

    public print(selected): void {
        const W = window.open(selected.referenceEntity.get('uriWatermarked'));
        W.window.print();
    }

    public get corpusTerms(): any {
        if (this.corpusId === this.settings.globalCorpus) {
            return this.settings.terms.globalCorpus;
        }

        const corpus = this.selectedProject
            ? this.selectedProject.corpus.find((item) => +item.id === +this.corpusId)
            : null;
        if (corpus && corpus.name === 'projects-management.research_space') {
            return this.settings.terms.research;
        }
        if (corpus && corpus.name === 'projects-management.lesson_space') {
            return this.settings.terms.lesson;
        }

        return null;
    }

    /**
     * Obtient `true` si la modal est affichable. La modal est affichable si au moins un champs est à afficher
     * @param step
     */
    public currentStepIsAllowed(step: ResourceCreationSteps): boolean {
        switch (step) {
            case ResourceCreationSteps.STEP1:
                return true;
            case ResourceCreationSteps.STEP2:
                return true; // TODO Si ce cas se présente, tester tout les champs de cette etape pour voir si au moins un champs est dispo
            case ResourceCreationSteps.STEP3:
                return this.displayField('chapters');
            case ResourceCreationSteps.STEP4:
                return this.displayField('concepts');
            default:
                return true;
        }
    }

    private displayField(name: string): boolean {
        const role = this.authService.accessLevel;
        let assetField = this.settings.assetFields[role];
        if (assetField === undefined) {
            assetField = this.settings.assetFields['default'];
        }
        return assetField.indexOf(name) > -1;
    }

    /**
     * check if url is for only pdf type document
     */
    public isDocumentTypePdf(): boolean {
        return this.router.url.indexOf('/corpus/pdf/') > -1;
    }

    /**
     * reset data by default value
     */
    public resetDataForModalOpening(): void {
        this.dataForModalOpening = {
            displayHeader: true,
            selectionMode: false,
            isMyCorpus: false,
            corpusFormatsAllowed: [],
            showCheckBox: true,
            callBack: null,
        };
    }

    /**
     * edit and save granule
     * @param resource
     * @param values
     */
    public editGranule(resource, values): void {
        const granule = new DataEntity(
            'granule',
            resource.attributes,
            this.octopusConnect,
            resource.id
        );
        for (const field in values) {
            granule.set(field, values[field]);
        }
        granule.save();
    }

    /**
     * Return array of Id. Each id represent the role ID defined by ther server
     *
     * @example `[4, 3]` for manager and administrator (if manager id is 4 and administrator id is 3)
     */
    public getAllowedRoleIdsForModelsCreation(): number[] {
        return this.allowedRolesForModelsCreation.map(
            (role) => this.roleNameToIdMapping[role]
        );
    }

    /**
     * current route is or not community corpus
     */
    public isCommunityCorpus(): boolean {
        return this.router.url.includes('corpus/community');
    }

    public getUseOnlyFirstConcept(): boolean {
        return this.settings?.useOnlyFirstConcept;
    }
}
