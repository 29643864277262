// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-accordion mat-expansion-panel {
  box-shadow: initial;
}
mat-accordion mat-expansion-panel mat-expansion-panel-header, mat-accordion mat-expansion-panel mat-expansion-panel-header:hover, mat-accordion mat-expansion-panel mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #999da4;
}

::ng-deep .mat-expansion-indicator:after {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/basic-page/basic-page/basic-page.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/@fuse/components/basic-page/basic-page/basic-page.component.scss"],"names":[],"mappings":"AAGI;EACI,mBAAA;ACFR;ADIQ;EACI,mBAAA;ACFZ;;ADOA;EACI,YAAA;ACJJ","sourcesContent":["@import '@fuse/scss/fuse';\n\nmat-accordion {\n    mat-expansion-panel {\n        box-shadow: initial;\n\n        mat-expansion-panel-header, mat-expansion-panel-header:hover, mat-expansion-panel-header:not([aria-disabled='true']):hover {\n            background: map_get($mat-fusedark, 200);\n        }\n    }\n}\n\n::ng-deep .mat-expansion-indicator:after{\n    color:white;\n}\n","mat-accordion mat-expansion-panel {\n  box-shadow: initial;\n}\nmat-accordion mat-expansion-panel mat-expansion-panel-header, mat-accordion mat-expansion-panel mat-expansion-panel-header:hover, mat-accordion mat-expansion-panel mat-expansion-panel-header:not([aria-disabled=true]):hover {\n  background: #999da4;\n}\n\n::ng-deep .mat-expansion-indicator:after {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
