import {Injectable} from '@angular/core';
import {ActivityGranule} from '@modules/activities/core/models';
import {DataEntity} from 'octopus-connect';
import {LayoutAlign, StepItemType, StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {Subject} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {StepperPosition} from '@modules/activities/core/shared-components/basic-stepper-container/stepper.position';
import {AuthenticationService} from '@modules/authentication';

@Injectable({
    providedIn: 'root'
})
export class StepperService {

    constructor(
        private activitiesService: ActivitiesService,
        private authenticationService: AuthenticationService,
        private communicationCenterService: CommunicationCenterService,
        private lessonsConfigurationService: LessonsConfigurationService,
        private lessonsService: LessonsService,
    ) {
    }

    public getStepperConfig(
        isCurrentPredicate: (activity: DataEntity, index: number) => boolean,
        onStepClick: Subject<DataEntity>,
        userSavesOfCurrentLesson: DataEntity[],
        visitedActivity: number[],
        userAnsweredActivity: number[],
    ): StepperOptions | null {
        const assignmentType = this.activitiesService.currentAssignment?.get('type_term')?.label;

        if (this.lessonsConfigurationService.getStepperConfig(assignmentType) === null) {
            return null;
        }
        // cas ou le parcours lancé est un sous parcours.
        const activities = this.lessonsService.subLessonContentEdited.length ? this.lessonsService.subLessonContentEdited : this.activitiesService.activitiesArray;
        return {
            steps: activities.map((activity, activityIndex) => ({
                encapsulatedObject: activity,
                isActive: this.lessonsService.isAssignmentWithMetacognition()
                    ? this.isUserSaveForActivity(activity, userSavesOfCurrentLesson)
                    : this.isStepActive(activity, visitedActivity, userAnsweredActivity, assignmentType),
                isCurrent: isCurrentPredicate(activity, activityIndex),
                isArrowActive: (this.activitiesService.presentArrayElementIndex === activityIndex
                    && this.activitiesService.playScreenStatus !== 1
                    && this.activitiesService.playScreenStatus !== 3),
                type: this.getStepType(activity)
            })),
            onStepItemClick: onStepClick,
            recap: {
                isActive: this.activitiesService.playScreenStatus === 3 || this.activitiesService.endScreenSeen,
                isAllowed: this.isAllowedRecap(assignmentType),
                isArrowActive: this.activitiesService.playScreenStatus === 3,
            },
            stepperLayout: {
                align: LayoutAlign[<string>this.lessonsConfigurationService.getStepperConfig(assignmentType).align],
                position: StepperPosition[<string>this.lessonsConfigurationService.getStepperConfig(assignmentType).position],
            },
            isListFormatStepper: this.lessonsConfigurationService.isListFormatStepper
        };
    }

    /**
     * if we have severals lessons in assignment, we check if we are a the last lesson for showing recap
     * @returns {boolean}
     */
    private isAllowedRecap(assignmentType: string): boolean {
        if (this.lessonsConfigurationService.getStepperConfig(assignmentType)?.isRecapActive !== true) {
            return false;
        }

        const assignment = this.activitiesService.currentAssignment;
        if ((assignment?.get('assignated_nodes') ?? []).length && this.lessonsService.currentLesson) {
            return this.lessonsService.currentLesson.id === +assignment.get('assignated_nodes')[assignment.get('assignated_nodes').length - 1].id;
        }

        return true;
    }


    // check if visited activity for stepper color point
    private isUserSaveForActivity(activity: DataEntity, userSavesOfCurrentLesson: DataEntity[]) {
        return this.lessonsService.isAssignmentWithMetacognition() &&
            !!userSavesOfCurrentLesson
                .find((userSave) => userSave.get('granule')[0] === activity.id.toString() &&
                    this.lessonsService.currentLesson && +this.lessonsService.currentLesson.id === +userSave.get('granuleParent'));
    };

    private isStepActive(activity: ActivityGranule, visitedActivity: number[], userAnsweredActivity: number[], assignmentType: string) {
        const index = this.activitiesService.activitiesArray.indexOf(activity);
        if (this.lessonsConfigurationService.getStepperConfig(assignmentType).isStepValidationNeedSave) {
            return this.activitiesService.visitedMediaActivity.includes(index)
                || userAnsweredActivity[index] === 1
                || userAnsweredActivity[index] === 3;
        }
        return visitedActivity.includes(index);
    };

    private getStepType(activity): StepItemType {
        switch (activity.type) {
            case 'audio' :
            case 'video' :
            case 'image' :
            case 'document' :
            case 'media' :
            case 'url' :
            case 'videoUrl' : {
                return StepItemType.media;
            }
            case 'divider' : {
                return StepItemType.divider;
            }
            default: {
                return StepItemType.activity;
            }
        }
    }
}
