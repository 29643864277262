import {Component, inject} from '@angular/core';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {brand} from '../../../settings';

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html'
})
export class ActivitiesComponent {
    public brand: string = brand;

    private lessonsConfigurationService = inject(LessonsConfigurationService);

    /**
     * check if we show header
     * @returns {boolean}
     */
    public get displayHeader(): boolean {
        return !this.lessonsConfigurationService.settings.hideHeader;
    }
}
