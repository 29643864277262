import {filter, map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {LayoutConfigService} from 'fuse-core/services/layout-config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {NotificationsService} from '@modules/notification/core/notifications.service';
import {Language, LanguageService} from 'shared/language.service';
import {brand, modulesSettings, langs, brandLogoSvg, tralalereBar} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DynamicNavigationService} from '../../../navigation/dynamic-navigation.service';
import {Roles, RolesOrDefault} from 'shared/models/roles';
import {IsAllowedBySettings} from '../../../guards/is-allowed-by-settings.class';
import {CommunicationCenterService} from '@modules/communication-center';
import {GlobalConfigurationService} from 'fuse-core/services/global-configuration.service';
import {NotificationsServiceCitizen} from '@modules/notification/core/notifications.service.citizen';

const settingsToolbar: ModelSchema = new ModelSchema({
    displayRole: Structures.boolean(false),
    showContact: Structures.object({default: false}),
    showContactAsGar: Structures.object({default: false}),
    feedbackMenu: Structures.object({default: false}),
    feedbackMenuAsGar: Structures.object({default: false}),
    isGoToLoginPageShortcutAllowed: Structures.boolean(true),
    helpMenu: Structures.object({default: true}),
    notificationButtonAccess: Structures.boolean(),
    showTechnicalProblemMessage: Structures.boolean(false),
    showWebAppBtn: Structures.boolean(false),
    translationAccess: Structures.object({show: false, withFlag: true}),
    showLangChoiceWhenPlayLesson: Structures.boolean(true),
    showTeacherLink: Structures.boolean(false),
    showAsksHeader: Structures.boolean(false),
    useSelectedLangAsDefault: Structures.boolean(false),
});
const settingsNotification: ModelSchema = new ModelSchema({
    show: Structures.boolean(),
});
const settingsLicensing: ModelSchema = new ModelSchema({
    visible: Structures.boolean(false),
});

const settingsTralalereToolbar: ModelSchema = new ModelSchema({
    displayed: Structures.boolean(false),
});

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
    private layoutConfigService = inject(LayoutConfigService);
    private _fuseSidebarService = inject(FuseSidebarService);
    private _translateService = inject(TranslateService);
    public authenticationService = inject(AuthenticationService);
    public accountService = inject(AccountManagementProviderService);
    private notificationsService = inject(NotificationsService);
    private notificationsServiceCitizen = inject(NotificationsServiceCitizen);
    private router = inject(Router);
    private communicationCenter = inject(CommunicationCenterService);
    private octopusConnect = inject(OctopusConnectService);
    private languageService = inject(LanguageService);
    private dynamicNavigation = inject(DynamicNavigationService);
    private isRouteAccessible = inject(IsAllowedBySettings);
    private route = inject(ActivatedRoute);
    public configuration = inject(GlobalConfigurationService);


    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    public languages = this.languageService.availableLanguages;
    navigation: unknown;
    selectedLanguage: unknown;
    userStatusOptions: unknown[];
    userInformation: DataEntity;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;

    private almostTwoAssignment = false;

    /** @deprecated TODO utiliser un service de configuration */
    public settings: {
        showLangChoiceWhenPlayLesson: boolean;
        showTechnicalProblemMessage: boolean;
        helpMenu: { [k in RolesOrDefault]: boolean };
        translationAccess: { show: boolean; withFlag: boolean };
        isGoToLoginPageShortcutAllowed: boolean;
        showContactAsGar: { [k in RolesOrDefault]: boolean };
        showContact: { [k in RolesOrDefault]: boolean };
        feedbackMenu: { [k in RolesOrDefault]: boolean };
        feedbackMenuAsGar: { [k in RolesOrDefault]: boolean };
        showTeacherLink: boolean;
        showAsksHeader: boolean;
        showWebAppBtn: boolean;
        useSelectedLangAsDefault: boolean;
    };
    /** @deprecated TODO utiliser un service de configuration */
    public settingsForNotification: {
        show: boolean;
    };
    /** @deprecated TODO utiliser un service de configuration */
    public showTranslateMenu: boolean;
    public showFlagImg: boolean;
    public showNotification: boolean;
    /** @deprecated TODO utiliser un service de configuration */
    public settingsTralereToolbar: { displayed: boolean };
    public isHelpPage = false;
    public hideLangueMenu = false;

    public activeLanguage$ = this.languageService.onLanguageChange$;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     */
    constructor() {
        this.settings = <any>settingsToolbar.filterModel(modulesSettings.featureAccess);
        this.settingsForNotification = <any>settingsNotification.filterModel(modulesSettings.notification);
        this.settingsTralereToolbar = <any>settingsTralalereToolbar.filterModel(tralalereBar);

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this.router.events.subscribe(() => {
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.isHelpPage = event.url.indexOf('/home') > -1;
                    this.hideLangueMenu = !this.settings.showLangChoiceWhenPlayLesson && event.url.indexOf('/assignment/lessons') > -1;
                });
        });
    }

    public get showTechnicalProblemMessage(): boolean {
        return this.settings.showTechnicalProblemMessage && this._translateService.currentLang &&
            this._translateService.translations[this._translateService.currentLang] &&
            this._translateService.translations[this._translateService.currentLang].generic &&
            this._translateService.translations[this._translateService.currentLang].generic.technical_problem;
    }

    public get showTechnicalProblemMessageGar(): boolean {
        return this.settings.showTechnicalProblemMessage && this._translateService.currentLang &&
            this._translateService.translations[this._translateService.currentLang] &&
            this._translateService.translations[this._translateService.currentLang].generic &&
            this._translateService.translations[this._translateService.currentLang].generic.technical_problem_gar;
    }

    public teacherWithAlmostTwoAssignment(): boolean {
        return this.settings.showTeacherLink && this.authenticationService.isTrainer() && this.almostTwoAssignment;
    }

    public showAsks(): boolean {
        return this.settings.showAsksHeader && this.authenticationService.isTrainer();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    public get showHelp(): boolean {
        if (Object.keys(this.settings.helpMenu).includes(this.authenticationService.accessLevel)) {
            return this.settings.helpMenu[this.authenticationService.accessLevel];
        }

        return this.settings.helpMenu['default'];
    }

    public get showLicensing(): boolean {
        return settingsLicensing.filterModel(modulesSettings.licensing).visible && !this.authenticationService.isManager();
    }

    public get hasNotifications(): boolean {
        return (this.notificationsServiceCitizen.hasNotifications || this.notificationsCount > 0) && !this.isPanelNotifOpen();
    }

    get notificationsCount(): number {
        return this.notificationsService.unreadNotificationsCount;
    }

    public get username(): string {
        if (this.userInformation) {
            if (this.userInformation.get('nickname')
                && this.userInformation.get('nickname') !== this.userInformation.get('label')) {
                return this.userInformation.get('nickname');
            }

            if (this.userInformation.get('label')) {
                return this.userInformation.get('label');
            }
        }

        return '';
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.allowTeacherInfoInRegardOfAssignmentRule();

        this.showTranslateMenu = this.settings.translationAccess.show;
        this.showFlagImg = this.settings.translationAccess.withFlag;
        this.showNotification = this.configuration.isNotificationEnabled;
        // Subscribe to the config changes
        this.layoutConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        if (this.settings?.useSelectedLangAsDefault) {
            this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
        }


        this.accountService.data.subscribe(_userInformation => {
            this.userInformation = this.accountService.loggedUser;
        });
    }

    private allowTeacherInfoInRegardOfAssignmentRule() {
        this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(user => {
            const options = {filter: {'assignator': user.id}};
            return this.octopusConnect.loadCollection('assignation_search', options.filter)
                .pipe(take(1))
                .subscribe(data => {
                    const filteredData = data.entities.filter(item => {
                        const createdAtTimestamp = parseInt(item.get('created')) * 1000;
                        const augusth2023Timestamp = new Date('2023-08-01').getTime();
                        return createdAtTimestamp >= augusth2023Timestamp;
                    });
                    this.almostTwoAssignment = filteredData.length >= 2;
                });
        });
    }

// -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    public openNotifications(): void {
        this.notificationsServiceCitizen.openNotificationDialog();
    }

    public isPanelNotifOpen(): boolean {
        return this.notificationsServiceCitizen.isNotifPanelOpen;
    }

    /**
     * Set the language citizen code version
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        localStorage.setItem('lang', lang.id);

        this.communicationCenter.getRoom('current-language').getSubject('id').next(lang.id);

        // Use the selected language for translations
        this._translateService.use(lang.id);
        // Update dynamic menu with new language
        this.dynamicNavigation.generateMenu(this.dynamicNavigation.currentMenuId, true);

        // check route with access settings for new language
        this.isRouteAccessible.checkRouteAllowedOrRedirect(this.route.snapshot, this.router.routerState.snapshot);
    }

    /**
     * Set the language platforme version
     *
     * @param lang
     */
    onLanguageClick(lang: Language): void {
        this.languageService.setActiveLanguage(lang);
    }

    logOut(): void {
        this.authenticationService.logoutFrom('http');
    }

    goToLoginPage(): void {
        this.router.navigate(['/login']);
    }

    public goToSite(pathKey: string): void {
        this._translateService.get(pathKey).subscribe((translation: string) => {
            window.open(translation, '_blank');
        });
    }

    public isRoleDisplayable(): boolean {
        return _.get(this.settings, 'displayRole', false);
    }

    public getRole(): string {
        return this.authenticationService.accessLevel;
    }

    public shouldDisplayGoToLoginPage(): boolean {
        const isAuthenticated = this.authenticationService.isAuthenticated === false;
        const isButtonAllowedByDefault = this.settings.isGoToLoginPageShortcutAllowed === true;

        return isAuthenticated && isButtonAllowedByDefault;
    }

    private getSettingForRole<T, U extends { [k in RolesOrDefault]: T }>(role: Roles, setting: U): T {
        return setting[role] || setting.default;
    }

    public showContact() {
        const role = <Roles>this.authenticationService.accessLevel;

        if (this.authenticationService.isGAR()) {
            return this.getSettingForRole(role, this.settings.showContactAsGar);
        } else {
            return this.getSettingForRole(role, this.settings.showContact);
        }
    }

    public showFeedBack() {
        if (this.authenticationService.isGAR() === false) {
            const role = <Roles>this.authenticationService.accessLevel;
            return this.getSettingForRole(role, this.settings.feedbackMenu);
        }

        return false;
    }

    public showFeedBackGar() {
        if (this.authenticationService.isGAR()) {
            const role = <Roles>this.authenticationService.accessLevel;
            return this.getSettingForRole(role, this.settings.feedbackMenuAsGar);
        }

        return false;
    }

    public navigateToDefaultRoute(): void {
        this.authenticationService.overrideDefaultRouteInRegardOfRole(true);
    }

    public navigateToContact() {
        this.router.navigate(['/feedback']);
    }

    public navigateToFeedback(): void {
        this.router.navigate(['/page/feedback']);
    }

    public navigateToFeedbackGar() {
        this.router.navigate(['/page/feedback-gar']);
    }
}