// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-error {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/users-import/users-import.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/groups-management/core/users-import/users-import.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF","sourcesContent":[".mat-error {\n  display: inline;\n}\n",".mat-error {\n  display: inline;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
