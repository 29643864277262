// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locked1 {
  opacity: 0.6;
  background: #efefef;
}

.locked0 {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/lessons-selection/lessons-selection.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/@fuse/components/lessons-selection/lessons-selection.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,mBAAA;ACDF;;ADIA;EACE,eAAA;ACDF","sourcesContent":["@import \"@fuse/scss/fuse\";\n\n.locked1{\n  opacity: 0.6;\n  background: #efefef;\n}\n\n.locked0{\n  cursor: pointer;\n}\n",".locked1 {\n  opacity: 0.6;\n  background: #efefef;\n}\n\n.locked0 {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
