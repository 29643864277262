import {Component, OnInit, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {brand} from '../../../../settings';
import {MindmapService} from '../mindmap.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, map, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import {MindmapRepositoryService} from '@modules/mindmap/core/mindmap-repository.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {SettingsService} from '@modules/mindmap/core/settings.service';

@Component({
    selector: 'app-mindmap-content-editor',
    templateUrl: './mindmap-content-editor.component.html',
})
export class MindmapContentEditorComponent implements OnInit {
    /**
     * Mindmap title used as header
     */
    public mindmapTitle = '';
    public urlToMindmap: SafeResourceUrl;
    /**
     * Current mindmap granule (from granule endpoint)
     */
    private mindmapGranule: DataEntity = null;

    constructor(private activatedRoute: ActivatedRoute,
                private dialog: MatDialog,
                private mindmapSvc: MindmapService,
                private mindmapRepoSvc: MindmapRepositoryService,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private settingsService: SettingsService,
                private selfDialogRef: MatDialogRef<MindmapContentEditorComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: Partial<{ mindmapGranule: DataEntity, forceAssociationOnSave: string | number }>) {
    }

    /**
     * Defined if the component a ready
     */
    public get isLoadingDone(): boolean {
        return this.mindmapGranule !== null;
    }

    ngOnInit(): void {
        if (this.dialogData?.mindmapGranule) {
            this.initialize(this.dialogData?.mindmapGranule);
        } else {
            this.route.params.pipe(
                mergeMap(params => this.mindmapRepoSvc.getMindmap(params['mindmapId'])),
                tap(mindmapGranule => {
                    this.initialize(mindmapGranule);
                })
            ).subscribe();
        }
    }

    /**
     * Close the component by redirecting to the mindmapList or by emit on the `Complete Subject`.
     * To access to the emit subject, the component need to have a subject unique id.
     * This unique id is used by the {@link MindmapService.onMindmapDataEditionCompleteSubject} to reference the subject.
     *
     * @remaks Sometimes, like by reloading component from nowhere but with the `onComplete queryParams` the on complete subject id can be not associated to a subject.
     */
    public close(): void {
        this.activatedRoute.queryParams.pipe(
            map(params => {
                if (params.hasOwnProperty('onComplete')) {
                    if (this.mindmapSvc.onMindmapDataEditionCompleteSubject.hasOwnProperty(params['onComplete'])) {
                        this.mindmapSvc.onMindmapDataEditionCompleteSubject[params['onComplete']].next(this.mindmapGranule);
                        return;
                    }
                }
                if ('close' in this.selfDialogRef) {
                    this.selfDialogRef.close();
                    return;
                }

                this.mindmapSvc.goToMindmapList();
            })
        ).subscribe();
    }

    /**
     * Reset the component with default values defined by the granule.
     * Different than reset the content.
     * HACK there are no login in the iframe, the give it the current user token for the v1
     * @param mindmapGranule
     */
    private initialize(mindmapGranule: DataEntity): void {
        this.mindmapGranule = mindmapGranule;
        this.mindmapTitle = this.mindmapGranule.get('metadatas').title;
        const mindmapId = this.mindmapGranule.get('reference').activity_content[0].id;
        const token = localStorage.getItem('http_accessToken');
        const noMailMenu = this.settingsService.noMailmenu ? '&noMailMenu=true' : '';
        this.urlToMindmap = this.sanitizer.bypassSecurityTrustResourceUrl(environment.mindmapUrl(brand) + mindmapId + '?token=' + token.slice(1, -1) + noMailMenu);
    }
}
