import {Component, Inject, Optional} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * This is a generic modal used in multiple context to alert the user :
 * - before living editor without saving
 * - before reset mindmap content
 */
@Component({
    selector: 'app-mindmap-generic-alert-modal',
    templateUrl: './mindmap-generic-alert-modal.component.html'
})
export class MindmapGenericAlertModalComponent {

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public modalData: { contentKey: string },
        public selfDialogRef: MatDialogRef<MindmapGenericAlertModalComponent>) {
    }

}