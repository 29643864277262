import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NotepadDataEditorModalComponent} from '@modules/notepad/core/notepad-data-editor-modal/notepad-data-editor-modal.component';
import {CommunicationCenterService} from '@modules/communication-center';
import {NavigationExtras, Router} from '@angular/router';
import {NotepadGenericAlertModalComponent} from '@modules/notepad/core/notepad-generic-alert-modal/notepad-generic-alert-modal.component';
import {INotepadFormData, NotepadRepositoryService} from '@modules/notepad/core/notepad-repository.service';
import {NotepadContentViewerModalComponent} from '@modules/notepad/core/notepad-content-viewer-modal/notepad-content-viewer-modal.component';
import {NotepadListComponent, NotepadListDialogData} from '@modules/notepad/core/notepad-list/notepad-list.component';
import {NotepadContentEditorComponent} from '@modules/notepad/core/notepad-content-editor/notepad-content-editor.component';

// Should be a copy of LessonToolDataCommunicationCenterInterface interface in GenericPluginService
export interface NotepadDataCommunicationCenterInterface {
    lesson: DataEntity;
    onComplete: ReplaySubject<DataEntity>;
}

/**
 * List field options to apply on notepad creation or edition
 *
 * @remarks For now, only the `associatedLessonId` field is used
 */
export interface INotepadFormOptions {
    [fieldName: string]: { disable: boolean };
}

@Injectable({
    providedIn: 'root'
})
/**
 * Define the notepad business rules of the application
 */
export class NotepadService {
    /**
     * Obtain the current user or null if not authenticated
     */
    public currentUser$ = new ReplaySubject<DataEntity>(1);
    /**
     * List of unique {@link ReplaySubject} used to identity when an notepad edition is done.
     * There should never have more than one subject at times, but a subject in this array can be an old one and we need a way to identify if it's the good one.
     * For resolve this problem, we use an object has an hashMap/Key->Value array. The `k` is the unique identifier to a subject
     */
    public onNotepadDataEditionCompleteSubject: { [k: string]: ReplaySubject<DataEntity> } = {};

    constructor(
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private notepadRepoSvc: NotepadRepositoryService,
        private octopusConnect: OctopusConnectService,
        private router: Router,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject<DataEntity>('userData')
            .pipe(
                filter(currentUser => !!currentUser),
                tap(currentUser => {
                    this.currentUser$.next(currentUser);
                })
            )
            .subscribe();

        /**
         * Used to create or edit a notepad from everywhere out of the current module
         */
        this.communicationCenter
            .getRoom('notepad')
            .getSubject('execute')
            .pipe(
                tap((args: NotepadDataCommunicationCenterInterface) =>
                    this.openEmbeddedNotepadListing(args)
                )
            )
            .subscribe();
    }

    /**
     * Obtains the paginated list of current user's notes
     * @param filterOptions
     * @return The {@link DataEntity} are `granules` and the are not of `notepads` but `BasicSearch` endpoint
     */
    public getCurrentUserPaginatedNotepads(filterOptions: CollectionOptionsInterface = {}): Observable<PaginatedCollection> {
        return this.currentUser$.pipe(
            filter(currentUser => !!currentUser),
            map(currentUser => _.merge({
                filter: {
                    author: currentUser.id
                }
            }, filterOptions)),
            mergeMap(options => this.notepadRepoSvc.getPaginatedNotepads(options)),
            take(1)
        );
    }

    /**
     * Open and return the notepad data editor modal.
     *
     * - It's the same way to create a note ({@link goToNotepadDataCreation}) but the save method passed to the modal is used to patch notepad
     *
     * @param notepad Used to defined which notepad to edit and the default form values.
     * Should be a entity given by the `BasicSearch` endpoint or similar because the default values are not in the same path for other endpoints
     * @param embedded
     * @param forceAssociationOnSave
     */
    public goToNotepadDataEdition(notepad: DataEntity, embedded: boolean, forceAssociationOnSave: number | string): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        const alreadyAssociatedNodes = _.get(
            notepad.get('reference'),
            'activity_content[0].associated_nodes'
        ) || [];

        return this.goToNotepadDataAndContentEditors({
            saveNotepad: (data) => this.notepadRepoSvc.updateNotepad(notepad.id, data),
            defaultValues: {
                title: _.get(notepad.get('metadatas'), 'title'),
                associatedLessonIds: alreadyAssociatedNodes.map(node => node.id),
            },
            embeddedContentEditor: embedded,
            forceAssociationOnSave
        });
    }

    /**
     * Open and return the notepad data editor modal.
     *
     * - It's the same way to edit a note ({@link goToNotepadDataCreation}) but the save method passed to the modal is used to create notepad*
     */
    public goToNotepadDataCreation(defaultValues?: INotepadFormData, formOptions?: INotepadFormOptions, embedded?: boolean): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        return this.goToNotepadDataAndContentEditors({
            saveNotepad: (data) => this.notepadRepoSvc.createNotepad(data),
            defaultValues,
            options: formOptions,
            embeddedContentEditor: embedded
        });
    }

    /**
     * Redirect to the list of current user's notepad
     */
    public goToNotepadList(): Promise<boolean> {
        return this.router.navigate(['notepad', 'list']);
    }

    /**
     * Ask user if he confirm the notepad deletion and, if it's ok, delete it
     * @param id of the notepad granule
     */
    public deleteNotepad(id: number | string): Observable<boolean> {
        const modalData = {data: {contentKey: 'notepad.ask_before_delete_alert'}};
        return this.dialog.open(NotepadGenericAlertModalComponent, modalData).afterClosed().pipe(
            filter(isConfirm => !!isConfirm),
            mergeMap(() => this.notepadRepoSvc.destroyNotepad(id))
        );
    }

    /**
     * Display the notepad content as a read-only modal
     * @param id of the notepad Granule
     */
    displayNotepad(id: number | string): void {
        this.dialog.open(NotepadContentViewerModalComponent, {data: {notepad$: this.notepadRepoSvc.getNotepad(id)}});
    }

    public goToLesson(associatedLessonId: string | number): void {
        this.communicationCenter.getRoom('lessons').next('playLesson', {id: associatedLessonId});
    }

    /**
     * Open the notepad editor modal and if there are a notepad given on closed go to notepad content editor
     * @param data The saveNotepad method receive the form data TODO give an interface of form data here
     */
    private goToNotepadDataAndContentEditors(
        data: {
            saveNotepad: (data) => Observable<DataEntity>;
            defaultValues: INotepadFormData;
            options?: INotepadFormOptions,
            embeddedContentEditor?: boolean
            forceAssociationOnSave?: number | string
        }
    ): MatDialogRef<NotepadDataEditorModalComponent, DataEntity | null> {
        const dialogRef = this.dialog.open(NotepadDataEditorModalComponent, {
            data
        });

        dialogRef.afterClosed().pipe(
            // If is notepadGranule empty is because the modal is closed without want to create/edit the notepad
            filter(notepadGranule => !!notepadGranule),
            tap(notepadGranule => {
                if (data.embeddedContentEditor) {
                    // If the notepad is embedded, open the notepad content editor modal
                    this.dialog.open(NotepadContentEditorComponent, {
                        data: {notepadGranule, forceAssociationOnSave: data.forceAssociationOnSave},
                        // todo faire mieux plus tard
                        width: '100vw',
                        maxWidth: '100vw',
                        height: '100vh',
                        maxHeight: '100vh',
                        panelClass: 'notepad-content-editor-dialog'
                    });
                } else {
                    // If the notepad is not embedded, redirect to the notepad content editor
                    this.goToNotepadContentEditor(notepadGranule);
                }
            })).subscribe();

        return dialogRef;
    }

    /**
     * Redirect the user to the notepad content editor page.
     *
     * @param notepadGranule
     * @param navigationExtras some data to pass by the url, show {@link onNotepadDataEditionCompleteSubject} for example
     */
    private goToNotepadContentEditor(notepadGranule: DataEntity, navigationExtras?: NavigationExtras): void {
        this.router.navigate(['notepad', notepadGranule.id, 'edit'], navigationExtras);
    }

    private openEmbeddedNotepadListing(args: NotepadDataCommunicationCenterInterface): void {
        this.dialog.open<NotepadListComponent, NotepadListDialogData>(NotepadListComponent, {
            data: {forceAssociationOnSave: args.lesson},
            // todo faire mieux plus tard
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            panelClass: 'notepad-listing-modal'
        });
    }

    public dissociateLesson(notepadEntity: DataEntity, associatedLesson: { title: string; id: string | number }) {
        return this.notepadRepoSvc.getNotepadActivityContent(notepadEntity.get('reference').activity_content[0].id).pipe(
            take(1),
            mergeMap((notepadActivityContent) => {
                const lessonIds = notepadActivityContent.get('associated_nodes')
                    .map(node => node.id)
                    .filter(lessonId => +lessonId !== +associatedLesson.id);
                const distinctLessonIds = _.uniq(lessonIds);
                notepadActivityContent.set('associated_nodes', distinctLessonIds);
                return notepadActivityContent.save();
            })
        );
    }
}