import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {InterfaceError} from 'octopus-connect';

@Component({
    selector: 'app-generic-error-modal',
    templateUrl: './generic-error-modal.component.html',
    styleUrls: ['./generic-error-modal.component.scss']
})
export class GenericErrorModalComponent implements OnInit {

    errors: InterfaceError[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Object,
        private ref: MatDialogRef<GenericErrorModalComponent>,
    ) { }

    ngOnInit() {
      this.errors = this.data['errors'];
    }

    clear() {
        this.errors.length = 0;
    }

    close() {
        this.ref.close();
    }

    ignoreAll() {
        this.ref.close({
            ignoreAll: true
        });
    }

}