import {Component, HostBinding, inject, OnInit} from '@angular/core';
import {CollectionOptionsInterface, DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivatedRoute, Router} from '@angular/router';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {AuthenticationService} from '@modules/authentication';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';

/**
 * component use to select a chapters before going on lesson list
 */
@Component({
    selector: 'app-period-list',
    templateUrl: './period-list.component.html',
})
export class PeriodListComponent implements OnInit {
    @HostBinding('class') hostClass: string = 'english';

    private octopusConnect = inject(OctopusConnectService);
    private communicationCenter = inject(CommunicationCenterService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private conf = inject(LessonsConfigurationService);
    private authenticationService = inject(AuthenticationService);

    private conceptId = '';
    private gradeId: number;
    public showSpinner = true;
    public chapters: Array<IChapters> = [];
    protected readonly status = IStatus;
    public assignmentCount = 0;
    public shouldShowLaunchButton = this.conf.settings.shouldShowLaunchButton;


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['conceptId']) {
                this.conceptId = params['conceptId'];
                this.hostClass = this.conf.periodBackGroundToUse(this.conceptId);
            }
            if (params['gradeId']) {
                this.gradeId = params['gradeId'];
            }
            this.getChapters();
        });
        this.setNumberOfAssignmentsForCurrentUser();
    }

    private setNumberOfAssignmentsForCurrentUser() {
        const optionsInterface: CollectionOptionsInterface = {
            filter: {
                'assignated_user': this.authenticationService.userData.id,
                'excludeAssignator': true,
                // 2683=pending 2681=assigned 2682=closed 4270=valid;
                'assignments_state': this.conf.getIdFilterToUseInMyLessonButton()
            }
        };

        this.communicationCenter.getRoom('assignment')
            .getSubject('loadPaginatedAssignmentsCallback')
            .pipe(
                mergeMap((loadPaginatedAssignments$: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) =>
                    loadPaginatedAssignments$(optionsInterface.filter)
                ),
                take(1),
                map(collection => collection.entities)
            ).subscribe(ass => {
            // set the number of assigment
            this.assignmentCount = ass.length;
        });
    }

    private getChapters(): void {
        this.showSpinner = true;
        // TODO Il y a un service qui permet de chargé les chapitre, de les types, de typer les filtres disponibles, etc.
        this.octopusConnect.loadCollection('chapters', {grade: this.gradeId, concepts: this.conceptId})
            .pipe(take(1)).subscribe(data => {
            this.setchapters(this.gradeId, this.conceptId, data.entities);
        });
    }

    /**
     * set chapter with additionnal data state and progress
     * @param grade
     * @param concepts
     * @param data
     * @private
     */
    private setchapters(grade: number, concepts: string, data: DataEntity[]): void {
        this.chapters = [];
        this.octopusConnect.loadCollection('user-progress', {educationalLevel: this.gradeId, concept: this.conceptId})
            .pipe(take(1),
                map(collection => collection.entities[0].get('currentUser')),)
            .subscribe(userProgress => {
                for (const entity of data) {
                    if (entity.get('name')) {
                        this.chapters.push({
                            id: +entity.id,
                            label: entity.get('label'),
                            name: entity.get('name'),
                            concepts: entity.get('concepts'),
                            grades: entity.get('grade'),
                            state: userProgress?.chapters.find(up => +up.chapterId === +entity.id)?.state,
                            progress: userProgress?.chapters.find(up => +up.chapterId === +entity.id)?.progress ? userProgress?.chapters.find(up => +up.chapterId === +entity.id)?.progress : 0
                        });
                    }
                }
                this.showSpinner = false;
            });
    }

    navigateToLesson(chapter: IChapters): void {
        // store data to permit to nanvigate to next one from header
        this.communicationCenter.getRoom('lessons').getSubject('chapters').next({
            chapters: this.chapters,
            currentChapter: chapter.id.toString(),
            grade: this.gradeId,
            concept: this.conceptId
        });
        this.router.navigate(['lessons', 'grade', this.gradeId, 'concept', this.conceptId, 'chapter', chapter.id.toString()]);
    }


    public getIcon(state: number): string {
        switch (state) {
            case 1:
                return 'assignment_period_list';
            case 2:
                return 'flag_period_list';
            default:
                return '';
        }
    }

    public goToMyAssignments(): void {
        this.router.navigate(['followed', 'my-assignments']);
    }

}


export interface IChapters {
    id: number,
    label: string,
    name: string,
    concepts: string[],
    grades: string[],
    state: string,
    progress: number,
}

export enum IStatus {
    'assigned', // tu as une leçon à faire ici
    'pending', // tu en étais là
    'pending2', // reprendre ici
    'closed', // terminé
    'not_started', // pas encore commencé
}

