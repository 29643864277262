import {Component, OnInit, TemplateRef} from '@angular/core';
import {GamecodeService} from '../gamecode.service';
import {DataCollection, DataEntity, OrderDirection} from 'octopus-connect';
import {filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CollectionPaginator} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import * as _ from 'lodash-es';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {GamecodeConfigurationService} from '../gamecode-configuration.service';
import {ContextualService} from '@modules/gamecode/core/services/contextual.service';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';

/**
 * list of filters. Could be a setting
 */
const filters = ['metadatas_title'];
/**
 * Max items per page
 */
const gamecodeListRange = 12;

@Component({
    selector: 'app-gamecode-list',
    templateUrl: './gamecode-list.component.html',
})
export class GamecodeListComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {

    /**
     * Define if component is ready or not
     */
    public isLoadingDone = false;
    /**
     * List of gamecode given by BasicSearch endpoint it's not activities/granule but seach/granule entities
     */
    public gamecodes: DataEntity[] = [];
    /**
     * Obtain the current page data relative to the pagination (count, page index, etc.)
     */
    public paginator: CollectionPaginator;
    /**
     * list of filters for {@link SearchFiltersComponent}
     */
    public filters: string[] = filters;
    /**
     * List of already associated lessons for the {@link SearchFiltersComponent} already formated and no need to add `all` by default
     * Used to filter current gamecodes by an associated lesson.
     */
    public alreadyAssociatedLessonsFilterList: { id: string | number, label: string }[] = [];
    /**
     * Max number of gamecode per page
     */
    public gamecodeListRange = gamecodeListRange;


    constructor(private gamecodeSvc: GamecodeService,
                private config: GamecodeConfigurationService,
                private contextualService: ContextualService,
                private dialog: MatDialog,
                private route: ActivatedRoute) {
        super();
    }

    /**
     * Go to the gamecode creation interface by calling the service and refresh the gamecode after it
     * @param $event
     */
    public createNote($event: MouseEvent): void {
        this.gamecodeSvc.goToGamecodeDataCreation({application: this.gamecodeSvc.application})
            .afterClosed()
            .pipe(
                filter(resource => !!resource),
                mergeMap(() => this.refreshNotes())
            )
            .subscribe();
    }

    /**
     * Triggered when a gamecode has changed, used to refresh the gamecode data.
     *
     * @param _gamecode BasicSearch granule of gamecode
     */
    public onGamecodeChanged(_gamecode: DataEntity): void {
        this.refreshNotes().subscribe();
    }

    /**
     * Triggered when user change page index from paginator
     * @param event
     */
    public onPaginateChange(event): void {
        this.paginator.page = event.pageIndex + 1;
        this.refreshNotes().subscribe();
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['application']) {
                this.gamecodeSvc.application = params['application'];
                this.refreshNotes().subscribe();
            }
        });

        this.setupContextual();
    }


    /**
     * Set the component in loading and get/load the notes
     * By default, only the authenticated user's note are loaded by the service
     *
     */
    refreshNotes(optionsInterface: CollectionOptionsInterface = {}): Observable<DataCollection> {
        const mergedOptionsInterface = _.merge({
            page: this.paginator ? this.paginator.page : 1,
            range: gamecodeListRange,
            filter: {
                application: this.gamecodeSvc.application,
            },
            orderOptions: [{field: 'created', direction: OrderDirection.DESC}],
        }, optionsInterface);

        this.isLoadingDone = false;
        return this.gamecodeSvc.getCurrentUserPaginatedGamecodes(mergedOptionsInterface).pipe(
            tap(paginatedCollection => this.paginator = paginatedCollection.paginator),
            mergeMap(paginatedCollection => paginatedCollection.collectionObservable),
            tap(dataCollection => {
                this.gamecodes = [...dataCollection.entities];
                this.isLoadingDone = true;
            }),
            take(1)
        );
    }

    private setupContextual(): void {
        this.contextualService.actionGamecodeAdd$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.createNote(null));
    }

    public get displayApplication(): string {
        return this.gamecodeSvc.application;
    }

    public get rolesCanShowBannerInfo(): string[] {
        return this.config.rolesCanShowBannerInfo;
    }

}