import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DataEntity} from 'octopus-connect';

@Component({
  selector: 'app-licencing-import',
  templateUrl: './licencing-import.component.html',
  styleUrls: ['./licencing-import.component.scss']
})
export class LicencingImportComponent implements OnInit {
    public result: DataEntity;


    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<LicencingImportComponent>) { }

  ngOnInit(): any {
      this.result = this.data;
  }

  close(): void {
      this.dialogRef.close();
  }

  get alreadyExist(): string {
      if (this.result) {
          return this.result.get('licenses.already_exist');
      }
  }

  get successNumber(): string {
      if (this.result) {
          return this.result.get('licenses.success_number');
      }
  }

}