import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../authentication';
import { MatTableDataSource } from '@angular/material/table';
import {ReplaySubject} from 'rxjs';
import {fuseAnimations} from 'fuse-core/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-widget-workgroup',
  templateUrl: './widget-workgroup.component.html',
  styleUrls: ['./widget-workgroup.component.scss'],
  animations: fuseAnimations
})
export class WidgetWorkgroupComponent implements OnInit {

    @Input('workgroupsSubscription') workgroupsSubscription: ReplaySubject<any>;

    public displayedColumns: string[] = ['workgroupname', 'learners'];
    public dataSource: MatTableDataSource<any> = new MatTableDataSource();

    entities: any;
    user: any;
    resource: boolean;


    constructor(
        public authService: AuthenticationService,
        private router: Router,
    ) {

    }

    ngOnInit(): any {
        this.workgroupsSubscription.subscribe((resources) => {
            this.dataSource.data = resources.slice(0, 3);
            this.resource = resources && resources.length;
        });
    }

    getCount(data): number | string {
      if (data) {
          return data.learners.length;
      }

      return '';
    }

    getLink(): void{
        this.router.navigate(['/groups', 'list', 'workgroups']);
    }

    getTerms(data): string {
        return +data > 1 ? 'generic.learners' : 'generic.learner';
    }

    public get align(): string {
        if (!this.empty) {
            return 'start center';
        }
        return 'center center';
    }

    get empty(): boolean {
        return !this.resource;
    }

}