// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-tab-body-wrapper {
  flex: 1 0 auto;
}

.mat-tab-body-wrapper {
  flex: 1 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-management.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/groups-management/core/groups-management.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;ACAJ;;ADGA;EACE,cAAA;ACAF","sourcesContent":[":host {\n  .mat-tab-body-wrapper {\n    flex: 1 0 auto;\n  }\n}\n.mat-tab-body-wrapper {\n  flex: 1 0 auto;\n}\n",":host .mat-tab-body-wrapper {\n  flex: 1 0 auto;\n}\n\n.mat-tab-body-wrapper {\n  flex: 1 0 auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
