export const activities = {
    // required params name for partner through iframe url
    accessMatrix: {
        activitiesListing: {
            view: ['learner', 'trainer', 'manager', 'administrator'],
        },
        lessonsListing: {
            view: ['parent', 'trainer', 'manager', 'administrator'],
        }
    },
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti']
    },
    activitiesBroadcastLifeCycle: true,  // envoi les événements du cycle de vie au module LRS Module via le communication service,
    activitiesDisplayedColumns: ['title', 'educationalLevel', 'chapters', 'action'],
    addFromActivities: false,
    allowedActivityTypes: [],
    allowedRolesForAutoAssignmentCreation: ['learner', 'parent', 'trainer', 'manager', 'administrator'],
    allowedRolesForModelsAssignation: ['manager', 'trainer'],
    allowErrorReporting: false,
    assignableLesson: true,
    autoCorrection: true,
    bannerInfoAddUsages: {'4056': 'autonomie', '4055': 'collectif'}, // TODO utiliser les labels et pas les ids, les ids sont différents en fonction des instances et envrionnements
    buttons: {
        player: {
            auto: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missing: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missingAudio: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            preview: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            }
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missing: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missingaudio: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            training: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missing: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        missingaudio: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            homework: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
        },
    },
    cardFieldsForPreview: ['chapters', 'description'],
    cardLayout: 'card-split-media-info',
    closeButtonPng: true,
    columns: {
        default: ['title', 'actions']
    },
    displayFeedback: false, // display feedback for each activities played
    displayFilters: false,
    displayFullscreenButton: true,
    displayLearnerInfo: false,
    rolesCanShowBannerInfo: ['administrator', 'manager', 'director', 'trainer', 'parent', 'learner'],
    dynamicContent: false,
    enableTTS: true, // add TTS option for wording & help
    filterListWithInListBoolean: true,
    filtersEditorActivitiesList: ['title', 'educationnalLevel', 'chapters'],
    filters: {
        default: ['title']
    },
    forceUrlByDefaultWhenBackToLessonList: true, // force par defaut l'utilisation d'une url predefinis pour le retour à l'ecran de listing des parcours
    hiddenActivityPreview: false,
    hideAddButtonLessonList: true,
    hideUserActionButtons: true,
    iframeParams: ['uid', 'prenom', 'codeclasse', 'token', 'assignationId', 'assignatedUser', 'idLesson'],       // required params name for partner through iframe url
    imageFullscreenButton: true,
    initLoadLessonFilter: ['author'],
    initialiseIframeUrl: true,
    isLessonEditionEnabled: {default : false, trainer: true},
    isLessonPreviewAccessible: {default: false, trainer: true},
    isModalConfirmBeforeCloseExo: true,
    isOnlyModelLesson: true, // should be called by example : enableSelectionByTab for lesson list component
    isPercentileDisplayAllowed: false,
    isPlayLessonActionIsLimitedToCollectiveAssignment: true,
    isRecapAtEndOfLessonEnabled: false,
    latexKeyboard: false,
    lessonDialogRequiredFields: {
        default: ['title', 'educationalLevel', 'assignation_type', 'usage'],
    },
    lessonStep: null,
    loadSubActivitiesOfAllSubLesson: false, // load activities from each sub-lesson in lesson at the initialise of the lesson to be played.
    menu: {
        models: false
    },
    menuLessonsDeployable: {default: ['onlyCollective', 'onlyIndividuals']},
    multiSelectionForActivitiesList: false,
    numberOfTryBeforeDisplayCorrection: 3,
    openDialogInfoAfterDuplicateLesson: {
        default: true,
        manager: false,
    },
    plugins: {
        mindmap: {
            iconIdentifier: 'mindmap.png',
            label: 'mindmap.title',
            octopusConnectRoom: 'mindmap',
            pluginType: 'lessonTool',
        },
        notepad: {
            iconIdentifier: 'notepad.png',
            label: 'notepad.title',
            octopusConnectRoom: 'notepad',
            pluginType: 'lessonTool',
        },
    },
    recommendationDisplayedColumns: ['checkbox', 'pseudo', 'lesson', 'level', 'type', 'buttons'], // 'confidence', retrait en attendant que ça soit opérationnel
    routeOnExitLesson: ['lessons', 'list', 'lessons'],
    saveLessonContentOptions: {
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: false // si chaque activités ou souslesson du parcours doit être sauvegardé
    },
    searchFields: {
        default: {
            default: [{'field': 'concepts', filters: {'showIn': 'lessons'}}, {field: 'title'}, {field: 'bookmarks'}, {field: 'launchSearch'}, {field: 'countEntities'}],
        },
        'search?usage=4055': {
            default: [{'field': 'concepts', filters: {'showIn': 'lessons'}}, {field: 'title'},  {field: 'bookmarks'}, {field: 'launchSearch'}, {field: 'countEntities'}]
        },
    },
    setAnswerWithUserSave: false, // à l'initialisation d'une activité, inscrit les réponses presente dans l'user-save
    shouldSetDefaultOptionsOnActivityList: true,
    showActivityTitle: true,
    showAddLessonButtonCard: true,
    showMultiZoneProgressBar: {
        default: false, // role
        learner: { // role
            default: true, // assignment type
            training: true, // assignment type
            homework: true, // assignment type
        }
    },
    showHelp: false,
    showLessonTitle: false,
    showSubInstruction: true,
    stepper: {
        default: { // role
            default: { // assignment type
                align: 'space-evenly',
                position: 'top',
                showRecap: false,
                stepValidationNeedSave: false
            }
        },
        learner: { // role
            default: null, // assignment type
            training: { // assignment type
                align: 'space-evenly',
                position: 'top',
                showRecap: false,
                stepValidationNeedSave: false,
                isListFormatStepper: false
            },
            homework: { // assignment type
                align: 'space-evenly',
                position: 'top',
                showRecap: false,
                stepValidationNeedSave: false,
                isListFormatStepper: false
            }
        },
    },
    symbolsForLatexKeyboard: {
        alphabetic: {
            data: {
                tabs: [
                    {
                        'classes': ['alphabetic'],
                        'columns': 11,
                        'label': 'abc',
                        'name': 'abc',
                        'rows': 4,
                        'symbols': [
                            {'button': 'a', 'latex': 'a', 'displayed': 'a'},
                            {'button': 'z', 'latex': 'z', 'displayed': 'z'},
                            {'button': 'e', 'latex': 'e', 'displayed': 'e'},
                            {'button': 'r', 'latex': 'r', 'displayed': 'r'},
                            {'button': 't', 'latex': 't', 'displayed': 't'},
                            {'button': 'y', 'latex': 'y', 'displayed': 'y'},
                            {'button': 'u', 'latex': 'u', 'displayed': 'u'},
                            {'button': 'i', 'latex': 'i', 'displayed': 'i'},
                            {'button': 'o', 'latex': 'o', 'displayed': 'o'},
                            {'button': 'p', 'latex': 'p', 'displayed': 'p'},

                            {'button': 'q', 'latex': 'q', 'displayed': 'q'},
                            {'button': 's', 'latex': 's', 'displayed': 's'},
                            {'button': 'd', 'latex': 'd', 'displayed': 'd'},
                            {'button': 'f', 'latex': 'f', 'displayed': 'f'},
                            {'button': 'g', 'latex': 'g', 'displayed': 'g'},
                            {'button': 'h', 'latex': 'h', 'displayed': 'h'},
                            {'button': 'j', 'latex': 'j', 'displayed': 'j'},
                            {'button': 'k', 'latex': 'k', 'displayed': 'k'},
                            {'button': 'l', 'latex': 'l', 'displayed': 'l'},
                            {'button': 'm', 'latex': 'm', 'displayed': 'm'},

                            {'button': 'w', 'latex': 'w', 'displayed': 'w'},
                            {'button': 'x', 'latex': 'x', 'displayed': 'x'},
                            {'button': 'c', 'latex': 'c', 'displayed': 'c'},
                            {'button': 'b', 'latex': 'b', 'displayed': 'b'},
                            {'button': 'v', 'latex': 'v', 'displayed': 'v'},
                            {'button': 'n', 'latex': 'n', 'displayed': 'n'},

                            {'button': '!', 'latex': '!', 'displayed': '!'},
                            {'button': ',', 'latex': ',', 'displayed': ','},
                            {'button': '.', 'latex': '.', 'displayed': '.'},
                            {'button': '?', 'latex': '?', 'displayed': '?'},
                            {'button': '⎵', 'latex': ' ', 'displayed': '\\hspace{5pt}', 'classes': ['spacebar']}
                        ]
                    },
                    {
                        'classes': ['alphabetic-maj'],
                        'columns': 11,
                        'label': 'ABC',
                        'name': 'ABC',
                        'rows': 4,
                        'symbols': [
                            {'button': 'A', 'latex': 'A', 'displayed': 'A'},
                            {'button': 'Z', 'latex': 'Z', 'displayed': 'Z'},
                            {'button': 'E', 'latex': 'E', 'displayed': 'E'},
                            {'button': 'R', 'latex': 'R', 'displayed': 'R'},
                            {'button': 'T', 'latex': 'T', 'displayed': 'T'},
                            {'button': 'Y', 'latex': 'Y', 'displayed': 'Y'},
                            {'button': 'U', 'latex': 'U', 'displayed': 'U'},
                            {'button': 'I', 'latex': 'I', 'displayed': 'I'},
                            {'button': 'O', 'latex': 'O', 'displayed': 'O'},
                            {'button': 'P', 'latex': 'P', 'displayed': 'P'},

                            {'button': 'Q', 'latex': 'Q', 'displayed': 'Q'},
                            {'button': 'S', 'latex': 'S', 'displayed': 'S'},
                            {'button': 'D', 'latex': 'D', 'displayed': 'D'},
                            {'button': 'F', 'latex': 'F', 'displayed': 'F'},
                            {'button': 'G', 'latex': 'G', 'displayed': 'G'},
                            {'button': 'H', 'latex': 'H', 'displayed': 'H'},
                            {'button': 'J', 'latex': 'J', 'displayed': 'J'},
                            {'button': 'K', 'latex': 'K', 'displayed': 'K'},
                            {'button': 'L', 'latex': 'L', 'displayed': 'L'},
                            {'button': 'M', 'latex': 'M', 'displayed': 'M'},

                            {'button': 'W', 'latex': 'W', 'displayed': 'W'},
                            {'button': 'X', 'latex': 'X', 'displayed': 'X'},
                            {'button': 'C', 'latex': 'C', 'displayed': 'C'},
                            {'button': 'V', 'latex': 'V', 'displayed': 'V'},
                            {'button': 'B', 'latex': 'B', 'displayed': 'B'},
                            {'button': 'N', 'latex': 'N', 'displayed': 'N'},

                            {'button': '!', 'latex': '!', 'displayed': '!'},
                            {'button': ',', 'latex': ',', 'displayed': ','},
                            {'button': '.', 'latex': '.', 'displayed': '.'},
                            {'button': '?', 'latex': '?', 'displayed': '?'},

                            {'button': '⎵', 'latex': ' ', 'displayed': '\\hspace{5pt}', 'classes': ['spacebar']}
                        ]
                    }
                ]
            },
            options: {
                backspaceButton: true,                               /* enable of not backspace's button */
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                toggleTabButton: true,                               /* enable of not toggleTab's button */
                toggleTabButtonOptions: { // options for button toggle in keyboard
                    displayUnique: true // one tab can be used to switch between all keyboards (ABC, abc, 123, ...)
                },
                validateButton: true, /* enable of not validate's button */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
            },
        },
        alphabeticUppercase: {
            data: {
                tabs: [
                    {
                        'classes': ['alphabeticUppercase'],
                        'columns': 11,
                        'label': 'ABC',
                        'name': 'ABC',
                        'rows': 4,
                        'symbols': [
                            {'button': 'A', 'latex': 'A', 'displayed': 'A'},
                            {'button': 'Z', 'latex': 'Z', 'displayed': 'Z'},
                            {'button': 'E', 'latex': 'E', 'displayed': 'E'},
                            {'button': 'R', 'latex': 'R', 'displayed': 'R'},
                            {'button': 'T', 'latex': 'T', 'displayed': 'T'},
                            {'button': 'Y', 'latex': 'Y', 'displayed': 'Y'},
                            {'button': 'U', 'latex': 'U', 'displayed': 'U'},
                            {'button': 'I', 'latex': 'I', 'displayed': 'I'},
                            {'button': 'O', 'latex': 'O', 'displayed': 'O'},
                            {'button': 'P', 'latex': 'P', 'displayed': 'P'},

                            {'button': 'Q', 'latex': 'Q', 'displayed': 'Q'},
                            {'button': 'S', 'latex': 'S', 'displayed': 'S'},
                            {'button': 'D', 'latex': 'D', 'displayed': 'D'},
                            {'button': 'F', 'latex': 'F', 'displayed': 'F'},
                            {'button': 'G', 'latex': 'G', 'displayed': 'G'},
                            {'button': 'H', 'latex': 'H', 'displayed': 'H'},
                            {'button': 'J', 'latex': 'J', 'displayed': 'J'},
                            {'button': 'K', 'latex': 'K', 'displayed': 'K'},
                            {'button': 'L', 'latex': 'L', 'displayed': 'L'},
                            {'button': 'M', 'latex': 'M', 'displayed': 'M'},

                            {'button': 'W', 'latex': 'W', 'displayed': 'W'},
                            {'button': 'X', 'latex': 'X', 'displayed': 'X'},
                            {'button': 'C', 'latex': 'C', 'displayed': 'C'},
                            {'button': 'V', 'latex': 'V', 'displayed': 'V'},
                            {'button': 'B', 'latex': 'B', 'displayed': 'B'},
                            {'button': 'N', 'latex': 'N', 'displayed': 'N'},

                            {'button': '!', 'latex': '!', 'displayed': '!'},
                            {'button': ',', 'latex': ',', 'displayed': ','},
                            {'button': '.', 'latex': '.', 'displayed': '.'},
                            {'button': '?', 'latex': '?', 'displayed': '?'},

                            {'button': '⎵', 'latex': ' ', 'displayed': '\\hspace{5pt}', 'classes': ['spacebar']}
                        ]
                    }
                ]
            },
            options: {
                backspaceButton: true,                               /* enable of not backspace's button */
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                toggleTabButton: false,                               /* enable of not toggleTab's button */
                toggleTabButtonOptions: { // options for button toggle in keyboard
                    displayUnique: false // one tab can be used to switch between all keyboards (ABC, abc, 123, ...)
                },
                validateButton: true, /* enable of not validate's button */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
            },
        },
        numeric: {
            data: {
                tabs: [
                    {
                        'classes': ['primaire', 'numeric'],
                        'columns': 6,
                        'label': '123',
                        'name': '123 primaire',
                        'rows': 4,
                        'symbols': [
                            {'button': '0', 'latex': '0', 'displayed': '0', 'classes': ['active']},
                            {'button': '(', 'latex': '(', 'displayed': '('},
                            {'button': ')', 'latex': ')', 'displayed': ')'},
                            {'button': '+', 'latex': '+', 'displayed': '+'},
                            {'button': ',', 'latex': ',', 'displayed': ','},
                            {'button': '-', 'latex': '-', 'displayed': '-'},
                            {'button': '.', 'latex': '.', 'displayed': '.'},
                            {'button': '1', 'latex': '1', 'displayed': '1', 'classes': ['active']},
                            {'button': '2', 'latex': '2', 'displayed': '2', 'classes': ['active']},
                            {'button': '3', 'latex': '3', 'displayed': '3', 'classes': ['active']},
                            {'button': '4', 'latex': '4', 'displayed': '4', 'classes': ['active']},
                            {'button': '5', 'latex': '5', 'displayed': '5', 'classes': ['active']},
                            {'button': '6', 'latex': '6', 'displayed': '6', 'classes': ['active']},
                            {'button': '7', 'latex': '7', 'displayed': '7', 'classes': ['active']},
                            {'button': '8', 'latex': '8', 'displayed': '8', 'classes': ['active']},
                            {'button': '9', 'latex': '9', 'displayed': '9', 'classes': ['active']},
                            {'button': ':', 'latex': ':', 'displayed': ':'},
                            {'button': '<', 'latex': '<', 'displayed': '<'},
                            {'button': '=', 'latex': '=', 'displayed': '='},
                            {'button': '>', 'latex': '>', 'displayed': '>'},
                        ]
                    }
                ]
            },
            options: {
                backspaceButton: true,                               /* enable of not backspace's button */
                blacklistTabs: [],
                focusByDefaultDisplayed: true, /* focus first field to fill by default (no need to click first) */
                hideKeyboardButton: false,                               /* enable of not hideKeyboard's button */
                keepKeyboardDisplayed: true, /* keep keyboard displayed even if user click outside keyboard range */
                moveLeftButton: false,                               /* enable of not moveLeft's button */
                moveRightButton: false,                               /* enable of not moveRight's button */
                toggleTabButton: false,                               /* enable of not toggleTab's button */
                validateButton: true, /* enable of not validate's button */
                validateButtonClass: 'validate', /* class css of validate's button */
                validateButtonText: 'Validate', /* text of validate's button (it have one by default) */
            },
        }
    },
    typeActivitiesToSkip: ['EXT'], // (lesson player) skip activity if activity's type is included,
    useSummaryPageInsteadOfRecap: true,
    hideHeader: true,
    isStepperAllowedAllStep: true,
    showInfoToTeacherAfterAssignment: true,
    isListFormatStepper: true // stepper is in list format (mat-select)
};
