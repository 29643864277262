export const featureAccess = {
    showTechnicalProblemMessage: false,
    showWebAppBtn: false, // show or not button webapp on mathia toolbar
    institutionsGroupAccess: false,
    translationAccess: {
        show: true,
        withFlag: true
    },
    helpMenu: {
        default: true,
        learner: true,
    },
    showLangChoiceWhenPlayLesson: false,
    showAsksHeader: false, // an header with text and an external link
    useSelectedLangAsDefault: true,
    subscribeToLanguageChange: false,
    addIndexToSkiptocontent: true,
    setRootFilter: true,
    initClearFiltersOnFormChanges: false,
    removeChapterOfTypeContest: true,
    useExtraDataUrl: true,
    useBlockPathAlone: true,
    toolbarUseCtzVersion: true,
    useHelpPageCitizenLogic: true,
    useRootFilter: true,
    passOnlyIdToRoomLessons: true,
    extraDataUrlAllowed: true,
    iconFromPlayLessonInActivityCard: 'play-rounded-fill',
};
