import {Routes} from '@angular/router';
import {CompassThemesComponent} from 'fuse-core/components/compass/compass-themes/compass-themes.component';
import {CompassComponent} from 'fuse-core/components/compass/home/compass.component';
import {CompassResourceComponent} from 'fuse-core/components/compass/resources/compass-resource/compass-resource.component';
import {CompassResourcesComponent} from 'fuse-core/components/compass/resources/compass-resources/compass-resources.component';
import {IsUserLogged} from '../../../app/guards';

export const routes: Routes = [
    {
        path: 'boussole',
        component: CompassComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole/explore/:id',
        component: CompassThemesComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole/explore/:exploreId/themes/:themeId/ressources/:ressourceId',
        component: CompassResourcesComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole/explore/:exploreId/themes/:themeId/ressources/:ressourceId/ressource/:id',
        component: CompassResourceComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole/themes/:themeId/ressources/:ressourceId',
        component: CompassResourcesComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'boussole/themes/:themeId/ressources/:ressourceId/ressource/:id',
        component: CompassResourceComponent,
        canActivate: [IsUserLogged],
    }
];