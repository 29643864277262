import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BasicPageComponent} from './basic-page/basic-page.component';
import {ModalPageComponent} from './modal-page/modal-page.component';
import {FullPageComponent} from './full-page/full-page.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ButtonBlockComponent} from './custom-blocks/button-block/button-block.component';
import {FusePipesModule} from "fuse-core/pipes/pipes.module";
import {FieldBlockComponent} from './custom-blocks/field-block/field-block.component';
import {MatChipsModule} from "@angular/material/chips";
import {FuseDirectivesModule} from "fuse-core/directives/directives";
import {DynamicModule} from "fuse-core/components";
import {CollectionsBlockComponent} from './custom-blocks/collections-block/collections-block.component';
import {RouterLink} from "@angular/router";
import { BasicPageListAsCardsComponent } from './basic-page-list-as-cards/basic-page-list-as-cards.component';
import {MatCardModule} from '@angular/material/card';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslateModule,
        FlexLayoutModule,
        FusePipesModule,
        MatChipsModule,
        FuseDirectivesModule,
        DynamicModule,
        RouterLink,
        MatCardModule,
        NgOptimizedImage,
        FuseSharedModule,
    ],
    declarations: [
        BasicPageComponent,
        ModalPageComponent,
        FullPageComponent,
        ButtonBlockComponent,
        FieldBlockComponent,
        CollectionsBlockComponent,
        BasicPageListAsCardsComponent,
    ],
    exports: [
        BasicPageComponent,
        ModalPageComponent,
        FullPageComponent
    ],
    providers: [
        BasicPageService
    ]
})
export class BasicPageModule {
}