import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity} from 'octopus-connect';
import {debounceTime, mergeMap, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {ReplaySubject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';

const ID_TYPE_CLASS = '2';
const REGEX = /^[0-9]+$/;

@Component({
    selector: 'app-join-group',
    templateUrl: './join-group.component.html'
})

/**
 * permit to join a school
 * good to know : school code is in reality store as a group
 * but not send with groups or workgroups list but with institution.
 * to store ans save it we change the group list of the user and back dispatch in regard of if
 * id is a group an institution or a workgroup
 */
export class JoinGroupComponent implements OnInit {
    public hasJoinASchool = false;
    public userSchool: {};
    public userSchools = [];
    public errorMessage = '';
    public loading = false;
    public codeControl: UntypedFormControl;
    @Output() groupJoined: EventEmitter<string> = new EventEmitter<string>();

    constructor(private profileService: ProfileService,
                private communicationCenter: CommunicationCenterService,
                private dialog: MatDialog,
                private translate: TranslateService) {
        this.getUserSchoolCode();
    }

    ngOnInit(): void {
        this.codeControl = new UntypedFormControl();
        this.codeControl.valueChanges.pipe(
            debounceTime(200),
            tap((value: string) => {
                this.codeControl.setErrors(null);

                if (value && !REGEX.test(value)) {
                    this.codeControl.markAsTouched();
                    this.codeControl.markAsDirty();
                    this.codeControl.setErrors({invalidFormat: true});
                } else if (this.userSchools.filter(u => u.id.toString() === this.codeControl.value).length > 0) {
                    // already join
                    this.codeControl.markAsTouched();
                    this.codeControl.markAsDirty();
                    this.codeControl.setErrors({alreadyJoin: true});
                } else {
                    this.codeControl.setErrors(null);
                }
            })
        ).subscribe();
    }

    /**
     * prepare data to store it in a friendly format (code class id and label) for the different class of user
     * use data already existing be carefull join and leave a group need to force a refresh
     * because this data are loaded only when app is launched
     * @private
     */
    private getUserSchoolCode(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList')
            .pipe(take(1))
            .subscribe(inst => {
                if (inst && inst.length > 0) {
                    this.userSchool = inst[0];
                    this.userSchools = inst;
                }
            });
    }

    public joinSchool(): void {
        if (!this.codeControl.errors && REGEX.test(this.codeControl.value)) {
            if (this.userSchools.filter(u => u.id.toString() === this.codeControl.value).length > 0) {
                return; // already join
            }
            this.loading = true;
            this.errorMessage = '';
            //ctz-settings
            if (this.profileService.getAllowedMultiplesGroups()) {
                const allGroups = [this.codeControl.value];
                // load group id and label and code class corresponding to code class use
                this.loadGroupsByCode(allGroups)
                    .pipe(take(1))
                    .subscribe((institution: DataEntity) => {
                        this.userSchool = {'code': institution.get('code'), 'groupname': institution.get('label'), 'id': institution.id.toString()};
                        this.userSchools.push(this.userSchool);
                        this.profileService.userInformation.set('groups', this.userSchools.map(u => u.id));
                        this.codeControl.setErrors(null);
                        this.codeControl.reset();
                        this.loading = false;
                    }, error => {
                        this.codeControl.setErrors({cantJoinInstitution: true});
                        this.loading = false;
                    });
            } else {
                // load group id and label and code class corresponding to code class use
                this.loadGroupsByCode(this.codeControl.value)
                    .pipe(take(1))
                    .subscribe((institution: DataEntity) => {
                        this.userSchool = {'code': institution.get('code'), 'groupname': institution.get('label'), 'id': institution.id};
                        this.profileService.userInformation.set('groups', [institution.id.toString()]);
                        this.codeControl.setErrors({invalidCode: false});
                        this.loading = false;
                    }, error => {
                        this.codeControl.setErrors({cantJoinInstitution: true});
                        this.loading = false;
                    });
            }
        }
    }

    public leaveGroup(id: string): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: undefined,
        };


        dialogConfig.data.bodyDialog = this.translate.instant('account-management.join_group_leave_confirm');
        dialogConfig.data.labelTrueDialog = this.translate.instant('generic.yes');
        dialogConfig.data.labelFalseDialog = this.translate.instant('generic.cancel');

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                panelClass: 'lesson-form-dialog',
                data: dialogConfig.data
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //ctz-setting
                if (this.profileService.getUseleaveGroupEvent()) {
                    const groupsArray = this.profileService.userInformation.get('groups').map(num => num.toString());
                    if (groupsArray && groupsArray.indexOf(id.toString()) > -1) {
                        groupsArray.splice(groupsArray.indexOf(id), 1);

                        // envoyer ce qui reste comme groupe?
                        this.userSchools = this.userSchools.filter(group => group.id !== id);
                        this.profileService.userInformation.set('groups', this.userSchools.map(u => u.id.toString()));
                        this.profileService.editUser(this.profileService.userInformation.attributes);
                        this.userSchool = null;

                        // delete also from userData in group service
                        this.communicationCenter.getRoom('groups-management')
                            .next('leaveGroup', {
                                id: id.toString()
                            });
                    }
                } else {
                    const groupsArray = this.profileService.userInformation.get('groups');
                    if (groupsArray && groupsArray.indexOf(id.toString()) > -1) {
                        groupsArray.splice(groupsArray.indexOf(id), 1);
                        this.profileService.userInformation.set('groups', [null]);
                        this.profileService.editUser(this.profileService.userInformation.attributes);
                        this.userSchool = null;
                    }
                }
            }
        });
    }

    /**
     * load the groups by code data are formated and contain id label and code
     * @param code id of group in string format
     * @private
     */
    private loadGroupsByCode(code: string | any[]): Observable<DataEntity> {
        const subject = new ReplaySubject<Observable<DataEntity>>(1);
        this.communicationCenter.getRoom('groups-management')
            .next('joinGroup', {
                id: code,
                callback: (institution: Observable<DataEntity>): void => {
                    subject.next(institution);
                }
            });
        // for reduce to only a simple observable
        return subject.pipe(mergeMap(obs => obs));
    }


    /**
     * force refresh data of list of institution (school)
     * because this data are loaded only when app is launched and only at this time
     * except if we force to reload them
     * @private
     */
    private refreshData(): void {
        this.communicationCenter
            .getRoom('groups-management')
            .getSubject<void>('refresh-institutionList')
            .next();
    }

    private editInstitutionRight(institution: DataEntity): Observable<DataEntity> {
        const admins = institution.get('admins').slice();
        const userRightInInstitution = admins.find((user) => +user.uid === +this.profileService.userInformation.id);
        if (userRightInInstitution) {
            userRightInInstitution.roles = {4: 'educator'};
        } else {
            admins.push({
                uid: this.profileService.userInformation.id.toString(),
                roles: {
                    4: 'educator',
                }
            });
        }
        institution.set('admins', admins);
        return institution.save(true);
    }
}