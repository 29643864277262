// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  width: 400px;
}
.content .title {
  text-align: center;
  font-weight: bold;
}
.content .error-container {
  max-height: 250px;
  min-height: 50px;
}
.content .buttons {
  text-align: center;
}
.content .buttons input {
  margin-right: 10px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/browser-test/core/modals/generic-error-modal/generic-error-modal.component.scss","webpack://./../ISC%20Lyc%C3%A9e/src/app/@modules/browser-test/core/modals/generic-error-modal/generic-error-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;ACCF;ADCE;EACE,kBAAA;EACA,iBAAA;ACCJ;ADEE;EACE,iBAAA;EACA,gBAAA;ACAJ;ADGE;EACE,kBAAA;ACDJ;ADGI;EACE,kBAAA;EACA,iBAAA;ACDN","sourcesContent":[".content {\n  width: 400px;\n\n  .title {\n    text-align: center;\n    font-weight: bold;\n  }\n\n  .error-container {\n    max-height: 250px;\n    min-height: 50px;\n  }\n\n  .buttons {\n    text-align: center;\n\n    input {\n      margin-right: 10px;\n      margin-left: 10px;\n    }\n  }\n}",".content {\n  width: 400px;\n}\n.content .title {\n  text-align: center;\n  font-weight: bold;\n}\n.content .error-container {\n  max-height: 250px;\n  min-height: 50px;\n}\n.content .buttons {\n  text-align: center;\n}\n.content .buttons input {\n  margin-right: 10px;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
